import React from 'react';
import Text from 'components/snaq-ui/content/text';

const TextTastic = ({ data }) => {
  return (
    <Text
      background={data.background}
      backgroundWidth={data.backgroundWidth}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
      splitContent={data.splitContent}
      textAlignment={data.textAlignment}
      title={data.title}
      text={data.text}
      ctaLabel={data.ctaLabel}
      ctaReference={data.ctaReference}
      ctaTitle={data.ctaTitle}
      ctaRel={data.ctaRel}
    />
  );
};

export default TextTastic;
