import React from 'react';
import PageTeasersSlider, { PageTeaserSlide } from 'components/snaq-ui/content/page-teasers-slider';

type Props = {
  title?: string;
  pageTeaserSlides: Array<PageTeaserSlide>;
  width?: string;
  height?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
};

const ProfileTeasersTastic = ({ id, data }: { id: string; data: Props }) => {
  const { title, pageTeaserSlides, width, height, background, innerSpacing, outerSpacing } = data;

  if (!pageTeaserSlides) return <></>;

  return (
    <PageTeasersSlider
      tasticId={id}
      title={title}
      items={pageTeaserSlides}
      width={width}
      height={height}
      background={background}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
    />
  );
};

export default ProfileTeasersTastic;
