import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import ProductList from 'components/snaq-ui/products/product-list';
import getCanonical from '../../../../../helpers/getCanonical';
import { useCategory } from '../../../../provider';

function ProductListTastic({ data }) {
  const router = useRouter();
  const canonicalUrl = getCanonical(router.asPath);
  const { facets: urlFacets, cursor: urlCursor } = router.query;

  const { items, facets, category, previousCursor, nextCursor, total, offset, query } = data?.data?.dataSource;
  const { setLastCategoryKey, removeLastCategoryKey } = useCategory();
  useEffect(() => {
    if (query?.category) {
      setLastCategoryKey(query.category);
    } else {
      removeLastCategoryKey();
    }
  }, [query?.category, setLastCategoryKey, removeLastCategoryKey]);

  if (!data?.data?.dataSource) return <></>;

  return (
    <>
      {urlFacets?.length || urlCursor?.length ? (
        <Head>
          <meta name="robots" content="noindex, follow" />
        </Head>
      ) : (
        <Head>
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href={canonicalUrl} />
        </Head>
      )}
      <ProductList
        products={items}
        totalProducts={total}
        offset={offset}
        facets={facets}
        category={category}
        previousCursor={previousCursor}
        nextCursor={nextCursor}
        background={data.background}
        innerSpacing={data.innerSpacing}
        outerSpacing={data.outerSpacing}
      />
    </>
  );
}

export default ProductListTastic;
