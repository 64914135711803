import React from 'react';
import CategoryTeasers from 'components/snaq-ui/content/category-teasers';
import { useCategories } from '../../../../provider';

const CategoryTeasersTastic = ({ id, data }) => {
  const { data: categories } = useCategories();

  if (!data?.categoryTeasers) return <></>;

  return (
    <CategoryTeasers
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
      tasticId={id}
      items={data.categoryTeasers}
      categories={categories || []}
    />
  );
};

export default CategoryTeasersTastic;
