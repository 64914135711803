import React, { useEffect, useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { fetchApiHub } from '../../../../../frontastic';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import { PaymentProvider } from '../payment';

interface Props {
  currentPaymentProvider: PaymentProvider | null;
  updatePaymentProviderHandler: (paymentProvider: PaymentProvider | null) => void;
  readyHandler: (stripeReady: boolean) => void;
}

const CheckoutForm: React.FC<Props> = ({ currentPaymentProvider, updatePaymentProviderHandler, readyHandler }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  useEffect(() => {
    if (!elements) {
      return;
    }
    if (currentPaymentProvider !== PaymentProvider.stripe) {
      setIsResetting(true);
      const paymentElement = elements.getElement('payment');
      if (paymentElement) {
        paymentElement.clear();
        paymentElement.collapse();
      }
      setTimeout(() => setIsResetting(false), 300);
    }
  }, [elements, currentPaymentProvider]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error: submitError } = await elements.submit();

    if (submitError) {
      setMessage(submitError.message);
      setIsLoading(false);
      return;
    }

    const { clientSecret } = await fetchApiHub(`/action/stripe/create-payment-intent`);

    if (!clientSecret) {
      setIsLoading(false);
      setMessage('An unexpected error occured.');
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}/checkout-return`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occured.');
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {/*
      <LinkAuthenticationElement
        id="link-authentication-element"
        // Access the email value like so:
        // onChange={(event) => {
        //  setEmail(event.value.email);
        // }}
        //
        // Prefill the email field like so:
        options={{ defaultValues: { email: 'foo@bar.com' } }}
      />
      */}
      <PaymentElement
        id="payment-element"
        options={{
          paymentMethodOrder: ['apple_pay', 'google_pay', 'card'],
          layout: {
            type: 'accordion',
            defaultCollapsed: true,
            radios: true,
            spacedAccordionItems: true,
          },
        }}
        onChange={() => {
          if (isResetting) {
            return;
          }
          updatePaymentProviderHandler(PaymentProvider.stripe);
        }}
        onReady={() => {
          updatePaymentProviderHandler(null);
          readyHandler(true);
        }}
      />
      {currentPaymentProvider === PaymentProvider.stripe && (
        <>
          <button disabled={isLoading || !stripe || !elements} id="submit" className="btn btn-primary my-3">
            <span id="button-text">
              {isLoading ? (
                <div className="spinner-border" id="spinner" role="status">
                  <span className="visually-hidden">
                    {formatMessage({ id: 'loading', defaultMessage: 'Loading' })}...
                  </span>
                </div>
              ) : (
                formatCheckoutMessage({ id: 'placeOrder', defaultMessage: 'Place order now' })
              )}
            </span>
          </button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </>
      )}
    </form>
  );
};

export default CheckoutForm;
