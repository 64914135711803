import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-library-books ${className}`}
    viewBox="0 0 15 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 9h3V7.5H6V9zm0-2.2h6V5.2H6v1.6zm0-2.3h6V3H6v1.5zM4.5 12c-.4 0-.8-.1-1.1-.4-.3-.3-.4-.7-.4-1.1v-9c0-.4.1-.8.4-1.1.3-.3.7-.4 1.1-.4h9c.4 0 .8.1 1.1.4.3.3.4.7.4 1.1v9c0 .4-.1.8-.4 1.1-.3.3-.6.4-1.1.4h-9zm0-1.5h9v-9h-9v9zm-3 4.5c-.4 0-.8-.1-1.1-.4-.3-.3-.4-.7-.4-1.1V3h1.5v10.5H12V15H1.5z" />
  </svg>
);

export default Icon;
