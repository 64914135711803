import { useMemo } from 'react';
import { Reference, ReferenceLink } from 'helpers/reference';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';

export interface Props {
  size?: string;
  ctaLink: Reference;
  image: MediaItemWithMods;
  badge?: string;
  text?: string;
}

const Entry: React.FC<Props> = ({ size, ctaLink, image, badge, text }) => {
  const entryClasses = useMemo(() => {
    let cssClass = 'teaser-card position-relative rounded overflow-hidden text-white d-block w-100';
    if (size === 'large') return (cssClass += ' teaser-card--large');
    return cssClass;
  }, [size]);

  const badgeClasses = useMemo(() => {
    let cssClass = 'teaser-card__badge position-absolute rounded-start mob-c-c4 end-0 top-0 bg-grey-100 p-2 mt-20';
    if (size === 'large') return (cssClass += ' dt-h-h2 mt-xl-40 px-xl-20 py-xl-10');
    cssClass += ' dt-c-c4';
    return cssClass;
  }, [size]);

  const textClasses = useMemo(() => {
    let cssClass = 'teaser-card__text position-absolute bottom-0 start-0 w-100 fw-bold mob-p-p2 m-0 p-20';
    if (size === 'large') return (cssClass += ' dt-h-h1 m-0 p-20 p-xl-40');
    cssClass += ' dt-p-p2';
    return cssClass;
  }, [size]);

  return (
    <ReferenceLink target={ctaLink} className={entryClasses}>
      <div className="teaser-card__image">
        <Image media={image} alt={image.media.name} layout="fill" className="object-fit-cover" />
      </div>
      {badge && <p className={badgeClasses}>{badge}</p>}
      {text && <p className={textClasses}>{text}</p>}
    </ReferenceLink>
  );
};

export default Entry;
