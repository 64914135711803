export type ProductSpecialDetailsProps = {
  title: string;
  description?: string;
};

export default function ProductSpecialDetails({ tasticId, items, background, innerSpacing, outerSpacing }) {
  const bgBlack = background === 'bg-black-full';

  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      <div className={`row justify-content-center row-gap-20 text-center ${bgBlack ? 'text-white' : ''}`}>
        {items.map((item: ProductSpecialDetailsProps, index: number) => (
          <div key={`${tasticId}${index}`} className="col-6 col-md-4">
            <div className="mob-h-h3 dt-h-h3 mb-10 mb-xl-15">{item.title}</div>
            {item.description && (
              <div className={`mob-h-h6 dt-h-h6 text-uppercase ${bgBlack ? 'text-grey-30' : ''}`}>
                {item.description}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
