import React, { useState } from 'react';
import NextLink from 'next/link';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import MultistepCheckout from 'components/snaq-ui/multistep-checkout';
import { getReferenceTarget, getTargetProps } from '../../../../helpers/reference';
import { useAccount } from '../../../provider';

const MultistepCheckoutTastic = ({ data }) => {
  const { loggedIn } = useAccount();

  const [orderAsGuest, setOrderAsGuest] = useState(false);

  if (!orderAsGuest && !loggedIn) {
    return (
      <div className="section-spacing">
        <Row>
          <Col xs={12} lg={4} className="d-flex mb-20">
            <div className="bg-grey-10 p-20 rounded d-flex flex-column w-100">
              <h2 className="h4 fw-bold mb-3 font-bold">{data.headlineGuest}</h2>
              <p className="mb-4">{data.descriptionGuest}</p>
              <hr className="mt-auto mb-4" />
              <button type="button" onClick={() => setOrderAsGuest(true)} className="btn btn-outline btn-lg">
                {data.buttonGuest}
              </button>
            </div>
          </Col>
          <Col xs={12} lg={4} className="d-flex mb-20">
            <div className="bg-grey-10 p-20 rounded d-flex flex-column w-100">
              <h2 className="h4 fw-bold mb-3 font-bold">{data.headlineLogin}</h2>
              <p className="mb-4">{data.descriptionLogin}</p>
              <hr className="mt-auto mb-4" />
              <NextLink href={getReferenceTarget(data.loginLink) + '?redirect=' + encodeURIComponent('/checkout')}>
                <a className="btn btn-primary btn-lg" {...getTargetProps(data.loginLink)}>
                  {data.buttonLogin}
                </a>
              </NextLink>
            </div>
          </Col>
          <Col xs={12} lg={4} className="d-flex mb-20">
            <div className="bg-grey-10 p-20 rounded d-flex flex-column w-100">
              <h2 className="h4 fw-bold mb-3 font-bold">{data.headlineRegister}</h2>
              <p className="mb-4">{data.descriptionRegister}</p>
              <hr className="mt-auto mb-4" />
              <NextLink href={getReferenceTarget(data.registerLink) + '?redirect=' + encodeURIComponent('/checkout')}>
                <a className="btn btn-primary btn-lg" {...getTargetProps(data.registerLink)}>
                  {data.buttonRegister}
                </a>
              </NextLink>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <MultistepCheckout
      termsLink={data.termsLink}
      privacyLink={data.privacyLink}
      paypalCredentials={data.paypalCredentials}
      stripeCredentials={data.stripeCredentials}
    />
  );
};

export default MultistepCheckoutTastic;
