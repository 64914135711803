import React from 'react';
import classnames from 'classnames';
import { default as IconChevronRight } from 'components/icons/chevron-right';
import useI18n from 'helpers/hooks/useI18n';
import { Reference, ReferenceLink } from 'helpers/reference';
import Image from 'frontastic/lib/image';
import { PageNodeTeaser } from '../../../../frontastic/tastics/snaq/categories/category-experts';
import { sm, lg } from '../../../../helpers/utils/screensizes';
import Slider, { SliderProps } from '../../slider';

export interface Props {
  tasticId: string;
  title: string;
  items: PageNodeTeaser[];
  ctaLabel?: string;
  ctaLink?: Reference;
  ctaTitle?: string;
  ctaRel?: string;
  sliderSettings?: object;
  width?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
  size?: string;
}

export default function ProfileTeasers({
  tasticId,
  title,
  items,
  ctaLabel,
  ctaLink,
  ctaTitle,
  ctaRel,
  sliderSettings,
  width,
  background,
  innerSpacing,
  outerSpacing,
  size,
}: Props) {
  const { t } = useI18n();
  const defaultSettings = {
    slidesPerView: 1.25,
    breakpoints: {
      [sm]: {
        slidesPerView: 2,
      },
      [lg]: {
        slidesPerView: 3,
      },
    },
    arrows: true,
    dots: false,
  };
  const sliderFixedMood: SliderProps = sliderSettings ? sliderSettings : defaultSettings;
  const sliderConfiguration: SliderProps = sliderFixedMood;

  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      {title && (
        <div className="d-flex justify-content-between align-items-end mb-20">
          {title && <h2 className="h1 products-slider__title mb-0">{title}</h2>}
          {ctaLabel && ctaLink && (
            <ReferenceLink
              target={ctaLink}
              title={ctaTitle}
              rel={ctaRel}
              className="products-slider__link link-reset dt-c-c2 mb-1"
            >
              <span className="d-none d-xl-block">{ctaLabel}</span>
              {IconChevronRight({})}
            </ReferenceLink>
          )}
        </div>
      )}
      <Slider
        {...sliderConfiguration}
        className={classnames(
          'swiper--mobile-full profile-slider',
          width === 'container-window-edge' && 'swiper--container-window-edge',
        )}
      >
        {items.map(
          (item: PageNodeTeaser, index: number) =>
            item.image && (
              <div key={`${tasticId}${index}`} className="d-flex w-100">
                {size === 'large' ? (
                  <div className="w-100">
                    <div className="row align-items-center">
                      <div className="col-12 col-6 col-md-6 ps-md-100 pe-md-25 pe-lg-50 pb-20 pb-md-0">
                        <div className="ratio ratio-4x3 rounded overflow-hidden">
                          <Image
                            media={item.image}
                            alt={item.image.media.name}
                            layout="fill"
                            className="object-fit-cover"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 ps-md-25 ps-lg-50 pe-md-100">
                        {item.title && <h3 className="h2">{item.title}</h3>}
                        {item.description && <p className="mob-p-p3 dt-p-p3">{item.description}</p>}
                        {item.ctaLabel && item.ctaReference && (
                          <ReferenceLink
                            target={item.ctaReference}
                            title={item.ctaTitle}
                            rel={item.ctaRel}
                            className="btn btn-primary"
                          >
                            {t(item.ctaLabel)}
                          </ReferenceLink>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-column w-100 rounded overflow-hidden bg-white border border-grey-20">
                    <Image media={item.image} alt={item.image.media.name} className="object-fit-cover" />
                    <div className="pt-20 pb-20 px-20 flex-grow-1 d-flex flex-column ">
                      {item.title && <div className="mob-p-p3 dt-p-p3 text-grey-90">{item.title}</div>}
                      {item.description && (
                        <div className="mob-p-p3 dt-p-p3 fw-medium text-grey-100 mt-10">{item.description}</div>
                      )}
                      {item.ctaLabel && item.ctaReference && (
                        <div className="mt-auto">
                          <ReferenceLink
                            target={item.ctaReference}
                            title={item.ctaTitle}
                            rel={item.ctaRel}
                            className="btn btn-sm w-100 btn-secondary mt-20"
                          >
                            {t(item.ctaLabel)}
                          </ReferenceLink>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ),
        )}
      </Slider>
    </div>
  );
}
