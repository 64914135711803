import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-copy ${className}`}
    viewBox="0 0 17 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 16c-.5 0-1-.2-1.4-.6-.4-.4-.6-.8-.6-1.4V2c0-.6.2-1 .6-1.4C5 .2 5.5 0 6 0h9c.6 0 1 .2 1.4.6.4.4.6.8.6 1.4v12c0 .6-.2 1-.6 1.4-.4.4-.8.6-1.4.6H6zm0-2h9V2H6v12zm-4 6c-.6 0-1-.2-1.4-.6C.2 19 0 18.6 0 18V5c0-.3.1-.5.3-.7S.7 4 1 4s.5.1.7.3.3.4.3.7v13h10c.3 0 .5.1.7.3.2.2.3.4.3.7s-.1.5-.3.7c-.2.2-.4.3-.7.3H2z" />
  </svg>
);

export default Icon;
