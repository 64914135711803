import { Money } from '@Types/product/Money';
import Typography from 'components/snaq-ui/typography';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';

export interface PriceProps {
  price: Money;
  className?: string;
  showFreeText?: boolean;
}

const Price: React.FC<PriceProps> = ({ price, className, showFreeText }) => {
  const { formatMessage } = useFormat({ name: 'checkout' });

  if (!price) {
    return (
      <div className={className || ''}>
        <Typography>&mdash;</Typography>
      </div>
    );
  }
  const _price = { currencyCode: 'EUR', ...price };
  const text =
    showFreeText && price?.centAmount === 0
      ? formatMessage({ id: 'freeShipping', defaultMessage: 'Free' })
      : CurrencyHelpers.formatForCurrency(_price);

  return (
    <div className={className || ''}>
      <Typography>{text}</Typography>
    </div>
  );
};

export default Price;
