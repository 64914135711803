import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Disclosure } from '@headlessui/react';
import { default as IconChevronDown } from 'components/icons/chevron-down';
import { default as IconMinus } from 'components/icons/minus';
import { default as IconPlus } from 'components/icons/plus';
import { useFormat } from 'helpers/hooks/useFormat';
import { URLParam } from 'helpers/utils/updateURLParams';

type SortingDisclosureProps = {
  updateSortingParams: (param: URLParam) => void;
};

type sortingParamID = 'asc' | 'desc' | 'relevance';

const SortingDisclosure: FC<SortingDisclosureProps> = ({ updateSortingParams }) => {
  const router = useRouter();
  const [currentSortingParam, setCurrentSortingParam] = useState<sortingParamID>(undefined);
  const { formatMessage } = useFormat({ name: 'product' });

  const handleChange = (e: ChangeEvent) => {
    setCurrentSortingParam(e.target.id as sortingParamID);

    updateSortingParams({
      key: 'sortAttributes[0][price]',
      value: e.target.id,
    });
  };

  // NOTE: "relevance" value used to handle absence of any specific sorting
  // (only works b/c "price" currently the only sorting param)
  const options: { label: string; value: string }[] = useMemo(
    () => [
      {
        label: formatMessage({ id: 'relevance', defaultMessage: 'Relevance' }),
        value: 'relevance',
      },
      {
        label: formatMessage({ id: 'priceAsc', defaultMessage: 'Price (ascending)' }),
        value: 'asc',
      },
      { label: formatMessage({ id: 'priceDesc', defaultMessage: 'Price (descending)' }), value: 'desc' },
    ],
    [formatMessage],
  );

  useEffect(() => {
    let defaultSortingParamID = router?.query['sortAttributes[0][price]'] as sortingParamID;
    if (!defaultSortingParamID) {
      if (router?.query['sort'] === 'price:asc') {
        defaultSortingParamID = 'asc';
      } else if (router?.query['sort'] === 'price:desc') {
        defaultSortingParamID = 'desc';
      } else {
        defaultSortingParamID = 'relevance';
      }
    }
    setCurrentSortingParam(defaultSortingParamID);
  }, [router?.query]);

  const currentSortingLabel = useMemo(() => {
    const param = options.find((option) => option.value === currentSortingParam);
    return param?.label ?? null;
  }, [options, currentSortingParam]);

  return (
    <>
      <div className="d-lg-none">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="disclosure-filter-btn pe-lg-30 disclosure-filter-btn--sorting">
                {formatMessage({ id: 'sortBy', defaultMessage: 'Sort by' })}
                {open ? IconMinus({ className: 'd-block d-lg-none' }) : IconPlus({ className: 'd-block d-lg-none' })}
                {currentSortingLabel && (
                  <>
                    <span className="d-none d-lg-block">:</span>
                    {/* Current active filter */}
                    <span className="d-none d-lg-block ms-lg-2 fw-bold">{currentSortingLabel}</span>
                  </>
                )}
                {IconChevronDown({ className: 'd-none d-lg-block' })}
              </Disclosure.Button>
              <Disclosure.Panel className="disclosure-filter-panel disclosure-filter-panel--sorting">
                <div className="disclosure-filter-panel__inner">
                  {options.map(({ label, value }, index) => (
                    <div className="form-check form-switch" key={index}>
                      <input
                        id={value}
                        name="price"
                        type="radio"
                        className="form-check-input"
                        onChange={handleChange}
                        checked={value === currentSortingParam}
                      />
                      <label className="form-check-label">{label}</label>
                    </div>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
      <div className="d-none d-lg-block">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="disclosure-filter-btn pe-lg-30 disclosure-filter-btn--sorting">
                {formatMessage({ id: 'sortBy', defaultMessage: 'Sort by' })}
                {open ? IconMinus({ className: 'd-block d-lg-none' }) : IconPlus({ className: 'd-block d-lg-none' })}
                {currentSortingLabel && (
                  <>
                    <span className="d-none d-lg-block">:</span>
                    {/* Current active filter */}
                    <span className="d-none d-lg-block ms-lg-2 fw-bold">{currentSortingLabel}</span>
                  </>
                )}
                {IconChevronDown({ className: 'd-none d-lg-block' })}
              </Disclosure.Button>
              <Disclosure.Panel className="dropdown">
                {({ close }) => (
                  <ul className="dropdown-menu show position-absolute end-0 mt-10">
                    {options.map(({ label, value }, index) => (
                      <li key={index}>
                        <div className="dropdown-item dropdown-item--check">
                          <input
                            id={value}
                            name="price"
                            type="radio"
                            className=""
                            onChange={(e) => {
                              handleChange(e);
                              close();
                            }}
                            checked={value === currentSortingParam}
                          />
                          <label htmlFor={value}>{label}</label>
                        </div>
                        {options.length - 1 !== index && <hr className="m-0" />}
                      </li>
                    ))}
                  </ul>
                )}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
};

export default SortingDisclosure;
