import React from 'react';
import TextAndMediaTwice from 'components/snaq-ui/content/text-and-media-twice';

const TextAndMediaTwiceTastic = ({ data }) => {
  return (
    <TextAndMediaTwice
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
      titleLeft={data.titleLeft}
      textLeft={data.textLeft}
      mediaLeft={data.mediaLeft}
      titleRight={data.titleRight}
      textRight={data.textRight}
      mediaRight={data.mediaRight}
    />
  );
};

export default TextAndMediaTwiceTastic;
