import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { default as IconMailCheck } from 'components/icons/mail-check';
import { useFormat } from 'helpers/hooks/useFormat';

export interface NewsletterProps {
  headline?: string;
  description?: string;
  inputPlaceholder?: string;
  ctaLabel?: string;
  successTitle?: string;
  successMessage?: string;
  disclaimer?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

export default function Newsletter({
  headline,
  description,
  inputPlaceholder,
  ctaLabel,
  disclaimer,
  successTitle,
  successMessage,
  background,
  innerSpacing,
  outerSpacing,
}: NewsletterProps) {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatNewsletterMessage } = useFormat({ name: 'newsletter' });

  //messages
  const fallbackHeadline = formatNewsletterMessage({
    id: 'headline.fallback',
    defaultMessage: 'Receive the latest fashion news!',
  });

  const fallbackDescription = formatNewsletterMessage({
    id: 'description.fallback',
    defaultMessage: 'Subscribe to our newsletter to get notified every time we launch a new collection.',
  });

  const fallbackPlaceholder = formatNewsletterMessage({
    id: 'placeholder.fallback',
    defaultMessage: 'Your email here',
  });

  const fallbackCta = formatNewsletterMessage({
    id: 'cta.fallback',
    defaultMessage: 'Subscribe',
  });

  const fallbackDisclaimer = formatNewsletterMessage({
    id: 'disclaimer.fallback',
    defaultMessage:
      "By clicking 'Submit' you agree that we may use your information in accordance with our privacy policy process the data for a specific purpose",
  });

  const fallbackSuccessTitle = formatNewsletterMessage({
    id: 'success.fallback.title',
    defaultMessage: 'Welcome aboard!',
  });
  const fallbackSuccessMessage = formatNewsletterMessage({
    id: 'success.fallback.message',
    defaultMessage: 'You’re subscribed. We’ll keep you up to date with all things fashion.',
  });

  //form data
  const [data, setData] = useState({ email: '' });
  // confirmation
  const [isConfirm, setIsConfirm] = useState(false);

  //input change handler
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  //submission handler
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    //validate
    const isValid = !!data.email;
    if (!isValid) {
      return alert(formatErrorMessage({ id: 'enter.email', defaultMessage: 'Please enter your email address' }));
    }
    //success
    //Add newsletter subscription logic here
    setIsConfirm(true);
  };

  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      <div className="newsletter">
        {!isConfirm ? (
          <Row className="justify-content-between">
            <Col xs={12} lg={4} xl={5}>
              <h2 className="h1 text-center text-lg-start newsletter__title">{headline || fallbackHeadline}</h2>
              <p className="mob-p-p2 dt-p-p2 text-center text-lg-start mb-xl-0">{description || fallbackDescription}</p>
            </Col>
            <Col xs={12} lg={7} xl={6}>
              <Form action="#" onSubmit={onSubmit}>
                <Row>
                  <Col xs={12} md={7} xxl={8} className="mb-10">
                    <input
                      type="email"
                      name="email"
                      className="form-control form-control-lg form-control-dark"
                      placeholder={inputPlaceholder || fallbackPlaceholder}
                      onChange={onChange}
                      required
                    />
                  </Col>
                  <Col xs={12} md={5} xxl={4} className="mb-10">
                    <Button variant="primary" size="lg" type="submit" className="w-100">
                      {ctaLabel || fallbackCta}
                    </Button>
                  </Col>
                </Row>
              </Form>
              <p className="mob-f-f4 dt-f-f4 mb-0 pt-xl-10">{disclaimer || fallbackDisclaimer}</p>
            </Col>
          </Row>
        ) : (
          <div className="text-center">
            <div className="d-flex justify-content-center mb-3">
              <div className="newsletter__icon">{IconMailCheck({ className: 'h-px-40 d-block' })}</div>
            </div>
            <h2 className="h1">{successTitle || fallbackSuccessTitle}</h2>
            <p className="mob-p-p2 dt-p-p2 mb-0">{successMessage || fallbackSuccessMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
}
