import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-search ${className}`}
    viewBox="0 0 21 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 20.7 12.3 14c-.6.5-1.3.9-2.1 1.1-.8.3-1.6.4-2.5.4-2.2 0-4-.8-5.5-2.3C.8 11.8 0 9.9 0 7.8s.8-4 2.3-5.5C3.8.8 5.6 0 7.8 0s4 .8 5.5 2.3c1.5 1.5 2.3 3.3 2.3 5.5 0 .9-.1 1.7-.4 2.5-.3.8-.7 1.5-1.1 2.1l6.7 6.7c.2.2.3.5.3.8 0 .3-.1.6-.4.8-.2.2-.5.3-.8.3-.4 0-.7-.1-.9-.3zM7.8 13.1c1.5 0 2.8-.5 3.8-1.6s1.6-2.3 1.6-3.8S12.6 5 11.6 4 9.2 2.4 7.8 2.4 5 2.9 4 4 2.4 6.3 2.4 7.8s.5 2.8 1.6 3.8 2.3 1.5 3.8 1.5z" />
  </svg>
);

export default Icon;
