import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-chevron-right ${className}`}
    viewBox="0 0 31 54"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.1 1.1l24.1 24c0.3 0.3 0.5 0.6 0.6 0.9c0.1 0.3 0.2 0.7 0.2 1.1s-0.1 0.7-0.2 1.1c-0.1 0.3-0.3 0.7-0.6 0.9 L6.1 53c-0.7 0.7-1.5 1-2.5 1s-1.8-0.3-2.6-1.1S0 51.4 0 50.4s0.3-1.8 1.1-2.5l21-20.9L1.1 6.1c-0.7-0.7-1-1.5-1-2.4 s0.3-1.8 1.1-2.5S2.7 0 3.6 0C4.6 0 5.4 0.3 6.1 1.1z" />
  </svg>
);

export default Icon;
