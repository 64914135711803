import parse from 'html-react-parser';

export interface CategorySeoProps {
  description: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

const CategorySeo: React.FC<CategorySeoProps> = ({ description, background, innerSpacing, outerSpacing }) => {
  if (!description) {
    return null;
  }
  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      {description && <div className="mob-p-p3 dt-p-p3 last-child-no-mb">{parse(description)}</div>}
    </div>
  );
};

export default CategorySeo;
