import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import { Facet } from '@Types/result/Facet';
import { RangeFacet } from '@Types/result/RangeFacet';
import { useDebounce } from 'usehooks-ts';
import { default as IconClose } from 'components/icons/close';
import { useFormat } from 'helpers/hooks/useFormat';
import { updateURLParams, URLParam } from 'helpers/utils/updateURLParams';
import PriceFilterDisclosure from './PriceFilterDisclosure';
import RangeFilterDisclosure from './RangeFilterDisclosure';
import SortingDisclosure from './SortingDisclosure';
import TermFilterDisclosure from './TermFilterDisclosure';

// Copied from packages/snaq/types/result/Facet.ts
// b/c some weird loader error if using exported enum
enum FacetTypes {
  BOOLEAN = 'boolean',
  TERM = 'term',
  RANGE = 'range',
}

type FiltersProps = {
  facets: Array<Facet | RangeFacet>;
  products: Product[];
  sortingParam: URLParam;
  updateSortingParams: (param: URLParam) => void;
};

type FilteringParamGroups = {
  [key: string]: URLParam[];
};

export const validFilterKeys: {
  [key: string]: { type: FacetTypes; defaultOpen: boolean; factFinderMapping?: string | null };
} = {
  ecom_brand: {
    type: FacetTypes.TERM,
    defaultOpen: true,
    factFinderMapping: 'Marke',
  },
  factfinder_categories: {
    type: FacetTypes.TERM,
    defaultOpen: true,
    factFinderMapping: 'Kategorien',
  },
  ecom_product_type: {
    type: FacetTypes.TERM,
    defaultOpen: true,
    factFinderMapping: 'Produkttyp',
  },
  ecom_weight_number: {
    type: FacetTypes.RANGE,
    defaultOpen: true,
    factFinderMapping: 'Gewicht Netto (kg)',
  },
  ecom_weight_camera_body_number: {
    type: FacetTypes.RANGE,
    defaultOpen: true,
    factFinderMapping: 'Gewicht Kamera Body (kg)',
  },
  ecom_format: {
    type: FacetTypes.TERM,
    defaultOpen: true,
    factFinderMapping: 'Format / Sensorformat',
  },
  ecom_image_resolution_classification: {
    type: FacetTypes.TERM,
    defaultOpen: true,
    factFinderMapping: 'Bildauflösung Klassifizierung',
  },
  ecom_type_lens: {
    type: FacetTypes.TERM,
    defaultOpen: true,
    factFinderMapping: 'Objektivtyp',
  },
  ecom_lens_mount: {
    type: FacetTypes.TERM,
    defaultOpen: true,
    factFinderMapping: 'Objektivanschluss',
  },
  ecom_focal_length_min_summary: {
    type: FacetTypes.TERM,
    defaultOpen: true,
    factFinderMapping: 'Brennweite Min',
  },
  ecom_focal_length_max_summary: {
    type: FacetTypes.TERM,
    defaultOpen: true,
    factFinderMapping: 'Brennweite Max',
  },
  ecom_viewfinder_type: {
    type: FacetTypes.TERM,
    defaultOpen: false,
    factFinderMapping: 'Sucher - Typ',
  },
  ecom_battery_type: {
    type: FacetTypes.TERM,
    defaultOpen: true,
    factFinderMapping: 'Akkutyp',
  },
  ecom_active_noise_cancelling: {
    type: FacetTypes.BOOLEAN,
    defaultOpen: false,
    factFinderMapping: 'Active Noise Cancellation (ANC)',
  },
  ecom_channels: {
    type: FacetTypes.RANGE,
    defaultOpen: true,
    factFinderMapping: 'Anzahl Kanäle',
  },
  ecom_construction_speaker: {
    type: FacetTypes.TERM,
    defaultOpen: false,
    factFinderMapping: 'Bauweise Lautsprecher',
  },
  ecom_amplifier_class: {
    type: FacetTypes.TERM,
    defaultOpen: false,
    factFinderMapping: 'Verstärkerklasse',
  },
  ecom_dmx_modi: {
    type: FacetTypes.BOOLEAN,
    defaultOpen: false,
    factFinderMapping: 'Integriertes DMX - Modul',
  },
  ecom_wearing_style: {
    type: FacetTypes.TERM,
    defaultOpen: false,
    factFinderMapping: 'Trageart',
  },
  ecom_read_range: {
    type: FacetTypes.TERM,
    defaultOpen: false,
    factFinderMapping: 'Lese - Reichweite / Lesbarkeit',
  },
  ecom_screen_size_number: {
    type: FacetTypes.RANGE,
    defaultOpen: true,
    factFinderMapping: 'Bildschirmdiagonale Zoll',
  },
  ecom_refresh_rate_max: {
    type: FacetTypes.TERM,
    defaultOpen: false,
    factFinderMapping: 'Bildwiederholfrequenz / Bildwiederholrate Max',
  },
  ecom_screen_brightness: {
    type: FacetTypes.TERM,
    defaultOpen: false,
    factFinderMapping: 'Helligkeit / Luminanz',
  },
  ecom_flicker_free: {
    type: FacetTypes.BOOLEAN,
    defaultOpen: false,
    factFinderMapping: 'Flickerfrei',
  },
  ecom_touch_screen: {
    type: FacetTypes.BOOLEAN,
    defaultOpen: true,
    factFinderMapping: 'Touchscreen',
  },
  ecom_bi_color_function: {
    type: FacetTypes.BOOLEAN,
    defaultOpen: true,
    factFinderMapping: 'BI Color Funktion',
  },
  ecom_guide_number: {
    type: FacetTypes.TERM,
    defaultOpen: false,
    factFinderMapping: 'Leitzahl',
  },
  ecom_tripod_legs: {
    type: FacetTypes.RANGE,
    defaultOpen: true,
    factFinderMapping: 'Anzahl Stativbeine',
  },
  ecom_payload_max_number: {
    type: FacetTypes.RANGE,
    defaultOpen: true,
    factFinderMapping: 'Traglast Max (kg)',
  },
  ecom_integrated_counterbalance: {
    type: FacetTypes.BOOLEAN,
    defaultOpen: false,
    factFinderMapping: 'Integrierter Gewichtsausgleich (CBS)',
  },
  ecom_unfoldable: {
    type: FacetTypes.BOOLEAN,
    defaultOpen: false,
    factFinderMapping: 'Ausklappbar',
  },
  ecom_tape_length: {
    type: FacetTypes.TERM,
    defaultOpen: false,
    factFinderMapping: 'Bandlänge',
  },
  ecom_trolley_connector: {
    type: FacetTypes.BOOLEAN,
    defaultOpen: false,
    factFinderMapping: 'Trolley Anschluss',
  },
  ecom_stackable: {
    type: FacetTypes.BOOLEAN,
    defaultOpen: false,
    factFinderMapping: 'Stapelbar',
  },
  ecom_color: {
    type: FacetTypes.TERM,
    defaultOpen: false,
    factFinderMapping: 'Farbe',
  },
  ecom_application_area: {
    type: FacetTypes.TERM,
    defaultOpen: true,
    factFinderMapping: 'Anwendungsbereich',
  },
};

const Filters: FC<FiltersProps> = ({ facets, products, sortingParam, updateSortingParams }) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'product' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const isFirstRender = useRef(true);
  const [priceFilteringParams, setPriceFilteringParams] = useState<URLParam[]>([]);
  const [filteringParamGroups, setFilteringParamGroups] = useState<FilteringParamGroups>({});

  const debouncedPriceFilteringParams = useDebounce<URLParam[]>(priceFilteringParams, 500);
  const debouncedFilteringParamGroups = useDebounce<FilteringParamGroups>(filteringParamGroups, 500);

  const filterKeys = useMemo(() => {
    return facets
      ? facets
          .filter((facet) => {
            if (!facet.key || !facet.key.includes('variants.attributes.')) {
              return false;
            }
            if (facet.type === FacetTypes.RANGE) {
              return 'min' in facet && 'max' in facet ? !isNaN(facet.min) && !isNaN(facet.max) : false;
            }
            return facet.terms && facet.terms.length > 0;
          })
          .map((facet) => facet.key.replace('variants.attributes.', ''))
          .filter((key) => Object.keys(validFilterKeys).includes(key))
          .sort((keyA, keyB) => {
            if (
              Object.keys(validFilterKeys).indexOf(keyA) === -1 ||
              Object.keys(validFilterKeys).indexOf(keyB) === -1
            ) {
              return 0;
            }
            return Object.keys(validFilterKeys).indexOf(keyA) - Object.keys(validFilterKeys).indexOf(keyB);
          })
      : [];
  }, [facets]);

  const selectedFilters = useMemo(() => {
    return facets
      ? facets.filter(
          (facet) =>
            !!facet.selected &&
            (Object.keys(validFilterKeys).includes(facet.key.replace('variants.attributes.', '')) ||
              facet.key === 'variants.price'),
        )
      : [];
  }, [facets]);

  const updateUrl = useCallback(() => {
    const params = [];

    if (router.query.query) {
      params.push({
        key: 'query',
        value: router.query.query,
      });
    }

    if (debouncedPriceFilteringParams) {
      params.push(...debouncedPriceFilteringParams);
    }

    if (sortingParam) {
      params.push(sortingParam);
    }

    if (debouncedFilteringParamGroups) {
      for (const [, value] of Object.entries(debouncedFilteringParamGroups)) {
        params.push(...value);
      }
    }

    if (params.length > 0) {
      params.push({
        key: 'cursor',
        value: 'offset:0',
      });
    }

    const currentURL = updateURLParams(params);

    router.push(currentURL);
  }, [debouncedPriceFilteringParams, sortingParam, debouncedFilteringParamGroups, router]);

  const updatePriceFilteringParams = (params: URLParam[]) => {
    setPriceFilteringParams(params);
  };

  const updateFilteringParams = (termKey: string, params: URLParam[]) => {
    setFilteringParamGroups((prevState) => ({
      ...prevState,
      [termKey]: [...params],
    }));
  };

  const removeFilteringParams = (termKey: string, value?: string) => {
    setFilteringParamGroups((prevState) => {
      const newState = { ...prevState };
      let newParams: URLParam[] = [];
      if (value) {
        newParams = prevState[termKey].filter((param) => param.value !== value);
      }
      if (newParams.length > 0) {
        newState[termKey] = [...newParams];
      } else {
        delete newState[termKey];
      }
      return {
        ...newState,
      };
    });
  };

  const handleFiltersSubmit = (e) => {
    e.preventDefault();
    updateUrl();
  };

  const handleFiltersReset = (e) => {
    e.preventDefault();
    setPriceFilteringParams([]);
    updateSortingParams(undefined);
    setFilteringParamGroups({});
  };

  const nf = useMemo(
    () =>
      new Intl.NumberFormat(router.locale || router.defaultLocale, {
        style: 'currency',
        currency: 'EUR', // TODO: Dynamic currency determination
        maximumFractionDigits: 0,
      }),
    [router.locale],
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    updateUrl();
  }, [sortingParam, debouncedFilteringParamGroups, debouncedPriceFilteringParams]);

  return (
    <form onSubmit={handleFiltersSubmit} onReset={handleFiltersReset}>
      <div className="d-lg-none">
        <SortingDisclosure updateSortingParams={updateSortingParams} />
      </div>
      {selectedFilters.length > 0 && (
        <>
          <div className="d-flex flex-wrap">
            {selectedFilters.map((facet, groupIndex) => {
              const displayPrice = facet.key === 'variants.price';
              if (
                displayPrice &&
                'minSelected' in facet &&
                'maxSelected' in facet &&
                !isNaN(facet.minSelected) &&
                !isNaN(facet.maxSelected)
              ) {
                return (
                  <div key={`selected-filters-group${groupIndex}`} className="d-flex flex-wrap">
                    <button
                      type="button"
                      onClick={() => {
                        setPriceFilteringParams([]);
                      }}
                      className="btn btn-dark btn-xs me-6 mb-6"
                    >
                      {nf.formatRange(facet.minSelected / 100, facet.maxSelected / 100)} {IconClose({})}
                    </button>
                  </div>
                );
              }
              const displayRange = !displayPrice && facet.type === FacetTypes.RANGE;
              const termKey = facet.key.replace('variants.attributes.', '');
              if (
                displayRange &&
                'minSelected' in facet &&
                'maxSelected' in facet &&
                !isNaN(facet.minSelected) &&
                !isNaN(facet.maxSelected)
              ) {
                let termLabel: string | null = null;
                for (const product of products) {
                  if (termLabel !== null) {
                    break;
                  }
                  for (const variant of product.variants) {
                    if (termLabel !== null) {
                      break;
                    }
                    for (const attributeLabelIndex in variant.attributeLabels) {
                      const attributeLabel = variant.attributeLabels[attributeLabelIndex];
                      if (attributeLabel.key === termKey) {
                        termLabel = attributeLabel.label;
                        break;
                      }
                    }
                  }
                }
                return (
                  <div key={`selected-filters-group${groupIndex}`} className="d-flex flex-wrap">
                    <button
                      type="button"
                      onClick={() => {
                        removeFilteringParams(termKey);
                      }}
                      className="btn btn-dark btn-xs me-6 mb-6"
                    >
                      {termLabel && termLabel + ': '}
                      {Intl.NumberFormat(router?.locale || router?.defaultLocale).formatRange(
                        facet.minSelected,
                        facet.maxSelected,
                      )}
                      {IconClose({})}
                    </button>
                  </div>
                );
              }
              const displayBoolean = !displayPrice && !displayRange && facet.type === FacetTypes.BOOLEAN;
              if (displayBoolean && 'terms' in facet && facet.terms.length === 2) {
                let termLabel: string | null = null;
                for (const product of products) {
                  if (termLabel !== null) {
                    break;
                  }
                  for (const variant of product.variants) {
                    if (termLabel !== null) {
                      break;
                    }
                    for (const attributeLabelIndex in variant.attributeLabels) {
                      const attributeLabel = variant.attributeLabels[attributeLabelIndex];
                      if (attributeLabel.key === termKey) {
                        termLabel = attributeLabel.label;
                        break;
                      }
                    }
                  }
                }
                const selectedTerms = facet.terms.filter((term) => !!term.selected);
                return (
                  <div key={`selected-filters-group${groupIndex}`} className="d-flex flex-wrap">
                    {selectedTerms.map((term, index) => {
                      return (
                        <button
                          key={`selected-filters-group${groupIndex}-item${index}`}
                          type="button"
                          onClick={() => {
                            removeFilteringParams(termKey, term.key);
                          }}
                          className="btn btn-dark btn-xs me-6 mb-6"
                        >
                          {termLabel && termLabel + ': '}
                          {term.label === 'T'
                            ? formatCommonMessage({ id: 'yes', defaultMessage: 'Yes' })
                            : formatCommonMessage({ id: 'no', defaultMessage: 'No' })}{' '}
                          {IconClose({})}
                        </button>
                      );
                    })}
                  </div>
                );
              }
              const displayTermValues = !displayPrice && !displayRange;
              if (displayTermValues && 'terms' in facet && facet.terms.length > 0) {
                const selectedTerms = facet.terms.filter((term) => !!term.selected);
                return (
                  <div key={`selected-filters-group${groupIndex}`} className="d-flex flex-wrap">
                    {selectedTerms.map((term, index) => {
                      return (
                        <button
                          key={`selected-filters-group${groupIndex}-item${index}`}
                          type="button"
                          onClick={() => {
                            removeFilteringParams(termKey, term.key);
                          }}
                          className="btn btn-dark btn-xs me-6 mb-6"
                        >
                          {term.label} {IconClose({})}
                        </button>
                      );
                    })}
                  </div>
                );
              }
              return null;
            })}
          </div>
          <hr />
        </>
      )}
      {filterKeys
        .filter((termKey) => termKey === 'ecom_brand')
        .map((termKey) => (
          <TermFilterDisclosure
            key={termKey}
            termKey={termKey}
            products={products}
            facets={facets}
            updateFilteringParams={updateFilteringParams}
            defaultOpen={validFilterKeys[termKey].defaultOpen}
          />
        ))}
      <PriceFilterDisclosure
        products={products}
        facets={facets}
        updatePriceFilteringParams={updatePriceFilteringParams}
      />
      {filterKeys
        .filter((termKey) => termKey !== 'ecom_brand')
        .map((termKey) => {
          if (validFilterKeys[termKey].type === FacetTypes.RANGE) {
            return (
              <RangeFilterDisclosure
                key={termKey}
                termKey={termKey}
                products={products}
                facets={facets}
                updateFilteringParams={updateFilteringParams}
                defaultOpen={validFilterKeys[termKey].defaultOpen}
              />
            );
          }
          if (validFilterKeys[termKey].type === FacetTypes.BOOLEAN) {
            return (
              <TermFilterDisclosure
                key={termKey}
                termKey={termKey}
                products={products}
                facets={facets}
                updateFilteringParams={updateFilteringParams}
                defaultOpen={validFilterKeys[termKey].defaultOpen}
                isBoolean={true}
              />
            );
          }
          return (
            <TermFilterDisclosure
              key={termKey}
              termKey={termKey}
              products={products}
              facets={facets}
              updateFilteringParams={updateFilteringParams}
              defaultOpen={validFilterKeys[termKey].defaultOpen}
            />
          );
        })}
      <div className="mt-8 flex justify-between gap-3">
        <button type="reset" className="btn btn-primary btn-sm w-100">
          {formatMessage({ id: 'clear', defaultMessage: 'Clear' })}
        </button>

        <button type="submit" className="btn btn-secondary btn-sm w-100 mt-2 d-lg-none">
          {formatMessage({ id: 'applyFilters', defaultMessage: 'Apply filters' })}
        </button>
      </div>
    </form>
  );
};

export default Filters;
