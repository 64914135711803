import React, { useMemo } from 'react';
import { default as IconCompareArrows } from 'components/icons/compare-arrows';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';

interface ComparisonButtonProps {
  comparisonSkus?: string[] | null;
  comparisonLink?: Reference;
}

const ComparisonButton: React.FC<ComparisonButtonProps> = ({ comparisonSkus, comparisonLink }) => {
  //i18n messages
  const { formatMessage: formatComparisonMessage } = useFormat({ name: 'comparison' });

  const targetLink = useMemo(() => {
    const link: Reference = { ...comparisonLink };
    if (!comparisonSkus) {
      return link;
    }
    const comparisonParams = `?skus=${encodeURI(comparisonSkus.join(','))}`;
    if ('link' in link) {
      link.link += comparisonParams;
    }
    if ('pageFolder' in link) {
      link.pageFolder._url += comparisonParams;
    }
    return link;
  }, [comparisonSkus, comparisonLink]);

  return (
    <div className="short-link">
      <ReferenceLink target={targetLink} className="short-link__item">
        <div className="short-link__icon">
          {IconCompareArrows({})}

          {comparisonSkus && comparisonSkus.length > 0 && (
            <div className="short-link__counter">
              {comparisonSkus.length}
              <div className="visually-hidden">
                {formatComparisonMessage({
                  id: 'comparison.items.in.view',
                  defaultMessage: 'items to compare, view comparison',
                })}
              </div>
            </div>
          )}
        </div>
        <div className="short-link__text">
          {formatComparisonMessage({
            id: 'comparison.title',
            defaultMessage: 'Comparison',
          })}
        </div>
      </ReferenceLink>
    </div>
  );
};

export default ComparisonButton;
