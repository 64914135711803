import React from 'react';
import { Reference, ReferenceLink } from 'helpers/reference';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import { sm, lg, md, xl } from '../../../../helpers/utils/screensizes';
import Slider from '../../slider';

const sliderConfiguration = {
  arrows: true,
  dots: false,
};

export interface Props {
  tasticId: string;
  title: string;
  items: PageTeaserSlide[];
  width?: string;
  height?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

export type PageTeaserSlide = {
  image: MediaItemWithMods;
  title: string;
  subtitle: string;
  reference: Reference;
  linkTitle?: string;
  linkRel?: string;
};

export default function PageTeasersSlider({
  tasticId,
  title,
  items,
  width,
  height,
  background,
  innerSpacing,
  outerSpacing,
}: Props) {
  let additionalSliderConfig = {};
  if ('450' === height) {
    additionalSliderConfig = {
      slidesPerView: 1.5,
      breakpoints: {
        [md]: {
          slidesPerView: 1.75,
        },
        [md]: {
          slidesPerView: 2,
        },
        [lg]: {
          slidesPerView: 2.75,
        },
        [xl]: {
          slidesPerView: 3.5,
        },
      },
    };
  }

  const extendetSliderConfiguration = { ...sliderConfiguration, ...additionalSliderConfig };

  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      {title && (
        <div className="d-flex justify-content-between align-items-end mb-20">
          {title && <h2 className="h1 products-slider__title mb-0">{title}</h2>}
        </div>
      )}
      <Slider
        {...extendetSliderConfiguration}
        className={`swiper--mobile-full page-teaser-slider ${
          width === 'container-window-edge' ? 'swiper--container-window-edge' : ''
        } page-teaser-slider--height-${height}`}
      >
        {items.map((item: PageTeaserSlide, index: number) => (
          <div key={`${tasticId}${index}`} className="d-flex w-100">
            <ReferenceLink
              target={item.reference}
              title={item.linkTitle}
              rel={item.linkRel}
              className="teaser-card position-relative rounded overflow-hidden text-white d-block w-100"
            >
              <div className="teaser-card__image">
                <Image media={item.image} alt={item.image.media.name} layout="fill" className="object-fit-cover" />
              </div>
              <div className="position-absolute bottom-0 start-0 w-100 text-white p-20">
                <p className="mb-0 mob-h-h5 dt-h-h5">{item.title}</p>
                {item.subtitle && <div className="mob-c-c5 dt-c-c5 pt-10">{item.subtitle}</div>}
              </div>
            </ReferenceLink>
          </div>
        ))}
      </Slider>
    </div>
  );
}
