import React, { useEffect } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useCart } from 'frontastic';
import { gtmPushAddPaymentInfoEvent } from '../../../../components/snaq-ui/multistep-checkout/panels/payment';
import Spinner from '../../../../components/snaq-ui/spinner';
import { useFormat } from '../../../../helpers/hooks/useFormat';

type Props = {
  data: {
    paymentConfirmation: {
      dataSource: {
        paymentIntentId: string;
        paymentSuccess: boolean;
      };
    };
  };
};

const StripeReturnTastic = ({ data }: Props) => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const router = useRouter();
  const { checkout, setLastOrderId } = useCart();
  const paymentSuccess = data?.paymentConfirmation?.dataSource?.paymentSuccess;

  useEffect(() => {
    if (paymentSuccess) {
      checkout()
        .then((order) => {
          if (order?.cartId) {
            setLastOrderId(order.cartId);
          }
          gtmPushAddPaymentInfoEvent(order);
          router.push('/thank-you');
        })
        .catch((e) => console.error(e));
    }
  }, [paymentSuccess]);

  return paymentSuccess === false ? (
    <div className="py-5">
      <p className="fs-2 fw-bold text-center">
        {formatCheckoutMessage({ id: 'wentWrong', defaultMessage: 'Something went wrong during payment' })}
      </p>
      <p className="text-center mt-5 mob-p-p4 dt-p-p4">
        <NextLink href="/checkout">
          <a className="link w-100">
            {formatCheckoutMessage({ id: 'backToCheckout', defaultMessage: 'Back to checkout' })}
          </a>
        </NextLink>
      </p>
    </div>
  ) : (
    <div className="d-flex align-items-center justify-content-center py-5">
      <Spinner />
    </div>
  );
};

export default StripeReturnTastic;
