import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-payment-american-express ${className}`}
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="#fff" d="M0 0v50h50V0H0z" />
    <path
      d="m41.1 12.3 1.6-4.4H50V0H0v50h50v-7.9h-6.9l-2.5-3-2.7 3H18.3V26.2h-6.5l8.1-18.3h7.9l1.9 4.1V7.9h9.8l1.6 4.4zm-5.5 2.9v-1.8l.7 1.8 3.2 8.7h3.2l3.3-8.7.6-1.7v10.4H50V10.5h-5.7l-2.6 6.8-.7 1.8-.7-1.8-2.6-6.8H32v13.3h3.4v-8.6h.2zm-7.4 8.6h3.9l-5.9-13.3h-4.5l-5.9 13.3h3.9l1-2.6h6.5l1 2.6zM23.3 15l.7-1.7.7 1.7 1.4 3.4H22l1.3-3.4zM21 26.2v13.3h11.1v-2.9h-7.7v-2.3H32v-2.9h-7.6v-2.3h7.7v-2.9H21zm23.5 13.3H49l-6.2-6.7 6.2-6.6h-4.4l-4 4.3-4-4.3h-4.4l6.2 6.7-6.2 6.6h4.3l4.1-4.3 3.9 4.3zm1.7-6.7 3.8 3.8V29l-3.8 3.8z"
      fill="#006fcf"
    />
  </svg>
);

export default Icon;
