import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { default as IconChevronRight } from 'components/icons/chevron-right';
import { Reference, ReferenceLink } from 'helpers/reference';
import Image from 'frontastic/lib/image';
import { PageNodeTeaser } from '../../../../frontastic/tastics/snaq/categories/category-experts';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { sm, lg } from '../../../../helpers/utils/screensizes';
import ExpertContactForms from '../../expert-contact';
import Slider, { SliderProps } from '../../slider';

const defaultSliderSettings = {
  slidesPerView: 1.25,
  breakpoints: {
    [sm]: {
      slidesPerView: 2,
    },
    [lg]: {
      slidesPerView: 3,
    },
  },
  arrows: true,
  dots: false,
};

export interface Props {
  tasticId: string;
  title: string;
  items: PageNodeTeaser[];
  ctaLabel?: string;
  ctaLink?: Reference;
  sliderSettings?: object;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
  size?: string;
  width?: string;
}

export default function ExpertTeasers({
  tasticId,
  title,
  items,
  ctaLabel,
  ctaLink,
  sliderSettings,
  background,
  innerSpacing,
  outerSpacing,
  size,
  width,
}: Props) {
  const { formatMessage } = useFormat({ name: 'common' });

  const sliderFixedMood: SliderProps = useMemo(() => {
    return sliderSettings ? sliderSettings : defaultSliderSettings;
  }, [sliderSettings]);

  const [expertShow, setExpertShow] = useState<number | null>(null);

  return (
    <>
      <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
        {title && (
          <div className="d-flex justify-content-between align-items-end mb-20">
            {title && <h2 className="h1 products-slider__title mb-0">{title}</h2>}
            {ctaLabel && ctaLink && (
              <ReferenceLink target={ctaLink} className="products-slider__link link-reset dt-c-c2 mb-1">
                <span className="d-none d-xl-block">{ctaLabel}</span>
                {IconChevronRight({})}
              </ReferenceLink>
            )}
          </div>
        )}
        <Slider
          {...sliderFixedMood}
          className={classnames(
            'swiper--mobile-full profile-slider',
            width === 'container-window-edge' && 'swiper--container-window-edge',
          )}
        >
          {items.map(
            (item: PageNodeTeaser, index: number) =>
              item.image && (
                <div key={`${tasticId}${index}`} className="d-flex w-100">
                  {size === 'large' ? (
                    <div className="w-100">
                      <div className="row align-items-center">
                        <div className="col-12 col-6 col-md-6 ps-md-100 pe-md-25 pe-lg-50 pb-20 pb-md-0">
                          <div className="ratio ratio-4x3 rounded overflow-hidden">
                            <Image
                              media={item.image}
                              alt={item.image.media.name}
                              layout="fill"
                              className="object-fit-cover"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 ps-md-25 ps-lg-50 pe-md-100">
                          {item.title && <h3 className="h2">{item.title}</h3>}
                          {item.description && <p className="mob-p-p3 dt-p-p3">{item.description}</p>}
                          {/* NOTE: Currently no link to expert page wanted
                          {item.ctaLabel && item.ctaReference && (
                            <ReferenceLink target={item.ctaReference} className="btn btn-primary">
                              {t(item.ctaLabel)}
                            </ReferenceLink>
                          )}
                          */}
                          <div className="mt-auto d-flex flex-wrap">
                            <button
                              className="btn btn-primary btn-sm btn-lg-md my-1 me-3"
                              onClick={() => {
                                setExpertShow(index);
                              }}
                            >
                              {formatMessage({
                                id: 'contactNow',
                                defaultMessage: 'Contact now',
                              })}
                            </button>
                            {/*
                            <button
                              className="btn btn-secondary btn-sm btn-lg-md my-1 me-3"
                              onClick={() => {
                                setExpertDefaultActiveKey('0');
                                setExpertShow(index);
                              }}
                            >
                              {formatContactformMessage({
                                id: 'request.callback',
                                defaultMessage: 'Request a callback',
                              })}
                            </button>
                            <button
                              className="btn btn-secondary btn-sm btn-lg-md my-1 me-3"
                              onClick={() => {
                                setExpertDefaultActiveKey('1');
                                setExpertShow(index);
                              }}
                            >
                              {formatContactformMessage({
                                id: 'write.message',
                                defaultMessage: 'Write a message',
                              })}
                            </button>
                            */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex flex-column w-100 rounded overflow-hidden bg-white border border-grey-20">
                      <Image media={item.image} alt={item.image.media.name} className="object-fit-cover" />
                      <div className="pt-20 pb-20 px-20 flex-grow-1 d-flex flex-column ">
                        {item.title && <div className="mob-p-p3 dt-p-p3 text-grey-90">{item.title}</div>}
                        {item.description && (
                          <div className="mob-p-p3 dt-p-p3 fw-medium text-grey-100 mt-10">{item.description}</div>
                        )}
                        {/* NOTE: Currently no link to expert page wanted
                        {item.ctaLabel && item.ctaReference && (
                          <div className="mt-auto">
                            <ReferenceLink target={item.ctaReference} className="btn btn-sm w-100 btn-secondary mt-20">
                              {t(item.ctaLabel)}
                            </ReferenceLink>
                          </div>
                        )}
                        */}
                        <div className="mt-auto d-flex flex-wrap">
                          <button
                            className="btn btn-primary btn-sm btn-lg-md my-1 me-3"
                            onClick={() => {
                              setExpertShow(index);
                            }}
                          >
                            {formatMessage({
                              id: 'contactNow',
                              defaultMessage: 'Contact now',
                            })}
                          </button>
                          {/*
                          <button
                            className="btn btn-secondary btn-sm btn-lg-md my-1 me-3"
                            onClick={() => {
                              setExpertDefaultActiveKey('callback');
                              setExpertShow(index);
                            }}
                          >
                            {formatContactformMessage({
                              id: 'request.callback',
                              defaultMessage: 'Request a callback',
                            })}
                          </button>
                          <button
                            className="btn btn-secondary btn-sm btn-lg-md my-1 me-3"
                            onClick={() => {
                              setExpertDefaultActiveKey('contact');
                              setExpertShow(index);
                            }}
                          >
                            {formatContactformMessage({
                              id: 'write.message',
                              defaultMessage: 'Write a message',
                            })}
                          </button>
                          */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ),
          )}
        </Slider>
      </div>
      {items.map((expertTeaser: PageNodeTeaser, index: number) => (
        <Offcanvas
          key={`${tasticId}offcanvas${index}`}
          show={expertShow === index}
          onHide={() => {
            setExpertShow(null);
          }}
          placement={'end'}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              {formatMessage({
                id: 'consulting',
                defaultMessage: 'Consulting',
              })}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="bg-grey-10 p-20 rounded mb-20">
              <div className="row align-items-center">
                <div className="col-3">
                  <div className="ratio ratio-1x1">
                    <Image
                      media={expertTeaser.v3image ? expertTeaser.v3image : expertTeaser.image}
                      className="object-fit-cover rounded-circle"
                      layout="fill"
                      alt={expertTeaser.v3image ? expertTeaser.v3image.media.name : expertTeaser.image.media.name}
                    />
                  </div>
                </div>
                <div className="col-9">
                  <div className="mob-p-p3 dt-p-3 fw-bold">
                    {expertTeaser.v3title ? expertTeaser.v3title : expertTeaser.title}
                  </div>
                </div>
              </div>
              <hr />
              {expertTeaser.v3description ? expertTeaser.v3description : expertTeaser.description}
            </div>
            <ExpertContactForms expertEmail={expertTeaser.email} />
          </Offcanvas.Body>
        </Offcanvas>
      ))}
    </>
  );
}
