import React from 'react';
import Link from 'next/link';
import parse from 'html-react-parser';
import { markdown } from 'markdown';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { default as IconFacebook } from 'components/icons/facebook';
import { default as IconInstagram } from 'components/icons/instagram';
import { default as IconLinkedIn } from 'components/icons/linked-in';
import { default as IconAmericanExpress } from 'components/icons/payment-american-express';
import { default as IconApple } from 'components/icons/payment-apple-borderless';
import { default as IconBancontact } from 'components/icons/payment-bancontact';
import { default as IconEps } from 'components/icons/payment-eps';
import { default as IconGiropay } from 'components/icons/payment-giropay';
import { default as IconGooglePay } from 'components/icons/payment-google-pay-borderless';
import { default as IconSofort } from 'components/icons/payment-klarna-sofort';
import { default as IconMastercard } from 'components/icons/payment-mastercard';
import { default as IconPayPal } from 'components/icons/payment-paypal';
import { default as IconVisa } from 'components/icons/payment-visa';
import { default as IconSnaq } from 'components/icons/snaq-by-qvest';
import { default as IconTwitter } from 'components/icons/twitter';
import { default as IconYouTube } from 'components/icons/youtube';
import RenderColumn, { Link as FooterLink, Column as FooterColumn } from './column';
import { ReferenceLink } from '../../../helpers/reference';

export interface Props {
  description?: string;
  address?: string;
  email?: string;
  phone?: string;
  columns: FooterColumn[];
  copyright?: string;
  copyrightLinks?: FooterLink[];
  facebookLink?: string;
  twitterLink?: string;
  linkedinLink?: string;
  youtubeLink?: string;
  instagramLink?: string;
}

const Footer: React.FC<Props> = ({
  description,
  address,
  email,
  phone,
  columns,
  copyright,
  copyrightLinks,
  facebookLink,
  twitterLink,
  linkedinLink,
  youtubeLink,
  instagramLink,
}) => {
  return (
    <footer className="page-footer bg-grey-full section-spacing-padding">
      <Row>
        <Col xs={12} xl={4}>
          <Link href="/">
            <a className="page-footer__logo mb-3">{IconSnaq({})}</a>
          </Link>
          {description && <div className="mb-3">{parse(markdown.toHTML(description))}</div>}
          {address && <div className="mb-2">{parse(markdown.toHTML(address))}</div>}
          {phone && (
            <p className="mb-2">
              <b>{phone}</b>
            </p>
          )}
          {email && (
            <p className="mb-3">
              <a href={`mailto:${email}`} className="link-reset">
                <b>{email}</b>
              </a>
            </p>
          )}
          <ul className="footer-socials__ul mt-4">
            {facebookLink && (
              <li className="footer-socials__li">
                <a href={facebookLink} target="_blank" rel="noreferrer" className="footer-socials__a">
                  {IconFacebook({})}
                </a>
              </li>
            )}
            {twitterLink && (
              <li className="footer-socials__li">
                <a href={twitterLink} target="_blank" rel="noreferrer" className="footer-socials__a">
                  {IconTwitter({})}
                </a>
              </li>
            )}
            {linkedinLink && (
              <li className="footer-socials__li">
                <a href={linkedinLink} target="_blank" rel="noreferrer" className="footer-socials__a">
                  {IconLinkedIn({})}
                </a>
              </li>
            )}
            {youtubeLink && (
              <li className="footer-socials__li">
                <a href={youtubeLink} target="_blank" rel="noreferrer" className="footer-socials__a">
                  {IconYouTube({})}
                </a>
              </li>
            )}
            {instagramLink && (
              <li className="footer-socials__li">
                <a href={instagramLink} target="_blank" rel="noreferrer" className="footer-socials__a">
                  {IconInstagram({})}
                </a>
              </li>
            )}
          </ul>
        </Col>
        <Col xs={12} xl={8}>
          <Accordion className="footer-nav">
            {columns.map((column, index) => (
              <RenderColumn key={'footer-nav-col' + index} column={column} index={index} />
            ))}
          </Accordion>
        </Col>
      </Row>
      <ul className="footer-payments__ul">
        <li className="footer-payments__li">{IconApple({})}</li>
        <li className="footer-payments__li">{IconGooglePay({})}</li>
        <li className="footer-payments__li">{IconPayPal({})}</li>
        <li className="footer-payments__li">{IconVisa({})}</li>
        <li className="footer-payments__li">{IconMastercard({})}</li>
        <li className="footer-payments__li">{IconAmericanExpress({})}</li>
        <li className="footer-payments__li">{IconGiropay({})}</li>
        <li className="footer-payments__li">{IconEps({})}</li>
        <li className="footer-payments__li">{IconSofort({})}</li>
        <li className="footer-payments__li">{IconBancontact({})}</li>
      </ul>
      <ul className="footer-info-nav__ul pb-0">
        {copyright && (
          <li className="footer-info-nav__li">
            <div style={{ lineHeight: 1.2 }}>{copyright}</div>
          </li>
        )}
        {copyrightLinks.map((copyrightLink, index) => (
          <li key={`footerInfoNav${index}`} className="footer-info-nav__li">
            <ReferenceLink
              target={copyrightLink.reference}
              title={copyrightLink.linkTitle}
              rel={copyrightLink.linkRel}
              className="footer-info-nav__a"
            >
              {copyrightLink.name}
            </ReferenceLink>
          </li>
        ))}
      </ul>
    </footer>
  );
};

export default Footer;
