export enum TrackEventKey {
  click = 'click',
  cart = 'cart',
  checkout = 'checkout',
  login = 'login',
  recommendationClick = 'recommendationClick',
  feedback = 'feedback',
}

export interface TrackEventData {
  id: string;
  title?: string;
  query?: string;
  pos?: number;
  page?: number;
  pageSize?: number;
  count?: number;
  price?: number;
}

export interface UseSearch {
  trackEvent: (event: TrackEventKey, data: Array<TrackEventData>) => Promise<any>;
}
