import React from 'react';
import Table, { TableProps } from '../../../../../components/snaq-ui/content/table';

type Props = {
  items: Array<TableProps>;
  background?: string;
  backgroundWidth?: string;
  innerSpacing?: string;
  outerSpacing?: string;
};

const TableTastic = ({ id, data }: { id: string; data: Props }) => {
  const { items, background, backgroundWidth, innerSpacing, outerSpacing } = data;

  return (
    <Table
      tasticId={id}
      items={items}
      background={background}
      backgroundWidth={backgroundWidth}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
    />
  );
};

export default TableTastic;
