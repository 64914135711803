import React from 'react';
import LinksBanner from 'components/snaq-ui/content/links-banner';

const HeroSliderTastic = ({ data }) => {
  return (
    <LinksBanner
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
      title={data.title}
      description={data.description}
      links={data.links}
    />
  );
};

export default HeroSliderTastic;
