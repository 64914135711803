import React from 'react';
import MediaYoutube from '../../../../../components/snaq-ui/content/media-youtube';

const MediaYoutubeTastic = ({ data }) => {
  return (
    <MediaYoutube
      youtubeId={data.youtubeId}
      width={data.width}
      alignment={data.alignment}
      caption={data.caption}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
};

export default MediaYoutubeTastic;
