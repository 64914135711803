import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-visibility-off ${className}`}
    viewBox="0 0 44 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.5,22.3l-2.2-2.2c0.9-2.4,0.4-4.3-1.4-5.9c-1.8-1.6-3.7-2-5.8-1.2L18,10.8c0.6-0.4,1.2-0.6,1.9-0.8
	c0.7-0.2,1.4-0.2,2.1-0.2c2.4,0,4.4,0.8,6,2.5c1.7,1.6,2.5,3.7,2.5,6c0,0.7-0.1,1.5-0.3,2.2S29.8,21.8,29.5,22.3z M35.9,28.8l-2-2
	c1.6-1.2,3.1-2.5,4.3-4c1.2-1.5,2.1-3,2.7-4.5c-1.7-3.7-4.2-6.6-7.5-8.8c-3.3-2.2-7-3.2-10.9-3.2c-1.4,0-2.8,0.1-4.3,0.4
	s-2.6,0.6-3.5,0.9l-2.3-2.3c1.2-0.5,2.7-1,4.5-1.4c1.8-0.4,3.6-0.6,5.3-0.6c4.8,0,9.1,1.4,13.1,4.1s6.8,6.4,8.7,10.9
	c-0.9,2.1-2,4.1-3.3,5.9S37.7,27.4,35.9,28.8z M38.8,40l-8.4-8.2c-1.2,0.5-2.5,0.8-3.9,1.1s-3,0.4-4.5,0.4c-4.9,0-9.3-1.4-13.2-4.1
	S1.9,22.8,0,18.2c0.7-1.7,1.6-3.4,2.8-5.1s2.6-3.2,4.3-4.7L0.8,2.2L2.9,0l37.8,37.9L38.8,40z M9.2,10.6c-1.2,0.9-2.4,2.1-3.6,3.6
	s-2,2.9-2.5,4.1c1.7,3.7,4.3,6.6,7.7,8.8s7.3,3.2,11.6,3.2c1.1,0,2.2-0.1,3.2-0.2c1.1-0.1,1.9-0.3,2.4-0.6l-3.2-3.2
	c-0.4,0.2-0.8,0.3-1.4,0.4c-0.5,0.1-1,0.1-1.5,0.1c-2.3,0-4.3-0.8-6-2.4c-1.7-1.6-2.5-3.7-2.5-6.1c0-0.5,0-1,0.1-1.5
	s0.2-0.9,0.4-1.3L9.2,10.6z"
    />
  </svg>
);

export default Icon;
