import React from 'react';
import { default as IconChevronRight } from 'components/icons/chevron-right';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { Reference, ReferenceLink } from 'helpers/reference';
import Entry from './entry';
import { PageNodeTeaser } from '../../../../frontastic/tastics/snaq/categories/category-experts';
import { sm, md, lg } from '../../../../helpers/utils/screensizes';
import Slider, { SliderProps } from '../../slider';

export interface Props {
  tasticId: string;
  title: string;
  items: PageTeaser[];
  ctaLabel?: string;
  ctaLink?: Reference;
  ctaTitle?: string;
  ctaRel?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

export interface PageTeaser extends PageNodeTeaser {
  ctaLink?: Reference;
}

export default function PageTeasers({
  tasticId,
  title,
  items,
  ctaLabel,
  ctaLink,
  ctaTitle,
  ctaRel,
  background,
  innerSpacing,
  outerSpacing,
}: Props) {
  const sliderSettings = {
    dots: false,
    freeMode: false,
    scrollbar: true,
    arrows: false,
    spaceBetween: 20,
    slidesPerView: 1.25,
    breakpoints: {
      [sm]: {
        slidesPerView: 1.75,
      },
      [md]: {
        slidesPerView: 2.75,
      },
    },
  };
  const sliderConfiguration: SliderProps = sliderSettings;

  const [isLG] = useMediaQuery(lg);

  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      {title && (
        <div className="d-flex justify-content-between align-items-end">
          {title && <h2 className="h1 products-slider__title mb-0">{title}</h2>}
          {ctaLabel && ctaLink && (
            <ReferenceLink
              target={ctaLink}
              title={ctaTitle}
              rel={ctaRel}
              className="products-slider__link link-reset dt-c-c2 mb-1"
            >
              <span className="d-none d-xl-block">{ctaLabel}</span>
              {IconChevronRight({})}
            </ReferenceLink>
          )}
        </div>
      )}
      <div className="row">
        <div className="col-12 col-lg-6 mb-20 mb-xl-0 pt-20">
          <Entry
            ctaLink={items[0].ctaLink ?? items[0].ctaReference}
            image={items[0].image}
            badge={items[0].title}
            text={items[0].description}
            size="large"
          />
        </div>
        <div className="col-12 col-lg-6">
          {isLG ? (
            <div className="row">
              {items.slice(1).map((entry, index) => (
                <div className="col-6 pt-20" key={`${tasticId}${index}`}>
                  <Entry
                    ctaLink={entry.ctaLink ?? entry.ctaReference}
                    image={entry.image}
                    badge={entry.title}
                    text={entry.description}
                  />
                </div>
              ))}
            </div>
          ) : (
            <Slider {...sliderConfiguration} className="swiper--mobile-full">
              {items.slice(1).map((entry, index) => (
                <Entry
                  key={`${tasticId}-slide-${index}`}
                  ctaLink={entry.ctaLink ?? entry.ctaReference}
                  image={entry.image}
                  badge={entry.title}
                  text={entry.description}
                />
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
}
