import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Disclosure } from '@headlessui/react';
import { Product } from '@Types/product/Product';
import debounce from 'lodash.debounce';
import { default as IconClose } from 'components/icons/close';
import { default as IconMinus } from 'components/icons/minus';
import { default as IconPlus } from 'components/icons/plus';
import { useFormat } from 'helpers/hooks/useFormat';
import { updateURLParams, URLParam } from 'helpers/utils/updateURLParams';
import { validFilterKeys } from './index';
import SortingDisclosure from './SortingDisclosure';
import RangeFilter from '../range-filter';
import PriceFilter from '../range-filter/price';

export type SearchFacet = {
  selected: 'TRUE' | 'FALSE';
  name: string;
  filterStyle: string;
  selectedElements: any[];
  selectionType: string;
  associatedFieldName: string;
  type: string;
  unit: string;
  elements: {
    absoluteMaxValue?: number;
    absoluteMinValue?: number;
    selectedMaxValue?: number;
    selectedMinValue?: number;
    text: string;
    selected: 'TRUE' | 'FALSE';
    totalHits: number;
  }[];
  decimalPlaces?: number;
};

interface Filter {
  name: string;
  values: {
    value: string;
  }[];
}

type FiltersProps = {
  filters?: Filter[];
  facets: SearchFacet[];
  products: Product[];
  sortingParam: URLParam;
  updateSortingParams: (param: URLParam) => void;
};

type TermFilteringParamGroups = {
  key: string;
  value: string;
}[];

const Filters: FC<FiltersProps> = ({ facets, sortingParam, updateSortingParams, filters }) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'product' });
  const [prevRouterQueryFilterString, setPrevRouterQueryFilterString] = useState<string>('');
  const [filteringParams, setFilteringParams] = useState<TermFilteringParamGroups>([]);
  const [rangeFilterValues, setRangeFilterValues] = useState<{ [key: string]: [number, number] }>({});

  // Set filterParams and rangeFilterValues from changed url path
  // TODO: Add sorting (use local sortingParam state) when needed/used
  useEffect(() => {
    if (!router.query?.filter || router.query.filter.toString() === prevRouterQueryFilterString) {
      return;
    }

    const filters = Array.isArray(router.query.filter) ? router.query.filter : router.query?.filter?.split(',') || [];
    const filterSets = [];
    const rangeFilterSets = {};
    filters.forEach((filter) => {
      const set = filter.split(':');
      if (set.length === 2) {
        const key = decodeURIComponent(set[0]);
        const value = decodeURIComponent(set[1]);
        // If value is an array set rangeFilterValues
        if (value.slice(0, 1) === '[' && value.slice(-1) === ']') {
          const arr = value.slice(1, -1).split(',');
          if (arr.length === 2) {
            const numArr = [parseFloat(arr[0]), parseFloat(arr[1])];
            if (typeof numArr[0] === 'number' && typeof numArr[1] === 'number') {
              rangeFilterSets[key] = numArr;
            }
          }
        }
        filterSets.push({
          key: key,
          value: value,
        });
      }
    });

    setPrevRouterQueryFilterString(router.query?.filter?.toString() || '');
    setRangeFilterValues({ ...rangeFilterSets });
    setFilteringParams([...filterSets]);
  }, [router.query.filter, prevRouterQueryFilterString]);

  // Set new url path from changed filterParams
  useEffect(() => {
    const params = [];

    if (router.query.query) {
      params.push({
        key: 'query',
        value: router.query.query,
      });
    }

    if (filteringParams) {
      params.push({
        key: 'filter',
        value:
          filteringParams
            .map(({ key, value }) => `${encodeURIComponent(key)}:${encodeURIComponent(value)}`)
            .join(',') || '',
      });
    }

    if (sortingParam) {
      params.push(sortingParam);
    }

    if (params.length > 0) {
      params.push({
        key: 'cursor',
        value: 'offset:0',
      });
    }

    const newPath = updateURLParams(params);
    router.push(newPath);
  }, [filteringParams, sortingParam]);

  const applyRangeFilters = useCallback(
    (name) => {
      if (rangeFilterValues[name]) {
        const [min, max] = rangeFilterValues[name];
        const text = `[${min},${max}]`;
        updateFilteringParam(name, text);
      } else {
        updateFilteringParam(name);
      }
    },
    [rangeFilterValues],
  );

  const addFilteringParams = (termKey: string, value: string) => {
    setFilteringParams((prev) => {
      return [...prev, { key: termKey, value }];
    });
  };

  const updateFilteringParam = (termKey: string, value?: string) => {
    setFilteringParams((prev) => {
      const params = prev.filter((param) => !(param.key === termKey));
      return value ? [...params, { key: termKey, value }] : [...params];
    });
  };

  // https://dmitripavlutin.com/react-throttle-debounce
  const debouncedUpdateFilteringParam = useCallback(debounce(updateFilteringParam, 500), []);

  const removeFilteringParams = (termKey: string, value: string) => {
    setFilteringParams((prev) => {
      return prev.filter((param) => !(param.key === termKey && param.value === value));
    });
  };

  const handleFiltersSubmit = (e) => {
    e.preventDefault();
  };

  const handleFiltersReset = useCallback(
    (e) => {
      e.preventDefault();
      updateSortingParams(undefined);
      setFilteringParams([]);
      setRangeFilterValues({});
    },
    [updateSortingParams],
  );

  // NOTE: Although somewhat redundant: brand, price and other term facets separated for better overview/control
  const brandsFacet = useMemo(
    () => facets?.find((facet) => facet.associatedFieldName === 'brand') as SearchFacet,
    [facets],
  );
  const priceFacet = useMemo(
    () => facets?.find((facet) => facet.associatedFieldName === 'price') as SearchFacet,
    [facets],
  );
  const priceFacetElement = useMemo(
    () => (priceFacet ? [...priceFacet.selectedElements, ...priceFacet.elements][0] : null),
    [priceFacet],
  );
  const priceRangeFacet = useMemo(
    () =>
      priceFacetElement?.absoluteMinValue &&
      priceFacetElement?.absoluteMaxValue &&
      priceFacetElement.absoluteMinValue < priceFacetElement.absoluteMaxValue
        ? {
            min: priceFacetElement.absoluteMinValue * 100, //turn into cents
            max: priceFacetElement.absoluteMaxValue * 100,
            minSelected: priceFacetElement.selectedMinValue * 100 ?? null,
            maxSelected: priceFacetElement.selectedMaxValue * 100 ?? null,
          }
        : null,
    [priceFacetElement],
  );
  const generalFacets = useMemo(() => {
    const sortingArr = Object.values(validFilterKeys)
      .filter((value) => !!value.factFinderMapping)
      .map((value) => value.factFinderMapping);
    return facets
      ?.filter((facet) => facet.associatedFieldName !== 'brand' && facet.associatedFieldName !== 'price')
      .sort((a, b) => {
        if (sortingArr.indexOf(a.associatedFieldName) === -1 || sortingArr.indexOf(b.associatedFieldName) === -1) {
          return 0;
        }
        return sortingArr.indexOf(a.associatedFieldName) - sortingArr.indexOf(b.associatedFieldName);
      }) as SearchFacet[];
  }, [facets]);

  return (
    <form onSubmit={handleFiltersSubmit} onReset={handleFiltersReset}>
      <div className="d-lg-none">
        <SortingDisclosure updateSortingParams={updateSortingParams} />
      </div>
      {filters?.length > 0 && (
        <>
          <div className="d-flex flex-wrap">
            {filters?.map(({ name, values }, groupIndex) => {
              const filterFacet = facets.find((facet) => facet.associatedFieldName === name);
              const displayRange = filterFacet?.filterStyle === 'SLIDER' && filterFacet?.type === 'FLOAT';
              return (
                <div key={`selected-filters-group${groupIndex}`} className="d-flex flex-wrap">
                  {values.map(({ value }, index) => {
                    let valueFormatted = value;
                    if (displayRange) {
                      let arr = [];
                      if (valueFormatted.slice(0, 1) === '[' && valueFormatted.slice(-1) === ']') {
                        arr = value.slice(1, -1).split(',');
                      } else {
                        arr = value.split(',');
                      }
                      if (arr.length === 2) {
                        valueFormatted = new Intl.NumberFormat(router.locale || router.defaultLocale, {
                          maximumFractionDigits: filterFacet.decimalPlaces || 0,
                        }).formatRange(arr[0], arr[1]);
                      }
                    }
                    return (
                      <button
                        key={`selected-filters-group${groupIndex}-item${index}`}
                        type="button"
                        onClick={() => {
                          removeFilteringParams(name, value);
                          if (displayRange) {
                            setRangeFilterValues({});
                          }
                        }}
                        className="btn btn-dark btn-xs me-6 mb-6"
                      >
                        {valueFormatted} {filterFacet?.unit} {IconClose({})}
                      </button>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <hr />
        </>
      )}
      {brandsFacet && (
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <Disclosure.Button className="disclosure-filter-btn">
                {brandsFacet.name}
                {open ? IconMinus({}) : IconPlus({})}
              </Disclosure.Button>
              <Disclosure.Panel className="disclosure-filter-panel">
                <div className="disclosure-filter-panel__inner">
                  {[...brandsFacet.selectedElements, ...brandsFacet.elements].map(({ text }, index) => {
                    const isSelected = filteringParams.some((param) => param.key === 'brand' && param.value === text);
                    return (
                      <div className="form-check filter-item" key={'brand' + text + index}>
                        <input
                          type={brandsFacet.selectionType === 'singleHideUnselected' ? 'radio' : 'checkbox'}
                          className="form-check-input"
                          checked={isSelected}
                          id={'brand-facet' + index}
                          onChange={() =>
                            isSelected ? removeFilteringParams('brand', text) : addFilteringParams('brand', text)
                          }
                        />
                        <label htmlFor={'brand-facet' + index} className="form-check-label">
                          {text}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
      {priceFacet && priceFacetElement && priceRangeFacet && (
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <Disclosure.Button className="disclosure-filter-btn">
                {priceFacet.name}
                {open ? IconMinus({}) : IconPlus({})}
              </Disclosure.Button>
              <Disclosure.Panel className="disclosure-filter-panel">
                <div className="disclosure-filter-panel__inner">
                  <div className="pb-20">
                    <PriceFilter
                      facet={priceRangeFacet}
                      currency="EUR" // TODO: Dynamic currency determination
                      onChange={([min, max]) => {
                        setRangeFilterValues((prev) => ({ ...prev, price: [min / 100, max / 100] }));
                        debouncedUpdateFilteringParam('price', `[${min / 100},${max / 100}]`); // Shortcut avoiding applyRangeFilters
                      }}
                    />
                    {/*
                    <button
                      type="button"
                      className="btn btn-dark btn-sm mt-20 w-100"
                      onClick={() => applyRangeFilters('price')}
                    >
                      {formatMessage({ id: 'apply', defaultMessage: 'Apply' })}
                    </button>
                     */}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
      {generalFacets.map((facet, indexOuter) => {
        const {
          filterStyle,
          name,
          selectionType,
          elements,
          selectedElements,
          associatedFieldName,
          unit,
          decimalPlaces,
        } = facet;
        const validFilter = Object.values(validFilterKeys).find((value) => value.factFinderMapping === name);
        const elems = [...selectedElements, ...elements];
        const rangeData = filterStyle === 'SLIDER' ? elems[0] : null;
        if (
          rangeData?.absoluteMinValue &&
          rangeData?.absoluteMaxValue &&
          !(rangeData?.absoluteMinValue < rangeData?.absoluteMaxValue)
        ) {
          return null;
        }
        return (
          <Disclosure
            key={`${associatedFieldName}-${selectionType}-${indexOuter}`}
            defaultOpen={!!validFilter?.defaultOpen || selectedElements.length > 0}
          >
            {({ open }) => (
              <>
                <Disclosure.Button className="disclosure-filter-btn">
                  {name}
                  {open ? IconMinus({}) : IconPlus({})}
                </Disclosure.Button>
                <Disclosure.Panel className="disclosure-filter-panel">
                  <div className="disclosure-filter-panel__inner">
                    {rangeData?.absoluteMinValue && rangeData?.absoluteMaxValue ? (
                      <div className="pb-20">
                        <RangeFilter
                          facet={{
                            min: rangeData.absoluteMinValue,
                            max: rangeData.absoluteMaxValue,
                            minSelected: rangeData.selectedMinValue ?? null,
                            maxSelected: rangeData.selectedMaxValue ?? null,
                          }}
                          onChange={([min, max]) => {
                            setRangeFilterValues((prev) => ({ ...prev, [name]: [min, max] }));
                            debouncedUpdateFilteringParam(name, `[${min},${max}]`); // Shortcut avoiding applyRangeFilters
                          }}
                          decimalPlaces={decimalPlaces}
                          unit={unit}
                        />
                        {/*
                        <button
                          type="button"
                          className="btn btn-dark btn-sm mt-20 w-100"
                          onClick={() => applyRangeFilters(name)}
                        >
                          {formatMessage({ id: 'apply', defaultMessage: 'Apply' })}
                        </button>
                         */}
                      </div>
                    ) : (
                      <>
                        {elems.map(({ text }, indexInner) => {
                          const isSelected = filteringParams.some(
                            (param) => param.key === associatedFieldName && param.value === text,
                          );
                          return (
                            <div className="form-check filter-item" key={'term' + text + indexInner}>
                              <input
                                type={selectionType === 'singleHideUnselected' ? 'radio' : 'checkbox'}
                                className="form-check-input"
                                checked={isSelected}
                                id={`${associatedFieldName}-facet-${indexInner}`}
                                onChange={() =>
                                  isSelected
                                    ? removeFilteringParams(associatedFieldName, text)
                                    : addFilteringParams(associatedFieldName, text)
                                }
                              />
                              {/* TODO: Handle filterFacet unit translation */}
                              <label
                                htmlFor={`${associatedFieldName}-facet-${indexInner}`}
                                className="form-check-label"
                              >
                                {text} {unit}
                              </label>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        );
      })}
      <div className="mt-8 flex justify-between gap-3">
        <button type="reset" className="btn btn-primary btn-sm w-100">
          {formatMessage({ id: 'clear', defaultMessage: 'Clear' })}
        </button>

        <button type="submit" className="btn btn-secondary btn-sm w-100 mt-2 d-lg-none">
          {formatMessage({ id: 'applyFilters', defaultMessage: 'Apply filters' })}
        </button>
      </div>
    </form>
  );
};

export default Filters;
