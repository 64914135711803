import React, { useState, useEffect, FormEvent, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Account } from '@Types/account/Account';
import { Cart as CartType } from '@Types/cart/Cart';
import Col from 'react-bootstrap/Col';
import { Trans, useTranslation } from 'react-i18next';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference } from 'helpers/reference';
import { NextFrontasticImage } from 'frontastic/lib/image';
import EmptyCart from './emptyCart';
import ItemList from './itemList';
import OrderSummary from './orderSummary';
import { gtmPushItemEvent } from '../../../frontastic/tastics/snaq/products/details';
import Price from '../price';
import Spinner from '../spinner';

export interface Props {
  pageTitle?: string;
  emptyStateImage?: NextFrontasticImage;
  emptyStateTitle?: string;
  emptyStateSubtitle?: string;
  emptyStateCTALabel?: string;
  emptyStateCTALink?: Reference;
  cart: CartType;
  editItemQuantity: (lineItemId: string, newQuantity: number) => Promise<void>;
  itemUpdating?: boolean;
  removeItem: (lineItemId: string) => void;
  account?: Account;
}

const Cart = ({
  cart,
  editItemQuantity,
  itemUpdating,
  removeItem,
  pageTitle,
  emptyStateImage,
  emptyStateTitle,
  emptyStateSubtitle,
  emptyStateCTALabel,
  emptyStateCTALink,
  account,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(true);

  //i18n messages
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { t } = useTranslation(['cart']);

  const router = useRouter();

  const onCheckout = (e: FormEvent) => {
    e.preventDefault();
    router.push('/checkout');
  };

  const goToProductPage = (_url: string) => router.push(_url);

  const onRemoveItem = async (lineItemId: string) => {
    const lineItem = cart?.lineItems.find((lineItem) => lineItem.lineItemId === lineItemId);
    const qtyChange = lineItem?.count || 0;
    await removeItem(lineItemId);
    if (qtyChange > 0) {
      gtmPushItemEvent('remove_from_cart', lineItem, lineItem.variant, qtyChange);
    }
  };

  useEffect(() => {
    if (cart?.lineItems) {
      setLoading(false);
    }
  }, [cart]);

  const minOrderCentAmount: number | null = useMemo(() => {
    if (typeof account?.minOrderValue === 'number' && account.minOrderValue > 0) {
      return account.minOrderValue * 100;
    }
    return null;
  }, [account]);

  const maxOrderCentAmount: number | null = useMemo(() => {
    if (typeof account?.maxOrderValue === 'number' && account.maxOrderValue > 0) {
      return account.maxOrderValue * 100;
    }
    return null;
  }, [account]);

  const minOrderAmountMessage = [
    <Price
      key={0}
      price={{
        centAmount: minOrderCentAmount,
        currencyCode: cart?.sum?.currencyCode,
      }}
      className="inline"
    />,
  ];

  const maxOrderAmountMessage = [
    <Price
      key={0}
      price={{
        centAmount: maxOrderCentAmount,
        currencyCode: cart?.sum?.currencyCode,
      }}
      className="inline"
    />,
  ];

  if (loading) {
    return (
      <div className="flex items-stretch justify-center py-20 px-12">
        <Spinner />
      </div>
    );
  }

  if ((!loading && !cart?.lineItems) || cart.lineItems.length < 1) {
    return (
      <main className="section-spacing">
        <EmptyCart
          pageTitle={pageTitle}
          image={emptyStateImage}
          title={emptyStateTitle}
          subtitle={emptyStateSubtitle}
          ctaLabel={emptyStateCTALabel}
          ctaLink={emptyStateCTALink}
        />
      </main>
    );
  }

  return (
    <main className="section-spacing">
      <h1 className="mb-40">{formatCartMessage({ id: 'cart.shopping', defaultMessage: 'Shopping Cart' })}</h1>
      {(minOrderCentAmount || maxOrderCentAmount) && (
        <div className="mb-6">
          {minOrderCentAmount && (
            <div className="my-1 text-sm">
              <Trans i18nKey="minOrderAmountMessage" t={t} components={minOrderAmountMessage} />
            </div>
          )}
          {maxOrderCentAmount && (
            <div className="my-1 text-sm">
              <Trans i18nKey="maxOrderAmountMessage" t={t} components={maxOrderAmountMessage} />
            </div>
          )}
        </div>
      )}
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">{formatMessage({ id: 'loading', defaultMessage: 'Loading' })}...</span>
          </div>
        </div>
      ) : (
        <form className="row">
          <Col xs={12} xl={8}>
            <ItemList
              cart={cart}
              editItemQuantity={editItemQuantity}
              itemUpdating={itemUpdating}
              goToProductPage={goToProductPage}
              removeItem={onRemoveItem}
            />
          </Col>
          <Col xs={12} xl={4}>
            <OrderSummary
              cart={cart}
              onSubmit={onCheckout}
              showOverview={false}
              showDiscountsForm={true}
              showDisclaimer={false}
              minOrderCentAmount={minOrderCentAmount}
              maxOrderCentAmount={maxOrderCentAmount}
            />
          </Col>
        </form>
      )}
    </main>
  );
};

export default Cart;
