import React from 'react';
import CategorySets from '../../../../../components/snaq-ui/categories/category-sets';

function CategorySelectionsTastic({ id, data }) {
  if (!data?.data?.dataSource) return <></>;

  return (
    <CategorySets
      tasticId={id}
      title={data.title}
      sets={data.data.dataSource}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
}

export default CategorySelectionsTastic;
