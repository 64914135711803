import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg className={`icon icon-tnt ${className}`} viewBox="0 0 92 33.4" fill="#f60" xmlns="http://www.w3.org/2000/svg">
    <path d="M81.7 9.4c.2 0 .4.2.4.4v2.8c0 .2-.2.4-.4.4h-3.2c-.4 0-.8-.1-.8-.1V24c0 .2-.2.4-.4.4h-3.5c-.2 0-.4-.2-.4-.4V13.1s-.3.1-.6.1h-3.4c-.2 0-.4-.2-.4-.4V9.9c0-.2.2-.4.4-.4h12.3v-.1zm-71.3.1c-.2 0-.4.2-.4.4v2.8c0 .2.2.4.4.4h3.4c.3 0 .6-.1.6-.1v11.1c0 .2.2.4.4.4h3.5c.2 0 .4-.2.4-.4v-11s.4.1.8.1h3.2c.2 0 .4-.2.4-.4V9.9c0-.2-.2-.4-.4-.4H10.4zM75.5 0c-6.4 0-12 3.7-14.8 9-2.8-5.3-8.3-9-14.7-9S34.1 3.7 31.3 9C28.5 3.7 23 0 16.6 0 7.5 0 0 7.5 0 16.7s7.4 16.7 16.6 16.7c6.4 0 11.9-3.7 14.7-9 2.8 5.3 8.3 9 14.7 9s11.9-3.7 14.7-9c2.8 5.3 8.3 9 14.7 9 9.1 0 16.6-7.5 16.6-16.7S84.6 0 75.5 0M16.6 29.6c-7.1 0-12.8-5.8-12.8-12.9S9.6 3.8 16.6 3.8c7.1 0 12.8 5.8 12.8 12.9s-5.7 12.9-12.8 12.9m29.4 0c-7.1 0-12.8-5.8-12.8-12.9S39 3.8 46 3.8c7.1 0 12.8 5.8 12.8 12.9.1 7.1-5.7 12.9-12.8 12.9m29.5 0c-7.1 0-12.8-5.8-12.8-12.9S68.5 3.8 75.5 3.8c7.1 0 12.8 5.8 12.8 12.9 0 7.1-5.8 12.9-12.8 12.9M52.4 9.2c.3 0 .5.2.5.4v14.1c0 .2-.2.4-.4.4h-2.9c-.2 0-.4-.1-.5-.2 0 0-5.3-7.2-5.4-7.4-.2-.2-.4-.7-.4-.7v7.8c0 .2-.2.4-.4.4h-3.3c-.2 0-.4-.2-.4-.4v-14c0-.2.2-.4.4-.4h2.9c.2 0 .4.1.5.2 0 0 5.2 7 5.4 7.4.2.2.4.7.4.7V9.6c0-.2.2-.4.4-.4h3.2" />
  </svg>
);

export default Icon;
