import React, { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { Order } from '@Types/cart/Order';
import { PaymentMethod } from '@Types/cart/PaymentMethod';
import classnames from 'classnames';
import { default as IconPayPal } from 'components/icons/paypal-text';
import { useCart, usePaypal } from 'frontastic';
import StripePaymentElement from './stripe/Payment';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import Spinner from '../../spinner';

export enum PaymentProvider {
  commercetools = 'commercetools',
  paypal = 'paypal',
  stripe = 'stripe',
}

const paymentMethods: PaymentMethod[] = [
  /* NOTE: Removed invoice & cashadvance (QVSTSHOP-1052)
  {
    paymentMethodId: 'invoice',
    name: 'Rechnung',
  },
  {
    paymentMethodId: 'cashadvance',
    name: 'Vorkasse',
  },
  */
  {
    paymentMethodId: 'paypal',
    name: 'Paypal',
  },
];

export const gtmPushAddPaymentInfoEvent = (order: Order) => {
  const items = [];
  if (order?.lineItems && order.lineItems.length > 0) {
    order.lineItems.forEach((lineItem) => {
      const item = {
        item_name: lineItem.name,
        item_id: lineItem.variant.sku,
        quantity: lineItem.count,
      };
      if ('brand' in lineItem) {
        item['item_brand'] = lineItem.brand.name;
      }
      if (lineItem.discountedPrice) {
        item['price'] = (lineItem.discountedPrice.centAmount / 100).toFixed(2);
      } else if (lineItem.price) {
        item['price'] = (lineItem.price.centAmount / 100).toFixed(2);
      }
      if ('categories' in lineItem && lineItem.categories.length > 0) {
        lineItem.categories
          .sort((categoryA, categoryB) => categoryA.depth - categoryB.depth)
          .forEach((category, index) => {
            item[`item_category${index > 0 ? index + 1 : ''}`] = category.name;
          });
      }
      items.push(item);
    });
  }
  if (order?.sum) {
    if (process.env.NODE_ENV === 'production' && window.dataLayer) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'add_payment_info',
        ecommerce: {
          currency: order.sum.currencyCode,
          value: (order.sum.centAmount / 100).toFixed(2),
          payment_type: order.payments?.[0].paymentMethod || undefined,
          items: items,
        },
      });
    } else {
      console.info('GtmEvent add_payment_info currency', order.sum.currencyCode);
      console.info('GtmEvent add_payment_info value', (order.sum.centAmount / 100).toFixed(2));
      if (order.payments?.[0].paymentMethod) {
        console.info('GtmEvent add_payment_info payment_type', order.payments?.[0].paymentMethod);
      }
      console.info('GtmEvent add_payment_info items', items);
    }
  }
};

type PaymentProps = {
  loggedIn: boolean;
  paypalCredentials: {
    dataSource: {
      clientID: string;
      base: string;
    };
  };
  stripeCredentials: {
    dataSource: {
      pubKey: string;
    };
  };
};

const Payment: React.FC<PaymentProps> = ({ loggedIn, paypalCredentials, stripeCredentials }) => {
  const router = useRouter();
  const { data, updateCart, checkout, addPaymentByMethod, setLastOrderId } = useCart();
  const { createOrder, capturePayment } = usePaypal();
  const { formatMessage } = useFormat({ name: 'checkout' });

  const [orderComment, setOrderComment] = useState<string>(data?.custom?.fields?.comment || '');
  const [currentPaymentProvider, setCurrentPaymentProvider] = useState<PaymentProvider | null>(null);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<PaymentMethod | null>(null);
  const [optionsInitializing, setOptionsInitializing] = useState<boolean>(true);

  const updateComment = useCallback(
    async (value: string) => {
      if (value === (data?.custom?.fields?.comment || '')) {
        return;
      }
      await updateCart({
        comment: value,
      });
    },
    [data, updateCart],
  );

  const handleOrderCommentChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setOrderComment(e.target.value);
  }, []);

  const handleOrderCommentBlur = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      await updateComment(e.target.value);
    },
    [updateComment],
  );

  const updatePaymentProvider = useCallback((paymentProvider: PaymentProvider | null) => {
    if (paymentProvider === PaymentProvider.commercetools) {
      setCurrentPaymentProvider(PaymentProvider.commercetools);
    }
    if (paymentProvider === PaymentProvider.paypal) {
      setCurrentPaymentProvider(PaymentProvider.paypal);
    }
    if (paymentProvider === PaymentProvider.stripe) {
      setCurrentPaymentProvider(PaymentProvider.stripe);
      setCurrentPaymentMethod(null);
    }
    if (paymentProvider === null) {
      setCurrentPaymentProvider(null);
      setCurrentPaymentMethod(null);
    }
  }, []);

  const updatePaymentMethod = useCallback(
    (paymentMethod: PaymentMethod) => {
      if (!paymentMethod?.paymentMethodId) {
        return;
      }
      setCurrentPaymentMethod(paymentMethod);
      updatePaymentProvider(
        paymentMethod.paymentMethodId === 'paypal' ? PaymentProvider.paypal : PaymentProvider.commercetools,
      );
    },
    [updatePaymentProvider],
  );

  // TODO: Determine if filter needs to be made configurable in studio
  // NOTE: Dummy setup -> subject to change
  const filterAvailablePaymentMethods = useCallback(
    (paymentMethod): boolean => {
      if (paymentMethod.paymentMethodId === 'invoice') {
        if (!loggedIn || data.billingAddress?.country === 'AT') {
          return false;
        }
      }
      return true;
    },
    [data, loggedIn],
  );

  const sendOrder = useCallback(async () => {
    if (!currentPaymentMethod?.paymentMethodId) {
      return;
    }
    // NOTE: paypal using /action/paypal/createOrder action to addPayment
    if (currentPaymentMethod.paymentMethodId !== 'paypal') {
      await addPaymentByMethod(currentPaymentMethod.paymentMethodId);
    }
    await updateComment(orderComment);
    try {
      const order = await checkout();
      if (order?.cartId) {
        setLastOrderId(order.cartId);
      }
      gtmPushAddPaymentInfoEvent(order);
      router.push('/thank-you');
    } catch (e) {
      console.error(e);
    }
  }, [router, checkout, addPaymentByMethod, currentPaymentMethod, updateComment, orderComment, setLastOrderId]);

  return (
    <section aria-labelledby="cart-heading">
      <label htmlFor="orderComment" className="form-label mb-20 mob-p-p2 dt-p-p2 text-uppercase fw-bold">
        {formatMessage({ id: 'comment', defaultMessage: 'Comment' })}
      </label>
      <textarea
        id="orderComment"
        name="orderComment"
        onChange={handleOrderCommentChange}
        onBlur={handleOrderCommentBlur}
        value={orderComment}
        rows={6}
        className="form-control"
      />
      <hr className="my-20" />
      <div className="mb-20 mob-p-p2 dt-p-p2 text-uppercase fw-bold">
        <span>{formatMessage({ id: 'payment', defaultMessage: 'Payment' })}</span>
      </div>
      {optionsInitializing && (
        <div className="position-relative d-flex align-items-center justify-content-center">
          <div className="position-absolute top-0 start-50 translate-middle-x">
            <Spinner />
          </div>
        </div>
      )}
      {paymentMethods
        ?.filter((paymentMethod) => filterAvailablePaymentMethods(paymentMethod))
        .map((paymentMethod, index) => {
          const { paymentMethodId, name } = paymentMethod;
          return (
            <div
              key={'paymentMethod' + index}
              className={classnames('mb-2 rounded border p-20', optionsInitializing && 'opacity-0')}
            >
              <div className="form-check form-check-payment">
                <input
                  type="radio"
                  aria-label="Payment method"
                  name="paymentMethodId"
                  id={name}
                  className="form-check-input"
                  checked={currentPaymentMethod?.paymentMethodId === paymentMethodId}
                  value={paymentMethodId}
                  disabled={optionsInitializing}
                  onChange={() => updatePaymentMethod(paymentMethod)}
                />
                <label htmlFor={name} className="form-check-label w-100">
                  {paymentMethodId === 'paypal' && <span className="payment-icon">{IconPayPal({})}</span>}
                  <div className="mob-p-p3 dt-p-p3">{name}</div>
                </label>
              </div>
            </div>
          );
        })}
      <div className={classnames(optionsInitializing && 'opacity-0')}>
        <StripePaymentElement
          currentPaymentProvider={currentPaymentProvider}
          updatePaymentProviderHandler={updatePaymentProvider}
          readyHandler={(stripeReady) => setOptionsInitializing(!stripeReady)}
          pubKey={stripeCredentials?.dataSource?.pubKey}
          cart={data}
        />
      </div>
      {(currentPaymentProvider === PaymentProvider.commercetools ||
        currentPaymentProvider === PaymentProvider.paypal) && (
        <div className={classnames('mt-3', optionsInitializing && 'opacity-0')}>
          {currentPaymentMethod?.paymentMethodId !== 'paypal' && (
            <button
              type="button"
              className="btn btn-primary btn-lg"
              disabled={!currentPaymentMethod}
              onClick={() => sendOrder()}
            >
              {formatMessage({ id: 'placeOrder', defaultMessage: 'Place order now' })}
            </button>
          )}
          <PayPalScriptProvider
            options={{
              clientId: paypalCredentials?.dataSource?.clientID,
              currency: data.totalGross?.currencyCode || 'EUR',
            }}
          >
            {currentPaymentMethod?.paymentMethodId === 'paypal' && (
              <PayPalButtons
                createOrder={async () => {
                  return await createOrder().then((resp) => {
                    return resp.orderId;
                  });
                }}
                onApprove={async () => {
                  const capture = await capturePayment();
                  await sendOrder();
                  return capture;
                }}
                style={{ layout: 'horizontal', label: 'pay' }}
              />
            )}
          </PayPalScriptProvider>
        </div>
      )}
    </section>
  );
};

export default Payment;
