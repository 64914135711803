import React from 'react';
import clsx from 'clsx';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ReactMarkdown from 'react-markdown';
import { default as IconLibraryBooks } from 'components/icons/library-books';
import Typography from 'components/snaq-ui/typography';
import { Reference, ReferenceLink } from 'helpers/reference';

export interface LinksBannerLinkProps {
  icon?: string;
  label?: string;
  reference?: Reference;
  linkTitle?: string;
  linkRel?: string;
  color?: string;
}
export interface LinksBannerProps {
  title: string;
  description: string;
  background: string;
  innerSpacing: string;
  outerSpacing: string;
  links: LinksBannerLinkProps[];
}

const LinksBanner: React.FC<LinksBannerProps> = ({
  title,
  description,
  background,
  innerSpacing,
  outerSpacing,
  links,
}) => {
  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      <div className="links-banner">
        <Row className="justify-content-center">
          <Col xs={12} xl={11}>
            {title && (
              <h2 className="h1 links-banner__title">
                <Typography>{title}</Typography>
              </h2>
            )}
            {description && (
              <div className="links-banner__text">
                <ReactMarkdown>{description}</ReactMarkdown>
              </div>
            )}
            <div className="links-banner__links">
              {links.map((link, index) => (
                <div key={'linksBannerLink' + index}>
                  <ReferenceLink
                    target={link.reference}
                    title={link.linkTitle}
                    rel={link.linkRel}
                    className={clsx(
                      link.color === 'primary' ? 'btn-primary' : 'btn-secondary',
                      ' btn btn-sm btn-xl-md',
                    )}
                  >
                    {IconLibraryBooks({})}
                    {link.label}
                  </ReferenceLink>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LinksBanner;
