import React from 'react';
import Slider, { SliderProps } from 'components/snaq-ui/slider';
import { Reference, ReferenceLink } from 'helpers/reference';
import Slide, { MediaTextSlideProps } from './slide';
import { default as IconChevronRight } from '../../../icons/chevron-right';

export interface MediaTextSliderProps {
  title?: string;
  ctaLabel?: string;
  ctaLink?: Reference;
  ctaTitle?: string;
  ctaRel?: string;
  slides: MediaTextSlideProps[];
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

const MediaTextSlider: React.FC<MediaTextSliderProps> = ({
  slides,
  background,
  innerSpacing,
  outerSpacing,
  title,
  ctaLabel,
  ctaLink,
  ctaTitle,
  ctaRel,
}) => {
  const sliderFixedMood: SliderProps = {
    arrows: false,
    dots: true,
    freeMode: false,
    scrollbar: false,
    loop: true,
    centeredSlides: true,
    spaceBetween: 20,
  };

  const sliderConfiguration: SliderProps = sliderFixedMood;

  return (
    <div className={`media-text-slider ${background} ${innerSpacing} ${outerSpacing}`}>
      {title && (
        <div className={'container'}>
          <div className="d-flex justify-content-between align-items-end pb-20">
            {title && <h2 className="h1 products-slider__title mb-0">{title}</h2>}
            {ctaLabel && ctaLink && (
              <ReferenceLink
                target={ctaLink}
                title={ctaTitle}
                rel={ctaRel}
                className="products-slider__link link-reset dt-c-c2 mb-1"
              >
                <span className="d-none d-xl-block">{ctaLabel}</span>
                {IconChevronRight({})}
              </ReferenceLink>
            )}
          </div>
        </div>
      )}
      <Slider {...sliderConfiguration}>
        {slides.map((slide, index) => (
          <Slide
            key={'mediaTextSlide' + index}
            youtubeId={slide.youtubeId}
            media={slide.media}
            mediaMobile={slide.mediaMobile}
            overline={slide.overline}
            title={slide.title}
            description={slide.description}
            ctaLabel={slide.ctaLabel}
            ctaReference={slide.ctaReference}
            contentPosition={slide.contentPosition}
            contentBackground={slide.contentBackground}
          />
        ))}
      </Slider>
    </div>
  );
};

export default MediaTextSlider;
