import CategorySets from '../../../../../components/snaq-ui/categories/category-sets';

function CategorySetsTastic({ id, data }) {
  if (!data?.data?.dataSource) return <></>;

  return (
    <CategorySets
      tasticId={id}
      sets={data.data.dataSource}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
}

export default CategorySetsTastic;
