import React, { useEffect, useState } from 'react';
import { LineItem } from '@Types/wishlist/LineItem';
import { Variant } from '@Types/wishlist/Variant';
import { Wishlist } from '@Types/wishlist/Wishlist';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference } from 'helpers/reference';
import EmptyWishlist from './empty_wishlist';
import List from './list';
import Spinner from '../spinner';

export interface Props {
  pageTitle?: string;
  emptyStateImage?: { media: any } | any;
  emptyStateTitle?: string;
  emptyStateSubtitle?: string;
  emptyStateCTALabel?: string;
  emptyStateCTALink?: Reference;
  items?: Wishlist;
  removeLineItems: (item: LineItem) => Promise<void>;
  addToCart: (variant: Variant) => Promise<void>;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

const WishList: React.FC<Props> = ({
  pageTitle,
  emptyStateImage,
  emptyStateTitle,
  emptyStateSubtitle,
  emptyStateCTALabel,
  emptyStateCTALink,
  items,
  removeLineItems,
  addToCart,
  background,
  innerSpacing,
  outerSpacing,
}) => {
  const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (items?.lineItems) setLoading(false);
  }, [items]);

  if (loading)
    return (
      <div className="d-flex align-items-center justify-content-center py-5">
        <Spinner />
      </div>
    );

  if (items.lineItems.length === 0)
    return (
      <main className={`${background} ${innerSpacing} ${outerSpacing}`}>
        <EmptyWishlist
          pageTitle={pageTitle}
          title={emptyStateTitle}
          subtitle={emptyStateSubtitle}
          ctaLabel={emptyStateCTALabel}
          ctaLink={emptyStateCTALink}
          image={emptyStateImage}
        />
      </main>
    );

  return (
    <main className={`${background} ${innerSpacing} ${outerSpacing}`}>
      <h1 className="mb-3">{formatWishlistMessage({ id: 'wishlist', defaultMessage: 'Wishlist' })}</h1>
      <List items={items.lineItems} removeLineItems={removeLineItems} addToCart={addToCart} />
    </main>
  );
};

export default WishList;
