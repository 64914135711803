import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Cart from 'components/snaq-ui/cart';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount, useCart } from 'frontastic/provider';
import { gtmPushItemEvent } from '../products/details';

const CartTastic = ({ data }) => {
  const { formatMessage } = useFormat({ name: 'cart' });
  const { data: cartList, removeItem, updateItem } = useCart();
  const { account } = useAccount();
  const router = useRouter();

  const [itemUpdating, setItemUpdating] = useState<boolean>(false);

  const editItemQuantity = async (lineItemId: string, newQuantity: number) => {
    setItemUpdating(true);
    try {
      const lineItem = cartList?.lineItems.find((lineItem) => lineItem.lineItemId === lineItemId);
      const qtyChange = newQuantity - (lineItem?.count || 0);
      await updateItem(lineItemId, newQuantity);
      if (qtyChange > 0) {
        gtmPushItemEvent('add_to_cart', lineItem, lineItem.variant, qtyChange);
      } else if (qtyChange < 0) {
        gtmPushItemEvent('remove_from_cart', lineItem, lineItem.variant, -qtyChange);
      }
    } finally {
      setItemUpdating(false);
    }
  };

  // GTM event tracking
  const [gtmEventPushed, setGtmEventPushed] = useState<boolean>(false);
  useEffect(() => {
    setGtmEventPushed(false);
  }, [router]);
  useEffect(() => {
    if (gtmEventPushed || !cartList) {
      return;
    }
    setGtmEventPushed(true);
    const items = [];
    if (cartList?.lineItems && cartList.lineItems.length > 0) {
      cartList.lineItems.forEach((lineItem) => {
        const item = {
          item_name: lineItem.name,
          item_id: lineItem.variant.sku,
          quantity: lineItem.count,
        };
        if ('brand' in lineItem) {
          item['item_brand'] = lineItem.brand.name;
        }
        if (lineItem.discountedPrice) {
          item['price'] = (lineItem.discountedPrice.centAmount / 100).toFixed(2);
        } else if (lineItem.price) {
          item['price'] = (lineItem.price.centAmount / 100).toFixed(2);
        }
        if ('categories' in lineItem && lineItem.categories.length > 0) {
          lineItem.categories
            .sort((categoryA, categoryB) => categoryA.depth - categoryB.depth)
            .forEach((category, index) => {
              item[`item_category${index > 0 ? index + 1 : ''}`] = category.name;
            });
        }
        items.push(item);
      });
    }
    if (process.env.NODE_ENV === 'production' && window.dataLayer) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'view_cart',
        ecommerce: {
          currency: cartList.sum.currencyCode,
          value: (cartList.sum.centAmount / 100).toFixed(2),
          items: items,
        },
      });
    } else {
      console.info('GtmEvent view_cart currency', cartList.sum.currencyCode);
      console.info('GtmEvent view_cart value', (cartList.sum.centAmount / 100).toFixed(2));
      console.info('GtmEvent view_cart items', items);
    }
  }, [gtmEventPushed, cartList]);

  return (
    <>
      <Head>
        <title>{formatMessage({ id: 'checkout', defaultMessage: 'checkout' })}</title>
        <meta name="description" content={formatMessage({ id: 'checkout', defaultMessage: 'checkout' })} />
      </Head>
      <Cart
        cart={cartList}
        removeItem={removeItem}
        editItemQuantity={editItemQuantity}
        itemUpdating={itemUpdating}
        pageTitle={data.pageTitle}
        emptyStateImage={data.emptyStateImage}
        emptyStateTitle={data.emptyStateTitle}
        emptyStateSubtitle={data.emptyStateSubtitle}
        emptyStateCTALabel={data.emptyStateCTALabel}
        emptyStateCTALink={data.emptyStateCTALink}
        account={account}
      />
    </>
  );
};

export default CartTastic;
