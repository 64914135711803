import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-chevron-up ${className}`}
    viewBox="0 0 82 47"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.6 37.7 38 1.2c.4-.4.9-.7 1.4-.9.5-.2 1-.3 1.6-.3.6 0 1.1.1 1.6.3.5.2 1 .5 1.4.9l36.5 36.5c1 1 1.5 2.3 1.5 3.8s-.5 2.8-1.6 3.9S78 47 76.6 47s-2.7-.5-3.8-1.6L41 13.5 9.2 45.4c-1 1-2.3 1.5-3.7 1.5s-2.8-.5-3.8-1.6C.5 44.2 0 42.9 0 41.5c0-1.5.5-2.7 1.6-3.8z" />
  </svg>
);

export default Icon;
