import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-support-agent ${className}`}
    viewBox="0 0 20 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 18v-2h8V8.9c0-1-.2-1.9-.6-2.7-.4-.8-.9-1.6-1.5-2.2s-1.4-1.1-2.2-1.5c-.8-.4-1.7-.6-2.7-.6s-1.9.2-2.7.6S5.7 3.3 5 4 3.9 5.3 3.5 6.2C3.2 7 3 7.9 3 8.9V15H2c-.6 0-1-.2-1.4-.6C.2 14 0 13.6 0 13v-2c0-.4.1-.7.3-1s.4-.5.7-.7L1.1 8c.2-1.2.5-2.3 1-3.3s1.2-1.8 2-2.5S5.8 1 6.8.6 8.9 0 10 0s2.2.2 3.2.6c1 .4 1.9.9 2.7 1.6.8.7 1.5 1.5 2 2.5s.9 2.1 1 3.3l.1 1.2c.3.2.5.4.7.7.2.3.3.6.3 1v2.3c0 .4-.1.7-.3 1-.2.3-.4.5-.7.7V16c0 .6-.2 1-.6 1.4-.4.4-.8.6-1.4.6H9zm-2-7c-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7s.1-.5.3-.7c.2-.2.4-.3.7-.3s.5.1.7.3c.2.2.3.4.3.7s-.1.5-.3.7c-.2.2-.4.3-.7.3zm6 0c-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7s.1-.5.3-.7c.2-.2.4-.3.7-.3s.5.1.7.3c.2.2.3.4.3.7s-.1.5-.3.7c-.2.2-.4.3-.7.3zM4 9.4c0-.9.1-1.8.4-2.6.3-.8.8-1.5 1.4-2 .6-.6 1.2-1 2-1.3S9.3 3 10.1 3c1.5 0 2.8.5 3.9 1.4 1.1 1 1.8 2.2 2 3.6-1.6 0-3-.4-4.2-1.3S9.5 4.9 8.9 3.5C8.6 4.9 8 6.1 7.2 7.1 6.3 8.2 5.3 8.9 4 9.4z" />
  </svg>
);

export default Icon;
