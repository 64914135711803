import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-payment-mastercard ${className}`}
    viewBox="0 0 131.39 86.9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="#ff5f00" d="M48.37 15.14h34.66v56.61H48.37z" />
    <path
      fill="#eb001b"
      d="M51.94 43.45a35.94 35.94 0 0 1 13.75-28.3 36 36 0 1 0 0 56.61 35.94 35.94 0 0 1-13.75-28.31Z"
    />
    <path
      fill="#f79e1b"
      d="M120.5 65.76V64.6h.5v-.24h-1.19v.24h.47v1.16Zm2.31 0v-1.4h-.36l-.42 1-.42-1h-.36v1.4h.26V64.7l.39.91h.27l.39-.91v1.06ZM123.94 43.45a36 36 0 0 1-58.25 28.3 36 36 0 0 0 0-56.61 36 36 0 0 1 58.25 28.3Z"
    />
  </svg>
);

export default Icon;
