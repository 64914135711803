import React, { useMemo } from 'react';
import { Variant } from '@Types/product/Variant';
import parse from 'html-react-parser';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { default as IconCopy } from 'components/icons/copy';
import { default as IconFacebook } from 'components/icons/facebook';
import { default as IconTwitter } from 'components/icons/twitter';
import Image from '../../../../frontastic/lib/image';
import { useFormat } from '../../../../helpers/hooks/useFormat';

export interface Props {
  variant: Variant;
}

export default function ProductReview({ variant }: Props) {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const reviewContent = useMemo(() => {
    if (!variant) {
      return null;
    }
    const review: {
      author?: string;
      imgSrc?: string;
      date?: string;
      short_description?: string;
      long_description?: string;
      pro?: string;
      contra?: string;
    } = {};
    if (variant.attributes['expert_review_author']) {
      review['author'] = variant.attributes['expert_review_author'];
    }
    if (
      variant.attributes['expert_review_author_image_url'] &&
      variant.attributes['expert_review_author_image_url'].label
    ) {
      review['imgSrc'] = variant.attributes['expert_review_author_image_url'].label;
    }
    if (variant.attributes['expert_review_date']) {
      review['date'] = variant.attributes['expert_review_date'];
    }
    if (variant.attributes['expert_review_short_description']) {
      review['short_description'] = variant.attributes['expert_review_short_description'];
    }
    if (variant.attributes['expert_review_long_description']) {
      review['long_description'] = variant.attributes['expert_review_long_description'];
    }
    if (variant.attributes['expert_review_pro']) {
      review['pro'] = variant.attributes['expert_review_pro'];
    }
    if (variant.attributes['expert_review_contra']) {
      review['contra'] = variant.attributes['expert_review_contra'];
    }
    return Object.keys(review).length > 0 ? review : null;
  }, [variant]);

  if (!reviewContent) return null;

  return (
    <>
      <div className="bg-grey-full section-spacing-padding-bottom">
        <Row>
          <Col>
            {/* START: Visible up to sm */}
            <div className="d-flex align-items-center pb-15 pb-sm-20 d-sm-none">
              {/* Image */}
              {reviewContent.imgSrc && reviewContent.author && (
                <div className="position-relative flex-shrink-0 me-15 me-sm-20 ratio ratio-1x1 w-px-40 w-px-sm-100">
                  <Image
                    src={reviewContent.imgSrc}
                    className="object-fit-cover rounded w-100 h-100"
                    alt={reviewContent.author}
                  />
                </div>
              )}
              {/* Title */}
              <h2 className="h1 mb-0 mb-sm-10">
                {formatProductMessage({
                  id: 'expertReview',
                  defaultMessage: 'Expert review',
                })}
              </h2>
            </div>
            {/* END: Visible up to sm */}

            <div className="d-flex align-items-center pb-10 pb-sm-20">
              {/* Image - Visible from sm */}
              {reviewContent.imgSrc && reviewContent.author && (
                <div className="d-none d-sm-block position-relative flex-shrink-0 me-15 me-sm-20 ratio ratio-1x1 w-px-40 w-px-sm-100">
                  <Image
                    src={reviewContent.imgSrc}
                    className="object-fit-cover rounded w-100 h-100"
                    alt={reviewContent.author}
                  />
                </div>
              )}
              <div>
                {/* Title - Visible from sm */}
                <h2 className="h1 d-none d-sm-block">
                  {formatProductMessage({
                    id: 'expertReview',
                    defaultMessage: 'Expert review',
                  })}
                </h2>
                <div className="d-flex mob-p-p2 dt-p-p2 text-uppercase align-items-center">
                  {/* Name */}
                  {reviewContent.author && <p className="m-0">{reviewContent.author}</p>}

                  {/* Date */}
                  {reviewContent.date && (
                    <>
                      {reviewContent.author && (
                        <span
                          className="mx-10 d-block bg-grey-100 rounded-circle"
                          style={{ width: '8px', height: '8px' }}
                        ></span>
                      )}
                      <p className="m-0">
                        {reviewContent.date && new Date(reviewContent.date).toLocaleDateString('de-DE')}
                      </p>
                    </>
                  )}

                  {/* Social list */}
                  {/* <ul className="list-unstyled d-flex ps-15 ps-sm-25">
                    <li className="pe-15">
                      <a href="#" className="d-block text-grey-100">
                        {IconCopy({className: 'h-px-18 w-auto d-block'})}
                      </a>
                    </li>
                    <li className="pe-15">
                      <a href="#" className="d-block text-grey-100">
                        {IconFacebook({className: 'h-px-18 w-auto d-block'})}
                      </a>
                    </li>
                    <li className="pe-15">
                      <a href="#" className="d-block text-grey-100">
                        {IconTwitter({className: 'h-px-18 w-auto d-block'})}
                      </a>
                    </li>
                  </ul> */}

                  {/* Visible from sm */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {/* long and short description */}
          {(reviewContent.short_description || reviewContent.long_description) && (
            <Col xl={reviewContent.pro || reviewContent.contra ? 5 : 12}>
              {reviewContent.short_description && parse(reviewContent.short_description)}

              <Accordion className="accordion--btn-style">
                <Accordion.Item eventKey="0">
                  <Accordion.Body className="last-child-no-mb">
                    {reviewContent.long_description && parse(reviewContent.long_description)}
                  </Accordion.Body>
                  <Accordion.Header>
                    {formatProductMessage({
                      id: 'readMore',
                      defaultMessage: 'Read more',
                    })}
                  </Accordion.Header>
                </Accordion.Item>
              </Accordion>
            </Col>
          )}

          {/* pro and contra list*/}
          {(reviewContent.pro || reviewContent.contra) && (
            <Col
              xl={reviewContent.short_description || reviewContent.long_description ? 7 : 12}
              className="mob-p-p3 dt-p-p3"
            >
              <div className="bg-white p-20 rounded-3 mt-20 mt-xl-0">
                <Row>
                  {reviewContent.pro && (
                    <Col sm={6}>
                      <div className="list-pro mob-p-p2 dt-p-p2">
                        <h5 className="mb-15">
                          {formatProductMessage({
                            id: 'whatWeLike',
                            defaultMessage: 'What we like',
                          })}
                        </h5>
                        {parse(reviewContent.pro)}
                      </div>
                    </Col>
                  )}
                  {reviewContent.contra && (
                    <Col sm={6}>
                      <div className="list-contra mob-p-p2 dt-p-p2 pt-20 pt-sm-0">
                        <h5 className="mb-15">
                          {formatProductMessage({
                            id: 'whatWeDontLike',
                            defaultMessage: 'What we don´t like',
                          })}
                        </h5>
                        {parse(reviewContent.contra)}
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
}
