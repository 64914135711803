import React, { useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { Reference, ReferenceLink } from 'helpers/reference';
import { sm, md, lg } from 'helpers/utils/screensizes';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { default as IconPlayCircle } from '../../../icons/play-circle';
import Typography from '../../typography';

export interface TextOnMediaProps {
  width?: string;
  roundedCorners?: boolean;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
  textPosition?: string;
  textColor?: string;
  textBackground?: string;
  title?: string;
  text?: string;
  ctaBackground?: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  ctaTitle?: string;
  ctaRel?: string;
  mediaHeight?: string;
  media?: MediaItemWithMods;
}

const TextOnMedia: React.FC<TextOnMediaProps> = ({
  width,
  roundedCorners,
  background,
  innerSpacing,
  outerSpacing,
  textPosition,
  textColor,
  textBackground,
  title,
  text,
  ctaBackground,
  ctaLabel,
  ctaReference,
  ctaTitle,
  ctaRel,
  mediaHeight,
  media,
}) => {
  const [isLg] = useMediaQuery(lg);
  const hasContent = 0 < title.length || 0 < text.length || 0 < ctaLabel.length;

  // define css classes
  const fullWidth = width === 'full';
  const widthCSS = fullWidth ? 'width-full' : '';
  const heightCSS = mediaHeight === 'dynamic' ? '' : `text-on-media--height text-on-media--height-${mediaHeight}`;
  const roundedCSS = roundedCorners && isLg ? 'rounded overflow-hidden' : '';
  const textPositionCSS = useMemo(() => {
    let cssClass = '';
    if (textPosition === 'lt') cssClass += ' justify-content-start';
    if (textPosition === 'lc') cssClass += ' justify-content-start align-items-center';
    if (textPosition === 'lb') cssClass += ' justify-content-start align-items-end';
    if (textPosition === 'rt') cssClass += ' justify-content-end';
    if (textPosition === 'rc') cssClass += ' justify-content-end align-items-end';
    if (textPosition === 'rb') cssClass += ' justify-content-end align-items-end';
    if (!hasContent) cssClass += ' d-none';
    return cssClass;
  }, [textPosition]);

  const textCSS = useMemo(() => {
    let cssClass = '';
    if (textBackground === 'blurry') cssClass += ' bg-md-blurry rounded p-md-40';
    if (textColor === 'white') cssClass += ' text-md-white';
    return cssClass;
  }, [textBackground]);

  const ctaCSS = useMemo(() => {
    let cssClass = 'btn btn-sm btn-xl-md';
    if (ctaBackground === 'red') cssClass += ' btn-primary';
    if (ctaBackground === 'black') cssClass += ' btn-secondary';
    return cssClass;
  }, [ctaBackground]);

  // define inline style height for container
  const contentMediaStyle = () => {
    const style = {
      height: mediaHeight === '' ? '' : isLg ? `${mediaHeight}px` : '',
    };
    return style;
  };

  const contentMedia = (() => {
    const mediaType = media?.media?.resourceType;

    if ('image' === mediaType) {
      return <Image media={media} alt={title} className={'object-fit-cover h-100 w-100 d-block'} />;
    }

    if ('video' === mediaType) {
      return (
        <video preload="metadata" controls={false} className={'object-fit-cover h-100 w-100 d-block'}>
          <source src={media?.media?.file} type={`video/${media?.media?.format}`} />
        </video>
      );
    }
    return <></>;
  })();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mediaVideo = 'video' === media?.media?.resourceType;

  // video modal
  const [modalShow, modalSetShow] = useState(false);
  const modalHandleClose = () => modalSetShow(false);
  const modalHandleShow = () => modalSetShow(true);

  const { formatMessage } = useFormat({ name: 'common' });

  return (
    <div
      className={`text-on-media ${heightCSS} ${widthCSS} ${roundedCSS} ${background} ${innerSpacing} ${outerSpacing}`}
    >
      <div
        className={`text-on-media__media position-relative overflow-hidden ${!fullWidth && !isLg ? 'rounded' : ''}`}
        style={contentMediaStyle()}
      >
        {contentMedia}
      </div>
      <div className={`position-md-absolute h-100 w-100 start-0 top-0`}>
        <div className={`container h-100 ${fullWidth ? 'px-lg-0' : 'px-0'}`}>
          <div className="row justify-content-center h-100">
            <div className="col h-100">
              <div className={`row h-100 ${textPositionCSS}`}>
                <div className={`col-12 col-lg-7 col-xl-6`}>
                  <div className="pt-20 p-md-50">
                    <div className={textCSS}>
                      {title && (
                        <div className={`mob-h-h2 dt-h-h2`}>
                          <h2>{title}</h2>
                        </div>
                      )}
                      {text && (
                        <div className={`mob-p-p3 dt-p-p3 pt-6`}>
                          <p className="mb-0">{text}</p>
                        </div>
                      )}
                      {mediaVideo && (
                        <div className={`pt-20`}>
                          <button className={'btn btn-sm btn-xl-md btn-primary'} onClick={modalHandleShow}>
                            {IconPlayCircle({})}
                            {formatMessage({
                              id: 'playVideo',
                              defaultMessage: 'Play video',
                            })}
                          </button>
                          <Modal show={modalShow} onHide={modalHandleClose} centered className={'modal-media'}>
                            <Modal.Header closeButton className={'p-0 border-0'}></Modal.Header>
                            <Modal.Body>
                              <video preload="metadata" controls={true} controlsList="nodownload" autoPlay={modalShow}>
                                <source src={media?.media?.file} type={`video/${media?.media?.format}`} />
                              </video>
                            </Modal.Body>
                          </Modal>
                        </div>
                      )}
                      {ctaLabel && ctaReference && (
                        <div className={`pt-20`}>
                          <ReferenceLink target={ctaReference} title={ctaTitle} rel={ctaRel} className={ctaCSS}>
                            <Typography>{ctaLabel}</Typography>
                          </ReferenceLink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextOnMedia;
