import React, { useMemo } from 'react';
import { Cart } from '@Types/cart/Cart';
import { default as IconEditSquare } from 'components/icons/edit-square';
import { useFormat } from '../../../../helpers/hooks/useFormat';

export interface Props {
  readonly cart: Cart;
  readonly changeStep?: (index: number) => void;
}

const ShippingSummary: React.FC<Props> = ({ cart, changeStep }) => {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const shippingMethod = useMemo(() => {
    if (!cart.availableShippingMethods || !cart.shippingInfo) {
      return null;
    }
    return cart.availableShippingMethods.find(
      (availableShippingMethod) => availableShippingMethod.shippingMethodId === cart.shippingInfo.shippingMethodId,
    );
  }, [cart]);

  const partialDelivery = useMemo(() => {
    return !!cart?.custom?.fields?.partial_delivery;
  }, [cart]);
  if (!shippingMethod?.name) return null;
  return (
    <section>
      <hr className="my-3" />
      <h4 className="mb-3 d-flex justify-content-between">
        <span className="mob-p-p3 dt-p-p3 text-uppercase fw-bold">
          {formatMessage({ id: 'order.shippingInfo', defaultMessage: 'Versandmethode' })}
        </span>
        <button type="button" className="icon-link mob-c-c4 dt-c-c4" onClick={() => changeStep(1)}>
          {IconEditSquare({})}
          {formatMessage({ id: 'change', defaultMessage: 'Change' })}
        </button>
      </h4>
      <p className="mob-p-p3 dt-p-p3 mb-1">{shippingMethod.name}</p>
      {shippingMethod.description && (
        <p className="mob-f-f3 dt-f-f3 m-0 fw-normal text-grey-70">{shippingMethod.description}</p>
      )}
      {partialDelivery && (
        <p className="mob-p-p3 dt-p-p3 mb-0 pt-3">
          {formatMessage({
            id: 'partialDelivery.requested',
            defaultMessage: 'Partial delivery requested',
          })}
        </p>
      )}
    </section>
  );
};

export default ShippingSummary;
