import React, { useMemo } from 'react';
import Typography from 'components/snaq-ui/typography';
import { Reference, ReferenceLink } from 'helpers/reference';

export interface TextProps {
  background?: string;
  backgroundWidth?: string;
  innerSpacing?: string;
  outerSpacing?: string;
  splitContent?: boolean;
  textAlignment: string;
  title: string;
  text: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  ctaTitle?: string;
  ctaRel?: string;
}

const Text: React.FC<TextProps> = ({
  background,
  backgroundWidth,
  innerSpacing,
  outerSpacing,
  splitContent,
  textAlignment,
  title,
  text,
  ctaLabel,
  ctaReference,
  ctaTitle,
  ctaRel,
}) => {
  const bgFullWidth = backgroundWidth === 'full';
  const backgroundColor = bgFullWidth ? `${background}-full` : background;
  const extraPadding = bgFullWidth ? '' : 'px-md-40 px-xl-50';

  const mobileBgCSS = useMemo(() => {
    let cssClass = 'pe-none d-md-none position-absolute top-0 start-0 w-100 h-100';
    if (background === 'bg-white') cssClass += ' bg-white-full';
    if (background === 'bg-black') cssClass += ' bg-black-full';
    if (background === 'bg-grey') cssClass += ' bg-grey-full';
    return cssClass;
  }, [background]);

  return (
    <div
      className={`position-relative ${textAlignment} ${backgroundColor} ${innerSpacing} ${outerSpacing} ${extraPadding} ${
        background === 'bg-black' ? 'text-white' : ''
      }`}
    >
      <div className="row justify-content-between">
        {title && (
          <div className={`col-12 ${splitContent ? 'col-md-5 col-xl-4' : ''}`}>
            <h2>{title}</h2>
          </div>
        )}

        {text && (
          <div className={`col-12 ${splitContent ? 'col-md-7 col-xl-6' : ''}`}>
            <p className="mob-p-p2 dt-p-p2">{text}</p>
            {ctaLabel && ctaReference && (
              <ReferenceLink target={ctaReference} title={ctaTitle} rel={ctaRel} className="btn btn-primary">
                <Typography>{ctaLabel}</Typography>
              </ReferenceLink>
            )}
          </div>
        )}
      </div>
      <div className={mobileBgCSS}></div>
    </div>
  );
};

export default Text;
