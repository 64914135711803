import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import classnames from 'classnames';
import { default as IconChevronRight } from 'components/icons/chevron-right';
import Slider, { SliderProps } from 'components/snaq-ui/slider';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { ReferenceLink, Reference } from 'helpers/reference';
import { sm, md, lg } from 'helpers/utils/screensizes';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import Tile from './tile';
import { useSearch } from '../../../../frontastic';
import { TrackEventKey } from '../../../../frontastic/provider/frontastic/UseSearch';

const sliderFixedMood: SliderProps = {
  slidesPerView: 1.5,
  breakpoints: {
    [sm]: {
      slidesPerView: 2,
    },
    [md]: {
      slidesPerView: 3,
    },
    [lg]: {
      slidesPerView: 4,
    },
  },
  arrows: true,
  dots: false,
};

export interface Props {
  products: Product[];
  title?: string;
  teaserImage?: MediaItemWithMods;
  teaserImageMobile?: MediaItemWithMods;
  teaserOverline?: string;
  teaserTitle?: string;
  teaserDescription?: string;
  teaserButtonLabel?: string;
  teaserButtonReference?: Reference;
  teaserButtonTitle?: string;
  teaserButtonRel?: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  ctaTitle?: string;
  ctaRel?: string;
  splitContent?: boolean;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

export default function ProductSlider({
  products,
  title,
  teaserImage,
  teaserImageMobile,
  teaserOverline,
  teaserTitle,
  teaserDescription,
  teaserButtonLabel,
  teaserButtonReference,
  teaserButtonTitle,
  teaserButtonRel,
  ctaLabel,
  ctaReference,
  ctaTitle,
  ctaRel,
  splitContent,
  background,
  innerSpacing,
  outerSpacing,
}: Props) {
  const [isTabletSize] = useMediaQuery(md);
  const router = useRouter();
  const { trackEvent } = useSearch();
  const handleTracking = useCallback(
    (id, title, index) => {
      if (!id) {
        return;
      }
      const query = Array.isArray(router.query?.query) ? router.query?.query.join(',') : router.query?.query;
      try {
        trackEvent(TrackEventKey.click, [
          {
            id: id,
            title: title,
            query: query || 'index',
            pos: index,
            page: 1,
            pageSize: products.length,
          },
        ]);
      } catch (e) {
        console.error(e);
      }
    },
    [trackEvent, router.query, products],
  );

  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      <div className="products-slider">
        {(!!title || (ctaLabel && ctaReference)) && (
          <div
            className={classnames(
              'd-flex  align-items-end mb-20',
              title ? 'justify-content-between' : 'justify-content-end',
            )}
          >
            {!!title && <h2 className="h1 products-slider__title mb-0">{title}</h2>}
            {ctaLabel && ctaReference && (
              <ReferenceLink
                target={ctaReference}
                ariaLabel={ctaLabel}
                title={ctaTitle}
                rel={ctaRel}
                className="products-slider__link link-reset dt-c-c2 mb-1"
              >
                <span className="d-none d-xl-block">{ctaLabel}</span>
                {IconChevronRight({})}
              </ReferenceLink>
            )}
          </div>
        )}
        {teaserImage && (
          <div className="products-slider__teaser mb-30 mb-xl-40">
            <div className="products-slider__img">
              <Image
                src={teaserImageMobile?.media && !isTabletSize ? teaserImageMobile.media.file : teaserImage.media.file}
                alt={teaserTitle ?? title}
                className="object-fit-cover position-absolute top-0 start-0 w-100 h-100 d-block"
              />
            </div>
            {(teaserOverline || teaserTitle || teaserDescription || teaserButtonReference) && (
              <div className={`products-slider__teaser-content justify-content-end align-items-start`}>
                {teaserOverline && <div className="text-uppercase mob-p-p4 dt-p-p2 pb-10">{teaserOverline}</div>}
                {teaserTitle && <h3 className="h2">{teaserTitle}</h3>}
                {teaserDescription && <p className="mob-p-15 dt-p-p3 mb-20">{teaserDescription}</p>}
                {teaserButtonLabel && teaserButtonReference && (
                  <ReferenceLink
                    target={teaserButtonReference}
                    ariaLabel={teaserButtonLabel}
                    title={teaserButtonTitle}
                    rel={teaserButtonRel}
                    className={`btn btn-primary ${splitContent ? 'mt-auto mt-md-0' : ''}`}
                  >
                    {teaserButtonLabel}
                  </ReferenceLink>
                )}
              </div>
            )}
          </div>
        )}
        <Slider {...sliderFixedMood} className="swiper--mobile-full">
          {products.map((product, index: number) => (
            <Tile
              {...product}
              key={index}
              trackingHandler={() => handleTracking(product.productId, product.name, index + 1)}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
}
