import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg className={`icon icon-dhl ${className}`} viewBox="0 0 175.7 38.8" xmlns="http://www.w3.org/2000/svg">
    <path fill="#fecc00" d="M0 38.8V0h175.7v38.8z" />
    <path
      d="M56.7 16.2c-.8 1-2.1 2.8-2.8 3.9-.4.5-1.1 1.5 1.3 1.5h12.5s2-2.7 3.7-5c2.3-3.1.2-9.6-8-9.6H31l-5.6 7.6H56c1.4 0 1.4.6.7 1.6zm-9.2 7.3c-2.4 0-1.7-1-1.3-1.5.8-1.1 2.1-2.8 2.9-3.9.8-1 .8-1.6-.8-1.6h-14L23 31.8h27.5c9.1 0 14.1-6.2 15.7-8.3H47.5zm17.9 8.3h16.1l6.1-8.3H71.5l-6.1 8.3zM107 7l-6.2 8.4h-7.2L99.8 7H83.7L72.9 21.6h39.5L123.1 7H107zM88.7 31.8h16.1l6.1-8.3H94.8l-6.1 8.3zM0 26.8v1.8h22.5l1.3-1.8H0zm26.2-3.3H0v1.8h24.9l1.3-1.8zM0 31.8h20.1l1.3-1.8H0v1.8zm152.1-3.3h23.7v-1.8h-22.4l-1.3 1.8zm-2.4 3.3h26.1V30H151l-1.3 1.8zm6.1-8.3-1.3 1.8h21.3v-1.8h-20zm-21.1-1.9L145.4 7h-17.1l-10.8 14.7h17.2zm-18.5 1.9-1.8 2.4c-2 2.7-.2 5.9 6.4 5.9h25.9l6.1-8.3h-36.6z"
      fill="#d50029"
    />
  </svg>
);

export default Icon;
