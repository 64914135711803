import React, { useMemo, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Account } from '@Types/account/Account';
import { Category } from '@Types/snaq/product/Category';
import classnames from 'classnames';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import { default as IconApps } from 'components/icons/apps';
import { default as IconFlash } from 'components/icons/flash';
import { default as IconMenu } from 'components/icons/menu-icon';
import Typography from 'components/snaq-ui/typography';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getReferenceTarget, isLiveReference, Reference, ReferenceLink } from 'helpers/reference';
import { xxl } from 'helpers/utils/screensizes';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import AccountButton from './account-button';
import CartButton from './cart-button';
import ComparisonButton from './comparison-button';
import HeaderMenu, { Tree } from './header-menu';
import SearchInput from './search-input';
import WishListButton from './wishlist-button';
import { useFormat } from '../../../helpers/hooks/useFormat';

export interface InfoProps {
  text: string;
  reference?: Reference;
  linkTitle?: string;
  linkRel?: string;
}

export interface Link {
  name: string;
  reference: Reference;
  linkTitle?: string;
  linkRel?: string;
  hideXL?: boolean;
  categoryKey?: string;
}

export interface HeaderProps {
  infos?: InfoProps[];
  links: Link[];
  cartItemCount: number;
  wishlistItemCount?: number;
  comparisonSkus?: string[] | null;
  logo: MediaItemWithMods;
  logoLink: Reference;
  account: Account;
  accountLink: Reference;
  wishlistLink?: Reference;
  comparisonLink?: Reference;
  cartLink: Reference;
  previewId: string;
  categories: Category[];
  specialButton: Link;
}

const Header: React.FC<HeaderProps> = ({
  infos,
  links,
  cartItemCount,
  wishlistItemCount,
  comparisonSkus,
  logo,
  logoLink,
  account,
  accountLink,
  wishlistLink,
  comparisonLink,
  cartLink,
  previewId,
  categories,
  specialButton,
}) => {
  const { formatMessage } = useFormat({ name: 'common' });

  const [open, setOpen] = useState(false);

  //input value
  const [searchValue, setSearchValue] = useState('');

  // mobile offcanvas navigation
  const [show, setShow] = useState(false);
  const handleoOffCanvasClose = () => setShow(false);
  const handleoOffCanvasShow = () => setShow(true);

  const [isXXL] = useMediaQuery(xxl);
  // NOTE: previewID apparently used if pageFolder not yet live in develop/staging envs
  const filteredLinks = useMemo(
    () => links.filter((link) => previewId || isLiveReference(link.reference) || !!link.categoryKey),
    [links],
  );

  const router = useRouter();

  return (
    <div className="d-block">
      {/* Mobile menu */}
      <HeaderMenu
        open={open}
        setOpen={setOpen}
        links={links}
        categories={categories}
        previewId={previewId}
        logo={logo}
        logoLink={logoLink}
      />

      <header className="page-header d-flex flex-column">
        {infos && (
          <div className="bg-primary px-10 pb-10 text-white w-100 mob-c-c4 dt-c-c4">
            <ul className="list-unstyled d-flex justify-content-center text-nowrap flex-wrap">
              {infos.map((item, index) => {
                return (
                  <li key={'infoBarEntry' + index} className="pt-10 px-10 px-lg-25 px-xl-30 px-xxl-80">
                    {item.reference ? (
                      <ReferenceLink
                        target={item.reference}
                        title={item.linkTitle}
                        rel={item.linkRel}
                        className="text-white text-decoration-none"
                      >
                        {item.text}
                      </ReferenceLink>
                    ) : (
                      item.text
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="d-flex flex-column justify-content-between align-self-md-center page-header__container w-100">
          <Row className="align-items-center py-20">
            {/* Burger menu for off canvas nav mobile */}
            <Col xs={4} className="d-md-none d-flex justify-content-start">
              <div onClick={handleoOffCanvasShow} className="short-link">
                <div className="short-link__item">
                  <div className="short-link__icon h-px-12">{IconMenu({})}</div>
                </div>
              </div>
            </Col>

            {/* Logo */}
            <Col xs={4} md={'auto'}>
              <ReferenceLink target={logoLink} className="logo mx-auto mx-md-0">
                <Image media={logo} alt="Logo" />
              </ReferenceLink>
            </Col>

            {/* Search form desktop */}
            <Col xs={'auto'} className="d-none d-md-block flex-grow-1 px-20 px-lg px-xxl-80">
              <SearchInput value={searchValue} />
            </Col>

            {/* Short links */}
            <Col xs={4} md={'auto'} className="ms-auto">
              <div className="short-links align-items-center">
                {/*<LanguageSwitcher /> */}
                {wishlistItemCount > 0 && (
                  <WishListButton wishlistItemCount={wishlistItemCount} wishlistLink={wishlistLink} />
                )}
                {comparisonSkus && <ComparisonButton comparisonSkus={comparisonSkus} comparisonLink={comparisonLink} />}
                <AccountButton account={account} accountLink={accountLink} />
                <CartButton cartItemCount={cartItemCount} cartLink={cartLink} />

                {/* Off canvas navigation mobile */}
                <Offcanvas show={show} onHide={handleoOffCanvasClose} className="offcanvas-navigation">
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                      <ReferenceLink target={logoLink} className="d-block w-px-100">
                        <Image media={logo} alt="Logo" />
                      </ReferenceLink>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Accordion as="ul" className="offcanvas-nav list-unstyled">
                      {filteredLinks.map((link, index) => {
                        if (link.categoryKey && Array.isArray(categories)) {
                          const category = categories.find((category) => category.categoryId === link.categoryKey);
                          if (category) {
                            return (
                              <Tree
                                key={'headerNavOffcanvasMobile' + index}
                                keyPrefix="headerNavOffcanvasMobile"
                                catalog={categories}
                                category={category}
                                setOpen={setShow}
                                level={1}
                              />
                            );
                          }
                        } else if (link.reference) {
                          return (
                            <li key={'headerNavOffcanvasMobile' + index} onClick={handleoOffCanvasClose}>
                              <ReferenceLink target={link.reference} title={link.linkTitle} rel={link.linkRel}>
                                <Typography>{link.name}</Typography>
                              </ReferenceLink>
                            </li>
                          );
                        }
                        return null;
                      })}
                    </Accordion>
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
            </Col>
          </Row>
          <div className="dt-main-navigation pb-20 d-none d-md-block">
            <Row className="align-items-center flex-nowrap">
              <Col xs={'auto'} className="flex-shrink-1">
                <nav className="dt-main-navigation">
                  <ul className="dt-main-navigation__ul">
                    <li className="dt-main-navigation__li dt-main-navigation__li--more">
                      <button
                        type="button"
                        className="btn-reset dt-main-navigation__a dt-main-navigation__a--more"
                        onClick={() => setOpen(!open)}
                      >
                        {IconApps({})}
                        {formatMessage({
                          id: 'allCategories',
                          defaultMessage: 'All categories',
                        })}
                      </button>
                    </li>
                    {filteredLinks.map((link, id) => {
                      if (link.categoryKey && Array.isArray(categories)) {
                        const category = categories.find((category) => category.categoryId === link.categoryKey);
                        if (category && !!category.slug) {
                          return (
                            <li key={`dt-main-navigation__li--${id}`} className={`dt-main-navigation__li ${id}`}>
                              <NextLink href={category.slug}>
                                <a
                                  title={link.linkTitle || undefined}
                                  rel={link.linkRel}
                                  className={classnames(
                                    'dt-main-navigation__a',
                                    router.asPath === `/${category.slug}` && 'active',
                                  )}
                                >
                                  {link.name}
                                </a>
                              </NextLink>
                            </li>
                          );
                        }
                      } else if (link.reference) {
                        const target = getReferenceTarget(link.reference);
                        if (!link.hideXL || isXXL) {
                          return (
                            <li key={`dt-main-navigation__li--${id}`} className={`dt-main-navigation__li ${id}`}>
                              <ReferenceLink
                                target={link.reference}
                                title={link.linkTitle}
                                rel={link.linkRel}
                                className={classnames(
                                  'dt-main-navigation__a',
                                  router.asPath === `/${target}` && 'active',
                                )}
                              >
                                <Typography>{link.name}</Typography>
                              </ReferenceLink>
                            </li>
                          );
                        }
                      }
                      return null;
                    })}
                  </ul>
                </nav>
              </Col>
              {specialButton.reference && specialButton.name && (
                <Col xs={'auto'} className="text-end ms-auto">
                  <ReferenceLink
                    target={specialButton.reference}
                    title={specialButton.linkTitle}
                    rel={specialButton.linkRel}
                    className="btn btn-primary btn-sm"
                  >
                    {specialButton.name} {IconFlash({})}
                  </ReferenceLink>
                </Col>
              )}
            </Row>
          </div>

          {/* Search form mobile */}
          <div className="pb-20 d-md-none">
            <SearchInput value={searchValue} />
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
