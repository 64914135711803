import React from 'react';
import CategoryHero from 'components/snaq-ui/categories/category-hero';

const CategoryHeroTastic = ({ data }) => {
  const category = data?.data?.dataSource;
  const background = data?.background;
  if (!category) return <></>;
  return (
    <CategoryHero
      background={background}
      image={category.category_header_image_url}
      title={category.seo_h1_main_heading_tag || category.name}
      subtitle={category.description}
    />
  );
};

export default CategoryHeroTastic;
