import ProductSlider from 'components/snaq-ui/products/slider';

function ProductSliderTastic({ data }) {
  if (!data?.data?.dataSource?.items || data.data.dataSource.items.length === 0) {
    return null;
  }

  return (
    <ProductSlider
      products={data.data.dataSource.items}
      title={data.title}
      ctaLabel={data.ctaLabel}
      ctaReference={data.ctaReference}
      ctaTitle={data.ctaTitle}
      ctaRel={data.ctaRel}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
}

export default ProductSliderTastic;
