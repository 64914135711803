import { FC, useCallback, useMemo } from 'react';
import { Disclosure } from '@headlessui/react';
import { Product } from '@Types/product/Product';
import { Facet } from '@Types/result/Facet';
import { RangeFacet } from '@Types/result/RangeFacet';
import { default as IconMinus } from 'components/icons/minus';
import { default as IconPlus } from 'components/icons/plus';
import PriceRange from 'components/snaq-ui/range-filter/price';
import { useFormat } from 'helpers/hooks/useFormat';
import { URLParam } from 'helpers/utils/updateURLParams';

type PriceFilterDisclosureProps = {
  facets: Facet[];
  products: Product[];
  updatePriceFilteringParams: (params: URLParam[]) => void;
};

const PriceFilterDisclosure: FC<PriceFilterDisclosureProps> = ({ products, facets, updatePriceFilteringParams }) => {
  const { formatMessage } = useFormat({ name: 'product' });

  const facet = useMemo(() => facets?.find((facet) => facet.identifier === 'variants.price') as RangeFacet, [facets]);

  const handleChange = useCallback(
    (values: [number, number]) => {
      const params = [
        { key: 'facets[variants.price][min]', value: values[0].toString() },
        { key: 'facets[variants.price][max]', value: values[1].toString() },
      ];
      updatePriceFilteringParams(params);
    },
    [updatePriceFilteringParams],
  );

  if (facet?.min === facet?.max) {
    return null;
  }

  return (
    <Disclosure defaultOpen>
      {({ open }) => (
        <>
          <Disclosure.Button className="disclosure-filter-btn">
            {formatMessage({ id: 'price', defaultMessage: 'Price' })}
            {open ? IconMinus({}) : IconPlus({})}
          </Disclosure.Button>
          <Disclosure.Panel className="disclosure-filter-panel disclosure-filter-panel--price">
            <div className="disclosure-filter-panel__inner">
              <PriceRange
                facet={facet}
                currency={products?.[0]?.variants?.[0]?.price?.currencyCode}
                onChange={handleChange}
              />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default PriceFilterDisclosure;
