import React from 'react';
import ProfileTeasers from 'components/snaq-ui/content/profile-teasers';
import { PageNodeTeaser } from '../../categories/category-experts';

type Props = {
  title?: string;
  profileTeasers: PageNodeTeaser[];
  width?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
};

const ProfileTeasersTastic = ({ id, data }: { id: string; data: Props }) => {
  if (!data?.profileTeasers) return <></>;

  const { title, profileTeasers } = data;

  return (
    <ProfileTeasers
      tasticId={id}
      title={title}
      items={profileTeasers}
      width={data.width}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
};

export default ProfileTeasersTastic;
