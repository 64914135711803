import React from 'react';
import parse from 'html-react-parser';
import Image from '../../../../frontastic/lib/image';
import { useFormat } from '../../../../helpers/hooks/useFormat';

export interface BrandHeroProps {
  imageSrc?: string;
  name: string;
  description?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

const BrandHero: React.FC<BrandHeroProps> = ({
  imageSrc,
  name,
  description,
  background,
  innerSpacing,
  outerSpacing,
}) => {
  const { formatMessage } = useFormat({ name: 'common' });
  if (!name && !description) {
    return null;
  }
  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      <h1 className="font-bold mb-20">{name}</h1>
      <div className="d-flex flex-column flex-sm-row">
        {description && (
          <div className={`mob-p-p3 dt-p-p3 ${imageSrc ? 'pt-20 pt-sm-0 pe-sm-80 pe-xxl-100 order-1 order-sm-0' : ''}`}>
            {parse(description)}
          </div>
        )}
        {imageSrc && <Image src={imageSrc} alt={name} className="w-px-150 h-auto align-self-start" />}
      </div>
    </div>
  );
};

export default BrandHero;
