import React, { FC, useEffect, useRef, useState } from 'react';
import { TwoThumbInputRange } from 'react-two-thumb-input-range';
import { useWindowSize } from 'usehooks-ts';
import Price from '../price';

type RangeInputValues = [number, number];

export interface RangeFacet {
  min?: number;
  max?: number;
  count?: number;
  minSelected?: number;
  maxSelected?: number;
}

export type PriceRangeProps = {
  facet: RangeFacet;
  currency: string;
  onChange: (values: [number, number]) => void;
};

const convertCents = (amountInCents: number) => Math.trunc(amountInCents / 100);

const PriceFilter: FC<PriceRangeProps> = ({ facet, onChange, currency }) => {
  const { min, max, minSelected, maxSelected } = facet as RangeFacet;
  const { width: windowWidth } = useWindowSize();
  const widthRef = useRef(null);
  const [initializing, setInitializing] = useState<boolean>(false);
  const [inputWidth, setInputWidth] = useState<number>(0);
  const [minPrice, setMinPrice] = useState<number | null>(null);
  const [maxPrice, setMaxPrice] = useState<number | null>(null);
  const [values, setValues] = useState<RangeInputValues>([minPrice, maxPrice]);

  useEffect(() => {
    setInputWidth(0);
    setTimeout(() => {
      if (widthRef.current) {
        setInputWidth(widthRef.current.clientWidth);
      }
    }, 200);
  }, [widthRef, windowWidth]);

  useEffect(() => {
    setInitializing(true);
    if (!max) {
      return;
    }
    const minAbsoluteEuro = convertCents(min || 0);
    const maxAbsoluteEuro = Math.ceil(convertCents(max));

    setMinPrice(minAbsoluteEuro);
    setMaxPrice(maxAbsoluteEuro);

    // Setting default values
    if (maxSelected) {
      const minSelectedEuro = convertCents(minSelected || 0);
      const maxSelectedEuro = Math.ceil(convertCents(maxSelected));
      const newMin = minSelectedEuro >= minAbsoluteEuro ? minSelectedEuro : minAbsoluteEuro;
      const newMax = maxSelectedEuro <= maxAbsoluteEuro ? maxSelectedEuro : maxAbsoluteEuro;
      if (newMin < newMax) {
        setValues([newMin, newMax]);
      } else {
        setValues([minAbsoluteEuro, maxAbsoluteEuro]);
      }
    } else {
      setValues([minAbsoluteEuro, maxAbsoluteEuro]);
    }
    setTimeout(() => {
      setInitializing(false);
    }, 200);
  }, [min, max, minSelected, maxSelected]);

  if (values[0] === null || values[1] === null) {
    return null;
  }

  return (
    <>
      <div className="price-range-filter" ref={widthRef}>
        <>
          <Price price={{ fractionDigits: 0, centAmount: values[0], currencyCode: currency }} />
          <div className="price-range-filter__spacer"></div>
          <Price price={{ fractionDigits: 0, centAmount: values[1], currencyCode: currency }} />
        </>
      </div>
      {!initializing && (
        <TwoThumbInputRange
          thumbStyle={{ borderRadius: '100%' }}
          inputStyle={{ width: inputWidth }}
          trackColor="#333"
          railColor="#e0e0e0"
          thumbColor="#333"
          showLabels={false}
          onChange={(updatedValues: RangeInputValues) => {
            if (updatedValues[0] < updatedValues[1]) {
              setValues(updatedValues);
              onChange([updatedValues[0] * 100, updatedValues[1] * 100]);
            }
          }}
          values={values}
          min={minPrice}
          max={maxPrice}
        />
      )}
    </>
  );
};

export default PriceFilter;
