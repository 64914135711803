import React from 'react';
import MarketingPopup from '../../../../../components/snaq-ui/content/marketing-popup';

const MarketingPopupTastic = ({ data }) => {
  return (
    <MarketingPopup
      activated={data.activated}
      startDate={data.startDate}
      endDate={data.endDate}
      interval={data.interval}
      image={data.image}
      ctaReference={data.ctaReference}
      ctaTitle={data.ctaTitle}
      ctaRel={data.ctaRel}
    />
  );
};

export default MarketingPopupTastic;
