import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-share ${className}`}
    viewBox="0 0 18 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15 20c-.8 0-1.5-.3-2.1-.9-.6-.6-.9-1.3-.9-2.1v-.4c0-.1 0-.2.1-.3l-7-4.1-.9.6c-.4.1-.8.2-1.2.2-.8 0-1.5-.3-2.1-.9-.6-.6-.9-1.3-.9-2.1s.3-1.5.9-2.1S2.2 7 3 7c.4 0 .8.1 1.1.2.4.2.7.3.9.6l7-4.1c0-.1-.1-.2-.1-.3V3c0-.8.3-1.5.9-2.1.7-.6 1.4-.9 2.2-.9s1.5.3 2.1.9c.6.6.9 1.3.9 2.1s-.3 1.5-.9 2.1c-.6.6-1.3.9-2.1.9-.4 0-.8-.1-1.1-.2-.4-.1-.7-.3-1-.6l-7 4.1c0 .1.1.2.1.3v.8c0 .1 0 .2-.1.3l7 4.1c.3-.2.6-.4 1-.6.3-.1.7-.2 1.1-.2.8 0 1.5.3 2.1.9.6.6.9 1.3.9 2.1s-.3 1.5-.9 2.1c-.6.6-1.3.9-2.1.9zm0-16c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7s-.1-.5-.3-.7c-.2-.2-.4-.3-.7-.3s-.5.1-.7.3c-.2.2-.3.4-.3.7s.1.5.3.7c.2.2.4.3.7.3zM3 11c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7-.2-.2-.4-.3-.7-.3s-.5.1-.7.3c-.2.2-.3.4-.3.7 0 .3.1.5.3.7.2.2.4.3.7.3zm12 7c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7s-.1-.5-.3-.7c-.2-.2-.4-.3-.7-.3s-.5.1-.7.3c-.2.2-.3.4-.3.7s.1.5.3.7c.2.2.4.3.7.3z" />
  </svg>
);

export default Icon;
