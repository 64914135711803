import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-mail ${className}`}
    viewBox="0 0 20 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 16c-.5 0-1-.2-1.4-.6C.2 15 0 14.6 0 14V2C0 1.5.2 1 .6.6S1.5 0 2 0h16c.6 0 1 .2 1.4.6s.6.9.6 1.4v12c0 .6-.2 1-.6 1.4-.4.4-.8.6-1.4.6H2zm8-7L2 4v10h16V4l-8 5zm0-2 8-5H2l8 5zM2 4V2v12V4z" />
  </svg>
);

export default Icon;
