import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-mail-check ${className}`}
    viewBox="0 0 68.5 57.5"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m54.8 32.5-4.9-4.9v-7.5a2.732 2.732 0 0 0-2.7-2.7H21.9c-.7 0-1.3.6-1.3 1.3v8.8l-4.9 4.9c-.1.1-.2.3-.3.4-.1.2-.1.3-.1.5V56c0 .4.1.7.4.9s.6.4.9.4h37.3c.4 0 .7-.1.9-.4s.4-.6.4-.9V33.5c0-.4-.1-.7-.4-1zM25.9 44.7l-8 8v-16l8 8zm2.8 1 .1-.1 6.4-6.4 6.4 6.4 9.3 9.2H19.6l9.1-9.1zm15.9-1 8-8v16l-8-8zM23.2 20.2h24v18.1h2.7v-6.9l2.1 2.1-9.3 9.3-6.5-6.5c-.3-.3-.6-.4-.9-.4-.4 0-.7.1-.9.4l-6.5 6.5-4.6-4.5V20.2zm-2.6 11.2v4.2l-2.1-2.1 2.1-2.1z"
      fill="#fff"
    />
    <path
      d="m35.1 32.9 6.6-6.6-1.9-1.9-2.1 2.1-3.6 3.6-2.8-2.8-1.9 1.9 3.8 3.8c.3.3.6.4.9.4.4-.1.7-.2 1-.5z"
      fill="#ff585d"
    />
    <path
      d="m.017 14.86.707-.706 5.87 5.869-.708.707zM61.84 20.008l5.869-5.869.707.707-5.869 5.87zM33.2 0h1v9h-1z"
      fill="#fff"
    />
  </svg>
);

export default Icon;
