import React from 'react';
import NextLink from 'next/link';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { useFormat } from 'helpers/hooks/useFormat';
import Price from '../../price';

type ShippingProps = {
  shippingMethods: ShippingMethod[];
  currentShippingMethod: ShippingMethod;
  onSelectShippingMethod: (shippingMethodId: ShippingMethod) => void;
  cartItemsCount: number;
  currentPartialDelivery: boolean;
  onTogglePartialDelivery: (currentPartialDelivery: boolean) => void;
};

const Shipping: React.FC<ShippingProps> = ({
  shippingMethods,
  currentShippingMethod,
  onSelectShippingMethod,
  cartItemsCount,
  currentPartialDelivery,
  onTogglePartialDelivery,
}) => {
  const { formatMessage } = useFormat({ name: 'checkout' });

  return (
    <>
      <section aria-labelledby="cart-heading">
        <form>
          <div className="mb-20 mob-p-p2 dt-p-p2 text-uppercase fw-bold">
            <span>{formatMessage({ id: 'shippingMethods', defaultMessage: 'Shipping methods' })}</span>
          </div>
          {shippingMethods?.map(({ shippingMethodId, name, description, rates }, index) => (
            <div key={'shippingMethod' + index} className="border p-20 mt-3 rounded">
              <div className="form-check">
                <input
                  type="radio"
                  aria-label="Shipping method"
                  name="shippingMethodId"
                  id={name}
                  className="form-check-input"
                  checked={currentShippingMethod?.shippingMethodId === shippingMethodId}
                  value={shippingMethodId}
                  onChange={() => onSelectShippingMethod(shippingMethods[index])}
                />
                <label htmlFor={name} key={index} className="form-check-label">
                  <div className="d-flex flex-column">
                    <div>
                      <div className="mob-p-p3 dt-p-p3">{name}</div>
                      <span className="mob-f-f3 dt-f-f3 fw-normal text-grey-70">{description}</span>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          ))}
          {cartItemsCount > 1 && (
            <>
              <div className="pt-4 mob-p-p3 dt-p-p3 text-uppercase fw-bold">
                <span>{formatMessage({ id: 'shippingOptions', defaultMessage: 'Shipping options' })}</span>
              </div>
              <div className="border p-20 mt-3 rounded">
                <div className="form-check">
                  <input
                    type="checkbox"
                    aria-label={formatMessage({ id: 'partialDelivery', defaultMessage: 'Partial delivery' })}
                    name="partialDelivery"
                    id="partialDelivery"
                    className="form-check-input"
                    checked={currentPartialDelivery}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onTogglePartialDelivery(e.target.checked);
                    }}
                  />
                  <label htmlFor="partialDelivery" className="form-check-label">
                    <div className="d-flex flex-column">
                      <div>
                        <div className="mob-p-p3 dt-p-p3">
                          {formatMessage({
                            id: 'partialDelivery.requested',
                            defaultMessage: 'Partial delivery requested',
                          })}
                        </div>
                        <span className="mob-f-f3 dt-f-f3 fw-normal text-grey-70">
                          {formatMessage({
                            id: 'partialDelivery.desc',
                            defaultMessage:
                              'Shipping multiple articles can lead to varied delivery timeframes. Since I want to receive each article as quick as possible, I would like to request partial deliveries if necessary.',
                          })}
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </>
          )}
        </form>
        <p className="mob-p-p4 dt-p-p4 pt-4">
          Sobald wir deine Bestellung verarbeitet haben, beträgt die <b>Versandzeit</b> bei unserem Logistikpartner{' '}
          <b>in der Regel 1-2 Werktage - ab 500 € sogar kostenlos</b>. Die geschätzte Verfügbarkeit deines
          Wunschproduktes findest du auf der jeweiligen Produktdetailseite. Wir bemühen uns deine Produkte so schnell es
          geht zu verschicken.
        </p>
        <p className="mob-p-p4 dt-p-p4">
          Weitere Informationen zu{' '}
          <NextLink href={'/versandinformationen'}>
            <a>Versand- und Rücksendeoptionen</a>
          </NextLink>{' '}
        </p>
      </section>
    </>
  );
};

export default Shipping;
