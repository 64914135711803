import React, { useState } from 'react';
import type { Address as AddressType } from '@Types/account/Address';
import { default as IconDelete } from 'components/icons/delete';
import { default as IconEdit } from 'components/icons/edit';
import { default as IconStar } from 'components/icons/star';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import DeleteConfirmationModal from '../modals/deleteConfirmation';
import UpdateAddressModal from '../modals/updateAddress';

export interface AddressProps {
  address: AddressType;
}

const Address: React.FC<AddressProps> = ({ address }) => {
  //account data
  const { removeAddress } = useAccount();

  //i18n messages
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  //handle address deletion
  const handleDelete = () => {
    return removeAddress(address.addressId);
  };

  //update modal
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  const openUpdateModal = () => setUpdateModalOpen(true);

  const closeUpdateModal = () => setUpdateModalOpen(false);

  //delete confirmation modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const openDeleteModal = () => setDeleteModalOpen(true);

  const closeDeleteModal = () => setDeleteModalOpen(false);

  return (
    <>
      <div>
        <div className="rounded bg-grey-10 p-20 mt-20">
          {address.isDefaultBillingAddress && !address.isDefaultShippingAddress && (
            <p className="mob-p-p4 td-p-p4 mb-0">
              {IconStar({ className: 'me-2' })}
              {formatAccountMessage({ id: 'address.billingAddress', defaultMessage: 'Billing address' })}
            </p>
          )}
          {address.isDefaultShippingAddress && !address.isDefaultBillingAddress && (
            <p className="mob-p-p4 td-p-p4 mb-0">
              {IconStar({ className: 'me-2' })}
              {formatAccountMessage({ id: 'address.deliveryAddress', defaultMessage: 'Delivery address' })}
            </p>
          )}
          {address.isDefaultBillingAddress && address.isDefaultShippingAddress && (
            <p className="mob-p-p4 td-p-p4 mb-0">
              {IconStar({ className: 'me-2' })}
              {formatAccountMessage({
                id: 'address.deliveryAndAddress',
                defaultMessage: 'Billing and delivery address',
              })}
            </p>
          )}
          {(address.isDefaultBillingAddress || address.isDefaultShippingAddress) && <hr className="my-2"></hr>}
          <dt className="fw-normal">
            {address.salutation && formatMessage({ id: `salutation.${address.salutation}`, defaultMessage: '-' })}{' '}
            {address.title}
          </dt>
          <dt className="fw-normal">
            {address.firstName} {address.lastName}
          </dt>
          {address.company && <dt className="fw-normal">{address.company}</dt>}
          {address.department && <dt className="fw-normal">{address.department}</dt>}
          {address.additionalAddressInfo && <dt className="fw-normal">{address.additionalAddressInfo}</dt>}
          <dt className="fw-normal">
            {address.streetName} {address.streetNumber}
          </dt>
          <dt className="fw-normal">
            {address.postalCode} {address.city}
          </dt>
          <dt className="fw-normal">{formatMessage({ id: `country.${address.country}`, defaultMessage: '-' })}</dt>
          {address.phone && (
            <dt className="fw-normal">
              <span className="font-medium">{formatMessage({ id: 'phone', defaultMessage: 'Phone' })}:</span>{' '}
              {address.phone}
            </dt>
          )}
          <div className="row mt-2">
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
              <button type="button" className="btn btn-primary btn-sm w-100" onClick={openUpdateModal}>
                {IconEdit({})}
                {formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
              </button>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mt-2 mt-sm-0">
              <button type="button" className="btn btn-secondary btn-sm w-100" onClick={openDeleteModal}>
                {IconDelete({})}
                {formatMessage({ id: 'remove', defaultMessage: 'Remove' })}
              </button>
            </div>
          </div>
        </div>
      </div>
      <UpdateAddressModal
        open={updateModalOpen}
        onClose={closeUpdateModal}
        defaultValues={address}
        openDeleteModal={openDeleteModal}
      />
      <DeleteConfirmationModal open={deleteModalOpen} onClose={closeDeleteModal} onConfirm={handleDelete} />
    </>
  );
};

export default Address;
