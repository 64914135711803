import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    width="18"
    height="12"
    className={`icon icon-menu ${className}`}
    viewBox="0 0 18 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 12c-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7 0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h16c.3 0 .5.1.7.3.2.2.3.4.3.7s-.1.5-.3.7c-.2.2-.4.3-.7.3H1zm0-5c-.3 0-.5-.1-.7-.3C.1 6.5 0 6.3 0 6c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h16c.3 0 .5.1.7.3.2.2.3.4.3.7s-.1.5-.3.7c-.2.2-.4.3-.7.3H1zm0-5c-.3 0-.5-.1-.7-.3C.1 1.5 0 1.3 0 1 0 .7.1.5.3.3.5.1.7 0 1 0h16c.3 0 .5.1.7.3.2.2.3.4.3.7s-.1.5-.3.7c-.2.2-.4.3-.7.3H1z" />
  </svg>
);

export default Icon;
