import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-heart ${className}`}
    viewBox="0 0 35 31"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m15.1 30-3-2.8c-3.1-2.8-5.9-5.6-8.4-8.4C1.2 16.1 0 13 0 9.6c0-2.7.9-5 2.8-6.9S6.9 0 9.6 0c1.5 0 3 .3 4.4 1 1.4.7 2.5 1.6 3.5 2.7 1-1.1 2.1-2 3.5-2.7 1.4-.7 2.8-1 4.4-1 2.7 0 5 .9 6.9 2.8C34.1 4.6 35 6.9 35 9.6c0 3.4-1.2 6.4-3.7 9.2-2.5 2.8-5.3 5.6-8.4 8.5l-3 2.7c-.7.6-1.5 1-2.4 1-.9 0-1.7-.3-2.4-1zm.7-22.8C15 6 14.1 5.1 13.1 4.4c-1-.6-2.1-.9-3.5-.9-1.8 0-3.2.6-4.4 1.8-1.1 1.1-1.7 2.6-1.7 4.3 0 1.5.5 3.1 1.6 4.8 1.1 1.7 2.4 3.4 3.9 5s3 3.1 4.6 4.5c1.6 1.4 2.9 2.6 3.9 3.5 1-.9 2.3-2.1 3.9-3.5s3.1-2.9 4.6-4.5c1.5-1.6 2.8-3.3 3.9-5 1.1-1.7 1.6-3.3 1.6-4.8 0-1.8-.6-3.2-1.8-4.4-1.2-1.2-2.6-1.8-4.4-1.8-1.4 0-2.5.3-3.5.9-1 .6-1.9 1.5-2.7 2.7-.2.3-.5.5-.7.7-.3.1-.6.2-.9.2-.3 0-.6-.1-.9-.2-.3 0-.6-.2-.8-.5z" />
  </svg>
);

export default Icon;
