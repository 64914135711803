import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-pdf ${className}`}
    viewBox="0 0 833.3 862"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M636.2 170.1h117.2c8.5 0 12.9-11.1 7.1-18L643.3 14.9c-2-2.3-4.5-3.4-7-3.4-5 0-9.8 4.2-9.8 10.7v137.3c0 5.9 4.3 10.7 9.7 10.6zm17.4-98.9 66.3 77.7h-66.3V71.2zM202 246.1c-16.5 0-29.8 13.3-29.8 29.8s13.3 29.8 29.8 29.8h429.3c16.5 0 29.8-13.3 29.8-29.8s-13.3-29.8-29.8-29.8H202zm93.8 265.1c0-16.5-11.9-22.7-26.6-22.7h-27.1v45.2h26.4c16 0 27.3-6.2 27.3-22.5zM461.5 539c0-26.1-12.7-50.1-44-50.1h-26.1V593h25.9c30.7 0 44.2-22.8 44.2-54zm310.9-155.9V189h-42v194.1H103V51c0-5 3.6-9 8-9h495.3V0H111C83.4 0 61 22.9 61 51v332.1H0v320.2h61V811c0 28.1 22.4 51 50 51h611.3c27.6 0 50-22.9 50-51V703.3h61V383.1h-60.9zM527.6 461h111.7v28.4h-77.9v39.2h73V557h-73v64h-33.8V461zm-170.9 0h63.5c46.6 0 77.1 30.7 77.1 78 0 46.7-29.6 82-78.5 82h-62l-.1-160zm-149.2 0h66.2c34 0 57.1 19.1 57.1 49.6 0 34.8-26.3 50.7-57.9 50.7h-30.8V621h-34.6V461zm522.9 350c0 5-3.6 9-8 9H111c-4.4 0-8-4-8-9V703.3h627.4V811z" />
  </svg>
);

export default Icon;
