import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg className={`icon icon-payment-google-pay ${className}`} viewBox="0 0 752 400" xmlns="http://www.w3.org/2000/svg">
    <path fill="#fff" d="M552 0H200C90 0 0 90 0 200s90 200 200 200h352c110 0 200-90 200-200S662 0 552 0z" />
    <path
      fill="#3c4043"
      d="M552 16.2c24.7 0 48.7 4.9 71.3 14.5 21.9 9.3 41.5 22.6 58.5 39.5 16.9 16.9 30.2 36.6 39.5 58.5 9.6 22.6 14.5 46.6 14.5 71.3s-4.9 48.7-14.5 71.3c-9.3 21.9-22.6 41.5-39.5 58.5-16.9 16.9-36.6 30.2-58.5 39.5-22.6 9.6-46.6 14.5-71.3 14.5H200c-24.7 0-48.7-4.9-71.3-14.5-21.9-9.3-41.5-22.6-58.5-39.5-16.9-16.9-30.2-36.6-39.5-58.5-9.6-22.6-14.5-46.6-14.5-71.3s4.9-48.7 14.5-71.3c9.3-21.9 22.6-41.5 39.5-58.5 16.9-16.9 36.6-30.2 58.5-39.5 22.6-9.6 46.6-14.5 71.3-14.5h352M552 0H200C90 0 0 90 0 200s90 200 200 200h352c110 0 200-90 200-200S662 0 552 0z"
    />
    <g>
      <g>
        <path
          fill="#3c4043"
          d="M358.6 214.2v60.5h-19.2V125.3h50.9c12.9 0 23.9 4.3 32.9 12.9 9.2 8.6 13.8 19.1 13.8 31.5 0 12.7-4.6 23.2-13.8 31.7-8.9 8.5-19.9 12.7-32.9 12.7h-31.7v.1zm0-70.5v52.1h32.1c7.6 0 14-2.6 19-7.7 5.1-5.1 7.7-11.3 7.7-18.3 0-6.9-2.6-13-7.7-18.1-5-5.3-11.3-7.9-19-7.9h-32.1v-.1z"
        />
        <path
          fill="#3c4043"
          d="M487.2 169.1c14.2 0 25.4 3.8 33.6 11.4 8.2 7.6 12.3 18 12.3 31.2v63h-18.3v-14.2h-.8c-7.9 11.7-18.5 17.5-31.7 17.5-11.3 0-20.7-3.3-28.3-10s-11.4-15-11.4-25c0-10.6 4-19 12-25.2 8-6.3 18.7-9.4 32-9.4 11.4 0 20.8 2.1 28.1 6.3v-4.4c0-6.7-2.6-12.3-7.9-17-5.3-4.7-11.5-7-18.6-7-10.7 0-19.2 4.5-25.4 13.6l-16.9-10.6c9.3-13.5 23.1-20.2 41.3-20.2zm-24.8 74.2c0 5 2.1 9.2 6.4 12.5 4.2 3.3 9.2 5 14.9 5 8.1 0 15.3-3 21.6-9s9.5-13 9.5-21.1c-6-4.7-14.3-7.1-25-7.1-7.8 0-14.3 1.9-19.5 5.6-5.3 3.9-7.9 8.6-7.9 14.1z"
        />
        <path fill="#3c4043" d="m637.5 172.4-64 147.2h-19.8l23.8-51.5-42.2-95.7h20.9l30.4 73.4h.4l29.6-73.4h20.9z" />
      </g>
      <g>
        <path
          fill="#4285f4"
          d="M282.2 202c0-6.3-.6-12.2-1.6-18h-80.5v33h46.4c-1.9 11-7.9 20.3-17.2 26.6V265h27.6c16.1-14.9 25.3-37 25.3-63z"
        />
        <path
          fill="#34a853"
          d="M229.3 243.6c-7.7 5.2-17.6 8.2-29.1 8.2-22.4 0-41.3-15.1-48.1-35.4h-28.5v22.1c14.1 28 43.1 47.2 76.6 47.2 23.1 0 42.6-7.6 56.7-20.7l-27.6-21.4z"
        />
        <path
          fill="#fabb05"
          d="M149.4 200c0-5.7 1-11.2 2.7-16.4v-22.1h-28.5c-5.8 11.6-9.1 24.6-9.1 38.5s3.3 26.9 9.1 38.5l28.5-22.1c-1.8-5.1-2.7-10.6-2.7-16.4z"
        />
        <path
          fill="#e94235"
          d="M200.2 148.3c12.6 0 23.9 4.4 32.9 12.9l24.5-24.4c-14.9-13.8-34.2-22.3-57.3-22.3-33.5 0-62.5 19.2-76.6 47.2l28.5 22.1c6.7-20.4 25.6-35.5 48-35.5z"
        />
      </g>
    </g>
  </svg>
);

export default Icon;
