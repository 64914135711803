import React from 'react';
import { Cart } from '@Types/cart/Cart';
import { default as IconEditSquare } from 'components/icons/edit-square';
import { useFormat } from '../../../../helpers/hooks/useFormat';

export interface Props {
  readonly cart: Cart;
  readonly changeStep?: (index: number) => void;
}

const AddressSummary: React.FC<Props> = ({ cart, changeStep }) => {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  if (!cart.shippingAddress && !cart.billingAddress) {
    return null;
  }

  {
    /* TODO: Maybe, it is not the best way */
  }
  const billingIsSameAsShipping = JSON.stringify(cart.shippingAddress) === JSON.stringify(cart.billingAddress);

  return (
    <>
      {cart.shippingAddress && (
        <section>
          <h4 className="mb-3 d-flex justify-content-between">
            <span className="mob-p-p3 dt-p-p3 text-uppercase fw-bold">
              {formatMessage({ id: 'order.shippingAddress', defaultMessage: 'Lieferadresse' })}
            </span>
            <button type="button" className="icon-link mob-c-c4 dt-c-c4" onClick={() => changeStep(0)}>
              {IconEditSquare({})}
              {formatMessage({ id: 'change', defaultMessage: 'change' })}
            </button>
          </h4>
          <p className="m-0">
            {cart.shippingAddress.salutation && (
              <>
                {formatCommonMessage({
                  id: `salutation.${cart.shippingAddress.salutation}`,
                  defaultMessage: '-',
                })}{' '}
              </>
            )}
            {cart.shippingAddress.title && <>{cart.shippingAddress.title} </>}
            {cart.shippingAddress.firstName && cart.shippingAddress.lastName && (
              <>
                {`${cart.shippingAddress.firstName} ${cart.shippingAddress.lastName}`}
                <br />
              </>
            )}
            {cart.shippingAddress.company && (
              <>
                {cart.shippingAddress.company}
                <br />
              </>
            )}
            {cart.shippingAddress.department && (
              <>
                {cart.shippingAddress.department}
                <br />
              </>
            )}
            {cart.shippingAddress.streetName && cart.shippingAddress.streetNumber && (
              <>
                {`${cart.shippingAddress.streetName} ${cart.shippingAddress.streetNumber}`}
                <br />
              </>
            )}
            {cart.shippingAddress.additionalAddressInfo && (
              <>
                {cart.shippingAddress.additionalAddressInfo}
                <br />
              </>
            )}
            {cart.shippingAddress.postalCode && cart.shippingAddress.city && (
              <>
                {`${cart.shippingAddress.postalCode} ${cart.shippingAddress.city}`}
                <br />
              </>
            )}
            {cart.shippingAddress.country && (
              <>
                {formatCommonMessage({ id: `country.${cart.shippingAddress.country}` })}
                <br />
              </>
            )}
          </p>
        </section>
      )}
      {cart.billingAddress && (
        <section>
          <hr className="my-3" />
          <h4 className="mb-3 d-flex justify-content-between">
            <span className="mob-p-p3 dt-p-p3 text-uppercase fw-bold">
              {formatMessage({ id: 'order.billingAddress', defaultMessage: 'Rechnungsadresse' })}
            </span>
            <button type="button" className="icon-link mob-c-c4 dt-c-c4" onClick={() => changeStep(0)}>
              {IconEditSquare({})}
              {formatMessage({ id: 'change', defaultMessage: 'Change' })}
            </button>
          </h4>
          {billingIsSameAsShipping ? (
            <p className="m-0">
              {formatMessage({ id: 'sameAsDeliveryAddress', defaultMessage: 'Same as delivery address' })}
            </p>
          ) : (
            <p className="m-0">
              {cart.billingAddress.salutation && (
                <>
                  {formatCommonMessage({
                    id: `salutation.${cart.billingAddress.salutation}`,
                    defaultMessage: '-',
                  })}{' '}
                </>
              )}
              {cart.billingAddress.title && <>{cart.billingAddress.title} </>}
              {cart.billingAddress.firstName && cart.billingAddress.lastName && (
                <>
                  {`${cart.billingAddress.firstName} ${cart.billingAddress.lastName}`}
                  <br />
                </>
              )}
              {cart.billingAddress.company && (
                <>
                  {cart.billingAddress.company}
                  <br />
                </>
              )}
              {cart.billingAddress.department && (
                <>
                  {cart.billingAddress.department}
                  <br />
                </>
              )}
              {cart.billingAddress.streetName && cart.billingAddress.streetNumber && (
                <>
                  {`${cart.billingAddress.streetName} ${cart.billingAddress.streetNumber}`}
                  <br />
                </>
              )}
              {cart.billingAddress.additionalAddressInfo && (
                <>
                  {cart.billingAddress.additionalAddressInfo}
                  <br />
                </>
              )}
              {cart.billingAddress.postalCode && cart.billingAddress.city && (
                <>
                  {`${cart.billingAddress.postalCode} ${cart.billingAddress.city}`}
                  <br />
                </>
              )}
              {cart.billingAddress.country && (
                <>
                  {formatCommonMessage({ id: `country.${cart.billingAddress.country}` })}
                  <br />
                </>
              )}
            </p>
          )}
        </section>
      )}
    </>
  );
};

export default AddressSummary;
