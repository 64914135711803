import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import PageTeasers from '../../../../../components/snaq-ui/content/page-teasers';
import { buildPagesListByCategory, PageNodeTeaser } from '../category-experts';

const CategoryArticlesTastic = ({ id, data }) => {
  const {
    title,
    pageFolder,
    categoryData,
    ctaLabel,
    ctaLink,
    ctaTitle,
    ctaRel,
    background,
    innerSpacing,
    outerSpacing,
  } = data;
  const router = useRouter();

  const articlesTeasers = useMemo(() => {
    const currentCategoryKey = categoryData?.dataSource?.categoryId;

    const items: PageNodeTeaser[] = buildPagesListByCategory(pageFolder?.children, router?.locale, currentCategoryKey);

    return items.sort((itemA, itemB) =>
      itemA.date && itemB.date ? new Date(itemB.date).getTime() - new Date(itemA.date).getTime() : 0,
    );
  }, [pageFolder, categoryData, categoryData?.dataSource?.categoryId]);

  if (articlesTeasers.length === 0) {
    return null;
  }

  return (
    <PageTeasers
      tasticId={id}
      title={title}
      items={articlesTeasers}
      ctaLabel={ctaLabel}
      ctaLink={ctaLink}
      ctaTitle={ctaTitle}
      ctaRel={ctaRel}
      background={background}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
    />
  );
};

export default CategoryArticlesTastic;
