import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-apps ${className}`}
    viewBox="0 0 640 640"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M80 640c-22 0-40.8-7.8-56.5-23.5S0 582 0 560s7.8-40.8 23.5-56.5C39.2 487.8 58 480 80 480s40.8 7.8 56.5 23.5C152.2 519.2 160 538 160 560s-7.8 40.8-23.5 56.5S102 640 80 640zm240 0c-22 0-40.8-7.8-56.5-23.5S240 582 240 560s7.8-40.8 23.5-56.5C279.2 487.8 298 480 320 480s40.8 7.8 56.5 23.5C392.2 519.2 400 538 400 560s-7.8 40.8-23.5 56.5S342 640 320 640zm240 0c-22 0-40.8-7.8-56.5-23.5S480 582 480 560s7.8-40.8 23.5-56.5C519.2 487.8 538 480 560 480s40.8 7.8 56.5 23.5C632.2 519.2 640 538 640 560s-7.8 40.8-23.5 56.5S582 640 560 640zM80 400c-22 0-40.8-7.8-56.5-23.5S0 342 0 320s7.8-40.8 23.5-56.5S58 240 80 240s40.8 7.8 56.5 23.5S160 298 160 320s-7.8 40.8-23.5 56.5S102 400 80 400zm240 0c-22 0-40.8-7.8-56.5-23.5S240 342 240 320s7.8-40.8 23.5-56.5S298 240 320 240s40.8 7.8 56.5 23.5S400 298 400 320s-7.8 40.8-23.5 56.5S342 400 320 400zm240 0c-22 0-40.8-7.8-56.5-23.5S480 342 480 320s7.8-40.8 23.5-56.5S538 240 560 240s40.8 7.8 56.5 23.5S640 298 640 320s-7.8 40.8-23.5 56.5S582 400 560 400zM80 160c-22 0-40.8-7.8-56.5-23.5S0 102 0 80s7.8-40.8 23.5-56.5S58 0 80 0s40.8 7.8 56.5 23.5S160 58 160 80s-7.8 40.8-23.5 56.5S102 160 80 160zm240 0c-22 0-40.8-7.8-56.5-23.5S240 102 240 80s7.8-40.8 23.5-56.5S298 0 320 0s40.8 7.8 56.5 23.5S400 58 400 80s-7.8 40.8-23.5 56.5S342 160 320 160zm240 0c-22 0-40.8-7.8-56.5-23.5S480 102 480 80s7.8-40.8 23.5-56.5S538 0 560 0s40.8 7.8 56.5 23.5S640 58 640 80s-7.8 40.8-23.5 56.5S582 160 560 160z" />
  </svg>
);

export default Icon;
