import React, { MouseEvent, useMemo } from 'react';
import NextLink from 'next/link';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import classnames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';
import DiscountForm from '../discount-form';
import DiscountList from '../discount-form/list';
import AddressSummary from '../multistep-checkout/panels/addressSummary';
import CartItems from '../multistep-checkout/panels/cart-items';
import ShippingSummary from '../multistep-checkout/panels/shippingSummary';
import Price from '../price';

interface Props {
  readonly cart: Cart;
  readonly onSubmit?: (e: MouseEvent) => void;
  readonly changeStep?: (index: number) => void;
  readonly submitButtonLabel?: string;
  readonly disableSubmitButton?: boolean;
  readonly showAddressSummary?: boolean;
  readonly showShippingSummary?: boolean;
  readonly showSubmitButton?: boolean;
  readonly showDiscountsForm?: boolean;
  readonly showDisclaimer?: boolean;
  readonly showOverview?: boolean;

  termsLink?: Reference;
  cancellationLink?: Reference;
  privacyLink?: Reference;
  minOrderCentAmount?: number;
  maxOrderCentAmount?: number;
}

const OrderSummary = ({
  cart,
  onSubmit,
  changeStep,
  showAddressSummary = false,
  showShippingSummary = false,
  showSubmitButton = true,
  showDiscountsForm = true,
  showDisclaimer = true,
  showOverview = true,
  submitButtonLabel,
  disableSubmitButton,
  termsLink,
  privacyLink,
  minOrderCentAmount,
  maxOrderCentAmount,
}: Props) => {
  //i18n messages
  const { formatMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { t } = useTranslation(['cart']);
  const { t: tCheckout } = useTranslation(['checkout']);

  const minOrderAmountError = useMemo(
    () => minOrderCentAmount && minOrderCentAmount > cart?.sum?.centAmount,
    [minOrderCentAmount, cart?.sum?.centAmount],
  );

  const maxOrderAmountError = useMemo(
    () => maxOrderCentAmount && maxOrderCentAmount < cart?.sum?.centAmount,
    [maxOrderCentAmount, cart?.sum?.centAmount],
  );

  const minOrderAmountErrorMessage = [
    <Price
      key={0}
      price={{
        centAmount: minOrderCentAmount,
        currencyCode: cart?.sum?.currencyCode,
      }}
      className="inline"
    />,
  ];

  const maxOrderAmountErrorMessage = [
    <Price
      key={0}
      price={{
        centAmount: maxOrderCentAmount,
        currencyCode: cart?.sum?.currencyCode,
      }}
      className="inline"
    />,
  ];

  const interpolatedComponents = [
    <ReferenceLink key={0} className="cursor-pointer font-medium text-accent-500 hover:underline" target={termsLink} />,
    <ReferenceLink
      key={1}
      className="cursor-pointer font-medium text-accent-500 hover:underline"
      target={privacyLink}
    />,
  ];

  const totalTaxes = useMemo(
    () => cart?.taxed?.taxPortions?.reduce((a, b) => a + b.amount.centAmount, 0),
    [cart?.taxed?.taxPortions],
  );

  const productPrice = useMemo(
    () =>
      cart?.lineItems?.reduce((a, b: LineItem) => {
        if (b.discountedPrice) {
          return a + b.discountedPrice.centAmount * b.count;
        } else {
          return a + b.price.centAmount * b.count;
        }
      }, 0),
    [cart?.lineItems],
  );

  const discountPrice = useMemo(() => {
    let discount = cart?.lineItems?.reduce((a, b) => {
      return (
        a +
        b.discounts.reduce((x, y) => {
          return x + y.discountedAmount.centAmount * (y.quantity ?? 1);
        }, 0)
      );
    }, 0);
    if (cart?.discountOnTotalPrice) {
      discount += cart.discountOnTotalPrice.centAmount;
    }
    return discount;
  }, [cart?.lineItems, cart?.discountOnTotalPrice]);

  const priceFractionDigits = useMemo(
    () => (cart?.lineItems && cart?.lineItems[0] ? cart.lineItems[0].price?.fractionDigits ?? 0 : 0),
    [cart?.lineItems],
  );

  const shippingPrice = useMemo(
    () => cart?.shippingInfo?.discountedPrice || cart?.shippingInfo?.price || {},
    [cart?.shippingInfo],
  );

  return (
    <>
      <section aria-labelledby="summary-heading">
        <div className="bg-grey-10 p-20 rounded mb-20">
          {showOverview && (
            <>
              <div className="mob-p-p3 dt-p-p3 fw-bold mb-3">
                <span>{formatMessage({ id: 'overview.order', defaultMessage: 'YOUR ORDER' })}</span>
              </div>
              <CartItems cart={cart} />
              <hr className="mt-3 mb-0 pt-3" />
            </>
          )}
          <dl className="dt-p-p3 m-0">
            <div className="d-flex justify-content-between pb-6">
              <dt className="fw-normal">{formatCartMessage({ id: 'subtotal', defaultMessage: 'Subtotal' })}</dt>
              <dd className="m-0">
                <Price
                  price={{
                    centAmount: productPrice,
                    currencyCode: cart?.sum?.currencyCode,
                  }}
                  className="m-0"
                />
              </dd>
            </div>
            {cart?.shippingInfo && (
              <div className="d-flex justify-content-between pb-6">
                <dt className="fw-normal">
                  <span>{formatCartMessage({ id: 'shipping.estimate', defaultMessage: 'Shipping estimate' })}</span>
                </dt>
                <dd className="m-0">
                  <Price
                    price={shippingPrice}
                    className={classnames('m-0', shippingPrice.centAmount === 0 && 'text-green-10')}
                    showFreeText={true}
                  />
                </dd>
              </div>
            )}
            <div className="border-bottom pb-3 mb-3">
              <div className="d-flex justify-content-between">
                <dt className="fw-normal">
                  <span>{formatCartMessage({ id: 'discounts', defaultMessage: 'Discounts' })}</span>
                </dt>
                <dd className="m-0">
                  <Price
                    price={
                      {
                        fractionDigits: priceFractionDigits,
                        centAmount: !discountPrice || discountPrice === 0 ? 0 : -discountPrice,
                        currencyCode: cart?.sum?.currencyCode,
                      } || {}
                    }
                    className="m-0"
                  />
                </dd>
              </div>
              {showDiscountsForm && <DiscountList cart={cart} className="list-unstyled mt-3 ms-3" />}
            </div>

            <div className="d-flex justify-content-between dt-p-p2 mt-3">
              <dt className="fw-medium">
                {formatCartMessage({ id: 'orderTotalNet', defaultMessage: 'Order total net' })}
              </dt>
              <dd className="m-0">
                <Price price={cart?.sum || {}} className="fw-medium m-0" />
              </dd>
            </div>

            {cart?.taxed && (
              <div className="d-flex justify-content-between dt-p-p3 mt-2">
                <dt className="fw-normal">{formatCartMessage({ id: 'orderVat', defaultMessage: 'Order vat' })}</dt>
                <dd className="m-0">
                  <Price price={{ centAmount: totalTaxes } || {}} className="fw-normal m-0" />
                </dd>
              </div>
            )}

            {cart?.totalGross && (
              <div className="d-flex justify-content-between dt-p-p3">
                <dt className="fw-normal">
                  {formatCartMessage({ id: 'orderTotalGross', defaultMessage: 'Order total gross' })}
                </dt>
                <dd className="m-0">
                  <Price price={cart?.totalGross || {}} className="fw-normal m-0" />
                </dd>
              </div>
            )}
          </dl>
        </div>
        {showDiscountsForm && <DiscountForm className="my-8" />}
        {minOrderAmountError && (
          <div className="mb-3 text-sm italic text-red-700">
            <Trans i18nKey="minOrderAmountErrorMessage" t={t} components={minOrderAmountErrorMessage} />
          </div>
        )}
        {maxOrderAmountError && (
          <div className="mb-3 text-sm italic text-red-700">
            <Trans i18nKey="maxOrderAmountErrorMessage" t={t} components={maxOrderAmountErrorMessage} />
          </div>
        )}
        {showSubmitButton && (
          <div>
            <button
              type="submit"
              onClick={onSubmit}
              className="btn btn-lg btn-primary w-100"
              disabled={disableSubmitButton || minOrderAmountError || maxOrderAmountError}
            >
              {submitButtonLabel || formatCartMessage({ id: 'checkout.go', defaultMessage: 'Go to checkout' })}
            </button>
            <div className="text-center pt-15 mob-p-p4 dt-p-p4">
              <NextLink href="/">
                <a className="link-reset w-100 mt-2">
                  {formatCartMessage({ id: 'continueShopping', defaultMessage: 'Continue shopping' })}
                </a>
              </NextLink>
            </div>
          </div>
        )}

        {(showAddressSummary || showShippingSummary) && (
          <div className="bg-grey-10 rounded p-20">
            {showAddressSummary && <AddressSummary cart={cart} changeStep={changeStep} />}
            {showShippingSummary && <ShippingSummary cart={cart} changeStep={changeStep} />}
          </div>
        )}

        {showDisclaimer && (
          <p className="pt-30 pb-40 text-center dt-p-p4 m-0">
            <Trans i18nKey="disclaimer" t={tCheckout} components={interpolatedComponents} />
          </p>
        )}
      </section>
    </>
  );
};

export default OrderSummary;
