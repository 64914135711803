import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-compare-arrows ${className}`}
    viewBox="0 0 25 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m16.6 11.1-4.5-4.5c-.1-.1-.2-.3-.3-.4s-.1-.3-.1-.5 0-.3.1-.5l.3-.3L16.6.4c.3-.3.6-.4.9-.4.3 0 .6.1.9.4s.4.5.4.9c0 .3-.1.6-.4.9L16 4.5h7.7c.4 0 .7.1.9.4.3.2.4.5.4.9s-.1.7-.4.9c-.2.2-.5.3-.8.3H16l2.3 2.3c.2.3.4.5.4.9 0 .3-.1.6-.4.9s-.5.4-.9.4c-.2 0-.5-.1-.8-.4zm-10 7.5c.3.3.6.4.9.4.3 0 .6-.1.9-.4l4.5-4.5c.1-.1.2-.3.3-.4s.1-.3.1-.5 0-.3-.1-.5c-.1-.1-.1-.3-.3-.4L8.4 7.9c-.3-.3-.6-.4-.9-.4s-.6.1-.9.4c-.2.2-.4.5-.4.9s.1.6.4.9L9 12H1.2c-.4 0-.7.1-.9.4-.2.2-.3.5-.3.9s.1.7.4.9c.2.2.5.4.9.4H9l-2.3 2.3c-.3.3-.4.5-.4.9s.1.6.3.8z" />
  </svg>
);

export default Icon;
