import { useMemo } from 'react';
import { useRouter } from 'next/router';
import ExpertButton from '../../../../../components/snaq-ui/content/expert-button';
import { Reference } from '../../../../../helpers/reference';
import { MediaItemWithMods } from '../../../../lib/image';
import { PageNodeTeaser } from '../../categories/category-experts';

type Props = {
  ctaLabel: string;
  ctaTitle?: string;
  alignment: string;
  background: string;
  paddingTop: string;
  paddingBottom: string;
  title: string;
  description: string;
  image: MediaItemWithMods;
  node: any;
};

const ExpertButtonTastic = ({ data }: { id: string; data: Props }) => {
  const { image, title, description, node } = data;
  const router = useRouter();

  const expertTeaser: PageNodeTeaser | null = useMemo(() => {
    if (
      !node.hasLivePage ||
      !(node?.configuration?.teaserMedia || image) ||
      !(node?.configuration?.teaserTitle || title) ||
      !node?.configuration?.contactEmail
    ) {
      return null;
    }

    const _urlObj: [key: string, value: any] | undefined = Object.entries(node._urls).find(
      (item: [key: string, value: string]) => router?.locale === item[0].split(/[^A-Za-z]/)[0],
    );

    const ctaReference: Reference | null =
      _urlObj && typeof _urlObj[1] === 'string'
        ? {
            type: 'page-folder',
            pageFolder: {
              pageFolderId: node.pageFolderId,
              name: node.name,
              hasLivePage: node.hasLivePage,
              _urls: {
                ...node._urls,
              },
              _url: _urlObj[1],
            },
            openInNewWindow: false,
          }
        : null;

    return {
      image: image ?? node.configuration.teaserMedia,
      title: title || node.configuration.teaserTitle || '',
      description: description || node.configuration.teaserDescription || undefined,
      v2image: node.configuration.teaser2Media ?? undefined,
      v2title: node.configuration.teaser2Title ?? undefined,
      v2description: node.configuration.teaser2Description ?? undefined,
      v3image: node.configuration.teaser3Media ?? undefined,
      v3title: node.configuration.teaser3Title ?? undefined,
      v3description: node.configuration.teaser3Description ?? undefined,
      email: node.configuration.contactEmail ?? undefined,
      ctaLabel: 'Read',
      ctaReference: ctaReference ?? undefined,
    };
  }, [image, title, description, node]);

  if (!expertTeaser) {
    return null;
  }

  return (
    <ExpertButton
      expertTeaser={expertTeaser}
      ctaLabel={data.ctaLabel}
      ctaTitle={data.ctaTitle}
      alignment={data.alignment}
      background={data.background}
      paddingTop={data.paddingTop}
      paddingBottom={data.paddingBottom}
    />
  );
};

export default ExpertButtonTastic;
