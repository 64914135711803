import React from 'react';
import Slider, { SliderProps } from 'components/snaq-ui/slider';
import Slide, { HeroSlideProps } from './slide';

export interface HeroSliderProps {
  heroslides: HeroSlideProps[];
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

const HeroSlider: React.FC<HeroSliderProps> = ({ heroslides, background, innerSpacing, outerSpacing }) => {
  const sliderFixedMood: SliderProps = {
    slidesPerView: 1,
    arrows: 1 < heroslides.length,
    dots: 1 < heroslides.length,
    spaceBetween: 0,
    freeMode: false,
    scrollbar: false,
    loop: 1 < heroslides.length,
  };

  const sliderConfiguration: SliderProps = sliderFixedMood;

  return (
    <div className={`hero-slider ${background} ${innerSpacing} ${outerSpacing}`}>
      <Slider {...sliderConfiguration}>
        {heroslides.map((hero, index) => (
          <Slide
            key={'heroSlide' + index}
            image={hero.image}
            imagemobile={hero.imagemobile}
            overline={hero.overline}
            title={hero.title}
            description={hero.description}
            subtext={hero.subtext}
            ctaLabel={hero.ctaLabel}
            ctaReference={hero.ctaReference}
            ctaTitle={hero.ctaTitle}
            ctaRel={hero.ctaRel}
          />
        ))}
      </Slider>
    </div>
  );
};

export default HeroSlider;
