import React, { useMemo } from 'react';
import PageTeasers, { PageTeaser } from 'components/snaq-ui/content/page-teasers';

const PageTeasersTastic = ({ id, data }) => {
  const { title, nodes, ctaLabel, ctaLink, ctaTitle, ctaRel, background, innerSpacing, outerSpacing } = data;

  const pageTeasers = useMemo(() => {
    const items: PageTeaser[] = [];
    nodes.forEach(({ node }) => {
      if (!node.hasLivePage || !node?.configuration?.teaserMedia || !node?.configuration?.teaserTitle) {
        return;
      }
      items.push({
        image: node.configuration.teaserMedia,
        title: node.configuration.teaserTitle ?? '',
        description: node.configuration.teaserDescription ?? undefined,
        ctaLink: {
          type: 'page-folder',
          pageFolder: {
            pageFolderId: node.pageFolderId,
            name: node.name,
            hasLivePage: node.hasLivePage,
            _urls: {
              ...node._urls,
            },
            _url: node._url,
          },
          openInNewWindow: false,
        },
      });
    });
    return items;
  }, [nodes]);

  return (
    <PageTeasers
      tasticId={id}
      title={title}
      items={pageTeasers}
      ctaLabel={ctaLabel}
      ctaLink={ctaLink}
      ctaTitle={ctaTitle}
      ctaRel={ctaRel}
      background={background}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
    />
  );
};

export default PageTeasersTastic;
