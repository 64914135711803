import React from 'react';
import BrandFooter from '../../../../../components/snaq-ui/brands/brand-footer';

const BrandFooterTastic = ({ data }) => {
  const brand = data?.data?.dataSource;
  if (!brand) return null;
  return (
    <BrandFooter
      country={brand.country}
      seoText={brand.seoText}
      standardsAndCertifications={brand.standardsAndCertifications}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
};

export default BrandFooterTastic;
