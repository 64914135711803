import React, { useMemo } from 'react';
import Header, { Link } from 'components/snaq-ui/header';
import { calculateCartCount } from 'helpers/utils/calculateCartCount';
import { useCart, useWishlist, useAccount, useComparison, useCategories } from 'frontastic/provider';

const HeaderTastic = ({ data, previewId }) => {
  const { data: categories } = useCategories();
  const { data: cart } = useCart();
  const { data: wishlist } = useWishlist();
  const { account } = useAccount();
  const { data: comparisonSkus } = useComparison();

  const specialButton: Link = useMemo(
    () => ({
      name: data.specialButtonLabel,
      reference: data.specialButtonReference,
      linkTitle: data.specialButtonLinkTitle,
      linkRel: data.specialButtonLinkRel,
    }),
    [data],
  );

  return (
    <Header
      infos={data.infoEntries}
      links={data.links}
      cartItemCount={calculateCartCount(cart?.lineItems) || 0}
      wishlistItemCount={wishlist?.lineItems?.length || 0}
      comparisonSkus={comparisonSkus}
      logo={data.logo}
      logoLink={data.logoLink}
      account={account}
      accountLink={data.accountLink}
      wishlistLink={data.wishlistLink}
      comparisonLink={data.comparisonLink}
      cartLink={data.cartLink}
      previewId={previewId}
      categories={categories ?? []}
      specialButton={specialButton}
    />
  );
};

export default HeaderTastic;
