import React, { useState } from 'react';
import Typography from 'components/snaq-ui/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { useEmail } from '../../../frontastic';

export interface ContactFormProps {
  headline?: string;
  description?: string;
  contactEmailAddress: string;
  senderLabel?: string;
  subjectLabel?: string;
  messageLabel?: string;
  ctaLabel?: string;
  successTitle?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

export default function ContactForm({
  headline,
  description,
  contactEmailAddress,
  senderLabel,
  subjectLabel,
  messageLabel,
  ctaLabel,
  successTitle,
  background,
  innerSpacing,
  outerSpacing,
}: ContactFormProps) {
  const { sendContactEmail } = useEmail();
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatContactFormMessage } = useFormat({ name: 'contactform' });

  const fallbackDescription = formatContactFormMessage({
    id: 'description.fallback',
    defaultMessage: 'If you have questions or suggestions, feel free to share them with us.',
  });

  const fallbackSender = formatContactFormMessage({
    id: 'sender.fallback',
    defaultMessage: 'Your email',
  });

  const fallbackSubject = formatContactFormMessage({
    id: 'subject.fallback',
    defaultMessage: 'Subject',
  });

  const fallbackMessage = formatContactFormMessage({
    id: 'message.fallback',
    defaultMessage: 'Message',
  });

  const fallbackCta = formatContactFormMessage({
    id: 'cta.fallback',
    defaultMessage: 'Send',
  });

  const fallbackSuccessTitle = formatContactFormMessage({
    id: 'success.fallback.title',
    defaultMessage: 'You request was sent successfully',
  });

  //form data
  const [data, setData] = useState({ email: '', subject: '', message: '' });
  // confirmation
  const [isConfirm, setIsConfirm] = useState(false);

  //input change handler
  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  //submission handler
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    //validate
    const isValid = !!data.email;
    if (!isValid) {
      return alert(formatErrorMessage({ id: 'enter.email', defaultMessage: 'Please enter your email address' }));
    }
    await sendContactEmail({
      to: contactEmailAddress,
      subject:
        data.subject ||
        formatContactFormMessage({
          id: 'contact.subject',
          defaultMessage: 'Contact form inquiry',
        }),
      text: data.message || undefined,
      contextMessage: `${formatContactFormMessage({
        id: 'contact.context',
        defaultMessage: 'Contact form inquiry from',
      })} ${data.email}`,
    });
    //success
    setIsConfirm(true);
  };

  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-8">
          <div className="mb-40">
            <h1>
              {headline ||
                formatContactFormMessage({
                  id: 'headline.fallback',
                  defaultMessage: 'Contact form',
                })}
            </h1>
            <p className="mob-p-p3 dt-p-p3 mb-0">{description || fallbackDescription}</p>
          </div>
          {!isConfirm ? (
            <div>
              <form action="#" onSubmit={onSubmit}>
                <div className="mb-3">
                  <label htmlFor="inputSender" className="form-label">
                    <Typography>{senderLabel || fallbackSender}</Typography>
                  </label>
                  <input
                    id="inputSender"
                    name="email"
                    type="email"
                    onChange={onChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="inputSubject" className="form-label">
                    <Typography>{subjectLabel || fallbackSubject}</Typography>
                  </label>
                  <input id="inputSubject" name="subject" type="text" onChange={onChange} className="form-control" />
                </div>
                <div className="mb-3">
                  <label htmlFor="inputMessage" className="form-label">
                    <Typography>{messageLabel || fallbackMessage}</Typography>
                  </label>
                  <textarea
                    id="inputMessage"
                    name="message"
                    onChange={onChange}
                    className="form-control"
                    rows={5}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  <Typography>{ctaLabel || fallbackCta}</Typography>
                </button>
              </form>
            </div>
          ) : (
            <div>
              <div className="alert alert-success" role="alert">
                <h1 className="h5 m-0">
                  <Typography>{successTitle || fallbackSuccessTitle}</Typography>
                </h1>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
