import Typography from 'components/snaq-ui/typography';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { Reference, ReferenceLink } from 'helpers/reference';
import { md } from 'helpers/utils/screensizes';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';

export interface HeroProps {
  image: MediaItemWithMods;
  imageMobile?: MediaItemWithMods;
  title: string;
  subtitle: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  ctaTitle?: string;
  ctaRel?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

const Hero: React.FC<HeroProps> = ({
  image,
  imageMobile,
  title,
  subtitle,
  ctaLabel,
  ctaReference,
  ctaTitle,
  ctaRel,
  background,
  innerSpacing,
  outerSpacing,
}) => {
  const [isTabletSize] = useMediaQuery(md);

  return (
    <div className={`hero ${background} ${innerSpacing} ${outerSpacing}`}>
      {image && <Image media={imageMobile?.media && !isTabletSize ? imageMobile : image} alt={title} />}

      <div className="hero__content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-5 offset-xl-1">
              <div className="h1">{subtitle}</div>
              <div className="mob-p-p2 dt-p-p2">{title}</div>
              {ctaLabel && ctaReference && (
                <ReferenceLink target={ctaReference} title={ctaTitle} rel={ctaRel} className="btn btn-primary">
                  <Typography>{ctaLabel}</Typography>
                </ReferenceLink>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
