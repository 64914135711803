import React, { useState, Fragment, useEffect } from 'react';
import { Address } from '@Types/account/Address';
import Modal from 'react-bootstrap/Modal';
import { default as IconInfo } from 'components/icons/info';
import { useFormat } from 'helpers/hooks/useFormat';
import useI18n from 'helpers/hooks/useI18n';
import { useAccount, useCart } from 'frontastic';
import { salutationKeys } from '../../sections/general';

export interface CreateAddressProps {
  open?: boolean;
  onClose?: () => void;
  addressId?: string;
}

const CreateAddress: React.FC<CreateAddressProps> = ({ open, onClose }) => {
  const { getProjectSettings } = useCart();
  const [countryOptions, setCountryOptions] = useState<string[]>(null);

  useEffect(() => {
    let isMounted = true;
    const fetchProjectSettings = async () =>
      getProjectSettings().then((data) => {
        if (isMounted) {
          setCountryOptions(data?.countries);
        }
      });
    fetchProjectSettings();
    return () => {
      isMounted = false;
    };
  }, [getProjectSettings]);

  //i18n messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });

  //account data
  const { addAddress, account } = useAccount();

  //I18n info
  const { country } = useI18n();

  //new address data
  const [data, setData] = useState({
    country,
    isDefaultBillingAddress: account.addresses.length === 0,
    isDefaultShippingAddress: account.addresses.length === 0,
  } as Address);

  //input change handler
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  //checkbox change handler
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.checked });
  };

  //select change handler
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  //submission handler
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    try {
      addAddress(data);
    } catch (err) {
    } finally {
      onClose();
    }
  };

  return (
    <Modal show={open} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <h2>{formatAccountMessage({ id: 'address.create.headline', defaultMessage: 'New Address' })}</h2>
            <p className="mob-p-p3 dt-p-p3 mb-0">
              {formatAccountMessage({
                id: 'address.create.dec',
                defaultMessage: 'Add a new address here to associate it with your account',
              })}
            </p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="mb-3">
            <label htmlFor="salutation" className="form-label">
              {formatMessage({ id: 'salutation', defaultMessage: 'Salutation' })}
            </label>
            <select required id="salutation" name="salutation" className="form-select" onChange={handleSelectChange}>
              <option value="" className="text-gray-600">
                {formatMessage({ id: 'choose', defaultMessage: 'Please choose' })}
              </option>
              {Object.values(salutationKeys).map((key) => (
                <option key={key} value={key} className="text-gray-600">
                  {formatMessage({ id: `salutation.${key}`, defaultMessage: '-' })}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="title" className="form-label">
              {formatMessage({ id: 'title', defaultMessage: 'Title' })}
            </label>
            <input
              type="text"
              name="title"
              id="title"
              autoComplete="honorific-prefix"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="first-name" className="form-label">
              {formatMessage({ id: 'firstName', defaultMessage: 'First Name' })} *
            </label>
            <input
              required
              type="text"
              name="firstName"
              id="first-name"
              autoComplete="given-name"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="last-name" className="form-label">
              {formatMessage({ id: 'lastName', defaultMessage: 'Last Name' })} *
            </label>
            <input
              required
              type="text"
              name="lastName"
              id="last-name"
              autoComplete="family-name"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="company" className="form-label">
              {formatAccountMessage({ id: 'companyName', defaultMessage: 'Company name' })} *
            </label>
            <input
              required
              type="text"
              name="company"
              id="company"
              autoComplete="organization"
              className="form-control"
              onChange={handleChange}
            />
            <small className="form-text text-muted">
              {formatAccountMessage({
                id: 'company.alternative',
                defaultMessage: 'Entrepreneurs, private addresses: enter name as company',
              })}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="department" className="form-label">
              {formatAccountMessage({ id: 'department', defaultMessage: 'Department' })}
            </label>
            <input type="text" name="department" id="department" className="form-control" onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label htmlFor="phone" className="form-label">
              {formatMessage({ id: 'phone', defaultMessage: 'Phone' })}
            </label>
            <input
              type="text"
              name="phone"
              id="phone"
              autoComplete="tel"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="additionalAddressInfo" className="form-label">
              {formatAccountMessage({ id: 'additionalAddressInfo', defaultMessage: 'Additional address info' })}
            </label>
            <input
              type="text"
              name="additionalAddressInfo"
              id="additionalAddressInfo"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="street-name" className="form-label">
              {formatMessage({ id: 'street.name', defaultMessage: 'Street Name' })} *
            </label>
            <input
              required
              id="street-name"
              name="streetName"
              type="text"
              autoComplete="address-line1"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="street-number" className="form-label">
              {formatMessage({ id: 'street.number', defaultMessage: 'Street Number' })} *
            </label>
            <input
              required
              type="text"
              name="streetNumber"
              id="street-number"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="postal-code" className="form-label">
              {formatMessage({ id: 'zipCode', defaultMessage: 'Postal Code' })} *
            </label>
            <input
              required
              type="text"
              name="postalCode"
              id="postal-code"
              autoComplete="postal-code"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="city" className="form-label">
              {formatMessage({ id: 'city', defaultMessage: 'City' })} *
            </label>
            <input
              required
              type="text"
              name="city"
              id="city"
              autoComplete="city"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="country" className="form-label">
              {formatMessage({ id: 'country', defaultMessage: 'Country' })} *
            </label>
            <select
              required
              id="country"
              name="country"
              autoComplete="country"
              className="form-select"
              value={data.country ?? ''}
              onChange={handleSelectChange}
            >
              <option value=""></option>
              {countryOptions
                ?.sort(function (codeA, codeB) {
                  const labelA = formatMessage({ id: `country.${codeA}`, defaultMessage: codeA });
                  const labelB = formatMessage({ id: `country.${codeB}`, defaultMessage: codeB });
                  if (labelA < labelB) {
                    return -1;
                  }
                  if (labelA > labelB) {
                    return 1;
                  }
                  return 0;
                })
                .map((code, i) => (
                  <option key={'country' + i} value={code} className="text-gray-600">
                    {formatMessage({ id: `country.${code}` })}
                  </option>
                ))}
            </select>
          </div>
          {account.addresses.length > 0 && (
            <>
              <div className="mb-3">
                <legend className="visually-hidden">
                  {formatAccountMessage({
                    id: 'address.setDefault.delivery',
                    defaultMessage: 'Set as default delivery address',
                  })}
                </legend>
                <div className="form-check">
                  <input
                    id="is-default-shipping-address"
                    aria-describedby="Set as default shipping address"
                    name="isDefaultShippingAddress"
                    type="checkbox"
                    className="form-check-input"
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="is-default-shipping-address" className="form-check-label">
                    {formatAccountMessage({
                      id: 'address.setDefault.delivery',
                      defaultMessage: 'Set as default delivery address',
                    })}
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <legend className="visually-hidden">
                  {formatAccountMessage({
                    id: 'address.setDefault.billing',
                    defaultMessage: 'Set as default billing address',
                  })}
                </legend>
                <div className="form-check">
                  <input
                    id="is-default-billing-address"
                    aria-describedby="Set as default billing addaress"
                    name="isDefaultBillingAddress"
                    type="checkbox"
                    className="form-check-input"
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="is-default-billing-address" className="form-check-label">
                    {formatAccountMessage({
                      id: 'address.setDefault.billing',
                      defaultMessage: 'Set as default billing address',
                    })}
                  </label>
                </div>
              </div>
            </>
          )}
          <div className="alert alert-info d-flex align-items-center" role="alert">
            {IconInfo({ className: 'icon-2em me-3' })}
            {formatAccountMessage({
              id: 'address.create.safety',
              defaultMessage:
                'All the personal associated to your account is safely stored in our database, and we will not share it with third parties.',
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary">
            {formatMessage({ id: 'save', defaultMessage: 'Save' })}
          </button>
          <button type="button" className="btn btn-secondary" onClick={onClose}>
            {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CreateAddress;
