import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { default as IconCheckOutline } from 'components/icons/check-outline';
import { useEmail } from '../../../frontastic';
import { useFormat } from '../../../helpers/hooks/useFormat';

interface ExpertContactFormsProps {
  expertEmail: string;
  defaultActiveKey?: string;
  contactContext?: string;
}

export default function ExpertContactForms({ expertEmail, defaultActiveKey, contactContext }: ExpertContactFormsProps) {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatContactformMessage } = useFormat({ name: 'contactform' });

  const { sendContactEmail } = useEmail();

  const [callbackIsConfirm, setCallbackIsConfirm] = useState(false);
  const callbackOnSubmit = async (e: React.FormEvent<HTMLFormElement>, email) => {
    e.preventDefault();
    const form = e.currentTarget;
    const isValid = !!email || !!e.target['callbackTel'].value;
    if (!isValid) {
      return;
    }
    let subject = formatContactformMessage({
      id: 'expert.callback.subject',
      defaultMessage: 'Callback request',
    });
    if (contactContext) {
      subject += ` ${formatContactformMessage({
        id: 'expert.callback.regarding',
        defaultMessage: 'regarding',
      })} ${contactContext}`;
    }
    let message = `${formatContactformMessage({
      id: 'expert.callback.message.phone',
      defaultMessage: 'Phone number',
    })}: ${e.target['callbackTel'].value}`;
    if (e.target['callbackCustomerNo'].value) {
      message += `\n\n${formatContactformMessage({
        id: 'expert.callback.message.customerid',
        defaultMessage: 'Customer ID',
      })}: ${e.target['callbackCustomerNo'].value}`;
    }
    message += `\n\n${formatContactformMessage({
      id: 'expert.callback.message.timeslot',
      defaultMessage: 'Timeslot',
    })}: ${e.target['callbackTime'].value}`;
    if (contactContext) {
      message += `\n\n${formatContactformMessage({
        id: 'expert.callback.message.context',
        defaultMessage: 'Context',
      })}: ${contactContext}`;
    }
    try {
      await sendContactEmail({
        to: email,
        subject: subject,
        text: message,
        contextMessage: `${formatContactformMessage({
          id: 'expert.callback.contextmessage',
          defaultMessage: 'Callback request from',
        })} ${e.target['callbackTel'].value}`,
      });
      setCallbackIsConfirm(true);
      setTimeout(() => {
        setCallbackIsConfirm(false);
        if (form) {
          form.reset();
        }
      }, 5000);
    } catch (e) {
      console.error(e);
    }
  };

  const [contactIsConfirm, setContactIsConfirm] = useState(false);
  const contactOnSubmit = async (e: React.FormEvent<HTMLFormElement>, email) => {
    e.preventDefault();
    const form = e.currentTarget;
    const isValid = !!email || !!e.target['contactSenderEmail'].value;
    if (!isValid) {
      return;
    }
    let subject = formatContactformMessage({
      id: 'expert.contact.subject',
      defaultMessage: 'Expert contact form inquiry',
    });
    if (e.target['contactSubject'].value) {
      subject = e.target['contactSubject'].value;
    }
    if (contactContext) {
      subject += ` ${formatContactformMessage({
        id: 'expert.contact.regarding',
        defaultMessage: 'regarding',
      })} ${contactContext}`;
    }
    const message = `${formatContactformMessage({
      id: 'expert.contact.message.intro',
      defaultMessage: 'Message from',
    })} ${e.target['contactSenderName'].value ?? e.target['contactSenderEmail'].value}:\n\n${
      e.target['contactMessage'].value
    }`;
    try {
      await sendContactEmail({
        to: email,
        subject: subject,
        text: message,
        contextMessage: `${formatContactformMessage({
          id: 'expert.contact.message.context',
          defaultMessage: 'Expert contact form inquiry from',
        })} ${e.target['contactSenderEmail'].value}`,
      });
      setContactIsConfirm(true);
      setTimeout(() => {
        setContactIsConfirm(false);
        if (form) {
          form.reset();
        }
      }, 5000);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Accordion
      defaultActiveKey={defaultActiveKey}
      onSelect={() => {
        setCallbackIsConfirm(false);
        setContactIsConfirm(false);
      }}
    >
      <Accordion.Item eventKey="callback">
        <Accordion.Header className="mob-p-p2 dt-p-p2">
          {formatContactformMessage({
            id: 'headline.callback',
            defaultMessage: 'Callback request',
          })}
        </Accordion.Header>
        <Accordion.Body>
          <div className="bg-grey-10 p-20 rounded">
            <form action="#" onSubmit={(e) => callbackOnSubmit(e, expertEmail)}>
              <div className="mb-3">
                <label htmlFor="callbackTel" className="form-label">
                  {formatMessage({
                    id: 'phone',
                    defaultMessage: 'Phone',
                  })}
                </label>
                <input
                  type="tel"
                  id="callbackTel"
                  name="callbackTel"
                  className="form-control"
                  placeholder="+49172…"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="callbackCustomerNo" className="form-label">
                  {formatMessage({
                    id: 'customerId',
                    defaultMessage: 'Customer ID',
                  })}
                  <small className="ms-1 text-muted">
                    (
                    {formatMessage({
                      id: 'optional',
                      defaultMessage: 'optional',
                    })}
                    )
                  </small>
                </label>
                <input type="text" id="callbackCustomerNo" name="callbackCustomerNo" className="form-control" />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  {formatContactformMessage({
                    id: 'callback.time',
                    defaultMessage: 'Callback time',
                  })}
                  <sup className="ms-1">*</sup>
                </label>
                <ul className="list-unstyled d-flex flex-wrap m-n6">
                  <li className="w-50 d-flex p-6">
                    <input
                      type="radio"
                      name="callbackTime"
                      id="callbackTime-1"
                      value={`${formatContactformMessage({
                        id: 'callback.time.today',
                        defaultMessage: 'Today',
                      })} 08:00 - 12:00`}
                      required
                      className="btn-check"
                    />
                    <label
                      htmlFor="callbackTime-1"
                      className="d-flex flex-column align-items-center justify-content-center btn btn-outline-grey lh-base fs-6 fw-normal w-100"
                    >
                      {formatContactformMessage({
                        id: 'callback.time.today',
                        defaultMessage: 'Today',
                      })}
                      <span className="d-block">08:00 - 12:00</span>
                    </label>
                  </li>
                  <li className="w-50 d-flex p-6">
                    <input
                      type="radio"
                      name="callbackTime"
                      id="callbackTime-2"
                      value={`${formatContactformMessage({
                        id: 'callback.time.today',
                        defaultMessage: 'Today',
                      })} 12:00 - 18:00`}
                      className="btn-check"
                    />
                    <label
                      htmlFor="callbackTime-2"
                      className="d-flex flex-column align-items-center justify-content-center btn btn-outline-grey lh-base fs-6 fw-normal w-100"
                    >
                      {formatContactformMessage({
                        id: 'callback.time.today',
                        defaultMessage: 'Today',
                      })}
                      <span className="d-block">12:00 - 18:00</span>
                    </label>
                  </li>
                  <li className="w-50 d-flex p-6">
                    <input
                      type="radio"
                      name="callbackTime"
                      id="callbackTime-3"
                      value={`${formatContactformMessage({
                        id: 'callback.time.tomorrow',
                        defaultMessage: 'Tomorrow',
                      })} 08:00 - 12:00`}
                      className="btn-check"
                    />
                    <label
                      htmlFor="callbackTime-3"
                      className="d-flex flex-column align-items-center justify-content-center btn btn-outline-grey lh-base fs-6 fw-normal w-100"
                    >
                      {formatContactformMessage({
                        id: 'callback.time.tomorrow',
                        defaultMessage: 'Tomorrow',
                      })}
                      <span className="d-block">08:00 - 12:00</span>
                    </label>
                  </li>
                  <li className="w-50 d-flex p-6">
                    <input
                      type="radio"
                      name="callbackTime"
                      id="callbackTime-4"
                      value={`${formatContactformMessage({
                        id: 'callback.time.tomorrow',
                        defaultMessage: 'Tomorrow',
                      })} 12:00 - 18:00`}
                      className="btn-check"
                    />
                    <label
                      htmlFor="callbackTime-4"
                      className="d-flex flex-column align-items-center justify-content-center btn btn-outline-grey lh-base fs-6 fw-normal w-100"
                    >
                      {formatContactformMessage({
                        id: 'callback.time.tomorrow',
                        defaultMessage: 'Tomorrow',
                      })}
                      <span className="d-block">12:00 - 18:00</span>
                    </label>
                  </li>
                  <li className="w-50 d-flex p-6">
                    <input
                      type="radio"
                      name="callbackTime"
                      id="callbackTime-5"
                      value={formatContactformMessage({
                        id: 'callback.time.this.week',
                        defaultMessage: 'Callback this week',
                      })}
                      className="btn-check"
                    />
                    <label
                      htmlFor="callbackTime-5"
                      className="d-flex flex-column align-items-center justify-content-center btn btn-outline-grey lh-base fs-6 fw-normal w-100"
                    >
                      {formatContactformMessage({
                        id: 'callback.time.this.week',
                        defaultMessage: 'Callback this week',
                      })}
                    </label>
                  </li>
                  <li className="w-50 d-flex p-6">
                    <input
                      type="radio"
                      name="callbackTime"
                      id="callbackTime-6"
                      value={formatContactformMessage({
                        id: 'callback.time.as.soon.as.possible',
                        defaultMessage: 'As soon as possible',
                      })}
                      className="btn-check"
                    />
                    <label
                      htmlFor="callbackTime-6"
                      className="d-flex flex-column align-items-center justify-content-center btn btn-outline-grey lh-base fs-6 fw-normal w-100"
                    >
                      {formatContactformMessage({
                        id: 'callback.time.as.soon.as.possible',
                        defaultMessage: 'As soon as possible',
                      })}
                    </label>
                  </li>
                </ul>
                <small className="d-block mt-2 lh-sm text-muted">
                  <sup>*</sup>{' '}
                  {formatContactformMessage({
                    id: 'callback.not.on.weekends',
                    defaultMessage: 'Callback requests on weekends will take place on the next available work day',
                  })}
                </small>
              </div>
              <button type="submit" className="btn btn-primary w-100">
                {formatMessage({
                  id: 'send.form',
                  defaultMessage: 'Send form',
                })}
              </button>
              {callbackIsConfirm && (
                <div className="alert alert-success d-flex align-items-center mt-3 mb-0" role="alert">
                  {IconCheckOutline({ className: 'me-20' })}
                  <div className="mob-p-p3 dt-p-p3 m-0 fw-bold">
                    {formatMessage({
                      id: 'form.sent',
                      defaultMessage: 'The form was sent',
                    })}
                  </div>
                </div>
              )}
            </form>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="contact">
        <Accordion.Header className="mob-p-p2 dt-p-p2">
          {formatContactformMessage({
            id: 'headline.fallback',
            defaultMessage: 'Contact form',
          })}
        </Accordion.Header>
        <Accordion.Body>
          <div className="bg-grey-10 p-20 rounded">
            <form action="#" onSubmit={(e) => contactOnSubmit(e, expertEmail)}>
              <div className="mb-3">
                <label htmlFor="contactSenderEmail" className="form-label">
                  {formatContactformMessage({
                    id: 'sender.fallback',
                    defaultMessage: 'Your email',
                  })}
                </label>
                <input
                  type="email"
                  id="contactSenderEmail"
                  name="contactSenderEmail"
                  className="form-control"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="contactSenderName" className="form-label">
                  {formatContactformMessage({
                    id: 'name.fallback',
                    defaultMessage: 'Name',
                  })}
                  <small className="ms-1 text-muted">
                    (
                    {formatMessage({
                      id: 'optional',
                      defaultMessage: 'optional',
                    })}
                    )
                  </small>
                </label>
                <input type="text" id="contactSenderName" name="contactSenderName" className="form-control" />
              </div>
              <div className="mb-3">
                <label htmlFor="contactSubject" className="form-label">
                  {formatContactformMessage({
                    id: 'subject.fallback',
                    defaultMessage: 'Subject',
                  })}
                </label>
                <select id="contactSubject" name="contactSubject" className="form-select">
                  <option
                    value={formatContactformMessage({
                      id: 'contact.subject.product',
                      defaultMessage: 'Question about product',
                    })}
                  >
                    {formatContactformMessage({
                      id: 'contact.subject.product',
                      defaultMessage: 'Question about product',
                    })}
                  </option>
                  <option
                    value={formatContactformMessage({
                      id: 'contact.subject.technical',
                      defaultMessage: 'Technical question',
                    })}
                  >
                    {formatContactformMessage({
                      id: 'contact.subject.technical',
                      defaultMessage: 'Technical question',
                    })}
                  </option>
                  <option
                    value={formatContactformMessage({
                      id: 'contact.subject.availability',
                      defaultMessage: 'Question about availability',
                    })}
                  >
                    {formatContactformMessage({
                      id: 'contact.subject.availability',
                      defaultMessage: 'Question about availability',
                    })}
                  </option>
                  <option
                    value={formatContactformMessage({
                      id: 'contact.subject.order',
                      defaultMessage: 'Question about my order',
                    })}
                  >
                    {formatContactformMessage({
                      id: 'contact.subject.order',
                      defaultMessage: 'Question about my order',
                    })}
                  </option>
                  <option
                    value={formatContactformMessage({
                      id: 'contact.subject.support',
                      defaultMessage: 'Support question',
                    })}
                  >
                    {formatContactformMessage({
                      id: 'contact.subject.support',
                      defaultMessage: 'Support question',
                    })}
                  </option>
                  <option
                    value={formatContactformMessage({
                      id: 'contact.subject.other',
                      defaultMessage: 'Other question',
                    })}
                  >
                    {formatContactformMessage({
                      id: 'contact.subject.other',
                      defaultMessage: 'Other question',
                    })}
                  </option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="contactMessage" className="form-label">
                  {formatContactformMessage({
                    id: 'message.fallback',
                    defaultMessage: 'Message',
                  })}
                </label>
                <textarea
                  id="contactMessage"
                  name="contactMessage"
                  className="form-control"
                  rows={5}
                  placeholder={formatContactformMessage({
                    id: 'your.message',
                    defaultMessage: 'Your message',
                  })}
                />
              </div>
              <button type="submit" className="btn btn-primary w-100">
                {formatMessage({
                  id: 'send.form',
                  defaultMessage: 'Send form',
                })}
              </button>
              {contactIsConfirm && (
                <div className="alert alert-success d-flex align-items-center mt-3 mb-0" role="alert">
                  {IconCheckOutline({ className: 'me-20' })}
                  <div className="mob-p-p3 dt-p-p3 m-0 fw-bold">
                    {formatMessage({
                      id: 'form.sent',
                      defaultMessage: 'The form was sent',
                    })}
                  </div>
                </div>
              )}
            </form>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
