import React from 'react';
import ProductsComparison from '../../../../../components/snaq-ui/products/products-comparison';

function ProductsComparsionTastic({ data }) {
  if (!data?.data?.dataSource) return <></>;

  const { products, compareSkus } = data.data.dataSource;

  return (
    <ProductsComparison
      products={products}
      compareSkus={compareSkus}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
}

export default ProductsComparsionTastic;
