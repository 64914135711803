import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-close ${className}`}
    viewBox="0 0 27.3 27.3"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.1,27.3L0,25.2l11.6-11.6L0,2.1L2.1,0l11.6,11.6L25.2,0l2.1,2.1L15.8,13.7l11.6,11.6l-2.1,2.1L13.7,15.8L2.1,27.3z" />
  </svg>
);

export default Icon;
