import React from 'react';
import { default as IconCart } from 'components/icons/cart';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';

interface CartButtonProps {
  cartItemCount?: number;
  cartLink?: Reference;
}

const CartButton: React.FC<CartButtonProps> = ({ cartItemCount, cartLink }) => {
  //i18n messages
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  return (
    <div className="short-link">
      <ReferenceLink target={cartLink} rel="nofollow" className="short-link__item">
        <div className="short-link__icon">
          {IconCart({})}

          {cartItemCount > 0 && (
            <div className="short-link__counter">
              {cartItemCount}
              <div className="visually-hidden">
                {formatCartMessage({
                  id: 'cart.items.in.view',
                  defaultMessage: 'items in cart, view cart',
                })}
              </div>
            </div>
          )}
        </div>
        <div className="short-link__text">
          {formatCartMessage({
            id: 'cart',
            defaultMessage: 'Cart',
          })}
        </div>
      </ReferenceLink>
    </div>
  );
};

export default CartButton;
