import React from 'react';
import { Cart } from '@Types/cart/Cart';
import { StringHelpers } from 'helpers/stringHelpers';
import { getFiles } from 'helpers/utils/getFiles';
import ProductPlaceholder from 'public/images/product-placeholder.jpg';
import Image from 'frontastic/lib/image';

export interface Props {
  readonly cart: Cart;
}

const CartItems: React.FC<Props> = ({ cart }) => {
  const getImageThumb = (url: string, size: string) => {
    if (!url || !size) return false;
    return url.replaceAll('200x200', size);
  };

  return (
    <ul role="list" className="list-unstyled ">
      {cart?.lineItems?.map((lineItem, i) => (
        <li key={i} className="">
          <div className="row">
            <div className="col-3">
              <Image
                src={
                  getImageThumb(getFiles(lineItem.variant.images, { number: 0, srcKey: '' }), '400x400') ||
                  ProductPlaceholder.src
                }
                alt={lineItem.name}
                className=""
              />
            </div>
            <div className="col-9">
              <h6 className="dt-p-p3">
                <span className="pr-2">{`${lineItem.count}x`}</span> {lineItem.name}
              </h6>
              {lineItem.variant.attributes?.color && (
                <p className="text-gray-500 dark:text-light-100">
                  {StringHelpers.capitaliseFirstLetter(lineItem.variant.attributes.color.label)}
                </p>
              )}
              {lineItem.variant.attributes?.size && (
                <p className="capitalize text-gray-500 dark:text-light-100">{lineItem.variant.attributes.size}</p>
              )}
            </div>
          </div>
          {i < cart.lineItems.length - 1 && <hr className="my-3" />}
        </li>
      ))}
    </ul>
  );
};

export default CartItems;
