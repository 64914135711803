import React from 'react';
import NextLink from 'next/link';

interface LinkReference {
  type: 'link';
  link: string;
  target?: string;
  openInNewWindow: boolean;
}

interface PageFolderReference {
  type: 'page-folder';
  pageFolder: {
    pageFolderId: string;
    name: string;
    hasLivePage: boolean;
    _urls: {
      [locale: string]: string;
    };
    _url: string;
  };
  openInNewWindow: boolean;
}

export type Reference = LinkReference | PageFolderReference;

export const getReferenceTarget = (target: Reference): string => {
  switch (target.type) {
    case 'link':
      return target.link || target.target;
    case 'page-folder':
      return target.pageFolder._url;
    default:
      //Log.warn('Reference ', target, ' is not valid reference')
      return '/';
  }
};

export function getTargetProps(target: LinkReference | PageFolderReference) {
  if (target.openInNewWindow) {
    return {
      target: '_blank',
      rel: 'noopener',
    };
  }

  return {};
}

export function isLiveReference(reference: LinkReference | PageFolderReference) {
  return (
    reference?.type &&
    (reference.type !== 'page-folder' || (reference.type === 'page-folder' && reference.pageFolder?.hasLivePage))
  );
}

interface Props {
  className?: string;
  target: Reference;
  ariaLabel?: string;
  title?: string;
  rel?: string;
}

export const ReferenceLink: React.FC<Props> = ({ target, className, ariaLabel, children, title, rel }) => {
  //no valid target for next/link
  if (!target)
    return (
      <NextLink href="#">
        <a
          aria-label={ariaLabel ? ariaLabel : title || undefined}
          title={title ? title : ariaLabel}
          className={className}
        >
          {children}
        </a>
      </NextLink>
    );

  const attributes = getTargetProps(target);

  if (rel) {
    const targetRel = attributes.rel || '';
    attributes.rel = targetRel.length > 0 ? `${targetRel} ${rel}` : rel;
  }

  const href = getReferenceTarget(target);

  return (
    <NextLink href={href}>
      <a
        aria-label={ariaLabel ? ariaLabel : title || undefined}
        title={title ? title : ariaLabel}
        className={className}
        {...attributes}
      >
        {children}
      </a>
    </NextLink>
  );
};
