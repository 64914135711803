import React from 'react';
import Footer from 'components/snaq-ui/footer';

const FooterTastic = ({ data }) => {
  const columns = [
    {
      header: data.headerCol1,
      links: data.linksCol1,
    },
    {
      header: data.headerCol2,
      links: data.linksCol2,
    },
    {
      header: data.headerCol3,
      links: data.linksCol3,
    },
    {
      header: data.headerCol4,
      links: data.linksCol4,
    },
  ];

  return (
    <div className="fixed-screen-width lg:relative-width">
      <Footer
        description={data.companyDescription}
        address={data.companyAddress}
        email={data.companyEmail}
        phone={data.companyPhone}
        columns={columns}
        copyright={data.copyright}
        copyrightLinks={data.copyrightLinks}
        facebookLink={data.facebookLink}
        twitterLink={data.twitterLink}
        linkedinLink={data.linkedinLink}
        youtubeLink={data.youtubeLink}
        instagramLink={data.instagramLink}
      />
    </div>
  );
};

export default FooterTastic;
