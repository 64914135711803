import React from 'react';
import MediaTextSlider from 'components/snaq-ui/content/media-text-slider';

const MediaTextSliderTastic = ({ data }) => {
  if (!data.slides) return null;

  return (
    <MediaTextSlider
      title={data.title}
      ctaLabel={data.ctaLabel}
      ctaLink={data.ctaLink}
      ctaTitle={data.ctaTitle}
      ctaRel={data.ctaRel}
      slides={data.slides}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
};

export default MediaTextSliderTastic;
