import { checkVAT, countries } from 'jsvat';
import { FormData } from '.';
import { isOtherEuCountry } from '../../../helpers/countryOptions';

export const requiredDataIsValid = (data: FormData, billingIsSameAsShipping: boolean, isAnonymous: boolean) => {
  const requiredInput = [
    'email',
    'shippingFirstName',
    'shippingLastName',
    'shippingCompany',
    'shippingStreetName',
    'shippingStreetNumber',
    'shippingPostalCode',
    'shippingCity',
    'shippingCountry',
  ];
  if (!billingIsSameAsShipping) {
    requiredInput.push(
      'billingFirstName',
      'billingLastName',
      'billingCompany',
      'billingStreetName',
      'billingStreetNumber',
      'billingPostalCode',
      'billingCity',
      'billingCountry',
    );
  }
  if (isAnonymous) {
    if (billingIsSameAsShipping) {
      if (isOtherEuCountry(data.shippingCountry)) {
        requiredInput.push('shippingVatId');
      }
    } else {
      if (isOtherEuCountry(data.billingCountry)) {
        requiredInput.push('billingVatId');
      }
    }
  }

  const mailFormat = /^[\w+-.]+@([\w-]+\.)+[\w-]{2,5}$/;

  const emptyInput = requiredInput.find((input) => !data[input] || data[input] === '');

  if (emptyInput || !data.email.match(mailFormat)) {
    return false;
  }

  if (
    (requiredInput.includes('shippingVatId') || (data['shippingVatId'] && data['shippingVatId'].length > 0)) &&
    !checkVAT(data['shippingVatId'], countries).isValid
  ) {
    return false;
  }

  if (
    (requiredInput.includes('billingVatId') || (data['billingVatId'] && data['billingVatId'].length > 0)) &&
    !checkVAT(data['billingVatId'], countries).isValid
  ) {
    return false;
  }

  return true;
};
