import React, { ChangeEvent, useState, useEffect } from 'react';
import { Account } from '@Types/account/Account';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { ProjectSettings } from '@Types/ProjectSettings';
import classnames from 'classnames';
import { checkVAT, countries } from 'jsvat';
import { useFormat } from 'helpers/hooks/useFormat';
import { getTaxedCountries } from 'helpers/utils/getTaxedCountries';
import { useCart } from 'frontastic/provider';
import { FormData } from '..';
import { isOtherEuCountry } from '../../../../helpers/countryOptions';
import { salutationKeys } from '../../account/details/sections/general';

type AddressProps = {
  data: FormData;
  selectedShippingAddressId: string;
  selectedBillingAddressId: string;
  account: Account;
  updateData: (data: FormData) => void;
  updateSelectedShippingAddressId: (id: string) => void;
  updateSelectedBillingAddressId: (id: string) => void;
  billingIsSameAsShipping: boolean;
  toggleBillingAddressOption: () => void;
  setShippingAddress: () => void;
  setBillingAddress: () => void;
};

const Address: React.FC<AddressProps> = ({
  data,
  selectedShippingAddressId,
  selectedBillingAddressId,
  account,
  updateData,
  updateSelectedShippingAddressId,
  updateSelectedBillingAddressId,
  billingIsSameAsShipping,
  toggleBillingAddressOption,
  setShippingAddress,
  setBillingAddress,
}) => {
  const [projectSettings, setProjectSettings] = useState<ProjectSettings>(null);
  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>(null);
  const [availableCountryOptions, setAvailableCountryOptions] = useState<string[]>(null);
  const { getProjectSettings, getShippingMethods } = useCart();
  const { formatMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const addresses = (account?.addresses ?? []).map((address) => ({
    display: `${address.city} - ${address.streetName} ${address.streetNumber}`,
    data: address.addressId,
  }));

  useEffect(() => {
    getShippingMethods().then((data) => {
      setShippingMethods(data);
    });

    getProjectSettings().then((data) => {
      setProjectSettings(data);
    });
  }, [getShippingMethods, getProjectSettings]);

  useEffect(() => {
    if (shippingMethods && projectSettings) {
      const totalCountries = getTaxedCountries(shippingMethods, projectSettings?.countries);

      setAvailableCountryOptions(totalCountries);
    }
  }, [shippingMethods, projectSettings]);

  const handleChange = (e: ChangeEvent) => {
    const updatedData = {
      ...data,
      [(e.target as HTMLInputElement | HTMLSelectElement)?.name]: (e.target as HTMLInputElement | HTMLSelectElement)
        ?.value,
    };
    updateData(updatedData);
  };

  return (
    <section aria-labelledby="cart-heading">
      <form>
        <div className="mb-20 mob-p-p2 dt-p-p2 text-uppercase fw-bold">
          <span>{formatMessage({ id: 'shippingTo', defaultMessage: 'Shipping to' })}</span>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <div className="mb-30">
              <label className="form-label" htmlFor="email">
                <span>{formatCommonMessage({ id: 'email', defaultMessage: 'Email' })}</span> *
              </label>
              <input
                className="form-control"
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                value={data.email}
              />
            </div>
          </div>
        </div>

        <div className="mb-3 mob-p-p3 dt-p-p3 fw-bold">
          <span>{formatMessage({ id: 'shippingAddress', defaultMessage: 'Shipping address' })}</span>
        </div>

        {account && addresses && (
          <>
            <div className="mb-4">
              <div className="col-span-full">
                <label htmlFor="shippingAddressId" className="form-label">
                  {formatMessage({ id: 'myAddresses', defaultMessage: 'Meine Adressen' })}
                </label>
                <div className="d-flex flex-column flex-sm-row">
                  <select
                    id="shippingAddressId"
                    name="shippingAddressId"
                    className="form-select"
                    onChange={(e: ChangeEvent) => {
                      updateSelectedShippingAddressId((e.target as HTMLSelectElement)?.value);
                    }}
                    value={selectedShippingAddressId}
                  >
                    <option value="">
                      {formatMessage({ id: 'chooseAddress', defaultMessage: 'Adresse auswählen' })}
                    </option>
                    {addresses.map((option, n) => (
                      <option key={n} value={option.data}>
                        {option.display}
                      </option>
                    ))}
                  </select>
                  <button
                    type="button"
                    onClick={setShippingAddress}
                    disabled={!selectedShippingAddressId || selectedShippingAddressId === ''}
                    className="btn btn-primary text-nowrap mt-3 mt-sm-0 ms-sm-20"
                  >
                    {formatMessage({ id: 'useAddress', defaultMessage: 'Adresse übernehmen' })}
                  </button>
                </div>
              </div>
            </div>
            <hr className="m-0 pb-3" />
          </>
        )}

        <div className="row">
          <div className="col-12 col-sm-6 mb-3">
            <label className="form-label" htmlFor="shipping-salutation">
              <span>{formatCommonMessage({ id: 'salutation', defaultMessage: 'Salutation' })}</span>
            </label>
            <select
              id="shipping-salutation"
              name="shippingSalutation"
              className="form-select"
              onChange={handleChange}
              value={data.shippingSalutation}
            >
              <option value=""></option>
              {Object.values(salutationKeys)?.map((key, i) => (
                <option key={'shippingSalutation' + i} value={key}>
                  {formatCommonMessage({ id: `salutation.${key}`, defaultMessage: '-' })}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-6 mb-3">
            <label className="form-label" htmlFor="shipping-title">
              <span>{formatCommonMessage({ id: 'title', defaultMessage: 'Title' })}</span>
            </label>
            <input
              className=" form-control"
              id="shipping-title"
              name="shippingTitle"
              type="text"
              onChange={handleChange}
              value={data.shippingTitle}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 mb-3">
            <label className="form-label" htmlFor="shipping-firstName">
              <span>{formatCommonMessage({ id: 'firstName', defaultMessage: 'First name' })}</span> *
            </label>
            <input
              className="form-control"
              id="shipping-firstName"
              name="shippingFirstName"
              type="text"
              onChange={handleChange}
              value={data.shippingFirstName}
              required
            />
          </div>
          <div className="col-12 col-sm-6 mb-3">
            <label className="form-label" htmlFor="shipping-lastName">
              <span>{formatCommonMessage({ id: 'lastName', defaultMessage: 'Last name' })}</span> *
            </label>
            <input
              className="form-control"
              id="shipping-lastName"
              name="shippingLastName"
              type="text"
              onChange={handleChange}
              value={data.shippingLastName}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className={classnames('col-12 mb-3', !account ? 'col-sm-4' : 'col-sm-6')}>
            <label className="form-label" htmlFor="shipping-company">
              <span>{formatAccountMessage({ id: 'companyName', defaultMessage: 'Company name' })}</span> *
            </label>
            <input
              className="form-control"
              id="shipping-company"
              name="shippingCompany"
              type="text"
              onChange={handleChange}
              value={data.shippingCompany}
              required
            />
            <small className="form-text text-muted">
              {formatAccountMessage({
                id: 'company.alternative',
                defaultMessage: 'Entrepreneurs, private addresses: enter name as company',
              })}
            </small>
          </div>
          {!account && (
            <div className="col-12 col-sm-4 mb-3">
              <label className="form-label" htmlFor="shipping-vatid">
                <span>{formatAccountMessage({ id: 'vatId', defaultMessage: 'Vat-id' })}</span>
                {billingIsSameAsShipping && isOtherEuCountry(data.shippingCountry) && ' *'}
              </label>
              <input
                className="form-control"
                id="shipping-vatid"
                name="shippingVatId"
                type="text"
                onChange={handleChange}
                value={data.shippingVatId}
                required={billingIsSameAsShipping && isOtherEuCountry(data.shippingCountry)}
              />
              {((billingIsSameAsShipping && isOtherEuCountry(data.shippingCountry)) ||
                (data.shippingVatId && data.shippingVatId.length > 0)) &&
                !checkVAT(data.shippingVatId, countries).isValid && (
                  <small className="form-text text-alert">
                    {formatMessage({
                      id: 'vatid.enter',
                      defaultMessage: 'Please enter a valid vat-id',
                    })}
                  </small>
                )}
            </div>
          )}
          <div className={classnames('col-12 mb-3', !account ? 'col-sm-4' : 'col-sm-6')}>
            <label className="form-label" htmlFor="shipping-department">
              <span>{formatAccountMessage({ id: 'department', defaultMessage: 'Department' })}</span>
            </label>
            <input
              className="form-control"
              id="shipping-department"
              name="shippingDepartment"
              type="text"
              onChange={handleChange}
              value={data.shippingDepartment}
            />
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="shipping-additional-address-info">
            {formatAccountMessage({ id: 'additionalAddressInfo', defaultMessage: 'Additional address info' })}
          </label>
          <input
            className="form-control"
            id="shipping-additional-address-info"
            name="shippingAdditionalAddressInfo"
            type="text"
            onChange={handleChange}
            value={data.shippingAdditionalAddressInfo}
          />
        </div>
        <div className="row">
          <div className="col-12 col-sm-8 mb-3">
            <label className="form-label" htmlFor="shipping-street-name">
              <span>{formatCommonMessage({ id: 'street.name', defaultMessage: 'Street name' })}</span> *
            </label>
            <input
              className="form-control"
              id="shipping-street-name"
              name="shippingStreetName"
              type="text"
              onChange={handleChange}
              value={data.shippingStreetName}
              required
            />
          </div>
          <div className="col-12 col-sm-4 mb-3">
            <label className="form-label" htmlFor="shipping-street-number">
              <span>{formatCommonMessage({ id: 'street.number', defaultMessage: 'Street number' })}</span> *
            </label>
            <input
              className="form-control"
              id="shipping-street-number"
              name="shippingStreetNumber"
              type="text"
              onChange={handleChange}
              value={data.shippingStreetNumber}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-4 mb-3">
            <label className="form-label" htmlFor="shipping-postalCode">
              <span>{formatCommonMessage({ id: 'zipCode', defaultMessage: 'Postal code' })}</span> *
            </label>
            <input
              className="form-control"
              id="shipping-postalCode"
              name="shippingPostalCode"
              type="text"
              onChange={handleChange}
              value={data.shippingPostalCode}
              required
            />
          </div>
          <div className="col-12 col-sm-8 mb-3">
            <label className="form-label" htmlFor="shipping-city">
              <span>{formatCommonMessage({ id: 'city', defaultMessage: 'City' })}</span> *
            </label>
            <input
              className="form-control"
              id="shipping-city"
              name="shippingCity"
              type="text"
              onChange={handleChange}
              value={data.shippingCity}
              required
            />
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="shipping-country">
            <span>{formatCommonMessage({ id: 'country', defaultMessage: 'Country' })}</span> *
          </label>
          <select
            id="shipping-country"
            name="shippingCountry"
            className="form-select"
            onChange={handleChange}
            value={data.shippingCountry}
          >
            <option value=""></option>
            {availableCountryOptions
              ?.sort(function (codeA, codeB) {
                const labelA = formatCommonMessage({ id: `country.${codeA}`, defaultMessage: codeA });
                const labelB = formatCommonMessage({ id: `country.${codeB}`, defaultMessage: codeB });
                if (labelA < labelB) {
                  return -1;
                }
                if (labelA > labelB) {
                  return 1;
                }
                return 0;
              })
              .map((code, i) => (
                <option key={'shipping-country' + i} value={code}>
                  {formatCommonMessage({ id: `country.${code}`, defaultMessage: code })}
                </option>
              ))}
          </select>
        </div>
        <div className="form-check">
          <input
            id="billing-same-as-shipping"
            type="checkbox"
            className="form-check-input"
            checked={billingIsSameAsShipping}
            onChange={toggleBillingAddressOption}
          />
          <label htmlFor="billing-same-as-shipping" className="form-check-label">
            {formatMessage({
              id: 'billingDetailsLabel',
              defaultMessage: 'Billing address is the same as shipping address',
            })}
          </label>
        </div>

        {!billingIsSameAsShipping && (
          <>
            <div className="pt-30 mb-20 mob-p-p2 dt-p-p2 text-uppercase fw-bold">
              <span>{formatMessage({ id: 'billingInformation', defaultMessage: 'Billing information' })}</span>
            </div>
            {account && addresses && (
              <>
                <div className="mb-4">
                  <div className="col-span-full">
                    <label htmlFor="billingAddressId" className="form-label">
                      {formatMessage({ id: 'myAddresses', defaultMessage: 'Meine Adressen' })}
                    </label>
                    <div className="d-flex flex-column flex-sm-row">
                      <select
                        id="billingAddressId"
                        name="billingAddressId"
                        className="form-select"
                        value={selectedBillingAddressId}
                        onChange={(e: ChangeEvent) => {
                          updateSelectedBillingAddressId((e.target as HTMLSelectElement)?.value);
                        }}
                      >
                        <option value="">
                          {formatMessage({ id: 'chooseAddress', defaultMessage: 'Adresse auswählen' })}
                        </option>
                        {addresses.map((option, n) => (
                          <option key={n} value={option.data}>
                            {option.display}
                          </option>
                        ))}
                      </select>
                      <button
                        type="button"
                        onClick={setBillingAddress}
                        disabled={!selectedBillingAddressId || selectedBillingAddressId === ''}
                        className="btn btn-primary text-nowrap mt-3 mt-sm-0 ms-sm-20"
                      >
                        {formatMessage({ id: 'useAddress', defaultMessage: 'Adresse übernehmen' })}
                      </button>
                    </div>
                  </div>
                </div>
                <hr className="m-0 pb-3" />
              </>
            )}

            <div className="row">
              <div className="col-12 col-sm-6 mb-3">
                <label className="form-label" htmlFor="billing-salutation">
                  <span>{formatCommonMessage({ id: 'salutation', defaultMessage: 'Salutation' })}</span>
                </label>
                <select
                  id="billing-salutation"
                  name="billingSalutation"
                  className="form-select"
                  onChange={handleChange}
                  value={data.billingSalutation}
                >
                  <option value=""></option>
                  {Object.values(salutationKeys)?.map((key, i) => (
                    <option key={'billingSalutation' + i} value={key}>
                      {formatCommonMessage({ id: `salutation.${key}`, defaultMessage: '-' })}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label className="form-label" htmlFor="billing-title">
                  <span>{formatCommonMessage({ id: 'title', defaultMessage: 'Title' })}</span>
                </label>
                <input
                  className="form-control"
                  id="billing-title"
                  name="billingTitle"
                  type="text"
                  onChange={handleChange}
                  value={data.billingTitle}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 mb-3">
                <label className="form-label" htmlFor="billing-firstName">
                  <span>{formatCommonMessage({ id: 'firstName', defaultMessage: 'First name' })}</span> *
                </label>
                <input
                  className="form-control"
                  id="billing-firstName"
                  name="billingFirstName"
                  type="text"
                  onChange={handleChange}
                  value={data.billingFirstName}
                  required
                />
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label className="form-label" htmlFor="billing-lastName">
                  <span>{formatCommonMessage({ id: 'lastName', defaultMessage: 'Last name' })}</span> *
                </label>
                <input
                  className="form-control"
                  id="billing-lastName"
                  name="billingLastName"
                  type="text"
                  onChange={handleChange}
                  value={data.billingLastName}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className={classnames('col-12 mb-3', !account ? 'col-sm-4' : 'col-sm-6')}>
                <label className="form-label" htmlFor="billing-company">
                  <span>{formatAccountMessage({ id: 'companyName', defaultMessage: 'Company name' })}</span> *
                </label>
                <input
                  className="form-control"
                  id="billing-company"
                  name="billingCompany"
                  type="text"
                  onChange={handleChange}
                  value={data.billingCompany}
                  required
                />
                <small className="form-text text-muted">
                  {formatAccountMessage({
                    id: 'company.alternative',
                    defaultMessage: 'Entrepreneurs, private addresses: enter name as company',
                  })}
                </small>
              </div>
              {!account && (
                <div className="col-12 col-sm-4 mb-3">
                  <label className="form-label" htmlFor="billing-vatid">
                    <span>{formatAccountMessage({ id: 'vatId', defaultMessage: 'Vat-id' })}</span>
                    {!billingIsSameAsShipping && isOtherEuCountry(data.billingCountry) && ' *'}
                  </label>
                  <input
                    className="form-control"
                    id="billing-vatid"
                    name="billingVatId"
                    type="text"
                    onChange={handleChange}
                    value={data.billingVatId}
                    required={!billingIsSameAsShipping && isOtherEuCountry(data.billingCountry)}
                  />
                  {((!billingIsSameAsShipping && isOtherEuCountry(data.billingCountry)) ||
                    (data.billingVatId && data.billingVatId.length > 0)) &&
                    !checkVAT(data.billingVatId, countries).isValid && (
                      <small className="form-text text-alert">
                        {formatMessage({
                          id: 'vatid.enter',
                          defaultMessage: 'Please enter a valid vat-id',
                        })}
                      </small>
                    )}
                </div>
              )}
              <div className={classnames('col-12 mb-3', !account ? 'col-sm-4' : 'col-sm-6')}>
                <label className="form-label" htmlFor="billing-department">
                  <span>{formatAccountMessage({ id: 'department', defaultMessage: 'Department' })}</span>
                </label>
                <input
                  className="form-control"
                  id="billing-department"
                  name="billingDepartment"
                  type="text"
                  onChange={handleChange}
                  value={data.billingDepartment}
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="billing-additional-address-info">
                {formatAccountMessage({ id: 'additionalAddressInfo', defaultMessage: 'Additional address info' })}
              </label>
              <input
                className="form-control"
                id="billing-additional-address-info"
                name="billingAdditionalAddressInfo"
                type="text"
                onChange={handleChange}
                value={data.billingAdditionalAddressInfo}
              />
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 mb-3">
                <label className="form-label" htmlFor="billing-street-name">
                  <span>{formatCommonMessage({ id: 'street.name', defaultMessage: 'Street name' })}</span> *
                </label>
                <input
                  className="form-control"
                  id="billing-street-name"
                  name="billingStreetName"
                  type="text"
                  onChange={handleChange}
                  value={data.billingStreetName}
                  required
                />
              </div>
              <div className="col-12 col-sm-8 mb-3">
                <label className="form-label" htmlFor="billing-street-number">
                  <span>{formatCommonMessage({ id: 'street.number', defaultMessage: 'Street number' })}</span> *
                </label>
                <input
                  className="form-control"
                  id="billing-street-number"
                  name="billingStreetNumber"
                  type="text"
                  onChange={handleChange}
                  value={data.billingStreetNumber}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-8 mb-3">
                <label className="form-label" htmlFor="billing-postalCode">
                  <span>{formatCommonMessage({ id: 'zipCode', defaultMessage: 'Postal code' })}</span> *
                </label>
                <input
                  className="form-control"
                  id="billing-postalCode"
                  name="billingPostalCode"
                  type="text"
                  onChange={handleChange}
                  value={data.billingPostalCode}
                  required
                />
              </div>
              <div className="col-12 col-sm-4 mb-3">
                <label className="form-label" htmlFor="billing-city">
                  <span>{formatCommonMessage({ id: 'city', defaultMessage: 'City' })}</span> *
                </label>
                <input
                  className="form-control"
                  id="billing-city"
                  name="billingCity"
                  type="text"
                  onChange={handleChange}
                  value={data.billingCity}
                  required
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="billing-country">
                <span>{formatCommonMessage({ id: 'country', defaultMessage: 'Country' })}</span> *
              </label>
              <select
                id="billing-country"
                name="billingCountry"
                className="form-select"
                onChange={handleChange}
                value={data.billingCountry}
              >
                <option value=""></option>
                {availableCountryOptions
                  ?.sort(function (codeA, codeB) {
                    const labelA = formatCommonMessage({ id: `country.${codeA}`, defaultMessage: codeA });
                    const labelB = formatCommonMessage({ id: `country.${codeB}`, defaultMessage: codeB });
                    if (labelA < labelB) {
                      return -1;
                    }
                    if (labelA > labelB) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((code, i) => (
                    <option key={'billing-country' + i} value={code}>
                      {formatCommonMessage({ id: `country.${code}`, defaultMessage: code })}
                    </option>
                  ))}
              </select>
            </div>
          </>
        )}
      </form>
    </section>
  );
};

export default Address;
