import React from 'react';
import TextOnMedia from 'components/snaq-ui/content/text-on-media';

const TextOnMediaTastic = ({ data }) => {
  return (
    <TextOnMedia
      width={data.width}
      roundedCorners={data.roundedCorners}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
      textPosition={data.textPosition}
      textColor={data.textColor}
      textBackground={data.textBackground}
      title={data.title}
      text={data.text}
      ctaBackground={data.buttonColor}
      ctaLabel={data.ctaLabel}
      ctaReference={data.ctaReference}
      ctaTitle={data.ctaTitle}
      ctaRel={data.ctaRel}
      mediaHeight={data.mediaHeight}
      media={data.media}
    />
  );
};

export default TextOnMediaTastic;
