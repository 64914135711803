import React, { useCallback, useEffect, useState } from 'react';
import { Term } from '@Types/result/Term';
import { TermFacet } from '@Types/result/TermFacet';
import { useFormat } from '../../../helpers/hooks/useFormat';

export type TermFilterParams = {
  index: number;
  value: string;
};

type TermFilterDisclosureProps = {
  facet: TermFacet;
  onChange: (values: Array<{ index: number; value: string }>) => void;
  isBoolean?: boolean;
};

const compareString = (stringA, stringB): number => {
  const parsedA = parseInt(stringA.split(' ')[0]);
  const parsedB = parseInt(stringB.split(' ')[0]);
  if (!isNaN(parsedA) && !isNaN(parsedB)) {
    return parsedA - parsedB;
  }
  return stringA.localeCompare(stringB);
};

const TermFilter: React.FC<TermFilterDisclosureProps> = ({ facet, onChange, isBoolean = false }) => {
  const [sortedTerms, setSortedTerms] = useState<Array<Term>>([]);
  const [params, setParams] = useState<Array<TermFilterParams>>([]);
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });

  useEffect(() => {
    const labeledTerms = facet?.terms?.filter((term) => typeof term.label === 'string') || [];
    const mappedTerms =
      labeledTerms
        .sort((termA, termB) => {
          // NOTE: Try/catch ... copied from earlier commit...
          // unsure, if JSON.parse is working at all b/c facet apparently always simple string,
          // resulting in error catch scenario
          try {
            const valueObjA = JSON.parse(termA.label);
            const valueObjB = JSON.parse(termB.label);
            const hasAmount = !!valueObjA.amount && !!valueObjB.amount;
            const hasUnit = !!valueObjA.unit && !!valueObjB.unit;
            if (hasAmount && hasUnit) {
              return valueObjA.amount - valueObjB.amount;
            } else {
              return compareString(termA.label, termB.label);
            }
          } catch (e) {
            return compareString(termA.label, termB.label);
          }
        })
        .map((term) => {
          let displayValue = term.label;
          // NOTE: Try/catch ... copied from earlier commit...
          // unsure, if JSON.parse is working at all b/c facet apparently always simple string,
          // resulting in error catch scenario
          try {
            const valueObj = JSON.parse(term.label);
            const hasAmount = !!valueObj.amount;
            const hasUnit = !!valueObj.unit;
            if (hasAmount && hasUnit) {
              const unitString = String(valueObj.unit);
              displayValue = `${parseInt(valueObj.amount)} ${
                unitString.charAt(0).toUpperCase() + unitString.slice(1).toLowerCase().replaceAll('_', ' ')
              }`;
            }
          } catch (e) {
            // Keep quiet
          }
          return { ...term, label: displayValue };
        }) || [];
    // NOTE: Reverse mappedTerms from ["F", "T"] to ["Ja", "Nein"] or ["Yes", "No"] b/c boolean
    if (isBoolean && facet.type === 'boolean' && mappedTerms.length === 2) {
      mappedTerms.reverse();
    }
    setSortedTerms(mappedTerms);
    setSelected(Object.fromEntries(mappedTerms.map((term) => [term.key, term.selected])));
    setParams(
      mappedTerms
        ?.map((term, index) => ({
          index,
          value: term.key,
          selected: term.selected,
        }))
        .filter((term) => term.selected) || [],
    );
  }, [facet, isBoolean]);

  const handleChange = useCallback(
    (index: number, checked: boolean) => {
      if (!sortedTerms) return;

      let newParams = [...params];

      if (!checked) newParams = newParams.filter((param) => param.index !== index);
      else newParams = [...newParams, { index, value: sortedTerms[index].key }];

      setSelected({ ...selected, [sortedTerms[index].key]: checked });

      setParams(newParams);
      onChange(newParams);
    },
    [onChange, sortedTerms, params, selected],
  );

  return (
    <>
      {sortedTerms.map((term, index) => {
        return (
          <div className="form-check filter-item" key={term.identifier}>
            <input
              id={term.identifier + index}
              type="checkbox"
              className="form-check-input"
              onChange={(e) => handleChange(index, e.target.checked)}
              checked={selected[term.key] ?? false}
            />
            {isBoolean ? (
              <label htmlFor={term.identifier + index} className="form-check-label">
                {term.label === 'T'
                  ? formatCommonMessage({ id: 'yes', defaultMessage: 'Yes' })
                  : formatCommonMessage({ id: 'no', defaultMessage: 'No' })}
              </label>
            ) : (
              <label htmlFor={term.identifier + index} className="form-check-label">
                {term.label}
              </label>
            )}
          </div>
        );
      })}
    </>
  );
};

export default TermFilter;
