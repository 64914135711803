import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { Reference, ReferenceLink } from 'helpers/reference';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import { useSession } from '../../../../frontastic';

// https://www.w3schools.com/js/js_cookies.asp
const getCookie = (cname: string): string | null => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export interface MarketingProps {
  activated: boolean;
  startDate: string;
  endDate: string;
  interval: number;
  image: MediaItemWithMods;
  ctaReference?: Reference;
  ctaTitle?: string;
  ctaRel?: string;
}

const MarketingPopup: React.FC<MarketingProps> = ({
  activated,
  startDate,
  endDate,
  interval = 0,
  image,
  ctaReference,
  ctaTitle,
  ctaRel,
}) => {
  const { getMarketingPopupTimestamp, setMarketingPopupTimestamp } = useSession();
  const router = useRouter();

  const marketingOptIn = useMemo(() => {
    /* NOTE: Set dummy CookieConsent cookie for development
    cookieCutter.set(
      'CookieConsent',
      '{stamp:%2772AxvDAs/qV2ZWzZj/YcmIKfG2VacZIhk5R8u4t7vQA+cg+tx5oC+Q==%27%2Cnecessary:true%2Cpreferences:true%2Cstatistics:true%2Cmarketing:true%2Cmethod:%27explicit%27%2Cver:1%2Cutc:1731069863427%2Cregion:%27de%27}',
    ); */
    const cookieConsent = getCookie('CookieConsent');
    if (!!cookieConsent) {
      try {
        const value = decodeURIComponent(cookieConsent);
        return value.includes('marketing:true');
      } catch {
        return null;
      }
    }
    return null;
  }, []);

  const [popupShow, setPopupShow] = useState(false);
  const handlePopupShow = () => {
    setTimeout(() => {
      setPopupShow(true);
    }, 5000);
  };
  const handlePopupClose = () => {
    setMarketingPopupTimestamp();
    setPopupShow(false);
  };

  useEffect(() => {
    if (popupShow || !activated || !marketingOptIn) {
      return;
    }
    if (startDate && !!Date.parse(startDate) && Date.parse(startDate) > Date.now()) {
      return;
    }
    if (endDate && !!Date.parse(endDate) && Date.parse(endDate) < Date.now()) {
      return;
    }
    const timestamp = getMarketingPopupTimestamp();
    if (!timestamp) {
      handlePopupShow();
      return;
    }
    const expiredTimestamp = new Date();
    let intervalHours = interval;
    if (intervalHours < 0) {
      intervalHours = -intervalHours;
    }
    expiredTimestamp.setHours(expiredTimestamp.getHours() - intervalHours);
    if (timestamp < expiredTimestamp.toISOString()) {
      handlePopupShow();
    }
  }, [activated, marketingOptIn, startDate, endDate, interval, getMarketingPopupTimestamp]);

  const altText = useMemo(() => {
    const lang = router.locale || router.defaultLocale;
    if (!image.title || !lang) {
      return '';
    }
    const titleKey = Object.keys(image.title).find((key) => key.startsWith(lang));
    return image.title[titleKey] || '';
  }, [image, router]);

  return marketingOptIn && image ? (
    <div className={clsx('popup-container', popupShow && 'd-block')}>
      <div className="popup">
        {ctaReference ? (
          <ReferenceLink target={ctaReference} title={ctaTitle} rel={ctaRel}>
            <Image media={image} alt={altText} layout="intrinsic" />
          </ReferenceLink>
        ) : (
          <Image media={image} alt={altText} layout="intrinsic" />
        )}
        <button onClick={handlePopupClose} className="popup-close">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0  27.3 27.3" fill="black">
            <path d="M2.1,27.3L0,25.2l11.6-11.6L0,2.1L2.1,0l11.6,11.6L25.2,0l2.1,2.1L15.8,13.7l11.6,11.6l-2.1,2.1L13.7,15.8L2.1,27.3z" />
          </svg>
        </button>
      </div>
    </div>
  ) : null;
};

export default MarketingPopup;
