import React, { useMemo, useState } from 'react';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
export interface TextAndMediaTwiceProps {
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
  titleLeft?: string;
  textLeft?: string;
  mediaLeft?: MediaItemWithMods;
  titleRight?: string;
  textRight?: string;
  mediaRight?: MediaItemWithMods;
}

const TextAndMediaTwice: React.FC<TextAndMediaTwiceProps> = ({
  background,
  innerSpacing,
  outerSpacing,
  titleLeft,
  textLeft,
  mediaLeft,
  titleRight,
  textRight,
  mediaRight,
}) => {
  const contentMedia = (file) => {
    const mediaType = file?.media?.resourceType;

    if ('image' === mediaType) {
      return <Image media={file} alt={file?.media?.alt} className={'d-block rounded'} />;
    }

    if ('video' === mediaType) {
      return (
        <video preload="metadata" controls={false} className={'d-block rounded'}>
          <source src={file?.media?.file} type={`video/${file?.media?.format}`} />
        </video>
      );
    }
    return <></>;
  };

  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      <div className="row">
        <div className={`col-12 col-md-6`}>
          <div className="w-100 section-spacing-padding px-md-40 pb-md-40 px-xl-50 pb-xl-50">
            {contentMedia(mediaLeft)}

            <div className="pt-40 pt-md-50">
              {titleLeft && <h2>{titleLeft}</h2>}
              {textLeft && <p className="m-0">{textLeft}</p>}
            </div>
          </div>
        </div>
        <div className={`col-12 col-md-6 d-flex position-relative`}>
          <div className="w-100 bg-black text-white section-spacing-padding px-md-40 pb-md-40 px-xl-50 pb-xl-50">
            {contentMedia(mediaRight)}
            <div className="pt-40 pt-xl-50">
              {titleRight && <h2>{titleRight}</h2>}
              {titleRight && <p className="m-0">{textRight}</p>}
              <div className="pe-none bg-black-full d-md-none position-absolute top-0 start-0 w-100 h-100"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextAndMediaTwice;
