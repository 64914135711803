import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-plus ${className}`}
    viewBox="0 0 28 28"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.5,15.5H0v-3h12.5V0h3v12.5H28v3H15.5V28h-3V15.5z" />
  </svg>
);

export default Icon;
