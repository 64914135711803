import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-youtube ${className}`}
    viewBox="0 0 549 386"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M537.5 60.4c-6.3-23.8-24.9-42.5-48.5-48.9C446.2 0 274.5 0 274.5 0S102.8 0 60 11.5c-23.6 6.4-42.2 25.1-48.5 48.9C0 103.5 0 193.4 0 193.4s0 89.9 11.5 133c6.3 23.8 24.9 41.7 48.5 48.1C102.8 386 274.5 386 274.5 386s171.7 0 214.5-11.5c23.6-6.4 42.2-24.3 48.5-48.1 11.5-43.1 11.5-133 11.5-133s0-89.9-11.5-133zM218.4 275V111.8l143.5 81.6L218.4 275z" />
  </svg>
);

export default Icon;
