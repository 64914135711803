import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-minus ${className}`}
    viewBox="0 0 28 3"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0,3V0h28v3H0z" />
  </svg>
);

export default Icon;
