import React from 'react';
import Testimonials, { Testimonial } from 'components/snaq-ui/content/testimonials';

type Props = {
  title?: string;
  testimonials: Array<Testimonial>;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
};

const TestimonialsTastic = ({ id, data }: { id: string; data: Props }) => {
  if (!data?.testimonials) return <></>;

  const { title, testimonials, background, innerSpacing, outerSpacing } = data;

  return (
    <Testimonials
      tasticId={id}
      title={title}
      items={testimonials}
      background={background}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
    />
  );
};

export default TestimonialsTastic;
