import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-cart ${className}`}
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 20c-.6 0-1-.2-1.4-.6-.4-.4-.6-.9-.6-1.4s.2-1 .6-1.4c.4-.4.9-.6 1.4-.6s1 .2 1.4.6c.4.4.6.9.6 1.4s-.2 1-.6 1.4c-.4.4-.8.6-1.4.6zm10 0c-.6 0-1-.2-1.4-.6-.4-.4-.6-.9-.6-1.4s.2-1 .6-1.4c.4-.4.9-.6 1.4-.6.6 0 1 .2 1.4.6.4.4.6.9.6 1.4s-.2 1-.6 1.4c-.4.4-.8.6-1.4.6zM5.2 4l2.4 5h7l2.8-5H5.2zM6 15c-.8 0-1.3-.3-1.7-1-.4-.7-.4-1.3-.1-2l1.4-2.4L2 2H1c-.3 0-.5-.1-.7-.3C.1 1.5 0 1.3 0 1 0 .7.1.5.3.3.5.1.7 0 1 0h1.6c.2 0 .4.1.6.2.2.1.3.2.4.4L4.2 2H19c.5 0 .8.2.9.5s.2.7 0 1l-3.6 6.4c-.2.3-.4.6-.7.8-.3.2-.6.3-1 .3H7.1L6 13h11c.3 0 .5.1.7.3.2.2.3.4.3.7s-.1.5-.3.7-.4.3-.7.3H6z" />
  </svg>
);

export default Icon;
