import SearchResult from 'components/snaq-ui/products/product-list/search-result';

function ProductListTastic({ data }) {
  if (!data?.data?.dataSource) return <></>;

  const { items, facets, category, previousCursor, nextCursor, total, offset, filters } = data?.data?.dataSource;

  return (
    <SearchResult
      filters={filters}
      products={items}
      totalProducts={total}
      offset={offset}
      facets={facets}
      category={category}
      previousCursor={previousCursor}
      nextCursor={nextCursor}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
}

export default ProductListTastic;
