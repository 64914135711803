import React from 'react';
import ContactForm, { ContactFormProps } from 'components/snaq-ui/contact-form';

interface Props {
  data: ContactFormProps;
}

const ContactFormTastic: React.FC<Props> = ({ data }) => {
  return <ContactForm {...data} />;
};

export default ContactFormTastic;
