import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { checkVAT, countries } from 'jsvat';
import { Trans, useTranslation } from 'react-i18next';
import { useFormat } from 'helpers/hooks/useFormat';
import Redirect from 'helpers/redirect';
import { Reference, ReferenceLink } from 'helpers/reference';
import { useAccount } from 'frontastic';
import { companyTypeKeys, salutationKeys } from '../details/sections/general';

export interface StaticCompany {
  id: string;
  erp_id: string;
  company_name: string;
}

export interface RegisterProps {
  loginLink?: Reference;
  contactLink?: Reference;
}

const Register: React.FC<RegisterProps> = ({ loginLink, contactLink }) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatSuccessMessage } = useFormat({ name: 'success' });
  const { formatMessage } = useFormat({ name: 'common' });
  const { t: tAccount } = useTranslation(['account']);
  const router = useRouter();
  const { redirect } = router.query;

  //account actions
  const { register, loggedIn, getCompanyByVatId } = useAccount();

  //register data
  const [data, setData] = useState({
    salutation: '',
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    companyType: '',
    staticCompanyName: '',
    companyName: '',
    vatId: '',
    externalId: '',
    newsletter: '',
  });

  //error
  const [error, setError] = useState('');

  //success
  const [success, setSuccess] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);

  const [staticCompanies, setStaticCompanies] = useState<StaticCompany[]>([]);
  //handle text input change
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'vatId') {
      setError('');
      const vatIdMandantory =
        data.companyType === companyTypeKeys.company || data.companyType === companyTypeKeys.companyEU;
      // https://se-panfilov.github.io/jsvat/
      const vatIdValid = checkVAT(e.target.value, countries).isValid;
      if (vatIdMandantory && !vatIdValid) {
        setError(formatErrorMessage({ id: 'vatid.invalid', defaultMessage: 'Invalid Vat-Id' }));
      }
      let companies: StaticCompany[] = [];
      if (vatIdValid) {
        try {
          const companyByVatId: any = await getCompanyByVatId(e.target.value);
          companies =
            companyByVatId?.value
              ?.filter((company) => !!company.company_name && !!company.erp_id)
              .map((company) => company) ?? [];
        } catch (e) {
          console.error(e);
        }
      }
      setStaticCompanies(companies);
      if (companies.length === 0) {
        setData((prevState) => ({
          ...prevState,
          vatId: e.target.value,
          companyName: '',
          staticCompanyName: '',
          externalId: '',
        }));
      } else if (companies.length === 1) {
        setData((prevState) => ({
          ...prevState,
          vatId: e.target.value,
          companyName: companies[0].company_name,
          externalId: companies[0].erp_id,
        }));
      } else {
        setData((prevState) => ({ ...prevState, vatId: e.target.value, staticCompanyName: '', externalId: '' }));
      }
    } else {
      setData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    }
  };

  //checkbox change handler
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prevState) => ({ ...prevState, [e.target.name]: e.target.checked ? 'true' : '' }));
  };

  //select change handler
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.name === 'staticCompanyName') {
      const company = staticCompanies.find((company) => company.id === e.target.value);
      if (company) {
        setData((prevState) => ({ ...prevState, staticCompanyName: company.company_name, externalId: company.erp_id }));
      }
    } else {
      setData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    }
  };

  //data validation
  const validate = () => {
    //validation schema
    const passwordsMatch = data.password === data.confirmPassword;

    //UI error messages
    if (!passwordsMatch)
      setError(formatErrorMessage({ id: 'password.noMatch', defaultMessage: "Passwords don't match" }));

    const vatIdMandantory =
      data.companyType === companyTypeKeys.company || data.companyType === companyTypeKeys.companyEU;
    const vatIdEntered = data.vatId.length > 0;
    // https://se-panfilov.github.io/jsvat/
    const vatIdValid = checkVAT(data.vatId, countries).isValid;
    if ((vatIdMandantory || vatIdEntered) && !vatIdValid) {
      setError(formatErrorMessage({ id: 'vatid.invalid', defaultMessage: 'Invalid Vat-Id' }));
    }

    //return a boolean representing the data validity
    return vatIdMandantory || vatIdEntered ? passwordsMatch && vatIdValid : passwordsMatch;
  };

  //form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //validate data
    if (!validate()) return;
    //processing starts
    setLoading(true);
    //try registering the user with given credentials
    try {
      const response = await register({
        salutation: data.salutation,
        title: data.title || undefined,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        companyName: data.staticCompanyName || data.companyName,
        companyType: data.companyType,
        vatId: data.vatId || undefined,
        externalId: data.externalId || undefined,
        newsletter: data.newsletter === 'true',
      });
      if (!response.accountId) {
        // Don't ask me, ask commercetools/frontastic about error handling :/
        if (JSON.stringify(response) === '"account_email_not_active_error"') {
          setError(
            formatErrorMessage({
              id: 'account.exists',
              defaultMessage: 'The account {email} already exists.',
              values: { email: data.email },
            }),
          );
        } else {
          setError(
            formatErrorMessage({
              id: 'account.create.fail',
              defaultMessage: "Sorry. We couldn't create your account.",
            }),
          );
        }
        setSuccess('');
      } else {
        setError('');
        setSuccess(
          formatSuccessMessage({
            id: 'account.created',
            defaultMessage: 'A verification email was sent to {email} ✓',
            values: { email: data.email },
          }),
        );
      }
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong.' }));
      setSuccess('');
    }
    //processing ends
    setLoading(false);
  };

  const interpolatedComponents = [
    <ReferenceLink
      key={0}
      className="cursor-pointer font-medium text-accent-500 hover:underline"
      target={contactLink}
    />,
  ];

  if (loggedIn) return <Redirect target={redirect ? decodeURIComponent(redirect.toString()) : '/'} />;

  return (
    <div className="section-spacing">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-8">
          <form onSubmit={handleSubmit}>
            <div className="py-6 text-center">
              <h1>{formatAccountMessage({ id: 'becomeMember', defaultMessage: 'Become a member' })}</h1>
              <h2 className="h5 fw-normal mb-40">
                {formatAccountMessage({
                  id: 'offers.doNotMiss',
                  defaultMessage: 'Don’t miss out on deals, offers, discounts and bonus vouchers.',
                })}
              </h2>
            </div>
            <div className="row mb-2">
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="salutation" className="form-label">
                  {formatMessage({ id: 'salutation', defaultMessage: 'Salutation' })} *
                </label>
                <select
                  id="salutation"
                  name="salutation"
                  required
                  className="form-select form-select-lg"
                  onChange={handleSelectChange}
                >
                  <option value="" className="text-gray-600">
                    {formatMessage({ id: 'choose', defaultMessage: 'Please choose' })}
                  </option>
                  {Object.values(salutationKeys).map((key) => (
                    <option key={key} value={key} className="text-gray-600">
                      {formatMessage({ id: `salutation.${key}`, defaultMessage: '-' })}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="title" className="form-label">
                  {formatMessage({ id: 'title', defaultMessage: 'Title' })}
                </label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  autoComplete="honorific-prefix"
                  className="form-control form-control form-control-lg"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="first-name" className="form-label">
                  {formatMessage({ id: 'firstName', defaultMessage: 'First Name' })} *
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="first-name"
                  autoComplete="given-name"
                  placeholder={formatAccountMessage({
                    id: 'firstName.enter',
                    defaultMessage: 'Enter your first name',
                  })}
                  required
                  className="form-control form-control form-control-lg"
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="last-name" className="form-label">
                  {formatMessage({ id: 'lastName', defaultMessage: 'Last Name' })} *
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="last-name"
                  autoComplete="family-name"
                  placeholder={formatAccountMessage({ id: 'lastName.enter', defaultMessage: 'Enter your last name' })}
                  required
                  className="form-control form-control form-control-lg"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12 mb-3">
                <label htmlFor="email" className="form-label">
                  {formatMessage({ id: 'email', defaultMessage: 'Email' })} *
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder={formatAccountMessage({ id: 'email.enter', defaultMessage: 'Enter your email' })}
                  required
                  className="form-control form-control form-control-lg"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="password" className="form-label">
                  {formatAccountMessage({ id: 'password', defaultMessage: 'Password' })} *
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  required
                  className="form-control form-control form-control-lg"
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="password" className="form-label">
                  {formatAccountMessage({ id: 'password.confirm', defaultMessage: 'Confirm Password' })} *
                </label>
                <input
                  id="confirm-password"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  required
                  className="form-control form-control form-control-lg"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="company-type" className="form-label">
                  {formatAccountMessage({ id: 'companyType', defaultMessage: 'Company type' })} *
                </label>
                <select
                  id="company-type"
                  name="companyType"
                  required
                  className="form-select form-select-lg"
                  onChange={handleSelectChange}
                >
                  <option value="" className="text-gray-600">
                    {formatMessage({ id: 'choose', defaultMessage: 'Please choose' })}
                  </option>
                  {Object.values(companyTypeKeys).map((key) => (
                    <option key={key} value={key} className="text-gray-600">
                      {formatAccountMessage({ id: `companyType.${key}`, defaultMessage: '-' })}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="vatId" className="form-label">
                  {formatAccountMessage({ id: 'vatId', defaultMessage: 'Vat-id' })}{' '}
                  {(data.companyType === companyTypeKeys.company || data.companyType === companyTypeKeys.companyEU) &&
                    '*'}
                </label>
                <input
                  type="text"
                  name="vatId"
                  id="vatid"
                  placeholder={formatAccountMessage({ id: 'vatId.enter', defaultMessage: 'Enter your vat-id' })}
                  required={
                    data.companyType === companyTypeKeys.company || data.companyType === companyTypeKeys.companyEU
                  }
                  className="form-control form-control form-control-lg"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12 col-sm-6 mb-3">
                {staticCompanies.length > 1 ? (
                  <>
                    <label htmlFor="static-company-name" className="form-label">
                      {formatAccountMessage({ id: 'companyName', defaultMessage: 'Company name' })}{' '}
                    </label>
                    <select
                      id="static-company-name"
                      name="staticCompanyName"
                      required
                      className="form-select form-select-lg"
                      onChange={handleSelectChange}
                    >
                      <option value="" className="text-gray-600">
                        {formatMessage({ id: 'choose', defaultMessage: 'Please choose' })}
                      </option>
                      {staticCompanies.map((company) => (
                        <option key={'company' + company.id} value={company.id} className="text-gray-600">
                          {company.company_name}
                        </option>
                      ))}
                    </select>
                  </>
                ) : (
                  <>
                    <label htmlFor="companyname" className="form-label">
                      {formatAccountMessage({ id: 'companyName', defaultMessage: 'Company name' })}{' '}
                      {staticCompanies.length === 0 && '*'}
                    </label>
                    <input
                      type="text"
                      name="companyName"
                      id="companyname"
                      autoComplete="organization"
                      placeholder={formatAccountMessage({
                        id: 'companyName.enter',
                        defaultMessage: 'Enter your company name',
                      })}
                      required={staticCompanies.length === 0}
                      disabled={staticCompanies.length > 0}
                      value={data.companyName}
                      className="form-control form-control form-control-lg"
                      onChange={handleChange}
                    />
                  </>
                )}
                {staticCompanies.length > 0 && (
                  <p className="mob-p-p4 dt-p-p4 mt-2">
                    <b>
                      {formatAccountMessage({
                        id: 'company.incorrect',
                        defaultMessage: 'Company name incorrect?',
                      })}
                    </b>{' '}
                    <Trans i18nKey="company.contactSupport" t={tAccount} components={interpolatedComponents} />
                  </p>
                )}
              </div>
            </div>
            <div className="form-check mb-4">
              <input
                type="checkbox"
                name="newsletter"
                id="newsletter"
                value="true"
                className="form-check-input"
                onChange={handleCheckboxChange}
              />
              <label htmlFor="newsletter" className="form-check-label">
                {formatMessage({ id: 'newsletter', defaultMessage: 'Newsletter' })}
              </label>
            </div>
            <div className="form-check mb-4">
              <input
                type="checkbox"
                name="acceptTerms"
                id="accept-terms"
                value="true"
                required
                className="form-check-input"
                onChange={handleCheckboxChange}
              />
              <label htmlFor="accept-terms" className="form-check-label">
                {formatMessage({ id: 'accept.terms', defaultMessage: 'AGB zustimmen' })} *
              </label>
            </div>
            {success && (
              <div className="alert alert-success mob-p-p3 dt-p-p3" role="alert">
                {success}
              </div>
            )}
            {error && (
              <div className="alert alert-danger mob-p-p3 dt-p-p3" role="alert">
                {error}
              </div>
            )}
            <div>
              <button type="submit" className="btn btn-primary btn-lg w-100" disabled={loading}>
                {formatAccountMessage({ id: 'register', defaultMessage: 'Register' })}
              </button>
              <p className="mb-0 mt-20 mob-p-p3 dt-p-p3 text-center">
                {formatAccountMessage({ id: 'account.alreadyHave', defaultMessage: 'Already have an account?' })}{' '}
                <ReferenceLink
                  target={loginLink}
                  className="font-medium text-accent-400 underline hover:text-accent-500"
                >
                  {formatAccountMessage({ id: 'account.login.here', defaultMessage: 'Login here' })}
                </ReferenceLink>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
