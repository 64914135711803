import { useMemo } from 'react';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

export interface MediaYoutubeProps {
  youtubeId: string;
  width: string;
  alignment: string;
  caption?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

const MediaYoutube: React.FC<MediaYoutubeProps> = ({
  youtubeId,
  width,
  alignment,
  caption,
  background,
  innerSpacing,
  outerSpacing,
}) => {
  const containerClass = useMemo(() => {
    if (alignment === 'bl') {
      return 'd-flex justify-content-start w-100';
    }
    if (alignment === 'bc') {
      return 'd-flex justify-content-center w-100';
    }
    if (alignment === 'br') {
      return 'd-flex justify-content-end w-100';
    }
    let cssClass = '';
    if (alignment === 'fl') {
      cssClass = 'float-start me-5';
    }
    if (alignment === 'fr') {
      cssClass = 'float-end ms-5';
    }
    if (width === 'w25') {
      cssClass += ' w-25';
    }
    if (width === 'w50') {
      cssClass += ' w-50';
    }
    if (width === 'w75') {
      cssClass += ' w-75';
    }
    return cssClass;
  }, [width, alignment]);

  const figureClass = useMemo(() => {
    if (alignment === 'fl' || alignment === 'fr') {
      return 'w-100';
    }
    if (width === 'w25') {
      return 'w-25';
    }
    if (width === 'w50') {
      return 'w-50';
    }
    if (width === 'w75') {
      return 'w-75';
    }
    return 'w-100';
  }, [width, alignment]);

  if (!youtubeId) {
    return null;
  }

  return (
    <div className={`${containerClass} ${background} ${innerSpacing} ${outerSpacing}`}>
      <figure className={`mb-0 ${figureClass}`}>
        <div className="rounded overflow-hidden">
          <LiteYouTubeEmbed
            id={youtubeId} // Default none, id of the video or playlist
            adNetwork={false} // Default true, to preconnect or not to doubleclick addresses called by YouTube iframe (the adnetwork from Google)
            // params="" // any params you want to pass to the URL, assume we already had '&' and pass your parameters string
            playlist={false} // Use true when your ID be from a playlist
            // playlistCoverId="L2vS_050c-M" // The ids for playlists did not bring the cover in a pattern to render so you'll need pick up a video from the playlist (or in fact, whatever id) and use to render the cover. There's a programmatic way to get the cover from YouTube API v3 but the aim of this component is do not make any another call and reduce requests and bandwidth usage as much as possibe
            poster="hqdefault" // Defines the image size to call on first render as poster image. Possible values are "default","mqdefault",  "hqdefault", "sddefault" and "maxresdefault". Default value for this prop is "hqdefault". Please be aware that "sddefault" and "maxresdefault", high resolution images are not always avaialble for every video. See: https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
            title={caption ?? 'YouTube Embed'} // a11y, always provide a title for iFrames: https://dequeuniversity.com/tips/provide-iframe-titles Help the web be accessible ;)
            noCookie={true} //Default false, connect to YouTube via the Privacy-Enhanced Mode using https://www.youtube-nocookie.com
          />
        </div>
        {caption && <figcaption className="d-block pt-20">{caption}</figcaption>}
      </figure>
    </div>
  );
};

export default MediaYoutube;
