import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-flash ${className}`}
    viewBox="0 0 12 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m4.9 13.8 4.4-5.3H5.9l.6-4.9-4 5.7h3l-.6 4.5zM3.5 11H.9c-.3 0-.6-.2-.8-.5-.2-.2-.1-.5.1-.8L6.6.5c.1-.2.3-.3.6-.4.2-.1.4-.1.6 0 .2.1.4.2.5.4.2.2.2.5.2.7l-.7 5.6h3.3c.4 0 .6.2.8.5.2.3.1.6-.1.9l-7 8.4c-.2.2-.3.3-.6.4-.2.1-.4 0-.7-.1-.2-.1-.4-.3-.5-.5-.1-.2-.2-.4-.1-.7l.6-4.7z" />
  </svg>
);

export default Icon;
