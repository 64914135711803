import React from 'react';

export interface SpacerProps {
  innerSpacing?: string;
}

const Spacer: React.FC<SpacerProps> = ({ innerSpacing }) => {
  return (
    <div className={innerSpacing}>
      <div className="bg-grey-20 h-px-1"></div>
    </div>
  );
};

export default Spacer;
