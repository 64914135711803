import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { LineItem } from '@Types/cart/LineItem';
import {
  Product,
  ProductAssociationType,
  ProductAssociationValue,
  ProductAssociationValueItem,
} from '@Types/product/Product';
import { ProductAttributeGroup } from '@Types/product/ProductAttributes';
import { Variant } from '@Types/product/Variant';
import ProductDetails, { UIProduct, UIDetail } from 'components/snaq-ui/products/product-details';
import { useCart, useWishlist, useComparison, useCategory } from 'frontastic';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import { buildPagesListByCategory, PageNodeTeaser } from '../../categories/category-experts';

export const gtmPushItemEvent = (gtmEventKey: string, product: Product | LineItem, variant: Variant, quantity = 1) => {
  const item = {
    item_name: product.name,
    item_id: variant.sku,
    quantity: quantity.toString(),
  };
  if ('brand' in product) {
    item['item_brand'] = product.brand.name;
  }
  if (variant.discountedPrice) {
    item['price'] = (variant.discountedPrice.centAmount / 100).toFixed(2);
  } else if (variant.price) {
    item['price'] = (variant.price.centAmount / 100).toFixed(2);
  }
  if ('categories' in product && product.categories.length > 0) {
    product.categories
      .sort((categoryA, categoryB) => categoryA.depth - categoryB.depth)
      .forEach((category, index) => {
        item[`item_category${index > 0 ? index + 1 : ''}`] = category.name;
      });
  }
  if (process.env.NODE_ENV === 'production' && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: gtmEventKey,
      ecommerce: {
        items: [item],
      },
    });
  } else {
    console.info('GtmEvent ' + gtmEventKey + ' item', item);
  }
};

function ProductDetailsTastic({ data }) {
  const router = useRouter();
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { expertsFolder, uspsTitle, usps } = data;
  const {
    product,
    productAttributeGroups,
    productAssociations,
    associatedProducts,
  }: {
    product: Product;
    productAttributeGroups: ProductAttributeGroup[];
    productAssociations: [ProductAssociationType, ProductAssociationValue][];
    associatedProducts?: Product[];
  } = data?.data?.dataSource;

  const currentVariantIdx = useMemo<number>(() => {
    const currentVariantSKU = router.asPath.match(/^.+(_|-)(\d{7})\??.*$/)[2];
    return product?.variants.findIndex(({ sku }) => sku === currentVariantSKU) ?? 0;
  }, [product, router.asPath]);

  const variant = useMemo<Variant>(() => product?.variants[currentVariantIdx], [product, currentVariantIdx]);

  const { data: cartList, addItem } = useCart();
  const { data: wishlist, addToWishlist, removeLineItem } = useWishlist();
  const { data: comparisonSkus, getComparisonSkus, addComparisonSku, removeComparisonSku } = useComparison();
  const { data: lastCategoryAncestors } = useCategory();

  const breadcrumbCategory = useMemo(() => {
    const lastCategory =
      lastCategoryAncestors?.items && lastCategoryAncestors.items.length > 0 ? lastCategoryAncestors.items[0] : null;

    const productInLastCategory = product?.categories.some(
      (category) => category.categoryId === lastCategory?.categoryId,
    );

    if (productInLastCategory) {
      return lastCategory;
    }

    return product?.categories && product.categories.length > 0 ? product.categories[0] : null;
  }, [product, lastCategoryAncestors]);

  // this maps the entire payload to a component
  // friendly datastructure, so data and presentation
  // stay decoupled.
  // TODO: properly type

  const prod = useMemo<UIProduct>(() => {
    const detailItems: UIDetail[] = [];
    variant?.attributeLabels.forEach((attributeLabel) => {
      const attributeKey = attributeLabel.key;
      const label = attributeLabel.label ?? null;
      let displayValue = null;
      try {
        const valueObj = JSON.parse(variant.attributes[attributeKey]);
        const hasAmount = !!valueObj.amount;
        const hasUnit = !!valueObj.unit;
        if (hasAmount && hasUnit) {
          const unitString = String(valueObj.unit);
          displayValue = `${parseInt(valueObj.amount)} ${
            unitString.charAt(0).toUpperCase() + unitString.slice(1).toLowerCase().replaceAll('_', ' ')
          }`;
        } else {
          displayValue = variant.attributes[attributeKey];
        }
      } catch (e) {
        displayValue = variant.attributes[attributeKey];
      }
      if (!label || displayValue === null) return;
      productAttributeGroups.forEach((group) => {
        const attributeItem = group.attributes.find((attribute) => attribute.key === attributeKey);
        if (attributeItem) {
          const detailGroupIndex = detailItems.findIndex((detail) => detail.name === group.name);
          let detailGroup: UIDetail;
          if (detailGroupIndex > -1) {
            detailGroup = detailItems.splice(detailGroupIndex, 1).shift();
          } else {
            detailGroup = {
              key: group.key,
              name: group.name,
              items: [],
            };
          }
          let val: string | null = null;
          if (typeof displayValue === 'string') {
            val = displayValue;
          } else if (typeof displayValue === 'number') {
            val = displayValue.toLocaleString();
          } else if (typeof displayValue === 'boolean') {
            val = displayValue
              ? formatCommonMessage({
                  id: 'yes',
                  defaultMessage: 'Yes',
                })
              : formatCommonMessage({
                  id: 'no',
                  defaultMessage: 'No',
                });
          } else if (typeof displayValue === 'object') {
            if (Array.isArray(displayValue) && displayValue.length > 0) {
              val = displayValue
                .filter((v) => !!v.label)
                .map((v) => v.label)
                .join(', ');
            } else {
              val = displayValue.label ?? null;
            }
          }
          if (val !== null) {
            detailGroup.items.push({
              key: attributeKey,
              label: label,
              value: val,
            });
          }
          detailItems.push(detailGroup);
        }
      });
    });

    let grossPrice = null;
    if (product?.vatRate) {
      if (variant?.discountedPrice) {
        grossPrice = {
          ...variant.discountedPrice,
          centAmount: variant.discountedPrice.centAmount * (1 + product.vatRate),
        };
      } else if (variant?.price) {
        grossPrice = {
          ...variant.price,
          centAmount: variant.price.centAmount * (1 + product.vatRate),
        };
      }
    }

    return {
      productId: product?.productId,
      name: product?.name,
      _url: product?._url,
      // add variants as well, so we can select and filter
      variants: product?.variants,
      price: variant?.price,
      discountedPrice: variant?.discountedPrice,
      uvpPrice: variant?.uvpPrice,
      grossPrice: grossPrice ?? undefined,
      // rating: 4,
      images: variant?.images?.map((img: string, id: number) => ({
        id: `${variant?.sku}-${id}`,
        src: img,
        alt: variant?.sku,
      })),
      isOnWishlist: !!wishlist?.lineItems?.find((lineItem) =>
        product?.variants.find((variant) => variant.sku === lineItem.variant.sku),
      ),
      description: product?.description || '',
      details: detailItems,
      brand: product?.brand,
      isNew: product?.isNew,
    };
  }, [product, variant, productAttributeGroups, wishlist, formatCommonMessage]);

  const mapAssociatedProducts = useCallback(
    (productAssociationKey: string) => {
      const prods = [];
      if (
        !productAssociationKey ||
        productAssociationKey.length === 0 ||
        !associatedProducts ||
        associatedProducts.length === 0
      ) {
        return prods;
      }
      const productAssociationTarget = productAssociations.find(
        (productAssociation) => productAssociation[0].value === productAssociationKey,
      );

      let associatedProductIds: string[] = [];
      if (productAssociationTarget && productAssociationTarget[1].value) {
        associatedProductIds = productAssociationTarget[1].value.map((val: ProductAssociationValueItem) => val.id);
      }

      associatedProducts
        .filter((product: Product) => associatedProductIds.includes(product.id))
        .forEach((product: Product) => {
          const variant = product.variants[0];
          if (!variant) {
            return;
          }
          const prod = {
            productId: product?.productId,
            name: product?.name,
            _url: product?._url,
            price: variant.price,
            discountedPrice: variant.discountedPrice,
            uvpPrice: variant.uvpPrice,
            // rating: 4,
            images: variant.images?.map((img: string, id: number) => ({
              id: `${variant.sku}-${id}`,
              src: img,
              alt: variant.sku,
            })),
            description: product?.description || '',
            isOnStock: variant.isOnStock,
            isNew: product?.isNew,
            isEcomSaleItem: variant.isEcomSaleItem,
            erpReprocurementTime: variant.erpReprocurementTime,
          };
          prods.push(prod);
        });
      return prods;
    },
    [productAssociations, associatedProducts],
  );

  const associatedVariantsProds = useMemo<UIProduct[]>(() => mapAssociatedProducts('VARIANT'), [mapAssociatedProducts]);

  const associatedCompatibilityProds = useMemo<UIProduct[]>(
    () => mapAssociatedProducts('COMPATIBILITY'),
    [mapAssociatedProducts],
  );

  const expertTeaser = useMemo(() => {
    const items: PageNodeTeaser[] = buildPagesListByCategory(
      expertsFolder?.children,
      router?.locale,
      breadcrumbCategory?.categoryId,
    );

    return items.sort((itemA, itemB) =>
      itemA.date && itemB.date ? new Date(itemB.date).getTime() - new Date(itemA.date).getTime() : 0,
    )[0];
  }, [expertsFolder, breadcrumbCategory]);

  const maxOrderAmount = useMemo(() => {
    let max = 100;
    const maxQty = variant?.attributes['ecom_maximum_order_quantity'];
    if (typeof maxQty === 'number') {
      max = maxQty;
    }
    const availableQuantity = variant?.availableQuantity;
    if (typeof availableQuantity === 'number' && availableQuantity < max && variant?.isEcomSaleItem) {
      max = availableQuantity;
    }
    return max;
  }, [variant]);

  const minOrderAmount = useMemo(() => {
    let min = 1;
    const minQty = variant?.attributes['ecom_minimum_order_quantity'];
    if (typeof minQty === 'number') {
      min = minQty;
    }
    if (min > maxOrderAmount) {
      min = maxOrderAmount;
    }
    return min;
  }, [variant, maxOrderAmount]);

  const maxOrderAmountMargin = useMemo(() => {
    const cartItem = cartList?.lineItems?.find((lineItem: LineItem) => lineItem.variant.sku === variant?.sku);
    const cartItemQty = cartItem?.count || 0;
    return maxOrderAmount - cartItemQty;
  }, [cartList, maxOrderAmount, variant]);

  //For SSR render when going back
  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        router.replace(as, undefined);
        return false;
      }

      return true;
    });

    return () => router.beforePopState(() => true);
  }, []);

  const handleAddToCart = (variant: Variant, orderQty): Promise<void> => {
    return addItem(variant, orderQty).then(() => {
      gtmPushItemEvent('add_to_cart', product, variant, orderQty);
    });
  };

  const handleAddToWishList = useCallback(async () => {
    const lineItem = wishlist?.lineItems?.find((lineItem) =>
      product?.variants.find((variant) => variant.sku === lineItem.variant.sku),
    );
    if (lineItem) {
      await removeLineItem(lineItem.lineItemId);
      gtmPushItemEvent('remove_from_wishlist', product, variant);
    } else if (variant?.sku) {
      await addToWishlist(variant.sku, 1);
      gtmPushItemEvent('add_to_wishlist', product, variant);
    }
  }, [wishlist, addToWishlist, removeLineItem, product, variant]);

  const handleToggleComparison = (sku) => {
    const skus = getComparisonSkus();
    if (skus && skus.includes(sku)) {
      removeComparisonSku(sku);
      gtmPushItemEvent('remove_from_comparison', product, variant);
    } else {
      addComparisonSku(sku);
      gtmPushItemEvent('add_to_comparison', product, variant);
    }
  };

  const handleVariantIdxChange = (idx: number) => {
    const variant = product?.variants[idx];
    if (!variant) {
      return;
    }
    const currentSku = router.asPath.match(/^.+(_|-)(\d{7})\??.*$/)[2];
    const url = `${router.asPath.replace(currentSku, variant.sku)}`;
    router.replace(url, undefined, { shallow: true });
  };

  // GTM event tracking
  const [gtmEventPushed, setGtmEventPushed] = useState<boolean>(false);
  useEffect(() => {
    setGtmEventPushed(false);
  }, [router]);
  useEffect(() => {
    if (gtmEventPushed || !product || !variant) {
      return;
    }
    setGtmEventPushed(true);
    gtmPushItemEvent('view_item', product, variant);
  }, [gtmEventPushed, product, variant]);

  if (!product || !variant || !prod) return <></>;

  return (
    <>
      <Head>
        <title>{!!product.metaTitle ? product.metaTitle : product.name}</title>
        {!!product.metaDescription && <meta name="description" content={product.metaDescription} key="desc" />}
      </Head>
      <ProductDetails
        product={prod}
        associatedVariantsProducts={associatedVariantsProds}
        associatedCompatibilityProducts={associatedCompatibilityProds}
        onAddToCart={handleAddToCart}
        variant={variant}
        onChangeVariantIdx={handleVariantIdxChange}
        onAddToWishlist={handleAddToWishList}
        comparisonSkus={comparisonSkus}
        onToggleComparison={handleToggleComparison}
        quickBuyEnabled={data.quickBuyEnabled}
        minOrderAmount={minOrderAmount}
        maxOrderAmount={maxOrderAmount}
        maxOrderAmountMargin={maxOrderAmountMargin}
        breadcrumbCategory={breadcrumbCategory}
        expertTeaser={expertTeaser}
        uspsTitle={uspsTitle}
        usps={usps}
      />
    </>
  );
}

export default ProductDetailsTastic;
