import React, { useCallback, useEffect, useRef, useState } from 'react';
import NextLink from 'next/link';
import { Account } from '@Types/account/Account';
import { Address as AddressType } from '@Types/account/Address';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { default as IconLogoDHL } from 'components/icons/logo-dhl';
import { default as IconLogoTNT } from 'components/icons/logo-tnt';
import { default as IconLogoUPS } from 'components/icons/logo-ups';
import { default as IconPaymentAmericanExpress } from 'components/icons/payment-american-express';
import { default as IconPaymentApple } from 'components/icons/payment-apple';
import { default as IconPaymentBancontact } from 'components/icons/payment-bancontact';
import { default as IconPaymentEps } from 'components/icons/payment-eps';
import { default as IconPaymentGiropay } from 'components/icons/payment-giropay';
import { default as IconPaymentGooglePay } from 'components/icons/payment-google-pay';
import { default as IconPaymentKlarnaSofort } from 'components/icons/payment-klarna-sofort';
import { default as IconPaymentMastercard } from 'components/icons/payment-mastercard';
import { default as IconPaymentPayPal } from 'components/icons/payment-paypal';
import { default as IconPaymentVisa } from 'components/icons/payment-visa';
import OrderSummary from 'components/snaq-ui/cart/orderSummary';
import Payment from 'components/snaq-ui/multistep-checkout/panels//payment';
import Address from 'components/snaq-ui/multistep-checkout/panels/address';
import Shipping from 'components/snaq-ui/multistep-checkout/panels/shipping';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount, useCart } from 'frontastic';
import { mapToCartStructure, mapToFormStructure } from './mapFormData';
import { requiredDataIsValid } from './requiredDataIsValid';
import { CartDetails } from '../../../frontastic/actions/cart';

export type FormData = {
  email: string;
  shippingSalutation?: string;
  shippingTitle?: string;
  shippingFirstName: string;
  shippingLastName: string;
  shippingCompany?: string;
  shippingVatId?: string;
  shippingDepartment?: string;
  shippingPhone?: string;
  shippingAdditionalAddressInfo?: string;
  shippingStreetName: string;
  shippingStreetNumber: string;
  shippingPostalCode: string;
  shippingCity: string;
  shippingCountry: string;
  billingSalutation?: string;
  billingTitle?: string;
  billingFirstName: string;
  billingLastName: string;
  billingCompany?: string;
  billingVatId?: string;
  billingDepartment?: string;
  billingAdditionalAddressInfo?: string;
  billingStreetName: string;
  billingStreetNumber: string;
  billingPostalCode: string;
  billingCity: string;
  billingCountry: string;
};

const equalsAccountAddress = (addressData, accountAddress) => {
  return (
    addressData.salutation === (accountAddress.salutation ?? '') &&
    addressData.title === (accountAddress.title ?? '') &&
    addressData.firstName === (accountAddress.firstName ?? '') &&
    addressData.lastName === (accountAddress.lastName ?? '') &&
    addressData.company === (accountAddress.company ?? '') &&
    addressData.department === (accountAddress.department ?? '') &&
    addressData.additionalAddressInfo === (accountAddress.additionalAddressInfo ?? '') &&
    addressData.streetName === (accountAddress.streetName ?? '') &&
    addressData.streetNumber === (accountAddress.streetNumber ?? '') &&
    addressData.postalCode === (accountAddress.postalCode ?? '') &&
    addressData.city === (accountAddress.city ?? '') &&
    addressData.country === (accountAddress.country ?? '')
  );
};

const MultistepCheckout = ({ termsLink, privacyLink, paypalCredentials, stripeCredentials }) => {
  const { data: cartList, updateCart, setShippingMethod, setPartialDelivery } = useCart();
  const { formatMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { loggedIn, account, addAddress, updateAddressTypeAssociation } = useAccount();
  const prevAccount = useRef<Account | null>(null);

  const containerRef = useRef();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);
  const [billingIsSameAsShipping, setBillingIsSameAsShipping] = useState<boolean>(true);
  const [currentShippingMethod, setCurrentShippingMethod] = useState<ShippingMethod | null>(null);
  const [cartItemsCount, setCartItemsCount] = useState<number>(0);
  const [currentPartialDelivery, setCurrentPartialDelivery] = useState<boolean>(false);
  const shippingMethodInitialized = useRef<boolean>(false);
  const [dataIsValid, setDataIsValid] = useState<boolean>(false);
  const [data, setData] = useState<FormData>({
    email: '',
    shippingSalutation: '',
    shippingTitle: '',
    shippingFirstName: '',
    shippingLastName: '',
    shippingCompany: '',
    shippingVatId: '',
    shippingDepartment: '',
    shippingAdditionalAddressInfo: '',
    shippingStreetName: '',
    shippingStreetNumber: '',
    shippingPostalCode: '',
    shippingCity: '',
    shippingCountry: '',
    billingSalutation: '',
    billingTitle: '',
    billingFirstName: '',
    billingLastName: '',
    billingCompany: '',
    billingVatId: '',
    billingDepartment: '',
    billingAdditionalAddressInfo: '',
    billingStreetName: '',
    billingStreetNumber: '',
    billingPostalCode: '',
    billingCity: '',
    billingCountry: '',
  });
  const [dataInitialized, setDataInitialized] = useState<boolean>(false);
  const [selectedShippingAddressId, setSelectedShippingAddressId] = useState<string>(
    account?.addresses.find((address) => address.isDefaultShippingAddress)?.addressId ?? '',
  );
  const [selectedBillingAddressId, setSelectedBillingAddressId] = useState<string>(
    account?.addresses.find((address) => address.isDefaultBillingAddress)?.addressId ?? '',
  );

  useEffect(() => {
    if (dataInitialized || !account) {
      return;
    }
    if (account.addresses.length === 0) {
      setData((prevData) => ({
        ...prevData,
        email: account.email || '',
        shippingSalutation: account.salutation || '',
        shippingTitle: account.title || '',
        shippingFirstName: account.firstName || '',
        shippingLastName: account.lastName || '',
        shippingCompany: account.companyName || '',
      }));
    }
    setDataInitialized(true);
  }, [account, dataInitialized]);

  const changeStep = (stepIndex: number) => {
    if (currentStepIndex > stepIndex) {
      setCurrentStepIndex(stepIndex);
    }
  };

  const toggleBillingAddressOption = () => {
    setBillingIsSameAsShipping(!billingIsSameAsShipping);
  };

  const generateStepTag = (index: number) => (
    <span className={`form-steps__number ${index == currentStepIndex ? `bg-primary` : 'bg-grey-10'}`}>
      <span className={` ${index == currentStepIndex ? `text-white` : 'body-color'}`}>{index + 1}</span>
    </span>
  );

  const addNewAddress = useCallback(
    async (newAddressData: AddressType) => {
      if (!account) {
        // Anonymous user
        return;
      }
      let prevAccountAddresses = [];
      if (prevAccount?.current?.addresses) {
        prevAccountAddresses = [...prevAccount.current.addresses];
      } else if (account.addresses) {
        prevAccountAddresses = [...account.addresses];
      }
      const prevAccountAddressIds = prevAccountAddresses.map((address) => address.addressId);
      try {
        const updatedAccount = await addAddress(newAddressData);
        prevAccount.current = updatedAccount;
        const updatedAddresses = updatedAccount.addresses;
        return updatedAddresses.find((addressItem) => !prevAccountAddressIds.includes(addressItem.addressId));
      } catch (e) {
        console.error(e);
      }
    },
    [account, addAddress],
  );

  const updateCartData = useCallback(
    async (data: FormData, addNewAddresses = false) => {
      if (!requiredDataIsValid(data, billingIsSameAsShipping, !account)) {
        return;
      }
      const updatedData: CartDetails = mapToCartStructure(
        data,
        billingIsSameAsShipping,
        selectedShippingAddressId,
        selectedBillingAddressId,
        !account,
      );
      setIsLoading(true);
      if (addNewAddresses && updatedData.shipping) {
        if (updatedData.shipping.addressId === '') {
          const newAddressData = {
            ...updatedData.shipping,
            addressId: undefined,
            isShippingAddress: true,
            isBillingAddress: billingIsSameAsShipping,
          };
          try {
            const newAddress = await addNewAddress(newAddressData);
            if (!!newAddress?.addressId) {
              updatedData.shipping.addressId = newAddress.addressId;
              if (updatedData.billing.addressId === '' && billingIsSameAsShipping) {
                updatedData.billing.addressId = newAddress.addressId;
              }
            }
          } catch (e) {
            console.error(e);
          }
        } else if (updatedData.shipping.addressId.length > 0) {
          const updateAddressData = {
            addressId: updatedData.shipping.addressId,
            isShippingAddress: true,
          };
          try {
            await updateAddressTypeAssociation(updateAddressData);
          } catch (e) {
            console.error(e);
          }
        }
      }
      if (addNewAddresses && updatedData.billing) {
        if (updatedData.billing.addressId === '' && !billingIsSameAsShipping) {
          const newAddressData = {
            ...updatedData.billing,
            addressId: undefined,
            isBillingAddress: true,
          };
          try {
            const newAddress = await addNewAddress(newAddressData);
            if (!!newAddress?.addressId) {
              updatedData.billing.addressId = newAddress.addressId;
            }
          } catch (e) {
            console.error(e);
          }
        } else if (updatedData.billing.addressId.length > 0) {
          const updateAddressData = {
            addressId: updatedData.billing.addressId,
            isBillingAddress: true,
          };
          try {
            await updateAddressTypeAssociation(updateAddressData);
          } catch (e) {
            console.error(e);
          }
        }
      }
      try {
        await updateCart(updatedData);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [
      account,
      billingIsSameAsShipping,
      selectedShippingAddressId,
      selectedBillingAddressId,
      addNewAddress,
      updateAddressTypeAssociation,
      updateCart,
    ],
  );

  const gotToNextStep = useCallback(async () => {
    if (currentStepIndex === 0) {
      await updateCartData(data, true);
    }

    setCurrentStepIndex(currentStepIndex + 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentStepIndex, updateCartData, data]);

  const checkSelectedShippingAddressIdReset = useCallback(
    (data) => {
      const addressData = {
        salutation: data.shippingSalutation,
        title: data.shippingTitle,
        firstName: data.shippingFirstName,
        lastName: data.shippingLastName,
        company: data.shippingCompany,
        department: data.shippingDepartment,
        additionalAddressInfo: data.shippingAdditionalAddressInfo,
        streetName: data.shippingStreetName,
        streetNumber: data.shippingStreetNumber,
        postalCode: data.shippingPostalCode,
        city: data.shippingCity,
        country: data.shippingCountry,
      };
      let existingAddressId = '';
      account?.addresses.forEach((accountAddress) => {
        if (equalsAccountAddress(addressData, accountAddress)) {
          existingAddressId = accountAddress.addressId;
        }
      });
      setSelectedShippingAddressId(existingAddressId);
    },
    [account],
  );

  const checkSelectedBillingAddressIdReset = useCallback(
    (data) => {
      const addressData = {
        salutation: data.billingSalutation,
        title: data.billingTitle,
        firstName: data.billingFirstName,
        lastName: data.billingLastName,
        company: data.billingCompany,
        department: data.billingDepartment,
        additionalAddressInfo: data.billingAdditionalAddressInfo,
        streetName: data.billingStreetName,
        streetNumber: data.billingStreetNumber,
        postalCode: data.billingPostalCode,
        city: data.billingCity,
        country: data.billingCountry,
      };
      let existingAddressId = '';
      account?.addresses.forEach((accountAddress) => {
        if (equalsAccountAddress(addressData, accountAddress)) {
          existingAddressId = accountAddress.addressId;
        }
      });
      setSelectedBillingAddressId(existingAddressId);
    },
    [account],
  );

  const updateData = useCallback(
    (data: FormData) => {
      checkSelectedShippingAddressIdReset(data);
      checkSelectedBillingAddressIdReset(data);
      setData((prevData) => {
        // Unsure, why cart update needed in this case???
        if (prevData.shippingCountry !== data.shippingCountry || prevData.billingCountry !== data.billingCountry) {
          updateCartData(data).catch((e) => console.error(e));
        }
        return data;
      });
    },
    [checkSelectedShippingAddressIdReset, checkSelectedBillingAddressIdReset, updateCartData],
  );

  const updatecurrentShippingMethod = useCallback(
    (shippingMethod: ShippingMethod) => {
      if (shippingMethod?.shippingMethodId) {
        setCurrentShippingMethod(shippingMethod);
        setShippingMethod(shippingMethod.shippingMethodId);
      }
    },
    [setShippingMethod],
  );

  const updateCurrentPartialDelivery = useCallback(
    (partialDelivery: boolean) => {
      setCurrentPartialDelivery(partialDelivery);
      setPartialDelivery(partialDelivery);
    },
    [setPartialDelivery],
  );

  const submitButtonLabel = [
    formatMessage({ id: 'goToShipping', defaultMessage: 'Go to shipping' }),
    formatMessage({ id: 'ContinueAndPay', defaultMessage: 'Continue and pay' }),
  ];

  const setShippingAddress = useCallback(() => {
    const shippingAddress = account?.addresses.find((address) => address.addressId === selectedShippingAddressId);
    setData((prevState) => ({
      ...prevState,
      email: account.email,
      shippingSalutation: shippingAddress.salutation ?? '',
      shippingTitle: shippingAddress.title ?? '',
      shippingFirstName: shippingAddress.firstName ?? '',
      shippingLastName: shippingAddress.lastName ?? '',
      shippingCompany: shippingAddress.company ?? '',
      shippingDepartment: shippingAddress.department ?? '',
      shippingAdditionalAddressInfo: shippingAddress.additionalAddressInfo ?? '',
      shippingStreetName: shippingAddress.streetName ?? '',
      shippingStreetNumber: shippingAddress.streetNumber ?? '',
      shippingPostalCode: shippingAddress.postalCode ?? '',
      shippingCity: shippingAddress.city ?? '',
      shippingCountry: shippingAddress.country ?? '',
    }));
  }, [account, selectedShippingAddressId]);

  const setBillingAddress = useCallback(() => {
    const billingAddress = account?.addresses.find((address) => address.addressId === selectedBillingAddressId);
    setData((prevState) => ({
      ...prevState,
      billingSalutation: billingAddress.salutation ?? '',
      billingTitle: billingAddress.title ?? '',
      billingFirstName: billingAddress.firstName ?? '',
      billingLastName: billingAddress.lastName ?? '',
      billingCompany: billingAddress.company ?? '',
      billingDepartment: billingAddress.department ?? '',
      billingAdditionalAddressInfo: billingAddress.additionalAddressInfo ?? '',
      billingStreetName: billingAddress.streetName ?? '',
      billingStreetNumber: billingAddress.streetNumber ?? '',
      billingPostalCode: billingAddress.postalCode ?? '',
      billingCity: billingAddress.city ?? '',
      billingCountry: billingAddress.country ?? '',
    }));
  }, [account, selectedBillingAddressId]);

  useEffect(() => {
    setDataIsValid(requiredDataIsValid(data, billingIsSameAsShipping, !account));
  }, [data, billingIsSameAsShipping, account]);

  useEffect(() => {
    setDisableSubmitButton(!dataIsValid || (currentStepIndex === 1 && !currentShippingMethod?.shippingMethodId));
  }, [dataIsValid, currentStepIndex, currentShippingMethod]);

  useEffect(() => {
    const defaultData = mapToFormStructure(cartList, !account);
    if (defaultData && requiredDataIsValid(defaultData, billingIsSameAsShipping, !account)) {
      updateData(defaultData);
    }
  }, [cartList, account]);

  useEffect(() => {
    if (!cartList || !cartList?.availableShippingMethods || cartList.availableShippingMethods.length === 0) {
      return;
    }
    const shippingMethod = cartList.availableShippingMethods.find(
      ({ shippingMethodId }) => shippingMethodId === cartList.shippingInfo?.shippingMethodId ?? '',
    );
    if (shippingMethod) {
      setCurrentShippingMethod(shippingMethod);
      return;
    }
    setCurrentShippingMethod(cartList.availableShippingMethods[0]);
  }, [cartList, cartList?.availableShippingMethods, cartList?.shippingInfo]);

  useEffect(() => {
    if (!cartList || !cartList?.lineItems || cartList.lineItems.length === 0) {
      return;
    }
    let count = 0;
    cartList.lineItems.forEach((lineItem) => {
      count += lineItem.count || 1;
    });
    setCartItemsCount(count);
  }, [cartList, cartList?.lineItems]);

  useEffect(() => {
    if (currentStepIndex !== 1 || isLoading || shippingMethodInitialized.current === true || !currentShippingMethod) {
      return;
    }
    shippingMethodInitialized.current = true;
    updatecurrentShippingMethod(currentShippingMethod);
  }, [currentStepIndex, isLoading, updatecurrentShippingMethod, currentShippingMethod]);

  // GTM event tracking
  const [gtmEventPushed, setGtmEventPushed] = useState<number>(-1);
  useEffect(() => {
    if (gtmEventPushed >= currentStepIndex || !cartList) {
      return;
    }
    setGtmEventPushed(currentStepIndex);
    let gtmEvent = 'begin_checkout';
    if (currentStepIndex === 1) {
      gtmEvent = 'add_address_info';
    }
    if (currentStepIndex === 2) {
      gtmEvent = 'add_shipping_info';
    }
    const items = [];
    if (cartList?.lineItems && cartList.lineItems.length > 0) {
      cartList.lineItems.forEach((lineItem) => {
        const item = {
          item_name: lineItem.name,
          item_id: lineItem.variant.sku,
          quantity: lineItem.count,
        };
        if ('brand' in lineItem) {
          item['item_brand'] = lineItem.brand.name;
        }
        if (lineItem.discountedPrice) {
          item['price'] = (lineItem.discountedPrice.centAmount / 100).toFixed(2);
        } else if (lineItem.price) {
          item['price'] = (lineItem.price.centAmount / 100).toFixed(2);
        }
        if ('categories' in lineItem && lineItem.categories.length > 0) {
          lineItem.categories
            .sort((categoryA, categoryB) => categoryA.depth - categoryB.depth)
            .forEach((category, index) => {
              item[`item_category${index > 0 ? index + 1 : ''}`] = category.name;
            });
        }
        items.push(item);
      });
    }
    let shippingTier = null;
    if (gtmEvent === 'add_shipping_info' && currentShippingMethod?.name) {
      shippingTier = currentShippingMethod?.name;
    }
    if (process.env.NODE_ENV === 'production' && window.dataLayer) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: gtmEvent,
        ecommerce: {
          currency: cartList.sum.currencyCode,
          value: (cartList.sum.centAmount / 100).toFixed(2),
          shipping_tier: shippingTier || undefined,
          items: items,
        },
      });
    } else {
      console.info('GtmEvent ' + gtmEvent + ' currency', cartList.sum.currencyCode);
      console.info('GtmEvent ' + gtmEvent + ' value', (cartList.sum.centAmount / 100).toFixed(2));
      if (shippingTier) {
        console.info('GtmEvent ' + gtmEvent + ' shipping_tier', shippingTier);
      }
      console.info('GtmEvent ' + gtmEvent + ' items', items);
    }
  }, [gtmEventPushed, currentStepIndex, cartList, currentShippingMethod]);

  const steps = [
    {
      name: formatMessage({ id: 'address', defaultMessage: 'Address' }),
      component: (
        <Address
          data={data}
          selectedShippingAddressId={selectedShippingAddressId}
          selectedBillingAddressId={selectedBillingAddressId}
          account={account}
          updateData={updateData}
          updateSelectedShippingAddressId={(id: string) => {
            setSelectedShippingAddressId(id);
          }}
          updateSelectedBillingAddressId={(id: string) => {
            setSelectedBillingAddressId(id);
          }}
          billingIsSameAsShipping={billingIsSameAsShipping}
          toggleBillingAddressOption={toggleBillingAddressOption}
          setShippingAddress={setShippingAddress}
          setBillingAddress={setBillingAddress}
        />
      ),
    },
    {
      name: formatCheckoutMessage({ id: 'shipping', defaultMessage: 'Shipping' }),
      component: (
        <Shipping
          shippingMethods={cartList?.availableShippingMethods}
          currentShippingMethod={currentShippingMethod}
          onSelectShippingMethod={updatecurrentShippingMethod}
          cartItemsCount={cartItemsCount}
          currentPartialDelivery={currentPartialDelivery}
          onTogglePartialDelivery={updateCurrentPartialDelivery}
        />
      ),
    },
    {
      name: formatCheckoutMessage({ id: 'payment', defaultMessage: 'Payment' }),
      component: (
        <Payment loggedIn={loggedIn} paypalCredentials={paypalCredentials} stripeCredentials={stripeCredentials} />
      ),
    },
  ];

  return (
    <div className="section-spacing">
      <div className="form-steps mb-50">
        {steps.map(({ name }, index) => (
          <button
            key={index}
            className="btn-reset d-flex align-items-center flex-column"
            onClick={() => changeStep(index)}
          >
            {generateStepTag(index)}
            <span className="d-block mob-p-p3 dt-p-p3 mt-10">{name}</span>
          </button>
        ))}
      </div>
      <div className="row" ref={containerRef}>
        <div className="col-12 col-xl-8 pe-xl-40">{steps[currentStepIndex].component}</div>
        <div className="col-12 col-xl-4 pt-40 pt-xl-0">
          <OrderSummary
            cart={cartList}
            submitButtonLabel={submitButtonLabel[currentStepIndex]}
            disableSubmitButton={isLoading || disableSubmitButton}
            showAddressSummary={currentStepIndex > 1}
            showShippingSummary={currentStepIndex > 1}
            showDiscountsForm={currentStepIndex < 1}
            showDisclaimer={currentStepIndex > 1}
            showSubmitButton={currentStepIndex < 2}
            onSubmit={gotToNextStep}
            changeStep={changeStep}
            termsLink={termsLink}
            privacyLink={privacyLink}
          />
          {currentStepIndex === 0 && (
            <div className="pt-40">
              <p className="mob-p-p4 dt-p-p4 mb-15">Wir verschicken mit:</p>
              <div className="d-flex align-items-center pb-15">
                {IconLogoDHL({ className: 'h-px-20 me-15' })}
                {IconLogoTNT({ className: 'h-px-20 me-15' })}
                {IconLogoUPS({ className: 'h-px-30 me-15' })}
              </div>
              <p className="mob-p-p4 dt-p-p4">
                <b>Kostenloser Versand ab 500 € netto.</b>
                <br />
                Weitere Informationen zu{' '}
                <NextLink href={'/versandinformationen'}>
                  <a>Versandkosten</a>
                </NextLink>
              </p>
            </div>
          )}
          {currentStepIndex === 1 && (
            <div className="pt-40">
              <p className="mob-p-p4 dt-p-p4 mb-15">Wir akzeptieren folgende Zahlungsmethoden</p>
              <div className="d-flex flex-wrap">
                {IconPaymentApple({ className: 'h-px-25 mb-15 me-15' })}
                {IconPaymentGooglePay({ className: 'h-px-25 mb-15 me-15' })}
                {IconPaymentPayPal({ className: 'h-px-25 mb-15 me-15' })}
                {IconPaymentVisa({ className: 'h-px-25 mb-15 me-15' })}
                {IconPaymentMastercard({ className: 'h-px-25 mb-15 me-15' })}
                {IconPaymentAmericanExpress({ className: 'h-px-25 mb-15 me-15' })}
                {IconPaymentGiropay({ className: 'h-px-25 mb-15 me-15' })}
                {IconPaymentEps({ className: 'h-px-25 mb-15 me-15' })}
                {IconPaymentKlarnaSofort({ className: 'h-px-25 mb-15 me-15' })}
                {IconPaymentBancontact({ className: 'h-px-25 mb-15 me-15' })}
              </div>
              <p className="mob-p-p4 dt-p-p4">
                <small>*Verfügbarkeit kann nach Region variieren</small>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultistepCheckout;
