import React, { useState, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import { default as IconPlayCircle } from 'components/icons/play-circle';
import Typography from 'components/snaq-ui/typography';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { Reference, ReferenceLink } from 'helpers/reference';
import { md } from 'helpers/utils/screensizes';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import { useFormat } from '../../../../helpers/hooks/useFormat';

export interface MediaTextSlideProps {
  youtubeId?: string;
  media: MediaItemWithMods;
  mediaMobile?: MediaItemWithMods;
  overline?: string;
  title?: string;
  description?: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  ctaTitle?: string;
  ctaRel?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
  contentBackground?: string;
  contentPosition?: string;
}

const Slide: React.FC<MediaTextSlideProps> = ({
  youtubeId,
  media,
  mediaMobile,
  overline,
  title,
  description,
  ctaLabel,
  ctaReference,
  ctaTitle,
  ctaRel,
  contentBackground,
  contentPosition,
}) => {
  const [isTabletSize] = useMediaQuery(md);

  const hasContent = useMemo(() => {
    return 0 < overline.length || 0 < title.length || 0 < description.length || 0 < ctaLabel.length;
  }, [overline, title, description, ctaLabel]);

  const contentClass = useMemo(() => {
    let cssClass = '';
    if (contentPosition === 'tl') cssClass += 'justify-content-start';
    if (contentPosition === 'tr') cssClass += 'justify-content-end';
    if (contentPosition === 'br') cssClass += 'justify-content-end align-items-end';
    if (contentPosition === 'bl') cssClass += 'align-items-end';
    if (contentBackground === 'blurry') cssClass += ' p-20 p-md-40';
    return cssClass;
  }, [contentPosition]);

  const contentInnerClass = useMemo(() => {
    if (!hasContent) return 'd-none';
    if (contentBackground === 'none') return 'bg-none';
    if (contentBackground === 'blurry') return 'bg-blurry rounded';
  }, [contentBackground]);

  const mediaType = useMemo(() => {
    return mediaMobile?.media && !isTabletSize ? mediaMobile.media.resourceType : media.media.resourceType;
  }, [mediaMobile, isTabletSize, media]);

  const contentMedia = (() => {
    if ('image' === mediaType) {
      return (
        <Image
          media={mediaMobile?.media && !isTabletSize ? mediaMobile : media}
          alt={title}
          layout="fill"
          className={'object-fit-cover'}
        />
      );
    }

    if ('video' === mediaType) {
      return (
        <video preload="metadata" controls={false}>
          {mediaMobile?.media && !isTabletSize ? (
            <source src={mediaMobile.media.file} type={`video/${mediaMobile.media.format}`} />
          ) : (
            <source src={media.media.file} type={`video/${media.media.format}`} />
          )}
        </video>
      );
    }
    return <></>;
  })();

  // video modal
  const [modalShow, modalSetShow] = useState(false);
  const modalHandleClose = () => modalSetShow(false);
  const modalHandleShow = () => modalSetShow(true);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const hasModal = 'video' === mediaType || youtubeId;
  const contentModal = (() => {
    if (youtubeId) {
      return (
        <LiteYouTubeEmbed
          id={youtubeId}
          adNetwork={false}
          playlist={false}
          poster="hqdefault"
          title={title ?? 'YouTube Embed'}
          noCookie={true}
        />
      );
    }

    return (
      <video preload="metadata" controls={true} controlsList="nodownload" autoPlay={modalShow}>
        {mediaMobile?.media && !isTabletSize ? (
          <source src={mediaMobile.media.file} type={`video/${mediaMobile.media.format}`} />
        ) : (
          <source src={media.media.file} type={`video/${media.media.format}`} />
        )}
      </video>
    );
  })();

  const { formatMessage } = useFormat({ name: 'common' });

  return (
    <div className={`w-100 position-relative overflow-hidden`}>
      <div className={`media-text-slide__media position-relative overflow-hidden ${isTabletSize ? 'rounded' : null}`}>
        {contentMedia}
      </div>
      <div className={`position-absolute h-100 w-100 start-0 top-0 d-flex ${contentClass}`}>
        <div className={`col-12 col-md-8 col-lg-7 col-xl-6`}>
          <div className={`media-text-slide__content-inner text-white p-20 my-20 my-md-0 p-md-40 ${contentInnerClass}`}>
            {overline && (
              <div className={`mob-h-h6 dt-h-h6 text-uppercase`}>
                <Typography>{overline}</Typography>
              </div>
            )}
            {title && (
              <div className={`mob-h-h2 dt-h-h2 ${overline ? 'pt-10' : null}`}>
                <Typography>{title}</Typography>
              </div>
            )}
            {description && (
              <div className={`mob-p-p3 dt-p-p3 pt-6`}>
                <Typography>{description}</Typography>
              </div>
            )}
            {hasModal && (
              <div className={`pt-20`}>
                <button className={'btn btn-sm btn-xl-md btn-primary'} onClick={modalHandleShow}>
                  {IconPlayCircle({})}
                  {formatMessage({
                    id: 'playVideo',
                    defaultMessage: 'Play video',
                  })}
                </button>
                <Modal show={modalShow} onHide={modalHandleClose} centered className={'modal-media'}>
                  <Modal.Header closeButton className={'p-0 border-0'}></Modal.Header>
                  <Modal.Body>{contentModal}</Modal.Body>
                </Modal>
              </div>
            )}
            {ctaLabel && ctaReference && (
              <div className={`pt-20`}>
                <ReferenceLink
                  target={ctaReference}
                  title={ctaTitle}
                  rel={ctaRel}
                  className={`btn btn-sm btn-xl-md btn-primary`}
                >
                  <Typography>{ctaLabel}</Typography>
                </ReferenceLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slide;
