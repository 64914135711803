import ProductSlider from 'components/snaq-ui/products/slider';

function ProductSelectionTastic({ data }) {
  if (!data?.data?.dataSource?.items || data.data.dataSource.items.length === 0) {
    return null;
  }

  return (
    <ProductSlider
      products={data.data.dataSource.items}
      title={data.title}
      teaserImage={data.teaserImage}
      teaserImageMobile={data.teaserImageMobile}
      teaserOverline={data.teaserOverline}
      teaserTitle={data.teaserTitle}
      teaserDescription={data.teaserDescription}
      teaserButtonLabel={data.teaserButtonLabel}
      teaserButtonReference={data.teaserButtonReference}
      teaserButtonTitle={data.teaserButtonTitle}
      teaserButtonRel={data.teaserButtonRel}
      ctaLabel={data.ctaLabel}
      ctaReference={data.ctaReference}
      ctaTitle={data.ctaTitle}
      ctaRel={data.ctaRel}
      splitContent={data.splitContent}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
}

export default ProductSelectionTastic;
