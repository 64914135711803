import React, { useMemo, useState } from 'react';
import { Reference, ReferenceLink } from 'helpers/reference';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import Typography from '../../typography';
export interface TextAndMediaProps {
  orientation?: string;
  background?: string;
  backgroundWidth?: string;
  innerSpacing?: string;
  outerSpacing?: string;
  titleMedia?: MediaItemWithMods;
  title?: string;
  text?: string;
  ctaColor?: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  ctaTitle?: string;
  ctaRel?: string;
  mediaHeight?: string;
  media?: MediaItemWithMods;
}

const TextAndMedia: React.FC<TextAndMediaProps> = ({
  orientation,
  background,
  backgroundWidth,
  innerSpacing,
  outerSpacing,
  titleMedia,
  title,
  text,
  ctaColor,
  ctaLabel,
  ctaReference,
  ctaTitle,
  ctaRel,
  mediaHeight,
  media,
}) => {
  const ctaCSS = useMemo(() => {
    let cssClass = 'btn btn-sm btn-xl-md';
    if (ctaColor === 'red') cssClass += ' btn-primary';
    if (ctaColor === 'black') cssClass += ' btn-secondary';
    if (ctaColor === 'grey') cssClass += ' btn-grey';
    return cssClass;
  }, [ctaColor]);

  const bgFullWidth = backgroundWidth === 'full';
  const backgroundColor = bgFullWidth ? `${background}-full` : background;
  const extraPadding = bgFullWidth ? '' : 'px-md-40 px-xl-50';

  const mobileBgCSS = useMemo(() => {
    let cssClass = 'pe-none d-md-none position-absolute top-0 start-0 w-100 h-100';
    if (background === 'bg-white') cssClass += ' bg-white-full';
    if (background === 'bg-black') cssClass += ' bg-black-full';
    if (background === 'bg-grey') cssClass += ' bg-grey-full';
    return cssClass;
  }, [background]);

  const contentMedia = (() => {
    const mediaType = media?.media?.resourceType;

    if ('image' === mediaType) {
      return <Image media={media} alt={title} className={'d-block rounded'} />;
    }

    if ('video' === mediaType) {
      return (
        <video preload="metadata" controls={false} className={'d-block rounded'}>
          <source src={media?.media?.file} type={`video/${media?.media?.format}`} />
        </video>
      );
    }
    return <></>;
  })();

  return (
    <div className={`position-relative ${backgroundColor} ${innerSpacing} ${outerSpacing} ${extraPadding}`}>
      <div
        className={`row justify-content-between align-items-center ${
          orientation === 'img-right' ? 'flex-row-reverse' : ''
        }`}
      >
        <div className="col-12 col-md-5 col-xl-6 pb-20 pb-md-0">{contentMedia}</div>
        <div className={`col-12 col-md-6 col-xl-5`}>
          {titleMedia && (
            <div className="pb-20">
              <Image media={titleMedia} layout="intrinsic" />
            </div>
          )}
          <h2 className={`${background === 'bg-black' ? 'text-grey-40' : ''}`}>{title}</h2>
          <p className={`mb-0 ${background === 'bg-black' ? 'text-grey-80' : ''}`}>{text}</p>
          {ctaLabel && ctaReference && (
            <div className={`pt-20`}>
              <ReferenceLink target={ctaReference} title={ctaTitle} rel={ctaRel} className={ctaCSS}>
                <Typography>{ctaLabel}</Typography>
              </ReferenceLink>
            </div>
          )}
        </div>
      </div>
      <div className={mobileBgCSS}></div>
    </div>
  );
};

export default TextAndMedia;
