import React from 'react';
import MediaImage from 'components/snaq-ui/content/media-image';

const MediaImageTastic = ({ data }) => {
  return (
    <MediaImage
      image={data.image}
      imageMobile={data.imageMobile}
      width={data.width}
      alignment={data.alignment}
      title={data.title}
      caption={data.caption}
      ctaReference={data.ctaReference}
      ctaTitle={data.ctaTitle}
      ctaRel={data.ctaRel}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
};

export default MediaImageTastic;
