import CategoryTitle from '../../../../../components/snaq-ui/categories/category-title';

function CategoryTitleTastic({ data }) {
  if (!data?.data?.dataSource) return <></>;

  const { items } = data.data.dataSource;

  const category = items && items.length > 0 ? items[0] : null;

  if (!category) {
    return null;
  }

  return <CategoryTitle category={category} background={data.background} />;
}

export default CategoryTitleTastic;
