import React, { useMemo } from 'react';

export type TableProps = {
  headline?: string;
  entries?: Array<TableEntryProps>;
};

export type TableEntryProps = {
  title?: string;
  description?: string;
};

export default function Table({ tasticId, items, background, backgroundWidth, innerSpacing, outerSpacing }) {
  const bgFullWidth = backgroundWidth === 'full';
  const backgroundColor = bgFullWidth ? `${background}-full` : background;
  const extraPadding = bgFullWidth ? '' : 'px-md-40 px-xl-50';

  const tableStyle = (element) => {
    let caption = 'text-body border-black';
    let th = 'text-body bg-white';
    let td = 'text-grey-80 bg-white';

    if (background === 'bg-black') {
      caption = 'text-white border-white';
      th = 'text-grey-30 bg-black';
      td = 'text-grey-80 bg-black';
    }

    if (element === 'caption') return caption;
    if (element === 'th') return th;
    if (element === 'td') return td;
  };

  const mobileBgCSS = useMemo(() => {
    let cssClass = 'pe-none d-md-none position-absolute top-0 start-0 w-100 h-100';
    if (background === 'bg-white') cssClass += ' bg-white-full';
    if (background === 'bg-black') cssClass += ' bg-black-full';
    if (background === 'bg-grey') cssClass += ' bg-grey-full';
    return cssClass;
  }, [background]);

  return (
    <div className={`position-relative ${backgroundColor} ${innerSpacing} ${outerSpacing} ${extraPadding}`}>
      <div className="row">
        {items.map((item: TableProps, tableIndex: number) => (
          <div
            key={`${tasticId}${tableIndex}`}
            className={`col-12 ${items.length > 1 ? 'col-lg-6' : ''} ${
              items.length === tableIndex + 1 ? 'pt-20 pt-lg-0' : ''
            }`}
          >
            <div className="table-responsive">
              <table className="table m-0" key={`table-${tasticId}${tableIndex}`}>
                <caption
                  className={`mob-p-p3 dt-p-p3 mb-2 mb-xl-0 fw-bold w-100 border-2 border-bottom p-0 pb-2 ${tableStyle(
                    'caption',
                  )}`}
                >
                  {item.headline}
                </caption>
                <tbody>
                  {item.entries.map((entry: TableEntryProps, rowIndex: number) => (
                    <tr key={`${tasticId}${tableIndex}${rowIndex}`}>
                      <th className={`ps-0 mob-p-p4 dt-p-3 ${tableStyle('th')}`}>{entry.title}</th>
                      <td className={`pe-0 ${tableStyle('td')}`}>{entry.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
      <div className={mobileBgCSS}></div>
    </div>
  );
}
