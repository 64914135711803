import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import ProfileTeasers from 'components/snaq-ui/content/profile-teasers';
import { Reference } from '../../../../../helpers/reference';
import { PageNodeTeaser } from '../../categories/category-experts';

const buildList = (nodes, locale) => {
  const listItems: PageNodeTeaser[] = [];

  if (!nodes) {
    return listItems;
  }

  nodes.forEach((node) => {
    if (!node.hasLivePage || !node?.configuration?.teaserMedia || !node?.configuration?.teaserTitle) {
      return;
    }

    const _urlObj: [key: string, value: any] | undefined = Object.entries(node._urls).find(
      (item: [key: string, value: string]) => locale === item[0].split(/[^A-Za-z]/)[0],
    );

    const ctaReference: Reference | null =
      typeof _urlObj[1] === 'string'
        ? {
            type: 'page-folder',
            pageFolder: {
              pageFolderId: node.pageFolderId,
              name: node.name,
              hasLivePage: node.hasLivePage,
              _urls: {
                ...node._urls,
              },
              _url: _urlObj[1],
            },
            openInNewWindow: false,
          }
        : null;

    listItems.push({
      date: node.configuration.propsDate ?? undefined,
      image: node.configuration.teaserMedia,
      title: node.configuration.teaserTitle ?? '',
      description: node.configuration.teaserDescription ?? undefined,
      ctaLabel: 'Read',
      ctaReference: ctaReference ?? undefined,
    });

    if (node.children.length > 0) {
      const childNodes = buildList(node.children, locale);

      childNodes.forEach((childNode) => listItems.push(childNode));
    }
  });

  return listItems;
};

const PageListTastic = ({ id, data }) => {
  const { title, tree, background, innerSpacing, outerSpacing } = data;
  const router = useRouter();

  const profileTeasers = useMemo(() => {
    const items: PageNodeTeaser[] = buildList(tree.children, router?.locale);

    return items.sort((itemA, itemB) =>
      itemA.date && itemB.date ? new Date(itemB.date).getTime() - new Date(itemA.date).getTime() : 0,
    );
  }, [tree]);

  return (
    <ProfileTeasers
      tasticId={id}
      title={title}
      items={profileTeasers}
      background={background}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
    />
  );
};

export default PageListTastic;
