import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import classnames from 'classnames';
import { default as IconTune } from 'components/icons/tune';
import Filters, { SearchFacet } from 'components/snaq-ui/filters/search-filters';
import SortingDisclosure from 'components/snaq-ui/filters/SortingDisclosure';
import { useFormat } from 'helpers/hooks/useFormat';
import { updateURLParams, URLParam } from 'helpers/utils/updateURLParams';
import List from './list';
import { useSearch } from '../../../../frontastic';
import { TrackEventKey } from '../../../../frontastic/provider/frontastic/UseSearch';

interface Filter {
  name: string;
  values: {
    value: string;
  }[];
}

export interface Props {
  filters?: Filter[];
  products: Product[];
  previousCursor: string;
  nextCursor: string;
  category: string;
  facets: SearchFacet[];
  totalProducts: number;
  offset?: number;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

export default function SearchResult({
  products,
  totalProducts,
  offset,
  previousCursor,
  nextCursor,
  facets,
  background,
  innerSpacing,
  outerSpacing,
  filters,
}: Props) {
  const router = useRouter();
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [sortingParam, setSortingParam] = useState<URLParam>(undefined);
  const [previousPageURL, setPreviousPageURL] = useState<string>('/');
  const [nextPageURL, setNextPageURL] = useState<string>('/');

  //i18n messages
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const disabledButtonClassName = 'disabled';

  const toggleFiltering = () => {
    setIsFiltering(!isFiltering);
  };

  const updateSortingParams = (param: URLParam) => {
    const newParam = ['asc', 'desc'].includes(param?.value) ? { key: 'sort', value: `price:${param.value}` } : null;
    setSortingParam(newParam);
  };

  useEffect(() => {
    const urlParams = Object.entries(router.query).map(([key, value]) => ({ key, value: `${value}` }));
    if (previousCursor) {
      setPreviousPageURL(updateURLParams([...urlParams, { key: 'cursor', value: previousCursor }]));
    }

    if (nextCursor) {
      setNextPageURL(updateURLParams([...urlParams, { key: 'cursor', value: nextCursor }]));
    }
  }, [previousCursor, nextCursor, router.query]);

  const facetSelected = useMemo(() => facets.some((facet) => facet.selected), [facets]);

  const { trackEvent } = useSearch();
  const handleTracking = useCallback(
    (id, title, index) => {
      if (!id) {
        return;
      }
      const query = Array.isArray(router.query?.query) ? router.query?.query.join(',') : router.query?.query;
      const offset = Array.isArray(router.query?.cursor)
        ? parseInt(router.query?.cursor[0].split('offset:')[1]) || 0
        : parseInt(router.query?.cursor.split('offset:')[1]) || 0;
      const pageSize = 24;
      const page = 1 + Math.floor(offset / pageSize);
      const pos = (page - 1) * pageSize + index;
      try {
        trackEvent(TrackEventKey.click, [
          {
            id: id,
            title: title,
            query: query || undefined,
            pos: pos,
            page: page,
            pageSize: pageSize,
          },
        ]);
      } catch (e) {
        console.error(e);
      }
    },
    [trackEvent, router.query],
  );

  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      <div className="row pt-30 mb-n2 align-items-end">
        <div className="col-12 col-lg-3"></div>
        <div className="col-12 col-lg-9">
          <h1 className="m-0">
            {formatMessage({ id: 'search.for', defaultMessage: 'Search for' })} &ldquo;
            {Array.isArray(router.query?.query) ? router.query?.query.join(' ') : router.query?.query}
            &ldquo;
          </h1>
        </div>
      </div>
      <div className="row pt-30 align-items-end">
        <div className="col-12 col-lg-3">
          <div className="h2 m-0 d-none d-lg-block">
            {formatProductMessage({ id: 'filter', defaultMessage: 'Filter' })}
          </div>
        </div>
        <div className="col-12 col-lg-9">
          <div className="d-flex justify-content-between align-items-center">
            {/* Number of products */}
            <div className="h6 text-grey-50 m-0 text-uppercase fw-normal">
              {totalProducts === 1 ? (
                <>
                  {`1 ${formatProductMessage({
                    id: 'outOfProducts.separator',
                    defaultMessage: 'out of',
                  })} 1 ${formatProductMessage({
                    id: 'product',
                    defaultMessage: 'Product',
                  })}`}
                </>
              ) : (
                <>
                  {products.length === 0 ? (
                    <>
                      {`0 ${formatProductMessage({
                        id: 'products',
                        defaultMessage: 'Products',
                      })}`}
                    </>
                  ) : (
                    <>
                      {`${offset + 1} - ${offset + products.length} ${formatProductMessage({
                        id: 'outOfProducts.separator',
                        defaultMessage: 'out of',
                      })} ${totalProducts} ${formatProductMessage({
                        id: 'outOfProducts.postfix',
                        defaultMessage: 'Products',
                      })}`}
                    </>
                  )}
                </>
              )}
            </div>
            <div>
              {/* Mobile toggler for offcanvas filter */}
              <button onClick={toggleFiltering} className="btn btn-reset mob-c-c1 dt-c-c1 fw-bold d-lg-none">
                {IconTune({ className: 'me-2' })}
                {formatProductMessage({ id: 'filterAndSort', defaultMessage: 'Filter & Sort' })}
              </button>

              {/* Desktop sorting Filter  */}
              <form className="d-none d-lg-block position-relative">
                <SortingDisclosure updateSortingParams={updateSortingParams} />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-30">
        <div className="col-12 col-lg-3">
          {/* Filter List */}
          <div className={`filter-list ${isFiltering ? 'active' : ''} pt-lg-0`}>
            {/* Offcanvas mobile filter toggle */}
            <button onClick={toggleFiltering} className="btn btn-reset d-lg-none filter-list__toggle">
              {formatProductMessage({ id: 'filterAndSort', defaultMessage: 'Filter & Sort' })}
            </button>
            <Filters
              filters={filters}
              facets={facets}
              products={products}
              sortingParam={sortingParam}
              updateSortingParams={updateSortingParams}
            />
          </div>
        </div>
        <div className={classnames('col-12', (facetSelected || totalProducts > 0) && 'col-lg-9')}>
          {/* Products List */}
          <div className={classnames(isFiltering && 'lg:col-span-2')}>
            <List products={products} filtering={isFiltering} trackingHandler={handleTracking} />
          </div>
          {/* Navigation */}
          {products?.length > 0 && (
            <nav className="col-12 pt-40" aria-label="Pagination">
              <div className="row d-flex justify-content-center">
                <div className="col-6 col-sm-4 col-md-3 col-xl-2">
                  <NextLink href={previousPageURL}>
                    <a className={`btn btn-sm w-100 btn-outline ${previousCursor ? '' : disabledButtonClassName}`}>
                      {formatMessage({ id: 'prev', defaultMessage: 'Previous' })}
                    </a>
                  </NextLink>
                </div>
                <div className="col-6 col-sm-4 col-md-3 col-xl-2">
                  <NextLink href={nextPageURL}>
                    <a className={`btn btn-sm w-100 btn-outline ${nextCursor ? '' : disabledButtonClassName}`}>
                      {formatMessage({ id: 'next', defaultMessage: 'Next' })}
                    </a>
                  </NextLink>
                </div>
              </div>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
}
