import { useCallback, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useFormat } from 'helpers/hooks/useFormat';
import { useDarkMode } from 'frontastic';

export interface Props {
  open?: boolean;
  onClose?: () => void;
  onConfirm?: () => Promise<unknown>;
}

const DeleteConfirmationModal: React.FC<Props> = ({ open, onClose, onConfirm }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const [processing, setProcessing] = useState(false);

  const handleConfirm = useCallback(async () => {
    setProcessing(true);

    await onConfirm?.();

    setProcessing(false);

    onClose?.();
  }, [onConfirm]);

  return (
    <Modal show={open} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-primary mob-h-h5 dt-h-h5 mb-0">
            {formatAccountMessage({
              id: 'address.delete.confirm',
              defaultMessage: 'Are you sure you want to delete this address?',
            })}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-wrap flex-column flex-sm-row">
          <button
            disabled={processing}
            type="button"
            className="btn btn-sm btn-xl-md btn-primary"
            onClick={handleConfirm}
            tabIndex={-1}
          >
            {formatMessage({ id: 'delete', defaultMessage: 'Delete' })}
          </button>
          <button
            type="button"
            className="btn btn-sm btn-xl-md btn-secondary mt-2 mt-sm-0 ms-sm-20"
            onClick={onClose}
            tabIndex={-1}
          >
            {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteConfirmationModal;
