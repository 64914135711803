import React from 'react';
import NextLink from 'next/link';
import { default as IconDesktopWindows } from 'components/icons/desktop-windows';
import { default as IconLeaf } from 'components/icons/leaf';
import { default as IconSupportAgent } from 'components/icons/support-agent';
import { useFormat } from '../../../../helpers/hooks/useFormat';

export interface BrandLinksProps {
  customerSupportUrl?: string;
  sustainabilityUrl?: string;
  websiteUrl?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

const BrandLinks: React.FC<BrandLinksProps> = ({
  customerSupportUrl,
  sustainabilityUrl,
  websiteUrl,
  background,
  innerSpacing,
  outerSpacing,
}) => {
  const { formatMessage } = useFormat({ name: 'common' });

  if (!customerSupportUrl && !sustainabilityUrl && !websiteUrl) {
    return null;
  }

  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      {(customerSupportUrl || sustainabilityUrl || websiteUrl) && (
        <>
          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            {websiteUrl && (
              <NextLink href={websiteUrl}>
                <a target="_blank" rel="noopener nofollow" className="icon-link mx-md-30 mx-xl-50 mx-xxl-80 h5">
                  {IconDesktopWindows({ className: 'h-px-20 h-px-xl-30 me-6 me-xl-20' })}
                  {formatMessage({ id: 'website', defaultMessage: 'Website' })}
                </a>
              </NextLink>
            )}
            {customerSupportUrl && (
              <NextLink href={customerSupportUrl}>
                <a
                  target="_blank"
                  rel="noopener nofollow"
                  className="icon-link mt-20 mt-md-0 mx-md-30 mx-xl-50 mx-xxl-80 h5"
                >
                  {IconSupportAgent({ className: 'h-px-20 h-px-xl-30 me-6 me-xl-20' })}
                  {formatMessage({ id: 'customerSupport', defaultMessage: 'Customer support' })}
                </a>
              </NextLink>
            )}
            {sustainabilityUrl && (
              <NextLink href={sustainabilityUrl}>
                <a
                  target="_blank"
                  rel="noopener nofollow"
                  className="icon-link mt-20 mt-md-0 mx-md-30 mx-xl-50 mx-xxl-80 h5"
                >
                  {IconLeaf({ className: 'h-px-20 h-px-xl-30 me-6 me-xl-20' })}
                  {formatMessage({ id: 'sustainability', defaultMessage: 'Sustainability' })}
                </a>
              </NextLink>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BrandLinks;
