import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-desktop-windows ${className}`}
    viewBox="0 0 20 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 18v-2h2v-2H2c-.5 0-1-.2-1.4-.6S0 12.6 0 12V2C0 1.5.2 1 .6.6S1.5 0 2 0h16c.6 0 1 .2 1.4.6s.6.9.6 1.4v10c0 .6-.2 1-.6 1.4s-.8.6-1.4.6h-6v2h2v2H6zm-4-6h16V2H2v10zm0 0V2v10z" />
  </svg>
);

export default Icon;
