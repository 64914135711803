import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// eslint-disable-next-line import/no-named-as-default
import toast from 'react-hot-toast';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic/provider';

export interface Props {
  className?: string;
}

const DiscountForm: React.FC<Props> = ({ className }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const [code, setCode] = useState('');
  const { redeemDiscountCode } = useCart();
  const [error, setError] = useState('');

  const onApplyDiscount = () => {
    redeemDiscountCode(code)
      .then((res) => {
        if (typeof res === 'string') {
          setError(res ?? '');
        } else {
          setError('');
        }
      })
      .catch((e: Error) => {
        // NOTE: Apparently errors are not forwarded from api-hub yet -> see Todos ... so this seems pretty useless!
        if ((e.message = '101')) {
          toast.error(formatCartMessage({ id: 'codeNotValid', defaultMessage: 'Code is not valid' }));
        }
      })
      .finally(() => setCode(''));
  };

  return (
    <div className={`${className}`}>
      <div className="rounded bg-grey-20 p-20 mb-20">
        <p className="dt-p-p3">{formatCartMessage({ id: 'cart.discount', defaultMessage: 'Discounts' })}</p>
        <Row>
          <Col xs={12} sm={8}>
            <input
              className="form-control form-control-lg"
              type="text"
              value={code}
              placeholder={formatCartMessage({
                id: 'cart.discount.code',
                defaultMessage: 'Code',
              })}
              onChange={(e) => setCode(e.target.value)}
            />
          </Col>
          <Col xs={12} sm={4}>
            <button
              type="button"
              onClick={onApplyDiscount}
              disabled={code === '' ? true : false}
              className="btn btn-lg btn-secondary w-100 mt-3 mt-sm-0"
            >
              {formatCartMessage({
                id: 'cart.apply',
                defaultMessage: 'Apply',
              })}
            </button>
          </Col>
        </Row>
        {/* NOTE: Workaround b/c errors not forwarded from api-hub */}
        {error.includes('failed') && error.includes('not found') && (
          <p className="mt-3 mb-0 dt-p-p4 text-primary">
            {formatCartMessage({ id: 'codeNotValid', defaultMessage: 'Code not valid' })}
          </p>
        )}
      </div>
    </div>
  );
};

export default DiscountForm;
