import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-payment-google-pay-borderless ${className}`}
    viewBox="0 0 80 31.8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#5F6368"
      d="M37.8,15.5v9.3h-3v-23h7.8c1.9,0,3.7,0.7,5.1,2c1.4,1.2,2.1,3,2.1,4.9s-0.7,3.6-2.1,4.9c-1.4,1.3-3.1,2-5.1,2
	L37.8,15.5L37.8,15.5z M37.8,4.6v8h5c1.1,0,2.2-0.4,2.9-1.2c1.6-1.5,1.6-4,0.1-5.5l-0.1-0.1c-0.8-0.8-1.8-1.3-2.9-1.2L37.8,4.6
	L37.8,4.6z"
    />
    <path
      fill="#5F6368"
      d="M56.7,8.6c2.2,0,3.9,0.6,5.2,1.8s1.9,2.8,1.9,4.8v9.6H61v-2.2h-0.1c-1.2,1.8-2.9,2.7-4.9,2.7
	c-1.7,0-3.2-0.5-4.4-1.5c-1.1-1-1.8-2.4-1.8-3.9c0-1.6,0.6-2.9,1.8-3.9s2.9-1.4,4.9-1.4c1.8,0,3.2,0.3,4.3,1v-0.7c0-1-0.4-2-1.2-2.6
	c-0.8-0.7-1.8-1.1-2.9-1.1c-1.7,0-3,0.7-3.9,2.1l-2.6-1.6C51.8,9.6,53.9,8.6,56.7,8.6z M52.9,20c0,0.8,0.4,1.5,1,1.9
	c0.7,0.5,1.5,0.8,2.3,0.8c1.2,0,2.4-0.5,3.3-1.4c1-0.9,1.5-2,1.5-3.2c-0.9-0.7-2.2-1.1-3.9-1.1c-1.2,0-2.2,0.3-3,0.9
	C53.3,18.4,52.9,19.1,52.9,20z"
    />
    <path fill="#5F6368" d="M80,9.1l-9.9,22.7h-3l3.7-7.9L64.3,9.2h3.2l4.7,11.3h0.1l4.6-11.3H80V9.1z" />
    <path
      fill="#4285F4"
      d="M25.9,13.5c0-0.9-0.1-1.8-0.2-2.7H13.2v5.1h7.1c-0.3,1.6-1.2,3.1-2.6,4v3.3H22C24.5,20.9,25.9,17.5,25.9,13.5z"
    />
    <path
      fill="#34A853"
      d="M13.2,26.4c3.6,0,6.6-1.2,8.8-3.2l-4.3-3.3c-1.2,0.8-2.7,1.3-4.5,1.3c-3.4,0-6.4-2.3-7.4-5.5H1.4v3.4
	C3.7,23.6,8.2,26.4,13.2,26.4z"
    />
    <path fill="#FBBC04" d="M5.8,15.7c-0.6-1.6-0.6-3.4,0-5.1V7.2H1.4c-1.9,3.7-1.9,8.1,0,11.9L5.8,15.7z" />
    <path
      fill="#EA4335"
      d="M13.2,5.2c1.9,0,3.7,0.7,5.1,2l0,0l3.8-3.8c-2.4-2.2-5.6-3.5-8.8-3.4c-5,0-9.6,2.8-11.8,7.3l4.4,3.4
	C6.8,7.5,9.8,5.2,13.2,5.2z"
    />
  </svg>
);

export default Icon;
