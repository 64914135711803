import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-chevron-left ${className}`}
    viewBox="0 0 47 82"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M37.7 80.4 1.2 44c-.4-.4-.7-.9-.9-1.4-.2-.5-.3-1-.3-1.6 0-.6.1-1.1.3-1.6.2-.5.5-1 .9-1.4L37.7 1.5C38.7.5 40 0 41.5 0s2.8.5 3.9 1.6S47 4 47 5.4s-.5 2.7-1.6 3.8L13.5 41l31.8 31.8c1 1 1.5 2.3 1.5 3.7 0 1.5-.5 2.8-1.6 3.8-1.1 1.1-2.3 1.6-3.8 1.6-1.4.1-2.6-.4-3.7-1.5z" />
  </svg>
);

export default Icon;
