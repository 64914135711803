import Markdown from 'components/snaq-ui/content/markdown';

const MarkdownTastic = ({ data }) => {
  return (
    <Markdown
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
      text={data.markdown}
      textAlignment={data.textAlignment}
    />
  );
};

export default MarkdownTastic;
