import React, { useCallback, useMemo } from 'react';
import { Product } from '@Types/product/Product';
import classnames from 'classnames';
import { default as IconChevronRight } from 'components/icons/chevron-right';
import Slider, { SliderProps } from 'components/snaq-ui/slider';
import { ReferenceLink, Reference } from 'helpers/reference';
import { sm, md, lg } from 'helpers/utils/screensizes';
import Tile from './tile';
import { useSearch } from '../../../../frontastic';
import { TrackEventKey } from '../../../../frontastic/provider/frontastic/UseSearch';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';

const sliderConfiguration: SliderProps = {
  slidesPerView: 1.5,
  breakpoints: {
    [sm]: {
      slidesPerView: 2,
    },
    [md]: {
      slidesPerView: 3,
    },
    [lg]: {
      slidesPerView: 4,
    },
  },
  arrows: true,
  dots: false,
};

export interface Props {
  products: Product[];
  title: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  ctaTitle?: string;
  ctaRel?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

export default function ProductHighlights({
  products,
  title,
  ctaLabel,
  ctaReference,
  ctaTitle,
  ctaRel,
  background,
  innerSpacing,
  outerSpacing,
}: Props) {
  const [isTabletSize] = useMediaQuery(lg);

  const primaryProduct: Product | null = useMemo(() => products[0] ?? null, [products]);

  const secondaryProducts: Product[] = useMemo(
    () => (isTabletSize ? products.slice(1, 5) : []),
    [products, isTabletSize],
  );

  const sliderProducts: Product[] = useMemo(
    () => (isTabletSize ? products.slice(5) : products.slice(1)),
    [products, isTabletSize],
  );

  const { trackEvent } = useSearch();
  const handleTracking = useCallback(
    (id, title, index) => {
      if (!id) {
        return;
      }
      try {
        trackEvent(TrackEventKey.click, [
          {
            id: id,
            title: title,
            query: 'highlights',
            pos: index,
            page: 1,
            pageSize: products.length,
          },
        ]);
      } catch (e) {
        console.error(e);
      }
    },
    [trackEvent, products],
  );

  return (
    <div className={`bg-white-full`}>
      <div className="products-slider">
        <div className="d-flex justify-content-between align-items-end mb-20">
          <h2 className="h1 products-slider__title mb-0">{title}</h2>
          {ctaLabel && ctaReference && (
            <ReferenceLink
              target={ctaReference}
              title={ctaTitle}
              rel={ctaRel}
              className="products-slider__link link-reset dt-c-c2 mb-1"
            >
              <span className="d-none d-xl-block">{ctaLabel}</span>
              {IconChevronRight({})}
            </ReferenceLink>
          )}
        </div>
        {(primaryProduct || secondaryProducts.length > 0) && (
          <div className="row pb-10">
            {/* Add class product-card-lg-cover for original design */}
            {primaryProduct && (
              <div className={classnames('pb-20 d-lg-flex', secondaryProducts.length > 0 ? 'col-6' : 'col-12')}>
                {/* Add style="product-card--full-img" for original design */}
                <Tile
                  product={primaryProduct}
                  size="large"
                  trackingHandler={() => handleTracking(primaryProduct.productId, primaryProduct.name, 1)}
                />
              </div>
            )}
            {secondaryProducts.length > 0 && (
              <div className={classnames('d-flex', primaryProduct ? 'col-6' : 'col-12')}>
                <div className="row">
                  {secondaryProducts.map((product, index: number) => (
                    <div key={index} className={classnames('pb-20 d-flex', primaryProduct ? 'col-6' : 'col-3')}>
                      <Tile
                        product={product}
                        trackingHandler={() => handleTracking(product.productId, product.name, index + 2)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        <div className={classnames('bg-grey-full section-spacing-padding-top--xs')}>
          <Slider {...sliderConfiguration} className="swiper--mobile-full">
            {sliderProducts.map((product, index: number) => (
              <Tile
                product={product}
                key={index}
                trackingHandler={() => handleTracking(product.productId, product.name, index + 6)}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
