import { FC, useCallback, useMemo } from 'react';
import { Disclosure } from '@headlessui/react';
import { Product } from '@Types/product/Product';
import { Facet } from '@Types/result/Facet';
import { RangeFacet } from '@Types/result/RangeFacet';
import { default as IconMinus } from 'components/icons/minus';
import { default as IconPlus } from 'components/icons/plus';
import Range from 'components/snaq-ui/range-filter';
import { URLParam } from 'helpers/utils/updateURLParams';

type RangeFilterDisclosureProps = {
  termKey: string;
  facets: Facet[];
  products: Product[];
  updateFilteringParams: (termKey: string, params: URLParam[]) => void;
  defaultOpen?: boolean;
};

const RangeFilterDisclosure: FC<RangeFilterDisclosureProps> = ({
  termKey,
  products,
  facets,
  updateFilteringParams,
  defaultOpen = false,
}) => {
  // CHECK: Unclear if this is an appropriate place and method to determine the termLabel
  // NOTE: "official" commercetoolsTermFacetResultToTermFacet solution useless, since it just maps the facetKey to termFacet.label
  const termLabel: string | null = useMemo(() => {
    let label: string | null = null;
    for (const product of products) {
      if (label !== null) {
        break;
      }
      for (const variant of product.variants) {
        if (label !== null) {
          break;
        }
        for (const attributeLabelIndex in variant.attributeLabels) {
          const attributeLabel = variant.attributeLabels[attributeLabelIndex];
          if (attributeLabel.key === termKey) {
            label = attributeLabel.label;
            break;
          }
        }
      }
    }
    return label;
  }, [termKey, products]);

  const facet = useMemo(
    () => facets?.find((facet) => facet.identifier === `variants.attributes.${termKey}`) as RangeFacet,
    [facets, termKey],
  );

  const handleChange = useCallback(
    (values: [number, number]) => {
      const params = [
        { key: `facets[variants.attributes.${termKey}][min]`, value: values[0].toString() },
        { key: `facets[variants.attributes.${termKey}][max]`, value: values[1].toString() },
      ];
      updateFilteringParams(termKey, params);
    },
    [updateFilteringParams, termKey],
  );

  if (!termLabel || facet?.min === facet?.max) {
    return null;
  }

  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button className="disclosure-filter-btn">
            {termLabel}
            {open ? IconMinus({}) : IconPlus({})}
          </Disclosure.Button>
          <Disclosure.Panel className="disclosure-filter-panel disclosure-filter-panel--price">
            <div className="disclosure-filter-panel__inner">
              <Range facet={facet} onChange={handleChange} termKey={termKey} />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default RangeFilterDisclosure;
