import parse from 'html-react-parser';
import Image from 'frontastic/lib/image';

export interface CategoryHeroProps {
  title: string;
  subtitle?: string;
  background?: string;
  image?: string;
}

const CategoryHero: React.FC<CategoryHeroProps> = ({ image, title, subtitle, background }) => {
  if (!title) return null;
  return (
    <div className={`${background} pb-20 pb-xl-40`}>
      <div className="position-relative">
        <div className="position-relative position-lg-absolute w-100 h-100 top-0 left-0 mb-20 mb-lg-0">
          <div className="ratio ratio-21x9 d-lg-none"></div>
          {image ? (
            <Image
              src={image}
              alt={title}
              layout="fill"
              className="position-absolute top-0 left-0 w-100 h-100 object-fit-cover rounded object-position-right"
              loading="eager"
            />
          ) : (
            <div className="position-absolute top-0 left-0 w-100 h-100 rounded bg-grey-30 bg-gradient"></div>
          )}
        </div>
        <div className="row position-relative z-1 pt-lg-45 pb-lg-50 min-h-px-lg-280 align-items-center">
          <div className="col-12 col-lg-5 offset-lg-1 text-lg-white">
            <h1 className="mb-0">{title}</h1>
            {subtitle && <div className="mob-p-p2 dt-p-p2 m-0 pt-10 last-child-no-mb">{parse(subtitle)}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryHero;
