import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-chevron-down ${className}`}
    viewBox="0 0 82 47"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M80.4 9.3 44 45.8c-.4.4-.9.7-1.4.9s-1 .3-1.6.3c-.6 0-1.1-.1-1.6-.3-.5-.2-1-.5-1.4-.9L1.5 9.3C.5 8.3 0 7 0 5.5s.5-2.8 1.6-3.9S4 0 5.4 0s2.7.5 3.8 1.6L41 33.5 72.8 1.6c1-1 2.3-1.5 3.7-1.5 1.5 0 2.8.5 3.8 1.6S82 4.1 82 5.5c0 1.5-.5 2.7-1.6 3.8z" />
  </svg>
);

export default Icon;
