import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-play-circle ${className}`}
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m7.5 14.5 7-4.5-7-4.5v9zM10 20c-1.4 0-2.7-.3-3.9-.8-1.2-.5-2.3-1.2-3.2-2.1s-1.6-2-2.1-3.2S0 11.4 0 10s.3-2.7.8-3.9S2 3.8 2.9 2.9s2-1.6 3.2-2.1S8.6 0 10 0s2.7.3 3.9.8 2.3 1.2 3.2 2.1 1.6 2 2.1 3.2c.5 1.2.8 2.5.8 3.9s-.3 2.7-.8 3.9c-.5 1.2-1.2 2.3-2.1 3.2s-2 1.6-3.2 2.1c-1.2.5-2.5.8-3.9.8zm0-2c2.2 0 4.1-.8 5.7-2.3S18 12.2 18 10s-.8-4.1-2.3-5.7S12.2 2 10 2s-4.1.8-5.7 2.3S2 7.8 2 10s.8 4.1 2.3 5.7S7.8 18 10 18z" />
  </svg>
);

export default Icon;
