import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-payment-apple-borderless ${className}`}
    viewBox="0 0 120.4 51.1"
    xmlns="http://www.w3.org/2000/svg"
    fill="#000"
  >
    <path
      d="M22.8,6.6c1.4-1.8,2.4-4.2,2.1-6.6c-2.1,0.1-4.6,1.4-6.1,3.1c-1.3,1.5-2.5,4-2.2,6.3C19,9.7,21.3,8.3,22.8,6.6 M24.9,9.9
	c-3.4-0.2-6.3,1.9-7.9,1.9c-1.6,0-4.1-1.8-6.8-1.8c-3.5,0.1-6.7,2-8.5,5.2c-3.6,6.3-1,15.6,2.6,20.7c1.7,2.5,3.8,5.3,6.5,5.2
	c2.6-0.1,3.6-1.7,6.7-1.7c3.1,0,4,1.7,6.8,1.6c2.8-0.1,4.6-2.5,6.3-5.1c2-2.9,2.8-5.7,2.8-5.8c0,0-5.5-2.1-5.5-8.3
	c-0.1-5.2,4.2-7.7,4.4-7.8C29.9,10.4,26.2,10,24.9,9.9 M54.3,2.9c7.3,0,12.5,5.1,12.5,12.4c0,7.4-5.2,12.5-12.7,12.5H46v12.9h-5.9
	V2.9H54.3z M46,22.9h6.7c5.1,0,8-2.8,8-7.5s-2.9-7.5-8-7.5H46V22.9L46,22.9z M68.3,32.9c0-4.8,3.7-7.8,10.3-8.2l7.6-0.4v-2.1
	c0-3.1-2.1-4.9-5.5-4.9c-3.3,0-5.3,1.6-5.8,4h-5.4c0.3-5,4.6-8.7,11.4-8.7c6.7,0,11,3.5,11,9.1v19h-5.4v-4.5h-0.1
	c-1.6,3.1-5.1,5-8.7,5C72.1,41.2,68.3,37.9,68.3,32.9L68.3,32.9z M86.1,30.4v-2.2l-6.8,0.4c-3.4,0.2-5.3,1.7-5.3,4.1c0,2.4,2,4,5,4
	C83,36.8,86.1,34,86.1,30.4z M96.9,50.9v-4.6c0.4,0.1,1.4,0.1,1.8,0.1c2.6,0,4-1.1,4.9-3.9c0-0.1,0.5-1.7,0.5-1.7l-10-27.6h6.1
	l7,22.5h0.1l7-22.5h6L110,42.2c-2.4,6.7-5.1,8.8-10.8,8.8C98.7,51.1,97.3,51,96.9,50.9z"
    />
  </svg>
);

export default Icon;
