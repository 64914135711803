import React from 'react';
import TextAndMedia from 'components/snaq-ui/content/text-and-media';

const TextAndMediaTastic = ({ data }) => {
  return (
    <TextAndMedia
      orientation={data.orientation}
      background={data.background}
      backgroundWidth={data.backgroundWidth}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
      titleMedia={data.titleMedia}
      title={data.title}
      text={data.text}
      ctaColor={data.ctaColor}
      ctaLabel={data.ctaLabel}
      ctaReference={data.ctaReference}
      ctaTitle={data.ctaTitle}
      ctaRel={data.ctaRel}
      mediaHeight={data.mediaHeight}
      media={data.media}
    />
  );
};

export default TextAndMediaTastic;
