import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';

const Security = () => {
  //account data
  const { changePassword } = useAccount();
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  //new passwords
  const initialData = { oldPassword: '', password: '', confirmPassword: '' };
  const [data, setData] = useState(initialData);

  //error
  const [error, setError] = useState('');

  //success
  const [success, setSuccess] = useState('');

  //loading..
  const [processing, setProcessing] = useState(false);

  //input change handler
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  //submission handler
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //loading starts
    setProcessing(true);
    //try updating user's password
    try {
      const response = await changePassword(data.oldPassword, data.password);
      if (response.accountId) {
        setSuccess(
          formatAccountMessage({ id: 'password.change.success', defaultMessage: 'Password successfully changed!' }),
        );
        setError('');
      } else {
        setError(
          formatAccountMessage({
            id: 'password.change.exception',
            defaultMessage: "Sorry, we couldn't fulfill your request",
          }),
        );
        setSuccess('');
      }
    } catch (err) {
      setError(formatAccountMessage({ id: 'password.change.wrong', defaultMessage: 'Wrong password entered' }));
      setSuccess('');
    } finally {
      setProcessing(false);
      setData(initialData);
    }
  };

  //save button disabled
  const submitDisabled =
    !(data.password && data.confirmPassword && data.oldPassword) || data.password !== data.confirmPassword;

  return (
    <div>
      <h2>{formatAccountMessage({ id: 'password.change', defaultMessage: 'Change password' })}</h2>
      <p className="mob-p-p3 dt-p-p3">
        {formatAccountMessage({
          id: 'account.security.warning',
          defaultMessage: 'Be careful when using this setting',
        })}
      </p>
      <form onSubmit={handleSubmit}>
        {success && (
          <Alert key="success" variant="success">
            {success}
          </Alert>
        )}
        {error && (
          <Alert key="danger" variant="danger">
            {error}
          </Alert>
        )}
        <div className="mb-3">
          <label htmlFor="old-password" className="form-label">
            {formatAccountMessage({
              id: 'password.old',
              defaultMessage: 'Old password',
            })}
          </label>
          <input
            aria-label="old password"
            id="old-password"
            name="oldPassword"
            type="password"
            className="form-control"
            required
            onChange={handleChange}
            value={data.oldPassword}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            {formatAccountMessage({
              id: 'password.new',
              defaultMessage: 'New Password',
            })}
          </label>
          <input
            aria-label="new password"
            id="password"
            name="password"
            type="password"
            className="form-control"
            required
            onChange={handleChange}
            value={data.password}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="confirm-password" className="form-label">
            {formatAccountMessage({
              id: 'password.confirm',
              defaultMessage: 'Confirm password',
            })}
          </label>
          <input
            aria-label="confirm new password"
            id="confirm-password"
            name="confirmPassword"
            type="password"
            className="form-control"
            required
            onChange={handleChange}
            value={data.confirmPassword}
          />
        </div>
        <button type="submit" className="btn btn-primary" disabled={submitDisabled || processing}>
          {formatMessage({ id: 'save', defaultMessage: 'Save' })}
        </button>
      </form>
    </div>
  );
};

export default Security;
