import React from 'react';
import BrandLinks from '../../../../../components/snaq-ui/brands/brand-links';

const BrandLinksTastic = ({ data }) => {
  const brand = data?.data?.dataSource;
  if (!brand) return null;
  return (
    <BrandLinks
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
      customerSupportUrl={brand.customerSupportUrl}
      sustainabilityUrl={brand.sustainabilityUrl}
      websiteUrl={brand.websiteUrl}
    />
  );
};

export default BrandLinksTastic;
