import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { checkVAT, countries } from 'jsvat';
import { Trans, useTranslation } from 'react-i18next';
import { useFormat } from 'helpers/hooks/useFormat';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useAccount, useCart } from 'frontastic';
import { ReferenceLink } from '../../../../../helpers/reference';
import Field from '../field';
import { AccountDetailsProps } from '../index';

const sectionKeys = {
  profile: 'profile',
  company: 'company',
  account: 'account',
};

export const salutationKeys = {
  male: 'male',
  female: 'female',
  diverse: 'diverse',
};

export const companyTypeKeys = {
  entrepreneur: 'entrepreneur',
  company: 'company',
  companyEU: 'companyEU',
};

const General: React.FC<AccountDetailsProps> = ({ contactLink }) => {
  //i18n messages
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { t: tAccount } = useTranslation(['account']);

  const { getProjectSettings } = useCart();
  const [locales, setLocales] = useState<string[]>(null);

  useEffect(() => {
    let isMounted = true;
    const fetchProjectSettings = async () =>
      getProjectSettings().then((data) => {
        if (isMounted) {
          setLocales(data?.languages);
        }
      });
    fetchProjectSettings();
    return () => {
      isMounted = false;
    };
  }, [getProjectSettings]);

  //next/router
  const router = useRouter();

  //account data
  const { account, update } = useAccount();

  //Form data
  const [data, setData] = useState({
    salutation: account?.salutation ?? '',
    title: account?.title ?? '',
    firstName: account?.firstName ?? '',
    lastName: account?.lastName ?? '',
    email: account?.email ?? '',
    companyName: account?.companyName ?? '',
    companyType: account?.companyType ?? '',
    vatId: account?.vatId ?? '',
    newsletter: account?.newsletter ? 'true' : '',
    locale: account?.locale ?? '',
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  //sections in edit mode
  const [inEdit, setInEdit] = useState<string[]>([]);

  const startEdit = useCallback((key: string) => {
    setInEdit((prevState) => {
      if (prevState.includes(key)) {
        return prevState;
      }
      return [...prevState, key];
    });
  }, []);

  const stopEdit = useCallback((key: string) => {
    setInEdit((prevState) => prevState.filter((prevKey) => prevKey !== key));
  }, []);

  const startEditAll = useCallback(() => {
    Object.values(sectionKeys).forEach((key) => startEdit(key));
  }, [startEdit]);

  const stopEditAll = useCallback(() => {
    setInEdit([]);
  }, []);

  //Input change
  const handleChange = useCallback((name: string, value: string) => {
    setErrors(() => ({}));
    setData((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (key: string) => {
      stopEdit(key);
      if (key === sectionKeys.profile) {
        await update({
          salutation: data.salutation,
          title: data.title,
          firstName: data.firstName,
          lastName: data.lastName,
          newsletter: data.newsletter === 'true',
        });
      }
      if (key === sectionKeys.company) {
        const vatIdMandantory =
          data.companyType === companyTypeKeys.company || data.companyType === companyTypeKeys.companyEU;
        const vatIdEntered = data.vatId.length > 0;
        // https://se-panfilov.github.io/jsvat/
        const vatIdValid = checkVAT(data.vatId, countries).isValid;
        if ((vatIdMandantory || vatIdEntered) && !vatIdValid) {
          setErrors((prevState) => ({
            ...prevState,
            vatId: formatErrorMessage({ id: 'vatid.invalid', defaultMessage: 'Invalid Vat-Id' }),
          }));
          startEdit(key);
          return;
        } else {
          setErrors((prevState) => ({
            ...prevState,
            vatId: undefined,
          }));
        }
        await update({
          companyName: data.companyName,
          companyType: data.companyType,
          vatId: data.vatId,
        });
      }
      if (key === sectionKeys.account) {
        await update({
          locale: data.locale,
        });
        router.replace(router.asPath, undefined, { locale: data.locale.split(/[^A-Za-z]/)[0] });
      }
    },
    [data, stopEdit, startEdit, update, router, formatErrorMessage],
  );

  const handleReset = useCallback(
    async (key: string) => {
      stopEdit(key);
      setData((prevState) => {
        let resetData = {};
        switch (key) {
          case sectionKeys.profile:
            resetData = {
              salutation: account?.salutation ?? '',
              title: account?.title ?? '',
              firstName: account?.firstName ?? '',
              lastName: account?.lastName ?? '',
              newsletter: account?.newsletter ? 'true' : '',
            };
            break;
          case sectionKeys.company:
            resetData = {
              companyName: account?.companyName ?? '',
              companyType: account?.companyType ?? '',
              vatId: account?.vatId ?? '',
            };
            break;
          case sectionKeys.account:
            resetData = {
              locale: account?.locale ?? '',
            };
            break;
        }
        return {
          ...prevState,
          ...resetData,
        };
      });
    },
    [stopEdit, account, router],
  );

  const handleSubmitAll = useCallback(async () => {
    stopEditAll();
    Object.values(sectionKeys).forEach((key) => handleSubmit(key));
  }, [stopEditAll, handleSubmit]);

  const handleResetAll = useCallback(async () => {
    stopEditAll();
    Object.values(sectionKeys).forEach((key) => handleReset(key));
  }, [stopEditAll, handleReset]);

  const interpolatedComponents = [
    <ReferenceLink
      key={0}
      className="cursor-pointer font-medium text-accent-500 hover:underline"
      target={contactLink}
    />,
  ];

  return (
    <div>
      <div>
        <h2>{formatAccountMessage({ id: 'profile', defaultMessage: 'Profile' })}</h2>
        <div className="d-none d-lg-block mb-3">
          {inEdit.includes(sectionKeys.profile) ? (
            <div className="d-flex">
              <button
                type="submit"
                className="btn btn-primary btn-sm me-2"
                onClick={() => handleSubmit(sectionKeys.profile)}
              >
                {formatMessage({ id: 'save', defaultMessage: 'Save' })}
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => handleReset(sectionKeys.profile)}
              >
                {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
              </button>
            </div>
          ) : (
            <div className="me-2">
              <button type="button" className="btn btn-secondary btn-sm" onClick={() => startEdit(sectionKeys.profile)}>
                {formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
              </button>
            </div>
          )}
        </div>
        <Field
          id="salutation"
          name="salutation"
          label={formatMessage({ id: 'salutation', defaultMessage: 'Salutation' })}
          value={data.salutation ?? ''}
          type="select"
          options={
            Object.values(salutationKeys).map((key) => ({
              name: formatMessage({ id: `salutation.${key}`, defaultMessage: '-' }),
              value: key,
            })) || []
          }
          required={true}
          disabled={!inEdit.includes(sectionKeys.profile)}
          onChange={(val) => handleChange('salutation', val)}
        />
        <Field
          id="title"
          name="title"
          label={formatMessage({ id: 'title', defaultMessage: 'Title' })}
          value={data?.title ?? ''}
          type="text"
          required={false}
          disabled={!inEdit.includes(sectionKeys.profile)}
          onChange={(val) => handleChange('title', val)}
        />
        <Field
          id="firstname"
          name="firstName"
          label={formatMessage({ id: 'firstName', defaultMessage: 'First Name' })}
          value={data?.firstName ?? ''}
          type="text"
          required={true}
          disabled={!inEdit.includes(sectionKeys.profile)}
          onChange={(val) => handleChange('firstName', val)}
        />
        <Field
          id="lastname"
          name="lastName"
          label={formatMessage({ id: 'lastName', defaultMessage: 'Last Name' })}
          value={data?.lastName ?? ''}
          type="text"
          required={true}
          disabled={!inEdit.includes(sectionKeys.profile)}
          onChange={(val) => handleChange('lastName', val)}
        />
        <Field
          id="email"
          name="email"
          label={formatMessage({ id: 'email', defaultMessage: 'Email' })}
          value={data?.email ?? ''}
          type="email"
          required={true}
          disabled={true}
          onChange={(val) => handleChange('email', val)}
        />
        <Field
          id="newsletter"
          name="newsletter"
          label={formatMessage({ id: 'newsletter', defaultMessage: 'Newsletter' })}
          checked={data?.newsletter === 'true'}
          value="true"
          type="checkbox"
          disabled={!inEdit.includes(sectionKeys.profile)}
          onChange={(val) => {
            handleChange('newsletter', val);
          }}
        />
      </div>
      <hr />
      <div>
        <h2>{formatAccountMessage({ id: 'company', defaultMessage: 'Firma' })}</h2>
        {account.externalId && (
          <p className="fw-bold mob-f-f1 dt-f-f1 m">
            {formatAccountMessage({ id: 'companyNumber', defaultMessage: 'Firmennummer' })}: {account.externalId}
          </p>
        )}
        {/* NOTE: Company related info should not be changed by user (user should contact support)
        <div className="d-none d-lg-block mb-3">
          {inEdit.includes(sectionKeys.company) ? (
            <div className="d-flex">
              <button
                type="submit"
                className="btn btn-primary btn-sm me-2"
                onClick={() => handleSubmit(sectionKeys.company)}
              >
                {formatMessage({ id: 'save', defaultMessage: 'Save' })}
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => handleReset(sectionKeys.company)}
              >
                {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
              </button>
            </div>
          ) : (
            <button type="button" className="btn btn-secondary btn-sm" onClick={() => startEdit(sectionKeys.company)}>
              {formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
            </button>
          )}
        </div>
        <p className="mob-p-p3 dt-p-p3">
          {formatAccountMessage({
            id: 'profile.desc',
            defaultMessage: 'This information will be displayed publicly so be careful what you share.',
          })}
        </p>
        */}
        <p className="mob-p-p3 dt-p-p3">
          <Trans i18nKey="company.desc" t={tAccount} components={interpolatedComponents} />
        </p>
        <Field
          id="companyname"
          name="companyName"
          label={formatAccountMessage({ id: 'companyName', defaultMessage: 'Company name' })}
          value={data?.companyName ?? ''}
          type="text"
          required={true}
          disabled={!inEdit.includes(sectionKeys.company)}
          onChange={(val) => handleChange('companyName', val)}
        />
        <Field
          id="companytype"
          name="companyType"
          label={formatAccountMessage({ id: 'companyType', defaultMessage: 'Company type' })}
          value={data.companyType ?? ''}
          type="select"
          options={
            Object.values(companyTypeKeys).map((key) => ({
              name: formatAccountMessage({ id: `companyType.${key}`, defaultMessage: '-' }),
              value: key,
            })) || []
          }
          required={true}
          disabled={!inEdit.includes(sectionKeys.company)}
          onChange={(val) => handleChange('companyType', val)}
        />
        <Field
          id="vatid"
          name="vatId"
          label={formatAccountMessage({ id: 'vatId', defaultMessage: 'Vat-id' })}
          value={data?.vatId ?? ''}
          type="text"
          required={data.companyType === companyTypeKeys.company || data.companyType === companyTypeKeys.companyEU}
          disabled={!inEdit.includes(sectionKeys.company)}
          onChange={(val) => handleChange('vatId', val)}
          errorMessage={errors.vatId}
        />
      </div>
      {/* NOTE: Temporarily hidden b/c QVSTSHOP-1130
      <hr />
      <div>
        <h2>{formatAccountMessage({ id: 'account', defaultMessage: 'Account' })}</h2>
        <div className="d-none d-lg-block mb-3">
          {inEdit.includes(sectionKeys.account) ? (
            <div className="d-flex">
              <button
                type="submit"
                className="btn btn-primary btn-sm me-2"
                onClick={() => handleSubmit(sectionKeys.account)}
              >
                {formatMessage({ id: 'save', defaultMessage: 'Save' })}
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => handleReset(sectionKeys.account)}
              >
                {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
              </button>
            </div>
          ) : (
            <button type="button" className="btn btn-secondary btn-sm" onClick={() => startEdit(sectionKeys.account)}>
              {formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
            </button>
          )}
        </div>
        <p className="mob-p-p3 dt-p-p3">
          {formatAccountMessage({
            id: 'account.desc',
            defaultMessage: 'Manage how information is displayed on your account.',
          })}
        </p>

        <Field
          id="locale"
          name="locale"
          label={formatMessage({ id: 'language', defaultMessage: 'Language' })}
          value={data.locale}
          type="select"
          options={
            locales?.map((locale) => ({
              name: mapLocaleToMeaningfulFormat(locale).name,
              value: locale,
            })) || []
          }
          required={true}
          disabled={!inEdit.includes(sectionKeys.account)}
          onChange={(val) => handleChange('locale', val)}
        />
      </div>
      */}

      <div className="d-lg-none">
        {inEdit.length ? (
          <div className="d-flex">
            <button onClick={handleSubmitAll} className="btn btn-primary me-3">
              {formatMessage({ id: 'save', defaultMessage: 'Save' })}
            </button>
            <button onClick={handleResetAll} className="btn btn-secondary">
              {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
            </button>
          </div>
        ) : (
          <button onClick={startEditAll} className="btn btn-primary">
            {formatAccountMessage({ id: 'settings.edit', defaultMessage: 'Edit settings' })}
          </button>
        )}
      </div>
    </div>
  );
};

export default General;
