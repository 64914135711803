import React from 'react';
import { Reference, ReferenceLink } from 'helpers/reference';
import Image from 'frontastic/lib/image';

type Props = {
  pageTitle?: string;
  image?: { media: any } | any;
  title?: string;
  subtitle?: string;
  callToAction?: string;
  callToActionLink?: Reference;
};

export const EmptyState: React.FC<Props> = ({
  pageTitle,
  image,
  title,
  subtitle,
  callToAction,
  callToActionLink,
}: Props) => {
  return (
    <div className="mx-auto max-w-2xl px-2 pt-16 pb-24 sm:px-4 lg:max-w-7xl lg:px-8">
      {pageTitle && <h1 className="text-center">{pageTitle}</h1>}

      {image && (
        <div className="row justify-content-center mt-20">
          <div className="col-1">
            <Image
              media={image.media ? image.media : { media: '' }}
              src={!image.media ? image : ''}
              className="w-10"
              alt="Empty Wishlist"
            />
          </div>
        </div>
      )}

      {title && (
        <div className="mt-4 text-center">
          <h2 className="text-3xl font-bold text-gray-600 dark:text-light-100">{title}</h2>
        </div>
      )}

      {subtitle && (
        <div className="mt-2 w-auto text-center">
          <h2 className="h5 mb-3">{subtitle}</h2>
        </div>
      )}
      {callToActionLink && (
        <div className="mt-8 mb-24 text-center">
          <ReferenceLink target={callToActionLink}>
            <button className="btn btn-primary btn-lg">{callToAction}</button>
          </ReferenceLink>
        </div>
      )}
    </div>
  );
};
