import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-payment-klarna-sofort ${className}`}
    viewBox="0 0 731.9 272.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M686.1 272.1H0L45.7 0h686.2l-45.8 272.1z" fill="#2e3235" />
    <path
      fill="#e5e5e0"
      d="M218.8 158.5c-11.7 0-19.9-7.6-19.9-19.5 0-13.2 10.2-25.5 24.9-25.5 11.7 0 19.9 8 19.9 19.9.1 13.1-10.2 25.1-24.9 25.1zm6.9-67.8c-30.8 0-54.1 22.5-54.1 49.4 0 24.4 19.2 41.2 45.4 41.2 30.8 0 54.1-23.2 54.1-50.1-.1-24.3-19.2-40.5-45.4-40.5zM404.4 158.5c-11.7 0-19.9-7.6-19.9-19.5 0-13.2 10.2-25.5 24.9-25.5 11.7 0 19.9 8 19.9 19.9 0 13.1-10.2 25.1-24.9 25.1zm6.8-67.8c-30.8 0-54.1 22.5-54.1 49.4 0 24.4 19.2 41.2 45.4 41.2 30.8 0 54.1-23.2 54.1-50.1 0-24.3-19.2-40.5-45.4-40.5zM503.4 133.6h-4l3-18.2h3.6c8.5 0 13.1 2.6 13.1 8.1 0 6.8-6.5 10.1-15.7 10.1zm43.8-14.3c0-16.3-12.8-26.8-35.9-26.8h-32.7l-14.3 87h27.5l4.5-27.4h1.3l14.6 27.4h31.5l-20.2-30.4c14.8-5.1 23.7-16.2 23.7-29.8zM140.1 127.2c-10.4-5.2-12.8-6.6-12.8-9.4 0-3.5 4.1-5.2 9.9-5.2 6.6 0 16.7.7 26.5 10 2.4-7.5 6.4-14.2 11.6-20.1-13.9-7.8-26.7-11.8-38.5-11.8-23.5 0-36.9 12.5-36.9 27.5 0 12.9 9.8 19.7 21.5 25.6 10.4 5.4 13.1 7 13.1 10.3 0 3.5-4.4 5.5-10.2 5.5-10.1 0-21.7-6.3-28.6-12.7l-4 24.2c7.2 5.1 17.5 10.4 33 10.4 24.2 0 37.3-12.1 37.3-27.7-.2-12.8-8.6-19.8-21.9-26.6zM343.4 130.7h-32.2l2.5-15.2h34.5c4.5-9.2 11.4-17 20-22.9h-56.5c-12.5 0-23.2 8.5-25.1 20l-11 67h27.5l4.5-27.4h32.7l2.4-14.9c.1-2.2.3-4.4.7-6.6zM547.8 92.5c7.3 5.6 11.9 13.4 12.8 22.9h23l-10.6 64h27.5l10.6-64h25.5l3.8-22.9h-92.6z"
    />
  </svg>
);

export default Icon;
