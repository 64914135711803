import React from 'react';
import NextLink from 'next/link';
import { Category } from '@Types/product/Category';
import ProductPlaceholder from 'public/images/product-placeholder.jpg';
import Image from 'frontastic/lib/image';
import Typography from '../../typography';

export interface Props {
  tasticId: string;
  categories: Category[];
  totalCategories: number;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}
const SubCategoryList: React.FC<Props> = (props) => {
  const { tasticId, categories, background, innerSpacing, outerSpacing } = props;

  if (!categories || categories.length === 0) {
    return null;
  }

  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      <div className="bg-white rounded p-20 pb-0">
        <div className="row">
          {categories.map((category) => (
            <div className="col-6 col-sm-4 col-sm-3 col-xl-2 mb-20 d-flex" key={`${tasticId}${category.categoryId}`}>
              <div className="category-item category-item--large">
                <picture className="category-item__image">
                  <Image src={category.category_teaser_image_url ?? ProductPlaceholder.src} alt={category.name} />
                </picture>
                <NextLink href={category._url}>
                  <a className="category-item__link">
                    <Typography>{category.name}</Typography>
                  </a>
                </NextLink>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubCategoryList;
