import BrandList from '../../../../../components/snaq-ui/brands/brand-list';

function BrandListTastic({ id, data }) {
  if (!data?.data?.dataSource) return <></>;

  return (
    <BrandList
      tasticId={id}
      title={data.title}
      brands={data.data.dataSource}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
}

export default BrandListTastic;
