import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useFormat } from 'helpers/hooks/useFormat';
import { getReferenceTarget, Reference } from 'helpers/reference';
import { useAccount } from 'frontastic';

export interface ResetPasswordProps {
  token?: string | string[];
  accountLink?: Reference;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ token, accountLink }) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  //next/router
  const router = useRouter();

  //account actions
  const { resetPassword } = useAccount();

  //reset password data data
  const [data, setData] = useState({ password: '', confirmPassword: '' });

  //error
  const [error, setError] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);

  //handle text input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  //data validation
  const validate = () => {
    //validation schema
    const passwordsMatch = data.password === data.confirmPassword;

    //UI error messages
    if (!passwordsMatch)
      setError(formatErrorMessage({ id: 'password.noMatch', defaultMessage: "Passwords don't match" }));

    //return a boolean representing the data validity
    return passwordsMatch;
  };

  //form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //validate data
    if (!validate()) return;
    //processing starts
    setLoading(true);
    //try registering the user with given credentials
    try {
      const response = await resetPassword(token as string, data.password);
      if (!response.accountId) {
        setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
      } else {
        setError('');
        router?.push(getReferenceTarget(accountLink));
      }
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
    //processing ends
    setLoading(false);
  };

  return (
    <div className="section-spacing">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6">
          <h1 className="h3 mb-20 text-center">
            {formatAccountMessage({ id: 'password.reset.headline', defaultMessage: 'Reset your password' })}
          </h1>
          <h2 className="h5 mb-40 text-center">
            {formatAccountMessage({
              id: 'password.reset.desc',
              defaultMessage: 'Fill the fields below to complete your password reset',
            })}
          </h2>
          {error && (
            <div className="alert alert-danger mob-p-p3 dt-p-p3" role="alert">
              {error}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="password" className="form-label">
                {formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="new-password"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                required
                className="form-control form-control-lg"
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="password" className="form-label">
                {formatAccountMessage({ id: 'password.confirm', defaultMessage: 'Confirm Password' })}
              </label>
              <input
                id="confirm-password"
                name="confirmPassword"
                type="password"
                autoComplete="new-password"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                required
                className="form-control form-control-lg"
                onChange={handleChange}
              />
            </div>
            <button type="submit" className="btn btn-primary btn-lg w-100" disabled={loading}>
              {formatAccountMessage({ id: 'password.reset', defaultMessage: 'Reset password' })}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
