import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-check-outline ${className}`}
    viewBox="0 0 20.3 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.7 14 18.3 3.4 16.8 2l-9.2 9.2-4.2-4.3L2 8.4 7.7 14zm0 2c-.2 0-.5 0-.8-.1s-.5-.2-.7-.5L.6 9.8c-.2-.2-.4-.5-.5-.7-.1-.5-.1-1 0-1.5.1-.2.2-.5.4-.7L2 5.5c.4-.4.9-.6 1.4-.6.5 0 1.1.2 1.4.6l2.8 2.8L15.4.5c.2-.2.4-.3.7-.4.2-.1.5-.1.7-.1s.5 0 .8.1c.2.1.5.2.7.4L19.7 2c.4.4.6.9.6 1.4 0 .3 0 .5-.1.8-.1.2-.2.5-.4.7L9.1 15.4c-.2.2-.4.4-.7.5s-.5.1-.7.1z" />
  </svg>
);

export default Icon;
