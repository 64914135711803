import React from 'react';
import NextLink from 'next/link';
import useI18n from 'helpers/hooks/useI18n';
import { Reference, ReferenceLink } from 'helpers/reference';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import { sm, md, xl } from '../../../../helpers/utils/screensizes';
import Slider, { SliderProps } from '../../slider';

export type CategoryTeaser = {
  image: MediaItemWithMods;
  ctaLabel: string;
  categoryKey?: string;
  ctaReference?: Reference;
  ctaTitle?: string;
  ctaRel?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
};

export default function CategoryTeasers({ tasticId, items, categories, background, innerSpacing, outerSpacing }) {
  const { t } = useI18n();

  const sliderFixedMood: SliderProps = {
    slidesPerView: 3.25,
    breakpoints: {
      [sm]: {
        slidesPerView: 4.25,
      },
      [md]: {
        slidesPerView: 5.25,
      },
      [xl]: {
        slidesPerView: 7.75,
      },
    },
    arrows: true,
    dots: false,
  };

  const sliderConfiguration: SliderProps = sliderFixedMood;

  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      <div className="row">
        <div className="col-12">
          <Slider {...sliderConfiguration} className="swiper--mobile-full">
            {items.map((item: CategoryTeaser, index: number) => {
              if (item.categoryKey && Array.isArray(categories)) {
                const category = categories.find((category) => category.categoryId === item.categoryKey);
                if (category) {
                  return (
                    <div className="category-item" key={`${tasticId}${index}`}>
                      {item.image && (
                        <picture className="category-item__image">
                          <Image src={item.image.media.file} alt={item.image.media.name} />
                        </picture>
                      )}
                      <NextLink href={category.slug}>
                        <a title={item.ctaTitle || undefined} rel={item.ctaRel} className="category-item__link">
                          {t(item.ctaLabel)}
                        </a>
                      </NextLink>
                    </div>
                  );
                }
              } else if (item.ctaReference) {
                return (
                  <div className="category-item" key={`${tasticId}${index}`}>
                    {item.image && (
                      <picture className="category-item__image">
                        <Image src={item.image.media.file} alt={item.image.media.name} />
                      </picture>
                    )}
                    <ReferenceLink
                      target={item.ctaReference}
                      title={item.ctaTitle}
                      rel={item.ctaRel}
                      className="category-item__link"
                    >
                      {t(item.ctaLabel)}
                    </ReferenceLink>
                  </div>
                );
              }
              return null;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
