import React, { useState, FC, Children, useMemo } from 'react';
import classnames from 'classnames';
import { Navigation, Pagination, Thumbs, Scrollbar, FreeMode } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'; // eslint-disable-line import/no-unresolved
import { v4 as uuidv4 } from 'uuid';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { xl } from '../../../helpers/utils/screensizes';

export type SliderProps = {
  className?: string;
  spaceBetween?: number;
  slidesPerView?: number;
  arrows?: boolean;
  arrowsAlways?: boolean;
  dots?: boolean;
  scrollbar?: boolean;
  scrollbarMobile?: boolean;
  freeMode?: boolean;
  slideWidth?: number;
  withThumbs?: boolean;
} & SwiperProps;

const Slider: FC<SliderProps> = ({
  className,
  slideWidth,
  slidesPerView,
  arrows = false,
  arrowsAlways = false,
  dots = true,
  freeMode = true,
  scrollbar = true,
  scrollbarMobile = false,
  spaceBetween = 20,
  withThumbs,
  children,
  ...props
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const uuid = useMemo(() => {
    uuidv4();
  }, [children]);

  const slides = Children.toArray(children).map((child, index) => (
    <SwiperSlide key={'slide' + uuid + index} style={slideWidth ? { width: `${slideWidth}px` } : {}}>
      {child}
    </SwiperSlide>
  ));
  const thumbs = Children.toArray(children).map((child, index) => (
    <SwiperSlide key={'thumb' + uuid + index} className="slider__thumb" style={{ height: '80px', overflow: 'hidden' }}>
      {child}
    </SwiperSlide>
  ));

  const [isDesktopSize] = useMediaQuery(xl);
  let scrollBarActive = scrollbar;
  if (!scrollbarMobile && !isDesktopSize) scrollBarActive = false;

  const containerClassName = classnames('slider__container', {
    'slider__container--with-thumbs': withThumbs,
    'slider__container--with-scrollbar': scrollBarActive,
    'slider__container--with-arrows': arrows,
    'slider__container--arrows-always': arrowsAlways,
  });
  const slidesClassName = classnames('slider', className);
  const thumbsClassName = classnames('slider__thumbs');

  const mainSlider = (
    <Swiper
      className={slidesClassName}
      modules={[Navigation, Pagination, Thumbs, Scrollbar, FreeMode]}
      thumbs={{ swiper: thumbsSwiper }}
      scrollbar={scrollBarActive ? { draggable: true } : false}
      pagination={dots ? { clickable: true, bulletActiveClass: 'slider__bullet--active' } : false}
      slidesPerView={slidesPerView ?? 'auto'}
      spaceBetween={spaceBetween}
      navigation={arrows}
      freeMode={freeMode}
      {...props}
    >
      {slides}
    </Swiper>
  );

  return (
    <div className={containerClassName}>
      {withThumbs ? (
        <>
          <div className="slider__thumbs">
            <Swiper
              className={thumbsClassName}
              modules={[Navigation, Thumbs]}
              navigation
              spaceBetween={15}
              slidesPerView={5}
              direction={'vertical'}
              watchSlidesProgress
              onSwiper={setThumbsSwiper}
            >
              {thumbs}
            </Swiper>
          </div>
          <div className="slider__slides">{mainSlider}</div>
        </>
      ) : (
        mainSlider
      )}
    </div>
  );
};

export default Slider;
