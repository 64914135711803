import React from 'react';
import { Account } from '@Types/account/Account';
import { default as IconAccountCircle } from 'components/icons/account-circle';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';
import { logout } from 'frontastic/actions/account';

interface AccountButtonProps {
  accountLink: Reference;
  account: Account;
}

const AccountButton: React.FC<AccountButtonProps> = ({ accountLink, account }) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="short-link">
      <ReferenceLink target={accountLink} className="short-link__item">
        <div className="short-link__icon">{IconAccountCircle({})}</div>
        <div className="short-link__text">
          {formatAccountMessage({
            id: 'account',
            defaultMessage: 'Account',
          })}
        </div>
      </ReferenceLink>
    </div>
  );
};

export default AccountButton;
