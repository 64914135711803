import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Image from 'frontastic/lib/image';
import { PageNodeTeaser } from '../../../../frontastic/tastics/snaq/categories/category-experts';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import ExpertContactForms from '../../expert-contact';
import Typography from '../../typography';

export interface Props {
  expertTeaser: PageNodeTeaser;
  ctaLabel: string;
  ctaTitle?: string;
  alignment: string;
  background: string;
  paddingTop: string;
  paddingBottom: string;
}

export default function ExpertButton({
  expertTeaser,
  ctaLabel,
  ctaTitle,
  alignment,
  background,
  paddingTop,
  paddingBottom,
}: Props) {
  const { formatMessage } = useFormat({ name: 'common' });

  const [expertShow, setExpertShow] = useState<boolean>(false);

  return (
    <>
      <div className={`d-flex ${alignment} ${background} ${paddingTop} ${paddingBottom}`}>
        <button
          className="btn btn-primary"
          title={ctaTitle || ''}
          onClick={() => {
            setExpertShow(true);
          }}
        >
          <Typography>{ctaLabel}</Typography>
        </button>
      </div>
      <Offcanvas
        show={expertShow}
        onHide={() => {
          setExpertShow(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {formatMessage({
              id: 'consulting',
              defaultMessage: 'Consulting',
            })}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="bg-grey-10 p-20 rounded mb-20">
            <div className="row align-items-center">
              <div className="col-3">
                <div className="ratio ratio-1x1">
                  <Image
                    media={expertTeaser.image ? expertTeaser.image : expertTeaser.v3image}
                    className="object-fit-cover rounded-circle"
                    layout="fill"
                    alt={
                      expertTeaser.image?.media?.name
                        ? expertTeaser.image.media.name
                        : expertTeaser.v3image?.media?.name || ''
                    }
                  />
                </div>
              </div>
              <div className="col-9">
                <div className="mob-p-p3 dt-p-3 fw-bold">
                  {expertTeaser.title ? expertTeaser.title : expertTeaser.v3title}
                </div>
              </div>
            </div>
            <hr />
            {expertTeaser.description ? expertTeaser.description : expertTeaser.v3description}
          </div>
          <ExpertContactForms expertEmail={expertTeaser.email} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
