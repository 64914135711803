import {
  Product,
  ProductAssociationType,
  ProductAssociationValue,
  ProductAssociationValueItem,
} from '@Types/product/Product';
import { useMemo } from 'react';
import ProductSlider from 'components/snaq-ui/products/slider';

function AssociatedProductsTastic({ data }) {
  const { associationsTypeValue } = data;
  const {
    productAssociations,
    associatedProducts,
  }: {
    productAssociations: [ProductAssociationType, ProductAssociationValue][];
    associatedProducts?: Product[];
  } = data?.data?.dataSource;

  const products = useMemo<Product[]>(() => {
    if (!associationsTypeValue || !associatedProducts) {
      return [];
    }
    const associationsTypeValues = associationsTypeValue.split(',').map((item) => item.trim());

    const productAssociationTargets = productAssociations.filter((productAssociation) =>
      associationsTypeValues.includes(productAssociation[0].value),
    );

    const associatedProductIds: string[] = [];

    productAssociationTargets.forEach((productAssociationTarget) => {
      const ids = productAssociationTarget[1].value.map((val: ProductAssociationValueItem) => val.id);
      const newIds = ids.filter((id) => !associatedProductIds.includes(id));
      associatedProductIds.push(...newIds);
    });

    return associatedProducts.filter((product: Product) => associatedProductIds.includes(product.id));
  }, [associationsTypeValue, productAssociations, associatedProducts]);

  if (products.length === 0) return null;

  return (
    <ProductSlider
      products={products}
      title={data.title}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
}

export default AssociatedProductsTastic;
