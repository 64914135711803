import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    width="100%"
    height="100%"
    className={`icon icon-leaf ${className}`}
    viewBox="0 0 17 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.4 14.6C1.6 13.8 1.1 13 .6 12S0 10 0 8.9s.2-2.1.6-3.1 1-2 1.9-2.9c.6-.6 1.3-1.1 2.2-1.5S6.6.7 7.8.4s2.5-.4 4-.4 3.2 0 5.1.2c.1 1.8.1 3.4.1 4.9s-.2 2.8-.4 4-.5 2.2-.9 3.1c-.4.9-.9 1.6-1.5 2.2-.9.9-1.8 1.5-2.8 1.9-1 .4-2 .6-3 .6-1.1 0-2.1-.2-3.2-.6-1.2-.4-2-1-2.8-1.7zm2.8-.4c.5.3 1 .5 1.5.6s1 .2 1.6.2c.8 0 1.5-.2 2.3-.5s1.5-.8 2.1-1.5c.3-.3.6-.7.9-1.3.3-.5.6-1.2.8-2.1.2-.9.4-1.9.5-3.2.1-1.2.1-2.6 0-4.4h-2.8c-1 0-2 .1-3.1.2-1 .1-2 .4-2.9.7S4.6 3.8 4 4.3c-.8.8-1.3 1.5-1.6 2.3S2 8 2 8.7c0 1 .2 1.8.6 2.6.4.7.7 1.3 1 1.6.7-1.3 1.6-2.6 2.8-3.8s2.5-2.2 4-3C9.2 7 8.1 8.2 7.2 9.6s-1.5 2.8-2 4.6z" />
  </svg>
);

export default Icon;
