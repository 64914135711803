import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-star ${className}`}
    viewBox="0 0 600 600"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M267.8 20.9 194.6 176 30.7 201c-29.4 4.5-41.2 42.3-19.9 64l118.5 120.7-28 170.5c-5 30.8 26 53.9 52 39.5L300 515.1l146.6 80.5c26 14.3 57.1-8.7 52-39.5l-28-170.5 118.5-120.7c21.3-21.7 9.5-59.5-19.9-64l-163.9-25-73.1-155c-13.1-27.7-51.2-28-64.4 0z" />
  </svg>
);

export default Icon;
