import React from 'react';
import classnames from 'classnames';
import parse from 'html-react-parser';
import { useFormat } from '../../../../helpers/hooks/useFormat';

export interface BrandFooterProps {
  country?: string;
  seoText?: string;
  standardsAndCertifications?: {
    code: string;
    label: string;
  }[];
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

const BrandFooter: React.FC<BrandFooterProps> = ({
  country,
  seoText,
  standardsAndCertifications,
  background,
  innerSpacing,
  outerSpacing,
}) => {
  const { formatMessage } = useFormat({ name: 'common' });
  if (!country && !standardsAndCertifications && !seoText) {
    return null;
  }
  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      {country && (
        <div className="mob-p-p4 dt-p-p3 fw-bold">
          {formatMessage({ id: 'companyLocation', defaultMessage: 'Company location' })}: {country}
        </div>
      )}
      {standardsAndCertifications && standardsAndCertifications.length > 0 && (
        <div className={classnames(country && 'mt-4')}>
          <div className="mb-1 mob-p-p4 dt-p-p3 fw-bold">
            {formatMessage({
              id: 'isoCertification',
              defaultMessage: 'ISO certification',
            })}
            :
          </div>
          <ul>
            {standardsAndCertifications.map((item, index) => (
              <li key={'brand-cert' + index}>{item.label}</li>
            ))}
          </ul>
        </div>
      )}
      {(country || standardsAndCertifications) && seoText && <hr className="my-30" />}
      {seoText && <div className="mob-p-p3 dt-p-p3">{parse(seoText)}</div>}
    </div>
  );
};

export default BrandFooter;
