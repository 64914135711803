import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-tune ${className}`}
    viewBox="0 0 36 36"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.4,36V24.8h3v4.1H36v3H18.4V36H15.4z M0,31.9v-3h12.4v3H0z M9.4,23.6v-4.1H0v-3h9.4v-4.2h3v11.3H9.4z M15.4,19.5v-3H36v3 H15.4z M23.6,11.2V0h3v4.1H36v3h-9.4v4.2H23.6z M0,7.1v-3h20.6v3H0z" />
  </svg>
);

export default Icon;
