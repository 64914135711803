import React, { useState } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import Address from '../address';
import CreateAddressModal from '../modals/createAddress';

const Addresses = () => {
  //18in messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  //account data
  const { account } = useAccount();

  //user addresses
  const addresses = account?.addresses;

  //create address modal
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const openCreateModal = () => {
    setCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };

  return (
    <>
      <h2>{formatAccountMessage({ id: 'address.myAddresses', defaultMessage: 'My Addresses' })}</h2>
      <p className="mob-p-p3 dt-p-p3">
        {formatAccountMessage({
          id: 'address.desc',
          defaultMessage: 'Manage or add delivery addresses for your account',
        })}
      </p>
      <button type="button" className="btn btn-primary" onClick={openCreateModal}>
        {formatAccountMessage({ id: 'address.add', defaultMessage: 'Add an address' })}
      </button>
      <dl className="m-0">
        {addresses?.map((address) => (
          <Address key={address.addressId} address={address} />
        ))}
      </dl>
      <CreateAddressModal open={createModalOpen} onClose={closeCreateModal} />
    </>
  );
};

export default Addresses;
