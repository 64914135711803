import React, { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import Button from 'react-bootstrap/Button';
import { default as IconArrowBack } from 'components/icons/arrow-back';
import { default as IconVisibility } from 'components/icons/visibility';
import { default as IconVisibilityOff } from 'components/icons/visibility-off';
import { useFormat } from 'helpers/hooks/useFormat';
import Redirect from 'helpers/redirect';
import { Reference, ReferenceLink } from 'helpers/reference';
import { useAccount } from 'frontastic';

export interface LoginProps {
  registerLink?: Reference;
  accountLink?: Reference;
}

const Login: React.FC<LoginProps> = ({ registerLink, accountLink }) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });
  const router = useRouter();
  const { redirect } = router.query;

  //account actions
  const { login, loggedIn, account, requestConfirmationEmail, requestPasswordReset } = useAccount();

  //login data
  const [data, setData] = useState({ email: '', password: '', rememberMe: false });

  //error
  const [error, setError] = useState('');

  //error additional info
  const [errorAdditional, setErrorAdditional] = useState('');

  //success
  const [success, setSuccess] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);

  //attempting to resend verification email
  const [resendVerification, setResendVerification] = useState(false);

  //attempting to request a password reset
  const [resendPasswordReset, setResendPasswordReset] = useState(false);

  //not on default login modal
  const subModal = resendVerification || resendPasswordReset;

  //Password is visible while typing
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  //Toggles password visibility
  const togglePasswordVisibility = useCallback(() => setIsPasswordVisible(!isPasswordVisible), [isPasswordVisible]);

  //get back to login modal
  const backToLogin = () => {
    setResendPasswordReset(false);
    setResendVerification(false);
  };

  //wants to resend verification
  const toResendVerification = () => {
    setResendVerification(true);
    setResendPasswordReset(false);
  };

  //requesting a password reset
  const toResendPassword = () => {
    setResendPasswordReset(true);
    setResendVerification(false);
  };

  //handle text input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  //handle checkbox input change
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.checked });
  };

  //login user
  const loginUser = async () => {
    try {
      const response = await login(data.email, data.password);
      if (!response.accountId)
        setError(formatErrorMessage({ id: 'auth.wrong', defaultMessage: 'Wrong email address or password' }));
      setErrorAdditional(
        formatErrorMessage({
          id: 'auth.wrongAdditional',
          defaultMessage: 'Ggf. musst du noch deine Anmeldung bestätigen. Schaue dazu in deinen Mails nach.',
        }),
      );
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
      setErrorAdditional('');
    }
  };

  //resend verification email for user
  const resendVerificationEmailForUser = async () => {
    try {
      await requestConfirmationEmail(data.email, data.password);
      setSuccess(
        formatAccountMessage({
          id: 'verification.resent',
          defaultMessage: 'An email was sent to {email}',
          values: { email: data.email },
        }),
      );
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
      setErrorAdditional('');
    }
  };

  //request a password reset for user
  const resendPasswordResetForUser = async () => {
    try {
      await requestPasswordReset(data.email);
      setSuccess(
        formatAccountMessage({
          id: 'verification.resent',
          defaultMessage: 'An email was sent to {email}',
          values: { email: data.email },
        }),
      );
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
      setErrorAdditional('');
    }
  };

  //form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //processing starts
    setLoading(true);
    //if user is attempting to resend verification email
    if (resendVerification) {
      await resendVerificationEmailForUser();
    }
    //if user is attempting tor equest a password reset
    else if (resendPasswordReset) {
      await resendPasswordResetForUser();
    }
    //if user wants to login
    else {
      await loginUser();
    }
    //processing ends
    setLoading(false);
  };

  if (!loading && loggedIn) {
    const locale = account?.locale?.split(/[^A-Za-z]/)[0] || '';
    const lang = locale === 'en' ? '/en' : '';
    const accountLinkModified = {
      link: undefined,
      ...accountLink,
    };
    if ('link' in accountLink) {
      accountLinkModified.link = lang + accountLink.link;
    }
    return <Redirect target={redirect ? decodeURIComponent(lang + redirect.toString()) : accountLinkModified} />;
  }

  return (
    <div className="section-spacing">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6">
          <form onSubmit={handleSubmit}>
            <div className="text-center mb-40">
              <h1>
                {resendPasswordReset
                  ? formatAccountMessage({ id: 'password.reset.headline', defaultMessage: 'Reset your password' })
                  : formatAccountMessage({ id: 'account.sign.in', defaultMessage: 'Sign in to your account' })}
              </h1>
              {!subModal && (
                <h2 className="h5 fw-normal">
                  {formatAccountMessage({ id: 'details.enter', defaultMessage: 'Please enter your details' })}
                </h2>
              )}
            </div>

            {success && (
              <div className="alert alert-success mob-p-p3 dt-p-p3" role="alert">
                {success}
              </div>
            )}
            {error && (
              <div className="alert alert-danger mob-p-p3 dt-p-p3" role="alert">
                {error}
                {errorAdditional && <small className="d-block">{errorAdditional}</small>}
              </div>
            )}

            <div className="mb-4">
              <label htmlFor="email" className="form-label">
                {formatMessage({ id: 'email', defaultMessage: 'Email' })}
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder={formatAccountMessage({ id: 'email.enter', defaultMessage: 'Enter your email' })}
                required
                className="form-control form-control-lg"
                onChange={handleChange}
              />
            </div>

            {!resendPasswordReset && (
              <>
                <label htmlFor="password" className="form-label">
                  {formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
                </label>
                <div className="input-group-absolute input-group-absolute--password">
                  <div className="relative mt-1">
                    <input
                      id="password"
                      name="password"
                      type={isPasswordVisible ? 'text' : 'password'}
                      autoComplete="current-password"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      required
                      className="form-control form-control-lg"
                      onChange={handleChange}
                    />
                    <span className="input-group-absolute__item" onClick={togglePasswordVisibility}>
                      {!isPasswordVisible ? IconVisibilityOff({}) : IconVisibility({})}
                    </span>
                  </div>
                </div>
              </>
            )}

            {subModal ? (
              <Button className="btn btn-outline btn-sm mb-4" onClick={backToLogin}>
                {IconArrowBack({})} {formatMessage({ id: 'back', defaultMessage: 'Back' })}
              </Button>
            ) : (
              <div className="d-flex flex-column align-items-md-center flex-md-row justify-content-between py-4">
                <div className="form-check">
                  <input
                    id="remember-me"
                    name="rememberMe"
                    type="checkbox"
                    className="form-check-input"
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="remember-me" className="form-check-label">
                    {formatMessage({ id: 'rememberMe', defaultMessage: 'Remember me' })}
                  </label>
                </div>
                <div className="mob-p-p4 dt-p-p4 link-reset" onClick={toResendPassword}>
                  {formatAccountMessage({ id: 'password.forgot', defaultMessage: 'Forgot your password?' })}
                </div>
                {/* <div className="flex items-center justify-end">
                <div className="text-sm">
                  <span
                    className="cursor-pointer font-medium text-accent-400 hover:text-accent-500"
                    onClick={toResendVerification}
                  >
                    {formatAccountMessage({
                      id: 'verification.resend',
                      defaultMessage: 'Bestätigungsmail erneut senden',
                    })}
                  </span>
                </div>
              </div> */}
              </div>
            )}
            <div>
              <button type="submit" className="btn btn-primary btn-lg w-100" disabled={loading}>
                {subModal
                  ? formatMessage({ id: 'submit', defaultMessage: 'Submit' })
                  : formatAccountMessage({ id: 'sign.in', defaultMessage: 'Sign in' })}
              </button>
              <p className="mt-4 text-center text-sm text-neutral-600">
                {formatAccountMessage({ id: 'account.doNotHave', defaultMessage: "Don't have an account?" })}{' '}
                <ReferenceLink
                  target={registerLink}
                  className="font-medium text-accent-400 underline hover:text-accent-500"
                >
                  {formatAccountMessage({ id: 'account.register.here', defaultMessage: 'Register here' })}
                </ReferenceLink>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
