import { useMemo } from 'react';
import { Reference, ReferenceLink } from 'helpers/reference';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import { md } from '../../../../helpers/utils/screensizes';

export interface MediaImageProps {
  image: MediaItemWithMods;
  imageMobile?: MediaItemWithMods;
  width: string;
  alignment: string;
  title?: string;
  caption?: string;
  ctaReference?: Reference;
  ctaTitle?: string;
  ctaRel?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

const MediaImage: React.FC<MediaImageProps> = ({
  image,
  imageMobile,
  width,
  alignment,
  title,
  caption,
  ctaReference,
  ctaTitle,
  ctaRel,
  background,
  innerSpacing,
  outerSpacing,
}) => {
  const [isTabletSize] = useMediaQuery(md);

  const containerClass = useMemo(() => {
    if (alignment === 'bl') {
      return 'd-flex justify-content-start w-100';
    }
    if (alignment === 'bc') {
      return 'd-flex justify-content-center w-100';
    }
    if (alignment === 'br') {
      return 'd-flex justify-content-end w-100';
    }
    let cssClass = '';
    if (alignment === 'fl') {
      cssClass = 'float-start me-5';
    }
    if (alignment === 'fr') {
      cssClass = 'float-end ms-5';
    }
    if (width === 'w25') {
      cssClass += ' w-25';
    }
    if (width === 'w50') {
      cssClass += ' w-50';
    }
    if (width === 'w75') {
      cssClass += ' w-75';
    }
    return cssClass;
  }, [width, alignment]);

  const figureClass = useMemo(() => {
    if (alignment === 'fl' || alignment === 'fr') {
      return 'w-100';
    }
    if (width === 'w25') {
      return 'w-25';
    }
    if (width === 'w50') {
      return 'w-50';
    }
    if (width === 'w75') {
      return 'w-75';
    }
    return 'w-100';
  }, [width, alignment]);

  if (!image) {
    return null;
  }

  return (
    <div className={`${containerClass} ${background} ${innerSpacing} ${outerSpacing}`}>
      <figure className={`mb-0 ${figureClass}`}>
        <div className="rounded overflow-hidden">
          {ctaReference ? (
            <ReferenceLink target={ctaReference} title={ctaTitle} rel={ctaRel}>
              <Image media={imageMobile?.media && !isTabletSize ? imageMobile : image} alt={title} />
            </ReferenceLink>
          ) : (
            <Image media={imageMobile?.media && !isTabletSize ? imageMobile : image} alt={title} />
          )}
        </div>
        {caption && <figcaption className="d-block pt-20">{caption}</figcaption>}
      </figure>
    </div>
  );
};

export default MediaImage;
