import React from 'react';
import { LineItem } from '@Types/wishlist/LineItem';
import { Variant } from '@Types/wishlist/Variant';
import WishlistCard from './wishlist-card';

export interface Props {
  items?: LineItem[];
  removeLineItems: (lineItem: LineItem) => void;
  addToCart: (variant: Variant) => Promise<void>;
}

const List: React.FC<Props> = ({ items, removeLineItems, addToCart }) => {
  return (
    <div className="row">
      {items.map((item, index) => (
        <div key={item.lineItemId} className="col-12 col-sm-6 col-lg-4 col-xxl-3 d-flex">
          <WishlistCard
            item={item}
            removeLineItems={removeLineItems}
            addToCart={addToCart}
            itemIndex={index}
            itemsCount={items.length}
          />
        </div>
      ))}
    </div>
  );
};

export default List;
