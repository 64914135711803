import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Reference, ReferenceLink } from 'helpers/reference';

export interface Link {
  name: string;
  reference: Reference;
  linkTitle?: string;
  linkRel?: string;
}

export interface Column {
  header: string;
  links: Link[];
}

export interface Props {
  column: Column;
  index: number;
}

const RenderColumn: React.FC<Props> = ({ column, index }) => {
  return (
    <Accordion.Item eventKey={index.toString()} className="footer-nav__item">
      <Accordion.Header className="footer-nav__header mob-c-c1 dt-c-c2">{column.header}</Accordion.Header>
      <Accordion.Body className="footer-nav__body">
        <ul className="list-unstyled footer-nav__ul">
          {column.links.map((item, i) => (
            <li key={i} className="footer-nav__li">
              <ReferenceLink
                target={item.reference}
                title={item.linkTitle}
                rel={item.linkRel}
                className="footer-nav__a link-reset mob-c-c3 dt-c-c3"
              >
                {item.name}
              </ReferenceLink>
            </li>
          ))}
        </ul>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default RenderColumn;
