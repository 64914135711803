import { Cart } from '@Types/cart/Cart';
import { CartDetails } from 'frontastic/actions/cart';
import { FormData } from '.';

export const mapToCartStructure = (
  data: FormData,
  billingIsSameAsShipping: boolean,
  selectedShippingAddressId: string,
  selectedBillingAddressId: string,
  isAnonymous: boolean,
): CartDetails => {
  const mappedData: CartDetails = {
    account: {
      email: data.email,
    },
    shipping: {
      addressId: selectedShippingAddressId || '',
      salutation: data.shippingSalutation,
      title: data.shippingTitle,
      firstName: data.shippingFirstName,
      lastName: data.shippingLastName,
      company: data.shippingCompany,
      department: data.shippingDepartment,
      additionalAddressInfo: data.shippingAdditionalAddressInfo,
      streetName: data.shippingStreetName,
      streetNumber: data.shippingStreetNumber,
      postalCode: data.shippingPostalCode,
      city: data.shippingCity,
      country: data.shippingCountry,
    },
  };
  if (billingIsSameAsShipping) {
    mappedData.billing = {
      ...mappedData.shipping,
    };
    if (isAnonymous) {
      mappedData.vatId = data.shippingVatId;
    }
  } else {
    mappedData.billing = {
      addressId: selectedBillingAddressId || '',
      salutation: data.billingSalutation,
      title: data.billingTitle,
      firstName: data.billingFirstName,
      lastName: data.billingLastName,
      company: data.billingCompany,
      department: data.billingDepartment,
      additionalAddressInfo: data.billingAdditionalAddressInfo,
      streetName: data.billingStreetName,
      streetNumber: data.billingStreetNumber,
      postalCode: data.billingPostalCode,
      city: data.billingCity,
      country: data.billingCountry,
    };
    if (isAnonymous) {
      mappedData.vatId = data.billingVatId;
    }
  }

  return mappedData;
};

export const mapToFormStructure = (data: Cart, isAnonymous: boolean): FormData => {
  if (!data?.shippingAddress) return;

  const vatId = data.custom?.fields?.vat_id || null;

  return {
    email: data.email,
    shippingSalutation: data.shippingAddress.salutation,
    shippingTitle: data.shippingAddress.title,
    shippingFirstName: data.shippingAddress.firstName,
    shippingLastName: data.shippingAddress.lastName,
    shippingCompany: data.shippingAddress.company,
    shippingVatId: isAnonymous && vatId ? vatId : undefined,
    shippingDepartment: data.shippingAddress.department,
    shippingAdditionalAddressInfo: data.shippingAddress.additionalAddressInfo,
    shippingStreetName: data.shippingAddress.streetName,
    shippingStreetNumber: data.shippingAddress.streetNumber,
    shippingPostalCode: data.shippingAddress.postalCode,
    shippingCity: data.shippingAddress.city,
    shippingCountry: data.shippingAddress.country,

    billingSalutation: data.billingAddress.salutation,
    billingTitle: data.billingAddress.title,
    billingFirstName: data.billingAddress.firstName,
    billingLastName: data.billingAddress.lastName,
    billingCompany: data.billingAddress.company,
    billingVatId: isAnonymous && vatId ? vatId : undefined,
    billingDepartment: data.billingAddress.department,
    billingAdditionalAddressInfo: data.billingAddress.additionalAddressInfo,
    billingStreetName: data.billingAddress.streetName,
    billingStreetNumber: data.billingAddress.streetNumber,
    billingPostalCode: data.billingAddress.postalCode,
    billingCity: data.billingAddress.city,
    billingCountry: data.billingAddress.country,
  } as FormData;
};
