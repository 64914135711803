import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Order } from '@Types/cart/Order';
import CheckoutForm from './CheckoutForm';
import { PaymentProvider } from '../payment';

interface Props {
  currentPaymentProvider: PaymentProvider | null;
  updatePaymentProviderHandler: (paymentProvider: PaymentProvider | null) => void;
  readyHandler: (stripeReady: boolean) => void;
  pubKey?: string;
  cart?: Order;
}

const Payment: React.FC<Props> = ({
  currentPaymentProvider,
  updatePaymentProviderHandler,
  readyHandler,
  pubKey,
  cart,
}) => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    if (!pubKey || !!stripePromise) {
      return;
    }
    setStripePromise(
      // loadStripe('pk_test_51NWKydCZnqXVoon17eNixgSwUav3RVzPY87euFcPbwzYRkavCCuphpGaUcBon0BKulB7Ft3vhdm3QrTG2UpMzNbN00aXfODZR5'),
      loadStripe(pubKey),
    );
  }, [pubKey, stripePromise]);

  const appearance = {
    variables: {
      fontFamily: 'Noto Sans, system-ui, sans-serif',
      colorText: '#333',
      colorPrimary: '#EB5961',
    },
    rules: {
      '.Text': {
        fontWeight: '400',
      },
      '.AccordionItem': {
        borderColor: '#d6d6d6',
        borderRadius: '5px',
        boxShadow: 'unset',
        padding: '20px',
        fontSize: '16px',
        color: '#333',
        fontWeight: '400',
      },
      '.AccordionItem--selected': {
        color: '#333',
      },
    },
  };

  return (
    <>
      {cart.totalGross && stripePromise && (
        <Elements
          stripe={stripePromise}
          options={{
            mode: 'payment',
            amount: cart.totalGross.centAmount,
            currency: cart.totalGross.currencyCode.toLowerCase(),
            appearance: appearance,
          }}
        >
          <CheckoutForm
            currentPaymentProvider={currentPaymentProvider}
            updatePaymentProviderHandler={updatePaymentProviderHandler}
            readyHandler={readyHandler}
          />
        </Elements>
      )}
    </>
  );
};

export default Payment;
