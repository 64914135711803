import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import NextLink from 'next/link';
import { LineItem } from '@Types/cart/LineItem';
import { Order } from '@Types/cart/Order';
import { Variant } from '@Types/product/Variant';
import classnames from 'classnames';
import Accordion from 'react-bootstrap/Accordion';
import { default as IconCheck } from 'components/icons/check';
import { default as IconPdf } from 'components/icons/pdf';
import Price from 'components/snaq-ui/price';
import Spinner from 'components/snaq-ui/spinner';
import { useFormat } from 'helpers/hooks/useFormat';
import { getFiles } from 'helpers/utils/getFiles';
import { useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
import { DOCUMENTS_DOWNLOAD_PATH } from '../../../../../helpers/constants/paths';

export interface Props {
  orders?: Order[];
}

const OrdersHistory: FC<Props> = ({ orders }) => {
  const [accountOrdersState, setAccountOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  //account data
  const { data: cartList, orderHistory, addItem } = useCart();

  const getImageThumb = (url: string, size: string) => {
    return url.replaceAll('200x200', size);
  };

  useEffect(() => {
    if (orderHistory) {
      orderHistory().then((data) => {
        setAccountOrders(data);
        setLoading(false);
      });
    } else {
      setAccountOrders(orders);
      setLoading(false);
    }
  }, [orders, orderHistory]);
  //18in messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatOrderMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage } = useFormat({ name: 'common' });

  const [addingToCart, setAddingToCart] = useState<string | null>(null);
  const [addedToCart, setAddedToCart] = useState<string | null>(null);

  const handleAddToCart = useCallback(
    async (name: string, variant: Variant, orderQty): Promise<void> => {
      if (loading || (variant.isEcomSaleItem && !variant.isOnStock) || orderQty < 1) return;
      setAddingToCart(variant.sku);
      await addItem(variant, orderQty);
      setAddingToCart(null);
      setAddedToCart(variant.sku);
    },
    [loading, addItem],
  );

  useEffect(() => {
    if (addedToCart) {
      setTimeout(() => {
        setAddedToCart(null);
      }, 1000);
    }
  }, [addedToCart]);

  return (
    <div>
      <h2>{formatAccountMessage({ id: 'my.orders.history', defaultMessage: 'My order history' })}</h2>
      <p className="mob-p-p3 dt-p-p3">
        {formatAccountMessage({
          id: 'orders.desc',
          defaultMessage: 'Check the status of recent orders, manage returns, and download invoices.',
        })}
      </p>
      {loading ? (
        <div className="d-flex justify-content-center p-40">
          <Spinner />
        </div>
      ) : accountOrdersState && accountOrdersState.length ? (
        <section aria-labelledby="recent-heading">
          <h3 id="recent-heading" className="visually-hidden">
            {formatAccountMessage({ id: 'orders.history', defaultMessage: 'Order history' })}
          </h3>
          <ul className="list-unstyled">
            {accountOrdersState?.map((order) => {
              const orderHasDocuments =
                (order.custom?.fields?.order_documents && order.custom.fields.order_documents.length > 0) ||
                (order.custom?.fields?.delivery_documents && order.custom.fields.delivery_documents.length > 0) ||
                (order.custom?.fields?.billing_documents && order.custom.fields.billing_documents.length > 0);
              let discountPrice = order?.lineItems?.reduce((a, b) => {
                return (
                  a +
                  b.discounts.reduce((x, y) => {
                    return x + y.discountedAmount.centAmount * (y.quantity ?? 1);
                  }, 0)
                );
              }, 0);
              if (order?.discountOnTotalPrice) {
                discountPrice += order.discountOnTotalPrice.centAmount;
              }
              const priceFractionDigits =
                order?.lineItems && order?.lineItems[0] ? order.lineItems[0].price?.fractionDigits ?? 0 : 0;
              return (
                <li key={order.cartId}>
                  <div className="rounded bg-grey-10 p-20 mb-20">
                    <div className="row">
                      <div className={classnames('col col-12', orderHasDocuments && 'col-xl-6')}>
                        <h5 className="mb-3">
                          {formatAccountMessage({
                            id: 'orders.details',
                            defaultMessage: 'Order details',
                          })}
                        </h5>
                        <dl className="mb-0">
                          <dt>
                            {formatAccountMessage({
                              id: 'orders.id',
                              defaultMessage: 'Order ID',
                            })}
                            :
                          </dt>
                          <dd>{order.orderId || '—'}</dd>
                          <dt>
                            {formatAccountMessage({
                              id: 'orders.date',
                              defaultMessage: 'Order date',
                            })}
                            :
                          </dt>
                          <dd>
                            <time dateTime={order.createdAt.toString()}>
                              {new Date(order.createdAt).toLocaleString('de-DE', {
                                year: '2-digit',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                              })}
                            </time>
                          </dd>
                          <dt>
                            {formatAccountMessage({
                              id: 'orders.status',
                              defaultMessage: 'Status',
                            })}
                            :
                          </dt>
                          <dd>
                            {order.orderState.toLowerCase() === 'open' &&
                              formatAccountMessage({
                                id: 'orders.status.open',
                                defaultMessage: 'Open',
                              })}
                            {order.orderState.toLowerCase() === 'confirmed' &&
                              formatAccountMessage({
                                id: 'orders.status.confirmed',
                                defaultMessage: 'Confirmed',
                              })}
                            {order.orderState.toLowerCase() === 'complete' &&
                              formatAccountMessage({
                                id: 'orders.status.complete',
                                defaultMessage: 'Complete',
                              })}
                            {order.orderState.toLowerCase() === 'cancelled' &&
                              formatAccountMessage({
                                id: 'orders.status.open',
                                defaultMessage: 'Cancelled',
                              })}
                          </dd>
                          {order.custom?.fields?.order_tracking_numbers &&
                            order.custom.fields.order_tracking_numbers.length > 0 && (
                              <>
                                <dt>
                                  {formatAccountMessage({
                                    id: 'orders.your.tracking.no',
                                    defaultMessage: 'Your tracking numbers',
                                  })}
                                  :
                                </dt>
                                <dd>{order.custom.fields.order_tracking_numbers.join(', ')}</dd>
                              </>
                            )}
                        </dl>
                      </div>

                      {orderHasDocuments && (
                        <div className="col col-12 col-xl-6 mt-4 mb-3 my-xl-0">
                          <h5 className="mb-3">
                            {formatAccountMessage({
                              id: 'orders.your.documents',
                              defaultMessage: 'Your documents',
                            })}
                          </h5>
                          <dl className="mb-0">
                            {order.custom?.fields?.order_documents &&
                              order.custom.fields.order_documents.length > 0 && (
                                <>
                                  <dt className="pb-6">
                                    {formatAccountMessage({
                                      id: 'orders.order.data',
                                      defaultMessage: 'Order data',
                                    })}
                                    :
                                  </dt>
                                  <div className="d-flex flex-wrap">
                                    {order.custom.fields.order_documents.map((doc, index) => (
                                      <dd key={doc + index}>
                                        <a
                                          href={DOCUMENTS_DOWNLOAD_PATH + doc}
                                          target="_blank"
                                          className="d-inline-flex link-reset"
                                          rel="noreferrer"
                                        >
                                          {IconPdf({ className: 'dt-p-p1 me-2 text-primary' })}
                                        </a>
                                      </dd>
                                    ))}
                                  </div>
                                </>
                              )}
                            {order.custom?.fields?.delivery_documents &&
                              order.custom.fields.delivery_documents.length > 0 && (
                                <>
                                  <dt className="pb-6">
                                    {formatAccountMessage({
                                      id: 'orders.delivery.notes',
                                      defaultMessage: 'Delivery notes',
                                    })}
                                    :
                                  </dt>
                                  <div className="d-flex flex-wrap">
                                    {order.custom.fields.delivery_documents.map((doc, index) => (
                                      <dd key={doc + index}>
                                        <a
                                          href={DOCUMENTS_DOWNLOAD_PATH + doc}
                                          target="_blank"
                                          className="d-inline-flex link-reset"
                                          rel="noreferrer"
                                        >
                                          {IconPdf({ className: 'dt-p-p1 me-2 text-primary' })}
                                        </a>
                                      </dd>
                                    ))}
                                  </div>
                                </>
                              )}
                            {order.custom?.fields?.billing_documents &&
                              order.custom.fields.billing_documents.length > 0 && (
                                <>
                                  <dt className="pb-6">
                                    {formatAccountMessage({
                                      id: 'orders.invoices',
                                      defaultMessage: 'Invoices',
                                    })}
                                    :
                                  </dt>
                                  <div className="d-flex flex-wrap">
                                    {order.custom.fields.billing_documents.map((doc, index) => (
                                      <dd key={doc + index}>
                                        <a
                                          href={DOCUMENTS_DOWNLOAD_PATH + doc}
                                          target="_blank"
                                          className="d-inline-flex link-reset"
                                          rel="noreferrer"
                                        >
                                          {IconPdf({ className: 'dt-p-p1 me-2 text-primary' })}
                                        </a>
                                      </dd>
                                    ))}
                                  </div>
                                </>
                              )}
                          </dl>
                        </div>
                      )}
                    </div>
                    <Accordion>
                      <Accordion.Item eventKey={order.cartId}>
                        <Accordion.Header className="mob-p-p3 dt-p-p3">
                          {formatOrderMessage({ id: 'order.summary', defaultMessage: 'Order summary' })}
                        </Accordion.Header>
                        <Accordion.Body className="mob-p-p3 dt-p-p3">
                          {order.lineItems.map((product) => {
                            let maxOrderAmount = 100;
                            const maxQty = product.variant?.attributes['ecom_maximum_order_quantity'];
                            if (typeof maxQty === 'number') {
                              maxOrderAmount = maxQty;
                            }
                            const availableQuantity = product.variant?.availableQuantity;
                            if (typeof availableQuantity === 'number' && availableQuantity < maxOrderAmount) {
                              maxOrderAmount = availableQuantity;
                            }
                            let minOrderAmount = 1;
                            const minQty = product.variant?.attributes['ecom_minimum_order_quantity'];
                            if (typeof minQty === 'number') {
                              minOrderAmount = minQty;
                            }
                            if (minOrderAmount > maxOrderAmount) {
                              minOrderAmount = maxOrderAmount;
                            }
                            const cartItem = cartList?.lineItems?.find(
                              (lineItem: LineItem) => lineItem.variant.sku === product.variant.sku,
                            );
                            const cartItemQty = cartItem?.count || 0;
                            const maxOrderAmountMargin = maxOrderAmount - cartItemQty;
                            const orderQty =
                              minOrderAmount <= maxOrderAmountMargin ? minOrderAmount : maxOrderAmountMargin;
                            return (
                              <div className="rounded bg-white p-20 mb-20" key={product.lineItemId}>
                                <div className="row">
                                  <div className="col-3 col-sm-2">
                                    <Image
                                      src={getFiles(product.variant.images, { number: 0, srcKey: '' })}
                                      alt={product.name}
                                      className="w-100 border rounded"
                                    />
                                  </div>
                                  <div className="col-9 col-sm-10 d-flex">
                                    <div className="row w-100">
                                      <div className="col-12">
                                        {product.variant?.sku && (
                                          <div className="mob-p-p5 dt-p-p4">
                                            {formatProductMessage({
                                              id: 'referenceNumber',
                                              defaultMessage: 'Reference number',
                                            })}
                                            : {product.variant.sku}
                                          </div>
                                        )}
                                        <div className="mob-p-p4 dt-p-p3 mb-auto">
                                          <span className="fw-bold">{product.count}</span>
                                          <span className="mx-1 mob-f-f4 dt-p-p4">x</span>
                                          <span className="fw-bold">{product.name}</span>
                                        </div>
                                      </div>
                                      <div className="col-12 col-sm-8 d-flex flex-column">
                                        <Price
                                          price={product.price}
                                          className={`${
                                            product.discountedPrice &&
                                            'text-grey-40 text-decoration-line-through mob-f-f4 dt-p-p4'
                                          } mt-2`}
                                        />
                                        {product.discountedPrice && (
                                          <Price
                                            price={product.discountedPrice}
                                            className="text-primary mob-p-p4 dt-p-p3"
                                          />
                                        )}
                                        <div className="d-flex mt-2 align-items-center">
                                          <NextLink href={product._url || ''}>
                                            <a className="btn btn-sm btn-outline">
                                              {formatProductMessage({
                                                id: 'product.view',
                                                defaultMessage: 'View product',
                                              })}
                                            </a>
                                          </NextLink>
                                          <button
                                            type="button"
                                            onClick={() => handleAddToCart(product.name, product.variant, orderQty)}
                                            className="ms-3 btn btn-sm btn-primary"
                                            disabled={
                                              (product.variant.isEcomSaleItem && !product.variant.isOnStock) ||
                                              !product?.price ||
                                              maxOrderAmountMargin < 1 ||
                                              !!addingToCart
                                            }
                                          >
                                            <>
                                              {!product.variant.isEcomSaleItem || product.variant.isOnStock
                                                ? formatProductMessage({ id: 'bag.add', defaultMessage: 'Add to bag' })
                                                : formatProductMessage({
                                                    id: 'outOfStock',
                                                    defaultMessage: 'Out of stock',
                                                  })}
                                            </>
                                            {addingToCart === product.variant.sku && (
                                              <div className="spinner-border" role="status">
                                                <span className="visually-hidden">
                                                  {formatMessage({
                                                    id: 'loading',
                                                    defaultMessage: 'Loading',
                                                  })}
                                                  ...
                                                </span>
                                              </div>
                                            )}
                                            {!addingToCart &&
                                              addedToCart === product.variant.sku &&
                                              IconCheck({ className: 'icon-lg' })}
                                          </button>
                                        </div>
                                      </div>
                                      <div className="col-12 col-sm-4 text-end mt-auto pt-3">
                                        <Price
                                          price={product.totalPrice}
                                          className={`${product.discountedPrice && 'text-primary'} fw-bold`}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <div className="row mt-3">
                      <div className="col col-12 d-flex justify-content-end">
                        <span className="me-2">
                          {formatOrderMessage({
                            id: 'shippingCosts',
                            defaultMessage: 'Shipping costs',
                          })}
                          :
                        </span>
                        <Price price={order.shippingInfo.price} />
                      </div>
                      <div className="col col-12 d-flex justify-content-end">
                        <span className="me-2">
                          {formatCartMessage({
                            id: 'discounts',
                            defaultMessage: 'Discounts',
                          })}
                          :
                        </span>
                        <Price
                          price={
                            {
                              fractionDigits: priceFractionDigits,
                              centAmount: !discountPrice || discountPrice === 0 ? 0 : -discountPrice,
                              currencyCode: order.sum.currencyCode,
                            } || {}
                          }
                        />
                      </div>
                      <div className="col col-12 d-flex justify-content-end">
                        <span className="me-2 fw-bold">
                          {formatCartMessage({
                            id: 'orderTotalNet',
                            defaultMessage: 'Order total net',
                          })}
                          :
                        </span>
                        <Price price={order.sum} className="fw-bold" />
                      </div>
                      <div className="col col-12 d-flex justify-content-end">
                        <span className="me-2 mt-2">
                          {formatCartMessage({
                            id: 'orderVat',
                            defaultMessage: 'Order vat',
                          })}
                          :
                        </span>
                        <Price price={order.taxed?.totalTax || {}} className="mt-2" />
                      </div>
                      <div className="col col-12 d-flex justify-content-end">
                        <span className="me-2">
                          {formatCartMessage({
                            id: 'orderTotalGross',
                            defaultMessage: 'Order total gross',
                          })}
                          :
                        </span>
                        <Price price={order.totalGross} />
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </section>
      ) : (
        <p className="mob-p-p3 dt-p-p3 fw-bold mb-0">
          {formatAccountMessage({
            id: 'orders.no.orders',
            defaultMessage: 'You have not placed any orders yet.',
          })}
        </p>
      )}
    </div>
  );
};

export default OrdersHistory;
