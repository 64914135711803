import React, { useMemo } from 'react';
import NextLink from 'next/link';
import { useFormat } from '../../../../helpers/hooks/useFormat';

export type Props = {
  background?: string;
  category: {
    name: string;
    ancestors: [
      {
        name: string;
        url?: string;
      },
    ];
  };
};

const CategoryTitle: React.FC<Props> = (data: Props) => {
  const category = data.category;
  const { formatMessage } = useFormat({ name: 'common' });

  const parentCategory = useMemo(() => category.ancestors?.at(-1), [category.ancestors]);

  if (!category) {
    return null;
  }
  return (
    <div className={data.background}>
      <div className="row pt-10 mb-n2 align-items-end">
        <div className="col-12 col-lg-3">
          {parentCategory?.url && (
            <div className="text-body mb-2">
              &lsaquo;{' '}
              <NextLink href={parentCategory.url}>
                <a className="text-body text-decoration-none">
                  {formatMessage({ id: 'back.to', defaultMessage: 'Back to' })} {parentCategory.name}
                </a>
              </NextLink>
            </div>
          )}
        </div>
        <div className="col-12 col-lg-9">
          <h1 className="h1 m-0">{category.name}</h1>
        </div>
      </div>
    </div>
  );
};

export default CategoryTitle;
