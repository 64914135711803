import React from 'react';
import Head from 'next/head';
import BrandHero from '../../../../../components/snaq-ui/brands/brand-hero';

const BrandHeroTastic = ({ data }) => {
  const brand = data?.data?.dataSource;
  if (!brand) return null;
  return (
    <>
      <Head>
        {brand.seoMetaTitle && <title>{brand.seoMetaTitle}</title>}
        {brand.seoMetaDescription && <meta name="description" content={brand.seoMetaDescription} key="desc" />}
        {brand.seoMetaKeywords && <meta name="keywords" content={brand.seoMetaKeywords} />}
      </Head>
      <BrandHero
        imageSrc={brand.imageSrc}
        name={brand.name}
        description={brand.description}
        background={data.background}
        innerSpacing={data.innerSpacing}
        outerSpacing={data.outerSpacing}
      />
    </>
  );
};

export default BrandHeroTastic;
