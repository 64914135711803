import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-back ${className}`}
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.7,17.5l12.4,12.4L16,32L0,16L16,0l2.1,2.1L5.7,14.5H32v3H5.7z" />
  </svg>
);

export default Icon;
