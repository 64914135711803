import React from 'react';
import HeroSlider from 'components/snaq-ui/content/hero-slider';

const HeroSliderTastic = ({ data }) => {
  if (!data.heroslides) return null;

  return (
    <HeroSlider
      heroslides={data.heroslides}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
};

export default HeroSliderTastic;
