import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    width="100%"
    height="100%"
    className={`icon icon-info ${className}`}
    viewBox="0 0 40 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.6,30h3V18h-3V30z M20,14.3c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.1c0-0.5-0.2-0.9-0.5-1.2 C20.9,11.2,20.5,11,20,11c-0.5,0-0.9,0.2-1.2,0.5s-0.5,0.7-0.5,1.2c0,0.5,0.2,0.8,0.5,1.1C19.1,14.1,19.5,14.3,20,14.3z M20,40 c-2.8,0-5.3-0.5-7.8-1.6c-2.4-1-4.5-2.5-6.4-4.3s-3.2-3.9-4.3-6.4c-1-2.4-1.6-5-1.6-7.8s0.5-5.4,1.6-7.8c1-2.4,2.5-4.5,4.3-6.3 s3.9-3.2,6.4-4.3c2.4-1,5-1.6,7.8-1.6s5.4,0.5,7.8,1.6c2.4,1,4.5,2.5,6.3,4.3s3.2,3.9,4.3,6.3c1.1,2.4,1.6,5,1.6,7.8 s-0.5,5.3-1.6,7.8c-1,2.4-2.5,4.5-4.3,6.4c-1.8,1.8-3.9,3.2-6.4,4.3C25.4,39.5,22.8,40,20,40z M20,37c4.7,0,8.7-1.7,12-5 c3.3-3.3,5-7.3,5-12.1s-1.6-8.7-4.9-12C28.8,4.7,24.7,3,20,3c-4.7,0-8.7,1.6-12,4.9C4.7,11.2,3,15.3,3,20c0,4.7,1.7,8.7,5,12 S15.3,37,20,37z" />
  </svg>
);

export default Icon;
