import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import ExpertTeasers from '../../../../../components/snaq-ui/content/expert-teasers';
import { Reference } from '../../../../../helpers/reference';
import { PageNodeTeaser } from '../../categories/category-experts';

type Props = {
  title?: string;
  nodes: any[];
  width?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
};

const ExpertTeasersTastic = ({ id, data }: { id: string; data: Props }) => {
  const { title, nodes, width, background, innerSpacing, outerSpacing } = data;
  const router = useRouter();

  const expertTeasers = useMemo(() => {
    const items: PageNodeTeaser[] = [];
    nodes.forEach(({ image, title, description, node }) => {
      if (
        !node.hasLivePage ||
        !(node?.configuration?.teaserMedia || image) ||
        !(node?.configuration?.teaserTitle || title) ||
        !node?.configuration?.contactEmail
      ) {
        return;
      }

      const _urlObj: [key: string, value: any] | undefined = Object.entries(node._urls).find(
        (item: [key: string, value: string]) => router?.locale === item[0].split(/[^A-Za-z]/)[0],
      );

      const ctaReference: Reference | null =
        _urlObj && typeof _urlObj[1] === 'string'
          ? {
              type: 'page-folder',
              pageFolder: {
                pageFolderId: node.pageFolderId,
                name: node.name,
                hasLivePage: node.hasLivePage,
                _urls: {
                  ...node._urls,
                },
                _url: _urlObj[1],
              },
              openInNewWindow: false,
            }
          : null;

      items.push({
        image: image ?? node.configuration.teaserMedia,
        title: title || node.configuration.teaserTitle || '',
        description: description || node.configuration.teaserDescription || undefined,
        v2image: node.configuration.teaser2Media ?? undefined,
        v2title: node.configuration.teaser2Title ?? undefined,
        v2description: node.configuration.teaser2Description ?? undefined,
        v3image: node.configuration.teaser3Media ?? undefined,
        v3title: node.configuration.teaser3Title ?? undefined,
        v3description: node.configuration.teaser3Description ?? undefined,
        email: node.configuration.contactEmail ?? undefined,
        ctaLabel: 'Read',
        ctaReference: ctaReference ?? undefined,
      });
    });
    return items;
  }, [nodes]);

  if (expertTeasers.length === 0) {
    return null;
  }

  return (
    <ExpertTeasers
      tasticId={id}
      title={title}
      items={expertTeasers}
      width={width}
      background={background}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
    />
  );
};

export default ExpertTeasersTastic;
