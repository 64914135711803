import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg className={`icon icon-ups ${className}`} viewBox="0 0 51.2 61" xmlns="http://www.w3.org/2000/svg">
    <path d="m1.3 18.2.2 25.2 5.7 7 17 9L45 49.2l4.3-10.3-.4-33.4-15.7-.4-16.4 2.7-15 8.5-.4 1.9Z" fill="#301506" />
    <path
      d="M25.6 0C15.7 0 7.1 1.9 0 5.7v30.2c0 6.3 2.4 11.7 6.9 15.4 4.2 3.4 17.1 9.1 18.7 9.8 1.5-.7 14.6-6.4 18.7-9.8 4.5-3.7 6.9-9 6.9-15.3V5.7C44.1 1.9 35.5 0 25.6 0Zm14.6 5.8c2.9 0 5.8.2 8.6.5v29.5c0 5.7-2.1 10.3-6 13.5-3.5 2.9-14.2 7.7-17.2 9-3-1.3-13.8-6.2-17.2-9-3.9-3.2-6-7.9-6-13.5V18.5C13.7 8.2 27.5 5.7 40.2 5.8ZM26.3 19.6c-2.4 0-4.4.5-6 1.6v29h4.5v-9.4c.4.1 1.1.3 2 .3 4.9 0 7.8-4.5 7.8-11s-2.9-10.5-8.2-10.5Zm15.2 0c-2.9 0-6 2.2-6 5.8 0 2.4.7 4.1 4.3 6.3 2 1.2 2.8 1.9 2.8 3.3 0 1.6-1 2.5-2.7 2.5-1.4 0-3.1-.8-4.3-1.8v4.1c1.4.8 3.1 1.4 4.9 1.4 4.4 0 6.4-3.1 6.5-6 0-2.6-.6-4.6-4.4-6.8-1.7-1-3-1.6-2.9-3.2 0-1.6 1.4-2.1 2.6-2.1 1.6 0 3.1.9 4 1.8V21c-.8-.6-2.4-1.5-4.9-1.4ZM4.6 20v14c0 4.7 2.2 7.1 6.6 7.1s5-.6 6.7-1.8V20h-4.4v16.8c-.5.3-1.2.5-2.1.5-2 0-2.3-1.9-2.3-3.1V20H4.6Zm21.7 3.2c2.6 0 3.7 2.1 3.7 7.1 0 4.9-1.2 7.2-3.8 7.2s-1.1-.1-1.4-.3V23.5c.4-.2 1-.3 1.6-.3Z"
      fill="#fab80a"
    />
  </svg>
);

export default Icon;
