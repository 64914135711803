import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-visibility ${className}`}
    viewBox="0 0 44 30"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22,23.5c2.4,0,4.4-0.8,6-2.5c1.7-1.7,2.5-3.7,2.5-6c0-2.4-0.8-4.4-2.5-6c-1.7-1.7-3.7-2.5-6-2.5c-2.4,0-4.4,0.8-6,2.5
	c-1.7,1.7-2.5,3.7-2.5,6c0,2.4,0.8,4.4,2.5,6C17.6,22.7,19.6,23.5,22,23.5z M22,20.6c-1.6,0-2.9-0.5-4-1.6c-1.1-1.1-1.6-2.4-1.6-4
	c0-1.6,0.5-2.9,1.6-4c1.1-1.1,2.4-1.6,4-1.6c1.6,0,2.9,0.5,4,1.6c1.1,1.1,1.6,2.4,1.6,4c0,1.6-0.5,2.9-1.6,4
	C24.9,20.1,23.5,20.6,22,20.6z M22,30c-4.9,0-9.3-1.4-13.2-4.1S1.9,19.5,0,15c1.9-4.5,4.9-8.1,8.8-10.9S17.1,0,22,0
	s9.3,1.4,13.2,4.2S42.1,10.5,44,15c-1.9,4.5-4.9,8.1-8.8,10.9S26.9,30,22,30z M22,27c4,0,7.8-1.1,11.1-3.3s6-5.1,7.7-8.7
	c-1.8-3.6-4.3-6.5-7.7-8.7C29.8,4.1,26,3,22,3c-4,0-7.8,1.1-11.1,3.3s-6,5.1-7.8,8.7c1.8,3.6,4.4,6.5,7.8,8.7C14.2,25.9,18,27,22,27
	z"
    />
  </svg>
);

export default Icon;
