import React from 'react';
import parse from 'html-react-parser';
import { markdown } from 'markdown';
import { Log } from 'helpers/errorLogger';

interface Props {
  textAlignment: string;
  text: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

const Markdown: React.FC<Props> = ({ textAlignment, text, background, innerSpacing, outerSpacing }) => {
  if (typeof text !== 'string') {
    Log.error(`Markdown: Invalid text property. Expected string but received ${typeof text}`);
    return <></>;
  }

  return (
    <div className={`markdown last-child-no-mb ${textAlignment} ${background} ${innerSpacing} ${outerSpacing}`}>
      {parse(markdown.toHTML(text))}
    </div>
  );
};

export default Markdown;
