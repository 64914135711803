import React from 'react';
import NextLink from 'next/link';
import { Brand } from '@Types/snaq/product/Brand';
import clsx from 'clsx';
import { default as IconChevronRight } from 'components/icons/chevron-right';
import ProductPlaceholder from 'public/images/product-placeholder.jpg';
import Image from '../../../../frontastic/lib/image';
import { Reference, ReferenceLink } from '../../../../helpers/reference';
import { sm, md } from '../../../../helpers/utils/screensizes';
import Slider, { SliderProps } from '../../slider';
import { brandRouteSlug } from '../brand-list';

const sliderConfiguration: SliderProps = {
  slidesPerView: 2.5,
  breakpoints: {
    [sm]: {
      slidesPerView: 3.5,
    },
    [md]: {
      slidesPerView: 4.75,
    },
  },
  arrows: true,
  dots: false,
};

export interface Props {
  tasticId: string;
  title: string;
  brands: Brand[];
  ctaLabel?: string;
  ctaReference?: Reference;
  ctaTitle?: string;
  ctaRel?: string;
  width?: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}
const BrandSelection: React.FC<Props> = (props) => {
  const {
    tasticId,
    title,
    brands,
    ctaLabel,
    ctaReference,
    ctaTitle,
    ctaRel,
    width,
    background,
    innerSpacing,
    outerSpacing,
  } = props;

  if (!brands || brands.length === 0) {
    return null;
  }

  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      <div className="brands-slider">
        <div className="d-flex justify-content-between align-items-end mb-20">
          {title && <h2 className="h1 products-slider__title mb-0">{title}</h2>}
          {ctaLabel && ctaReference && (
            <ReferenceLink
              target={ctaReference}
              title={ctaTitle}
              rel={ctaRel}
              className="products-slider__link link-reset dt-c-c2 mb-1"
            >
              <span className="d-none d-xl-block">{ctaLabel}</span>
              {IconChevronRight({})}
            </ReferenceLink>
          )}
        </div>
        <Slider
          {...sliderConfiguration}
          className={`swiper--mobile-full ${width === 'container-window-edge' ? 'swiper--container-window-edge' : ''}`}
        >
          {brands.map((brand: Brand, index: number) => (
            <div
              key={`${tasticId}${index}`}
              className={clsx(
                'brands-slider__slide w-100 rounded bg-white',
                background === 'bg-white-full' && 'border border-grey-20',
              )}
            >
              <NextLink href={brandRouteSlug + '/' + brand.key}>
                <a className="text-white d-block position-relative">
                  <Image src={brand.imageSrc || ProductPlaceholder.src} alt={brand.name} />
                </a>
              </NextLink>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default BrandSelection;
