import React from 'react';
import Link from 'next/link';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

export type Props = {
  background?: string;
  category: {
    name: string;
    ancestors: [
      {
        name: string;
        url?: string;
      },
    ];
  };
};

const Breadcrumbs: React.FC<Props> = (data: Props) => {
  const category = data.category;
  if (!category) {
    return null;
  }
  return (
    <div className={data.background}>
      <Breadcrumb className="breadcrumb-wrapper mob-c-c3 dt-c-c3">
        <Breadcrumb.Item linkAs={Link} href="/">
          Home
        </Breadcrumb.Item>
        {category.ancestors?.map((ancestor, index) => (
          <Breadcrumb.Item key={'breadcrumb' + index} linkAs={Link} href={ancestor.url ?? ''}>
            {ancestor.name}
          </Breadcrumb.Item>
        ))}
        <Breadcrumb.Item>{category.name}</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

export default Breadcrumbs;
