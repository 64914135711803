import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-facebook ${className}`}
    viewBox="0 0 496 493"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M496 248C496 111 385 0 248 0S0 111 0 248c0 123.8 90.7 226.4 209.2 245V319.7h-63V248h63v-54.6c0-62.2 37-96.5 93.7-96.5 27.1 0 55.5 4.8 55.5 4.8v61h-31.3c-30.8 0-40.4 19.1-40.4 38.7V248h68.8l-11 71.7h-57.8V493C405.3 474.4 496 371.8 496 248z" />
  </svg>
);

export default Icon;
