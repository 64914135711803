import Typography from 'components/snaq-ui/typography';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { Reference, ReferenceLink } from 'helpers/reference';
import { md, xl } from 'helpers/utils/screensizes';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';

export interface HeroSlideProps {
  image: MediaItemWithMods;
  imagemobile?: MediaItemWithMods;
  overline?: string;
  title?: string;
  description?: string;
  subtext?: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  ctaTitle?: string;
  ctaRel?: string;
}

const Slide: React.FC<HeroSlideProps> = ({
  image,
  imagemobile,
  overline,
  title,
  description,
  subtext,
  ctaLabel,
  ctaReference,
  ctaTitle,
  ctaRel,
}) => {
  const [isTabletSize] = useMediaQuery(md);
  const [isDesktopSize] = useMediaQuery(xl);

  return (
    <div className={`hero-slide`}>
      <div className={`hero-slide__img`}>
        <Image src={imagemobile && !isTabletSize ? imagemobile.media.file : image.media.file} alt={title} />
      </div>
      <div className={`hero-slide__content text-white d-flex align-items-end`}>
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-12 col-md-8 col-lg-7 col-xl-6 col-xl-5 offset-xl-1`}>
              {overline && (
                <div className={`hero-slide__overline mob-h-h6 dt-h-h6 text-uppercase pb-10`}>
                  <Typography>{overline}</Typography>
                </div>
              )}
              {title && (
                <div className={`hero-slide__title mob-h-h1 dt-h-h1 pb-10`}>
                  <Typography>{title}</Typography>
                </div>
              )}
              {description && (
                <div className={`hero-slide__description mob-p-p2 dt-p-p2 pb-10`}>
                  <Typography>{description}</Typography>
                </div>
              )}
              {subtext && (
                <div className={`hero-slide__subtext mob-h-h4 dt-h-h4 pb-10`}>
                  <Typography>{subtext}</Typography>
                </div>
              )}
              {ctaLabel && ctaReference && (
                <div className={`hero-slide__btn ${isDesktopSize ? 'py-10' : 'hero-slide__btn--cover'}`}>
                  <ReferenceLink
                    target={ctaReference}
                    title={ctaTitle}
                    rel={ctaRel}
                    className={`${isDesktopSize ? 'btn btn-primary' : ''}`}
                  >
                    <Typography>{ctaLabel}</Typography>
                  </ReferenceLink>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slide;
