// Check file sources and return wanted types
export const getFiles = (fileList, parameter = {}) => {
  const defaults = {
    srcKey: 'src',
    type: 'img',
    number: -1,
  };

  const settings = { ...defaults, ...parameter };

  const types = {
    img: ['jpeg', 'svg', 'png', 'jpg', 'jpeg', 'jpe', 'jif', 'jfif', 'gif'],
    pdf: ['pdf'],
  };

  const src = (file) => {
    return settings.srcKey ? file[settings.srcKey] : file;
  };

  const list = fileList.filter((file) => types[settings.type].includes(src(file).split('.').pop()));

  if (settings.number > -1) return list[settings.number];

  return list;
};
