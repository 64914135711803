import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-twitter ${className}`}
    viewBox="0 0 548 445"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M491.7 110.9c.3 4.9.3 9.7.3 14.6C492 274 379 445 172.5 445 108.8 445 49.7 426.6 0 394.6c9 1 17.7 1.4 27.1 1.4 52.5 0 100.8-17.7 139.4-48-49.4-1-90.8-33.4-105-77.9 7 1 13.9 1.7 21.2 1.7 10.1 0 20.2-1.4 29.6-3.8-51.5-10.4-90.1-55.6-90.1-110.2v-1.4c15 8.3 32.3 13.6 50.8 14.3-30.3-20.2-50.1-54.6-50.1-93.5 0-20.9 5.6-40 15.3-56.7C93.5 88.7 176.6 133.2 269.8 138c-1.7-8.3-2.8-17-2.8-25.7C267 50.4 317.1 0 379.4 0c32.3 0 61.5 13.6 82.1 35.5 25.4-4.9 49.7-14.3 71.3-27.1-8.3 26.1-26.1 48-49.4 61.9 22.6-2.4 44.5-8.7 64.7-17.4-15.4 22.2-34.5 42-56.4 58z" />
  </svg>
);

export default Icon;
