import React, { useCallback, useEffect, useRef, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AsyncTypeahead, Menu } from 'react-bootstrap-typeahead';
import { default as IconSearch } from 'components/icons/search';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getFiles } from 'helpers/utils/getFiles';
import { xl } from 'helpers/utils/screensizes';
import { fetchApiHub } from 'frontastic';
import Image from 'frontastic/lib/image';
import ProductPlaceholder from '../../../public/images/product-placeholder.jpg';
import Price from '../price';
import ProductCard from '../products/card';

export interface Props extends React.ComponentProps<'input'> {
  onSubmit?: () => void;
}

const SearchInput: React.FC<Props> = ({ onSubmit, ...props }) => {
  const [isDesktopSize] = useMediaQuery(xl);
  //formatted messages
  const { formatMessage } = useFormat({ name: 'common' });
  const [isLoading, setIsLoading] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState<any[]>([]);
  const typeaheadRef = useRef(null);
  const submitRef = useRef(null);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      if (typeaheadRef.current) {
        typeaheadRef.current.clear();
      }
      setOptions([]);
      setIsLoading(false);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  const handleSearch = useCallback((query: string) => {
    setIsLoading(true);

    fetchApiHub(`/action/search/product?search=${query}`, { method: 'post' }).then((results) => {
      if (!results) {
        setIsLoading(false);
        return;
      }
      const { products, searchTerms, categories, brands } = results;
      const opts = [];
      if (products) {
        opts.push(...products);
      }
      if (searchTerms) {
        opts.push(...searchTerms);
      }
      if (categories) {
        opts.push(...categories);
      }
      if (brands) {
        opts.push(...brands);
      }
      setOptions(opts);
      setIsLoading(false);
    });
  }, []);

  const submit = useCallback(() => {
    if (formDisabled) {
      return;
    }
    if (onSubmit) {
      onSubmit();
    } else if (submitRef.current) {
      submitRef.current.closest('form')?.submit();
    }
  }, [onSubmit, formDisabled]);

  const getImageThumb = (url: string, size: string) => {
    if (!url || !size) return false;
    return url.replaceAll('200x200', size);
  };

  return (
    <Form action="suche" method="GET">
      <Form.Group controlId="headerSearch" className="input-group-absolute">
        <Form.Label className="visually-hidden">
          {formatMessage({
            id: 'search',
            defaultMessage: 'Search',
          })}
        </Form.Label>
        <AsyncTypeahead
          id="async-typeahead"
          ref={typeaheadRef}
          labelKey="name"
          filterBy={() => true}
          isLoading={isLoading}
          minLength={3}
          onSearch={handleSearch}
          options={options}
          placeholder={`${formatMessage({ id: 'search', defaultMessage: 'Search' })}`}
          onInputChange={(text) => {
            setSearchTerm(text);
            setFormDisabled(text.length < 3);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              submit();
            }
          }}
          renderMenu={(options, menuProps) => {
            const products = options.filter((option) => !!option._url);
            const searchTerms = options.filter((option) => option.type === 'searchTerm');
            const categories = options.filter((option) => option.type === 'category');
            const brands = options.filter((option) => option.type === 'brand');
            return (
              <Menu {...menuProps} className="container">
                <div className="row">
                  {(searchTerms?.length > 0 || brands?.length > 0) && (
                    <div className="col-12">
                      <div className="row">
                        {searchTerms.length > 0 && (
                          <div className="col-12 col-xl-4">
                            <h5 className="mt-3 mob-p-p4 dt-p-p4 fw-bold">Suchbegriffe</h5>
                            {searchTerms.map((option, index) => (
                              <NextLink
                                key={'typeahead-result-search-term' + index}
                                href={'/suche?query=' + option.query + '&queryFromSuggest=' + searchTerm}
                              >
                                <a className="btn btn-xs btn-light-grey me-10 mb-10">{option.name}</a>
                              </NextLink>
                            ))}
                          </div>
                        )}

                        {brands.length > 0 && (
                          <div className="col-12 col-xl-4">
                            <h5 className="mt-3 mob-p-p4 dt-p-p4 fw-bold">Marken</h5>
                            {brands.map((option, index) => (
                              <NextLink
                                key={'typeahead-result-brand' + index}
                                href={
                                  '/suche?query=' +
                                  option.query +
                                  '&queryFromSuggest=' +
                                  searchTerm +
                                  '&filter=' +
                                  option.filter +
                                  '&cursor=offset%3A0'
                                }
                              >
                                <a className="btn btn-xs btn-light-grey me-10 mb-10">{option.name}</a>
                              </NextLink>
                            ))}
                          </div>
                        )}

                        {/* TODO - Category - Wrong links */}
                        {/*
                        {categories.length > 0 && (
                          <div className="col-12 col-xl-4">
                            <h5 className="mt-3 mob-p-p4 dt-p-p4 fw-bold">Kategorien</h5>
                            {categories.map((option, index) => (
                              <NextLink
                                key={'typeahead-result-category' + index}
                                href={
                                  '/suche?query=' +
                                  option.query +
                                  '&queryFromSuggest' +
                                  searchTerm +
                                  '&filter=' +
                                  option.filter
                                }
                              >
                                <a className="btn btn-xs btn-light-grey me-10 mb-10">{option.name}</a>
                              </NextLink>
                            ))}
                          </div>
                        )}
                        */}
                      </div>
                    </div>
                  )}
                  {products.length > 0 && (
                    <div className="col-12">
                      <h5 className="mt-3 mob-p-p2 dt-p-p2 fw-bold">Produkte</h5>
                      <div className="row">
                        {products.map((option, index) => {
                          const price = option.variants?.[0].price;
                          const discountedPrice = option.variants?.[0].discountedPrice;
                          const uvpPrice = option.variants?.[0].uvpPrice;
                          const isOnStock = !!option.variants?.[0].isOnStock;
                          const isEcomSaleItem = !!option.variants?.[0].isEcomSaleItem;
                          const erpReprocurementTime = option.variants?.[0].erpReprocurementTime;
                          const isNew = option.isNew;
                          const usedStatus = option.variants?.[0].usedStatus || undefined;
                          const badges = option.variants?.[0].badges || undefined;
                          return (
                            <div
                              className="dropdown-item__search-wrapper mb-20 d-flex"
                              key={'typeahead-result-product' + index}
                            >
                              {isDesktopSize && (
                                <ProductCard
                                  size="small"
                                  imageSrc={
                                    getImageThumb(
                                      getFiles(option.variants[0]?.images, {
                                        number: 0,
                                        srcKey: '',
                                      }),
                                      '200x200',
                                    ) || ProductPlaceholder.src
                                  }
                                  imageAlt={option.name}
                                  title={option.name}
                                  price={price}
                                  discountedPrice={discountedPrice}
                                  uvpPrice={uvpPrice}
                                  isOnStock={isOnStock}
                                  isEcomSaleItem={isEcomSaleItem}
                                  erpReprocurementTime={erpReprocurementTime}
                                  isNew={isNew}
                                  usedStatus={usedStatus}
                                  badges={badges}
                                  link={option._url}
                                />
                              )}
                              {!isDesktopSize && (
                                <NextLink href={option._url}>
                                  <a className="dropdown-item__search link-reset">
                                    <Image
                                      src={getFiles(option.variants[0]?.images, { number: 0, srcKey: '' })}
                                      layout="fill"
                                      style={{ objectFit: 'cover' }}
                                    />
                                    <span className="d-block">
                                      <span className="d-block mob-c-c3 dt-c-c3 mb-2">{option.name}</span>
                                      {price && (
                                        <span className="d-flex align-items-center">
                                          {discountedPrice && (
                                            <Price price={discountedPrice} className="text-primary mob-c-c4 dt-c-c4" />
                                          )}
                                          <Price
                                            price={price}
                                            className={classnames(
                                              'mob-c-c4 dt-c-c4',
                                              discountedPrice && 'text-decoration-line-through ms-2 text-grey-40',
                                            )}
                                          />
                                        </span>
                                      )}
                                    </span>
                                  </a>
                                </NextLink>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="text-center pt-20 pb-40">
                        <Button
                          type="submit"
                          className="btn btn-link btn-sm text-decoration-none fw-normal"
                          ref={submitRef}
                        >
                          Alle Ergebnisse anzeigen
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </Menu>
            );
          }}
          /*renderMenuItemChildren={(option) => {
            return option?.id ? (
              <NextLink href={option?._url} key={option?.id}>
                <a className="dropdown-item__search link-reset">
                  <Image
                    src={getFiles(option?.variants[0]?.images, { number: 0, srcKey: '' })}
                    layout="fill"
                    objectFit="cover"
                  />
                  {option?.name}
                </a>
              </NextLink>
            ) : (
              <div
                className="dropdown-item__search link-reset"
                onClick={() => {
                  handleSearch(option?.query);
                  submit();
                }}
              >
                {option?.name}
              </div>
            );
          }}*/
          inputProps={{ name: 'query' }}
          type="text"
        />
        <Button
          variant="transparent"
          type="submit"
          disabled={formDisabled}
          className={classnames('input-group-absolute__item border-0', formDisabled && 'opacity-25')}
          ref={submitRef}
        >
          {IconSearch({})}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default SearchInput;
