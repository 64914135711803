import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-account-circle ${className}`}
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.8 15.1c.8-.7 1.8-1.2 2.8-1.5S8.9 13 10 13s2.2.2 3.3.6 2 .9 2.8 1.5c.6-.7 1-1.5 1.4-2.3.3-.9.5-1.8.5-2.8 0-2.2-.8-4.1-2.3-5.7S12.2 2 10 2s-4.1.8-5.7 2.3S2 7.8 2 10c0 1 .2 1.9.5 2.8.3.8.8 1.6 1.3 2.3zM10 11c-1 0-1.8-.3-2.5-1s-1-1.5-1-2.5.3-1.8 1-2.5S9 4 10 4s1.8.3 2.5 1 1 1.5 1 2.5-.3 1.8-1 2.5-1.5 1-2.5 1zm0 9c-1.4 0-2.7-.3-3.9-.8S3.8 18 2.9 17.1c-.9-.9-1.6-2-2.1-3.2S0 11.4 0 10s.3-2.7.8-3.9S2 3.8 2.9 2.9s2-1.6 3.2-2.1S8.6 0 10 0s2.7.3 3.9.8 2.3 1.2 3.2 2.1c.9.9 1.6 2 2.1 3.2s.8 2.5.8 3.9-.3 2.7-.8 3.9-1.2 2.3-2.1 3.2c-.9.9-2 1.6-3.2 2.1s-2.5.8-3.9.8zm0-2c.9 0 1.7-.1 2.5-.4.8-.3 1.5-.6 2.1-1.1-.6-.5-1.4-.9-2.1-1.1S10.9 15 10 15c-.9 0-1.7.1-2.5.4S6 16 5.3 16.5c.7.5 1.4.9 2.2 1.1.8.3 1.6.4 2.5.4zm0-9c.4 0 .8-.1 1.1-.4.3-.3.4-.6.4-1.1s-.1-.8-.4-1.1c-.3-.3-.7-.4-1.1-.4-.4 0-.8.1-1.1.4s-.4.7-.4 1.1.1.8.4 1.1.7.4 1.1.4z" />
  </svg>
);

export default Icon;
