import React from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Category } from '@Types/snaq/product/Category';
import classnames from 'classnames';
import Accordion from 'react-bootstrap/Accordion';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Typography from 'components/snaq-ui/typography';
import { isLiveReference, ReferenceLink } from 'helpers/reference';
import { Link } from './index';
import Image from '../../../frontastic/lib/image';
import { useFormat } from '../../../helpers/hooks/useFormat';

interface TreeProps {
  keyPrefix: string;
  catalog: Category[];
  parentUuid?: string;
  category?: Category;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  level: number;
}
export const Tree: React.FC<TreeProps> = ({ keyPrefix, catalog, parentUuid, category, setOpen, level }) => {
  const currentLevel = category
    ? [category]
    : catalog.filter((item) => parentUuid && item.parentUuid === parentUuid && !!item.slug);

  const router = useRouter();

  const { formatMessage } = useFormat({ name: 'common' });

  return (
    <>
      {currentLevel.map((item, index) => {
        const hasChildren = catalog.some((child) => child.parentUuid === item.uuid);

        return hasChildren ? (
          <Accordion.Item key={keyPrefix + level + index} as="li" eventKey={keyPrefix + item.uuid}>
            <Accordion.Header
              className={classnames(
                `offcanvas-nav__button offcanvas-nav__button--has-children offcanvas-nav__button-lvl-${level}`,
                router.asPath.includes(`/${item.slug}`) && 'active',
              )}
            >
              {item.name}
            </Accordion.Header>
            <Accordion.Body>
              <NextLink href={item._url}>
                <a
                  className={classnames(
                    `offcanvas-nav__a offcanvas-nav__a-lvl-${level + 1} ms-1`,
                    level > 1 ? 'ps-2 ps-xl-3' : 'ps-1',
                    router.asPath.includes(`/${item.slug}`) && 'active',
                  )}
                  onClick={() => setOpen(false)}
                  rel="follow"
                  title={item.name}
                >
                  {formatMessage({
                    id: 'to',
                    defaultMessage: 'To',
                  })}{' '}
                  {item.name}
                </a>
              </NextLink>
              <Accordion as="ul" className="offcanvas-nav list-unstyled my-0">
                <Tree
                  keyPrefix={keyPrefix}
                  catalog={catalog}
                  parentUuid={item.uuid}
                  setOpen={setOpen}
                  level={level + 1}
                />
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
        ) : (
          <li key={keyPrefix + level + index}>
            <NextLink href={item._url}>
              <a
                className={classnames(
                  `offcanvas-nav__a offcanvas-nav__a-lvl-${level}`,
                  router.asPath.includes(`/${item.slug}`) && 'active',
                )}
                onClick={() => setOpen(false)}
                rel="follow"
                title={item.name}
              >
                {item.name}
              </a>
            </NextLink>
          </li>
        );
      })}
    </>
  );
};

interface HeaderMenuProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  categories: Category[];
  links: Link[];
  previewId?: string;
  logo?: any;
  logoLink?: any;
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({ open, setOpen, categories, links, previewId, logo, logoLink }) => {
  const closeMenu = () => setOpen(false);

  // NOTE: previewID apparently used if pageFolder not yet live in develop/staging envs
  return (
    <Offcanvas show={open} onHide={closeMenu} className="offcanvas-navigation">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <ReferenceLink target={logoLink} className="d-block w-px-100">
            <Image media={logo} alt="Logo" />
          </ReferenceLink>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Accordion as="ul" className="offcanvas-nav list-unstyled">
          {links
            .filter((link) => previewId || isLiveReference(link.reference) || !!link.categoryKey)
            .map((link, index) => {
              if (link.categoryKey && Array.isArray(categories)) {
                const category = categories.find((category) => category.categoryId === link.categoryKey);
                if (category) {
                  return (
                    <Tree
                      key={'headerNavOffcanvasDesktop' + index}
                      keyPrefix="headerNavOffcanvasDesktop"
                      catalog={categories}
                      category={category}
                      setOpen={setOpen}
                      level={1}
                    />
                  );
                }
              } else if (link.reference) {
                return (
                  <li
                    className={`offcanvas-nav__li-lvl-1`}
                    key={'headerNavOffcanvasDesktop' + index}
                    onClick={closeMenu}
                  >
                    <ReferenceLink target={link.reference} title={link.linkTitle} rel={link.linkRel}>
                      <Typography>{link.name}</Typography>
                    </ReferenceLink>
                  </li>
                );
              }
              return null;
            })}
        </Accordion>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default HeaderMenu;
