import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-instagram ${className}`}
    viewBox="0 0 449 449"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M224.6 109.4c-63.7 0-115.1 51.4-115.1 115.1s51.4 115.1 115.1 115.1 115.1-51.4 115.1-115.1-51.4-115.1-115.1-115.1zm0 189.9c-41.2 0-74.8-33.6-74.8-74.8s33.6-74.8 74.8-74.8 74.8 33.6 74.8 74.8-33.7 74.8-74.8 74.8zm146.6-194.6c0 14.9-12 26.9-26.8 26.9-14.9 0-26.8-12-26.8-26.9s12-26.9 26.8-26.9 26.8 12 26.8 26.9zm76.2 27.2c-1.7-36-9.9-67.8-36.3-94.1C384.9 11.6 353 3.3 317 1.5c-37-2-148-2-185.1.1C96 3.3 64.2 11.5 37.8 37.7S3.4 95.9 1.6 131.8C-.5 168.9-.5 280 1.6 317.1c1.7 36 9.9 67.8 36.3 94.1 26.3 26.2 58.1 34.5 94.1 36.3 37.1 2.1 148.1 2.1 185.2 0 36-1.7 67.8-9.9 94.1-36.3 26.2-26.2 34.5-58.1 36.3-94.1 1.9-37.1 1.9-148.1-.2-185.2zm-47.9 225c-7.8 19.6-22.9 34.8-42.7 42.7-29.5 11.7-99.7 9-132.3 9s-102.9 2.6-132.3-9c-19.6-7.8-34.8-22.9-42.7-42.7-11.7-29.6-9-99.7-9-132.4s-2.6-102.9 9-132.4c7.8-19.6 22.9-34.8 42.7-42.7 29.5-11.7 99.7-9 132.3-9s102.9-2.6 132.3 9c19.6 7.8 34.8 22.9 42.7 42.7 11.7 29.6 9 99.7 9 132.4s2.8 102.9-9 132.4z" />
  </svg>
);

export default Icon;
