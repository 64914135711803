import React, { FC, useState } from 'react';

export type DropdownProps = {
  labelClass?: string;
  selectClass?: string;
  items: Array<{ label: string; value: string }>;
  label?: string;
  onChange?: (selectedValue: string) => void;
  value?: string;
  defaultValue?: string;
  disable?: boolean;
};

const Dropdown: FC<DropdownProps> = ({
  labelClass,
  selectClass,
  label,
  items,
  onChange,
  value,
  defaultValue,
  disable,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const handleChange = (value: string) => {
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <label className={labelClass}>
      {label}
      <select
        className={selectClass}
        value={value || selectedValue}
        onChange={(event) => {
          handleChange(event.target.value);
        }}
        disabled={disable}
      >
        {items.map(({ label, value }, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
      </select>
    </label>
  );
};

export default Dropdown;
