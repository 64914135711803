import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import ExpertContactForms from '../../expert-contact';

export interface Props {
  tasticId: string;
  title: string;
  items: ExpertItem[];
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}

export type ExpertItem = {
  image: MediaItemWithMods;
  title: string;
  description?: string;
  email?: string;
  date?: string;
};

export default function ExpertList({ tasticId, title, items, background, innerSpacing, outerSpacing }: Props) {
  const { formatMessage } = useFormat({ name: 'common' });

  const [expertShow, setExpertShow] = useState<number | null>(null);

  return (
    <>
      <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
        {title && (
          <div className="d-flex justify-content-between align-items-end mb-20">
            {title && <h2 className="h1 products-slider__title mb-0">{title}</h2>}
          </div>
        )}
        {items.map(
          (item: ExpertItem, index: number) =>
            item.image && (
              <div key={`${tasticId}${index}`} className="my-3">
                <div className="row align-items-center">
                  <div className="col-4 col-md-3">
                    <div className="ratio ratio-4x3 rounded overflow-hidden">
                      <Image
                        media={item.image}
                        alt={item.image.media.name}
                        layout="fill"
                        className="object-fit-cover"
                      />
                    </div>
                  </div>
                  <div className="col-8 col-md-9">
                    {item.title && <h3 className="h2">{item.title}</h3>}
                    {item.description && <p className="mob-p-p3 dt-p-p3">{item.description}</p>}
                    {/* NOTE: Currently no link to expert page wanted
                          {item.ctaLabel && item.ctaReference && (
                            <ReferenceLink target={item.ctaReference} className="btn btn-primary">
                              {t(item.ctaLabel)}
                            </ReferenceLink>
                          )}
                          */}
                    <div className="mt-auto d-flex flex-wrap">
                      <button
                        className="btn btn-primary btn-sm btn-lg-md my-1 me-3"
                        onClick={() => {
                          setExpertShow(index);
                        }}
                      >
                        {formatMessage({
                          id: 'details',
                          defaultMessage: 'Details',
                        })}
                      </button>
                      {/*
                      <button
                        className="btn btn-secondary btn-sm btn-lg-md my-1 me-3"
                        onClick={() => {
                          setExpertDefaultActiveKey('callback');
                          setExpertShow(index);
                        }}
                      >
                        {formatContactformMessage({
                          id: 'request.callback',
                          defaultMessage: 'Request a callback',
                        })}
                      </button>
                      <button
                        className="btn btn-secondary btn-sm btn-lg-md my-1 me-3"
                        onClick={() => {
                          setExpertDefaultActiveKey('contact');
                          setExpertShow(index);
                        }}
                      >
                        {formatContactformMessage({
                          id: 'write.message',
                          defaultMessage: 'Write a message',
                        })}
                      </button>
                      */}
                    </div>
                  </div>
                </div>
              </div>
            ),
        )}
      </div>
      {items.map((expertTeaser: ExpertItem, index: number) => (
        <Offcanvas
          key={`${tasticId}offcanvas${index}`}
          show={expertShow === index}
          onHide={() => {
            setExpertShow(null);
          }}
          placement={'end'}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              {formatMessage({
                id: 'consulting',
                defaultMessage: 'Consulting',
              })}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="bg-grey-10 p-20 rounded mb-20">
              <div className="row align-items-center">
                <div className="col-3">
                  <div className="ratio ratio-1x1">
                    <Image
                      media={expertTeaser.image}
                      className="object-fit-cover rounded-circle"
                      layout="fill"
                      alt={expertTeaser.image.media.name}
                    />
                  </div>
                </div>
                <div className="col-9">
                  <div className="mob-p-p3 dt-p-3 fw-bold">{expertTeaser.title}</div>
                </div>
              </div>
              <hr />
              {expertTeaser.description}
            </div>
            <ExpertContactForms expertEmail={expertTeaser.email} />
          </Offcanvas.Body>
        </Offcanvas>
      ))}
    </>
  );
}
