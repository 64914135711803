import React from 'react';
import ProductSpecialDetails, {
  ProductSpecialDetailsProps,
} from '../../../../../components/snaq-ui/content/product-special-details';

type Props = {
  productSpecialDetails: Array<ProductSpecialDetailsProps>;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
};

const ProductSpecialDetailsTastic = ({ id, data }: { id: string; data: Props }) => {
  if (!data?.productSpecialDetails) return <></>;

  const { productSpecialDetails, background, innerSpacing, outerSpacing } = data;

  return (
    <ProductSpecialDetails
      tasticId={id}
      items={productSpecialDetails}
      background={background}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
    />
  );
};

export default ProductSpecialDetailsTastic;
