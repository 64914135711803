import React from 'react';
import NextLink from 'next/link';
import { CategorySet } from '@Types/snaq/product/CategorySet';
import Typography from '../../typography';

export interface Props {
  tasticId: string;
  title?: string;
  sets: CategorySet[];
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}
const CategorySets: React.FC<Props> = (props) => {
  const { tasticId, title, sets, background, innerSpacing, outerSpacing } = props;

  if (!sets || sets.length === 0) {
    return null;
  }

  return (
    <div className={`${background} ${innerSpacing} ${outerSpacing}`}>
      {title && (
        <div className="mb-8 w-auto text-center">
          <h2 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-light-100">{title}</h2>
        </div>
      )}
      <ul role="list" className="my-4 flex flex-wrap">
        {sets.map((set) => {
          const { parent, children } = set;
          return (
            <li key={`${tasticId}${parent.categoryId}`} className="my-1 mr-6">
              <NextLink href={parent._url}>
                <a className="bg-gray-100 py-1 px-2 text-xl font-bold text-gray-500 dark:text-light-100">
                  <Typography>{parent.name}</Typography>
                </a>
              </NextLink>
              <ul role="list" className="mt-2">
                {children.map((category) => (
                  <li key={`${tasticId}${parent.categoryId}${category.categoryId}`} className="my-1">
                    <NextLink href={category._url}>
                      <a className="text-l py-1 px-2 text-gray-500 dark:text-light-100">
                        <Typography>{category.name}</Typography>
                      </a>
                    </NextLink>
                  </li>
                ))}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CategorySets;
