import Breadcrumbs from '../../../../components/snaq-ui/breadcrumbs';

function BreadcrumbsTastic({ data }) {
  if (!data?.data?.dataSource) return <></>;

  const { items } = data.data.dataSource;

  const category = items && items.length > 0 ? items[0] : null;

  if (!category) {
    return null;
  }

  return <Breadcrumbs category={category} background={data.background} />;
}

export default BreadcrumbsTastic;
