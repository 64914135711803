import React from 'react';
import Hero from 'components/snaq-ui/content/hero';

const HeroTastic = ({ data }) => {
  return (
    <Hero
      image={data.image}
      imageMobile={data.imageMobile}
      title={data.title}
      subtitle={data.subtitle}
      ctaLabel={data.ctaLabel}
      ctaReference={data.ctaReference}
      ctaTitle={data.ctaTitle}
      ctaRel={data.ctaRel}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
};

export default HeroTastic;
