import BrandSelection from '../../../../../components/snaq-ui/brands/brand-selection';

function BrandSelectionTastic({ id, data }) {
  if (!Array.isArray(data?.data?.dataSource) || data.data.dataSource.length === 0) return <></>;

  return (
    <BrandSelection
      tasticId={id}
      title={data.title}
      brands={data.data.dataSource}
      ctaLabel={data.ctaLabel}
      ctaReference={data.ctaReference}
      ctaTitle={data.ctaTitle}
      ctaRel={data.ctaRel}
      width={data.width}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
}

export default BrandSelectionTastic;
