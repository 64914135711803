import { EmptyState } from 'components/snaq-ui/empty-state';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference } from 'helpers/reference';
import { NextFrontasticImage } from 'frontastic/lib/image';

interface Props {
  pageTitle?: string;
  image?: NextFrontasticImage;
  title?: string;
  subtitle?: string;
  ctaLabel?: string;
  ctaLink?: Reference;
}

const EmptyCart = ({ pageTitle, image, title, subtitle, ctaLabel, ctaLink }: Props) => {
  //i18n messages
  const { formatMessage } = useFormat({ name: 'cart' });

  return (
    <EmptyState
      pageTitle={pageTitle ? pageTitle : formatMessage({ id: 'cart.shopping', defaultMessage: 'Shopping Cart' })}
      title={title}
      subtitle={
        subtitle
          ? subtitle
          : formatMessage({ id: 'cart.empty', defaultMessage: 'Currently you have no items in your cart' })
      }
      callToAction={
        ctaLabel ? ctaLabel : formatMessage({ id: 'cart.start.shopping', defaultMessage: 'Start Shopping' })
      }
      callToActionLink={ctaLink}
      image={image}
    />
  );
};

export default EmptyCart;
