import Typography from 'components/snaq-ui/typography';
import { Reference, ReferenceLink } from 'helpers/reference';

export interface ButtonProps {
  ctaLabel: string;
  ctaReference: Reference;
  ctaTitle?: string;
  ctaRel: string;
  alignment: string;
  background: string;
  paddingTop: string;
  paddingBottom: string;
}

const Button: React.FC<ButtonProps> = ({
  ctaLabel,
  ctaReference,
  ctaTitle,
  ctaRel,
  alignment,
  background,
  paddingTop,
  paddingBottom,
}) => {
  return (
    <div className={`d-flex ${alignment} ${background} ${paddingTop} ${paddingBottom}`}>
      <ReferenceLink target={ctaReference} title={ctaTitle || ''} rel={ctaRel} className="btn btn-primary">
        <Typography>{ctaLabel}</Typography>
      </ReferenceLink>
    </div>
  );
};

export default Button;
