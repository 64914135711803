import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-heart-filled ${className}`}
    viewBox="0 0 40 36.7"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20,36.7l-2-1.9c-3.5-3.2-6.5-6-8.8-8.4S5.1,22,3.7,20.1s-2.3-3.5-2.9-5S0,12.1,0,10.6C0,7.6,1,5,3,3s4.5-3,7.5-3,c1.9,0,3.7,0.5,5.3,1.4s3,2.2,4.2,3.9c1.4-1.8,2.9-3.1,4.5-4s3.2-1.3,5-1.3c3,0,5.5,1,7.5,3c2,2,3,4.5,3,7.5c0,1.5-0.3,3.1-0.8,4.6,c-0.5,1.5-1.5,3.2-2.9,5c-1.4,1.9-3.2,4-5.5,6.3s-5.2,5.1-8.8,8.4L20,36.7z" />
  </svg>
);

export default Icon;
