import React from 'react';
import { default as IconLogout } from 'components/icons/logout';
import { useFormat } from 'helpers/hooks/useFormat';
import useHash from 'helpers/hooks/useHash';
import { Reference } from 'helpers/reference';
import { logout } from 'frontastic/actions/account';
import { AddressesSection, GeneralSection, SecuritySection, OrdersHistorySection } from './sections/exporter';
import { useAccount } from '../../../../frontastic';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export interface AccountDetailsProps {
  loginLink?: Reference;
  contactLink?: Reference;
}

const AccountDetails: React.FC<AccountDetailsProps> = ({ contactLink }) => {
  //i18n messages
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const { account } = useAccount();

  //current window hash
  const hash = useHash();

  //tabs
  const tabs = [
    { name: formatAccountMessage({ id: 'general', defaultMessage: 'General' }), href: '#' },
    { name: formatAccountMessage({ id: 'my.addresses', defaultMessage: 'My addresses' }), href: '#addresses' },
    { name: formatAccountMessage({ id: 'orders.history', defaultMessage: 'Order history' }), href: '#orders' },
    { name: formatAccountMessage({ id: 'password.change', defaultMessage: 'Change password' }), href: '#security' },
  ];

  //tabs change (mobile only)
  const handleTabChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    window.location.hash = e.target.value;
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <div className="section-spacing">
        <h1 className="mb-40">{formatAccountMessage({ id: 'settings', defaultMessage: 'Settings' })}</h1>
        <div className="row">
          <div className="col-12 col-md-4 pe-xl-40">
            <div className="bg-grey-10 p-20 pb-md-0 mb-30 mb-md-0 rounded-top">
              <p className="mob-p-p2 dt-p-p2 fw-bold mb-1">
                {account?.firstName
                  ? formatAccountMessage({ id: 'hello', defaultMessage: 'Hi, ' }) + account.firstName
                  : account.lastName
                  ? formatAccountMessage({ id: 'hello', defaultMessage: 'Hi, ' }) + account.lastName
                  : formatAccountMessage({ id: 'hello', defaultMessage: 'Hi, ' }) +
                    formatAccountMessage({ id: 'user', defaultMessage: 'User ' })}
              </p>

              {/* Mobile tab select */}
              <div className="d-md-none">
                <label htmlFor="selected-tab" className="visually-hidden">
                  {formatMessage({ id: 'select.tab', defaultMessage: 'Select a tab' })}
                </label>
                <select
                  id="selected-tab"
                  name="selected-tab"
                  className="form-select mt-2"
                  value={tabs.find((tab) => tab.href === hash).href}
                  onChange={handleTabChange}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name} value={tab.href}>
                      {tab.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Desktop tab select */}
              <nav className="d-none d-md-flex flex-md-column py-md-4">
                {tabs.map((tab, i) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      tab.href === hash ? 'text-primary' : '',
                      i + 1 !== tabs.length ? 'border-bottom mb-3 pb-3' : '',
                      'link-reset d-block mob-p-p2 dt-p-p2',
                    )}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>

            {/* Desktop Logout Button */}
            <div className="bg-grey-10 p-20 rounded-bottom d-none d-md-block">
              <button onClick={handleLogout} className="btn btn-primary w-100">
                {IconLogout({})}
                {formatAccountMessage({ id: 'signout', defaultMessage: 'Logout' })}
              </button>
            </div>
          </div>
          <div className="col-12 col-md-8">
            {/* Tab content */}
            {hash === '#' && (
              <div className="grow">
                <GeneralSection contactLink={contactLink} />
              </div>
            )}
            {hash === '#addresses' && (
              <div className="grow">
                <AddressesSection />
              </div>
            )}
            {hash === '#security' && (
              <div className="grow">
                <SecuritySection />
              </div>
            )}
            {hash === '#orders' && (
              <div className="grow">
                <OrdersHistorySection />
              </div>
            )}
            {/* Mobile Logout Button */}
            <div className="d-md-none text-end pt-20">
              <button onClick={handleLogout} className="btn btn-primary btn-sm">
                {IconLogout({})}
                {formatAccountMessage({ id: 'signout', defaultMessage: 'Logout' })}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDetails;
