import SubCategoryList from '../../../../../components/snaq-ui/categories/subcategory-list';

function SubCategoryListTastic({ id, data }) {
  if (!data?.data?.dataSource) return <></>;
  const background = data?.background;
  const innerSpacing = data?.innerSpacing;
  const outerSpacing = data?.outerSpacing;

  const { items, total } = data.data.dataSource;

  return (
    <SubCategoryList
      background={background}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
      tasticId={id}
      categories={items}
      totalCategories={total}
    />
  );
}

export default SubCategoryListTastic;
