const CookieDeclarationTastic = () => {
  return (
    <script
      id="CookieDeclaration"
      src="https://consent.cookiebot.com/cf9fa8f5-6fa0-4ac0-9d0f-5ff3a3e2c75a/cd.js"
      type="text/javascript"
      async
    />
  );
};

export default CookieDeclarationTastic;
