import React from 'react';
import CategorySeo from 'components/snaq-ui/categories/category-seo';

const CategorySeoTastic = ({ data }) => {
  const category = data?.data?.dataSource;
  if (!category) return <></>;
  const background = data?.background;
  const innerSpacing = data?.innerSpacing;
  const outerSpacing = data?.outerSpacing;
  return (
    <CategorySeo
      background={background}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
      description={category.long_description}
    />
  );
};

export default CategorySeoTastic;
