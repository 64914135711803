import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import ExpertTeasers from '../../../../../components/snaq-ui/content/expert-teasers';
import { Reference } from '../../../../../helpers/reference';
import { MediaItemWithMods } from '../../../../lib/image';

export interface PageNodeTeaser {
  image: MediaItemWithMods;
  title: string;
  description?: string;
  v2image?: MediaItemWithMods;
  v2title?: string;
  v2description?: string;
  v3image?: MediaItemWithMods;
  v3title?: string;
  v3description?: string;
  email?: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  ctaTitle?: string;
  ctaRel?: string;
  date?: string;
}

export const buildPagesListByCategory = (nodes, locale, currentCategoryKey, ctaLabel = 'Read') => {
  const listItems: PageNodeTeaser[] = [];

  if (!nodes) {
    return listItems;
  }

  nodes.forEach((node) => {
    if (
      !node.hasLivePage ||
      !node?.configuration?.teaserMedia ||
      !node?.configuration?.teaserTitle ||
      !node?.configuration?.categoryKeys ||
      !node.configuration.categoryKeys.some((categoryItem) => categoryItem.categoryKey === currentCategoryKey)
    ) {
      return;
    }

    const _urlObj: [key: string, value: any] | undefined = Object.entries(node._urls).find(
      (item: [key: string, value: string]) => locale === item[0].split(/[^A-Za-z]/)[0],
    );

    const ctaReference: Reference | null =
      _urlObj && typeof _urlObj[1] === 'string'
        ? {
            type: 'page-folder',
            pageFolder: {
              pageFolderId: node.pageFolderId,
              name: node.name,
              hasLivePage: node.hasLivePage,
              _urls: {
                ...node._urls,
              },
              _url: _urlObj[1],
            },
            openInNewWindow: false,
          }
        : null;

    listItems.push({
      date: node.configuration.propsDate ?? undefined,
      image: node.configuration.teaserMedia,
      title: node.configuration.teaserTitle ?? '',
      description: node.configuration.teaserDescription ?? undefined,
      v2image: node.configuration.teaser2Media ?? undefined,
      v2title: node.configuration.teaser2Title ?? undefined,
      v2description: node.configuration.teaser2Description ?? undefined,
      v3image: node.configuration.teaser3Media ?? undefined,
      v3title: node.configuration.teaser3Title ?? undefined,
      v3description: node.configuration.teaser3Description ?? undefined,
      email: node.configuration.contactEmail ?? undefined,
      ctaLabel: ctaLabel,
      ctaReference: ctaReference ?? undefined,
    });

    if (node.children.length > 0) {
      const childNodes = buildPagesListByCategory(node.children, locale, currentCategoryKey, ctaLabel);

      childNodes.forEach((childNode) => listItems.push(childNode));
    }
  });

  return listItems;
};

const CategoryExpertsTastic = ({ id, data }) => {
  const { title, expertsFolder, categoryData, background, innerSpacing, outerSpacing } = data;
  const router = useRouter();

  const expertTeasers = useMemo(() => {
    const currentCategoryKey = categoryData?.dataSource?.categoryId;

    const items: PageNodeTeaser[] = buildPagesListByCategory(
      expertsFolder?.children,
      router?.locale,
      currentCategoryKey,
    );

    return items
      .filter((item) => !!item.email)
      .sort((itemA, itemB) =>
        itemA.date && itemB.date ? new Date(itemB.date).getTime() - new Date(itemA.date).getTime() : 0,
      );
  }, [expertsFolder, categoryData]);

  if (expertTeasers.length === 0) {
    return null;
  }

  return (
    <ExpertTeasers
      tasticId={id}
      background={background}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
      size="large"
      title={title}
      items={expertTeasers}
      sliderSettings={{
        slidesPerView: 1,
        dots: true,
        arrows: true,
        arrowsAlways: true,
        scrollbar: false,
        freeMode: false,
      }}
    />
  );
};

export default CategoryExpertsTastic;
