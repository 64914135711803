import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-local-shipping ${className}`}
    viewBox="0 0 27 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.7 19c-1 0-1.8-.3-2.5-1s-1-1.5-1-2.5c-.6 0-1.1-.3-1.5-.7-.4-.4-.6-1-.6-1.6V2.3c0-.6.2-1.2.7-1.6.3-.5.9-.7 1.5-.7h15c.6 0 1.2.2 1.6.7.4.4.7 1 .7 1.6v2.5h2.8c.2 0 .4 0 .5.1.1.1.2.2.3.4l3.5 4.7c.1.1.1.2.2.3 0 .1.1.2.1.4v3.7c0 .3-.1.6-.3.8-.2.2-.5.3-.8.3h-1.1c0 1-.3 1.8-1 2.5s-1.5 1-2.5 1-1.8-.3-2.5-1-1-1.5-1-2.5H9.3c0 1-.3 1.8-1 2.5-.8.7-1.6 1-2.6 1zm0-1.9c.5 0 .9-.2 1.2-.5.3-.3.5-.7.5-1.2s-.2-.8-.5-1.2c-.3-.3-.7-.5-1.2-.5s-.9.2-1.2.5c-.3.4-.5.8-.5 1.3s.2.8.5 1.2c.4.2.7.4 1.2.4zM1.9 2.3v11s0 .1.1.1l.1.1h.7c.3-.5.7-.9 1.2-1.2.5-.3 1.1-.5 1.7-.5.6 0 1.2.2 1.7.5s.9.7 1.2 1.2h9.1V2.3c0-.1 0-.2-.1-.3-.1-.1-.2-.1-.3-.1h-15c-.1 0-.2 0-.3.1-.1.1-.1.2-.1.3zm19.3 14.8c.5 0 .9-.2 1.2-.5.3-.3.5-.7.5-1.2s-.2-.8-.5-1.2c-.3-.3-.7-.5-1.2-.5s-.9.2-1.2.5c-.3.3-.5.7-.5 1.2s.2.8.5 1.2c.3.3.7.5 1.2.5zM19.5 11h5.7l-3.3-4.3h-2.4V11z" />
  </svg>
);

export default Icon;
