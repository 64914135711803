import React from 'react';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import { xs, md, xl } from '../../../../helpers/utils/screensizes';
import Slider, { SliderProps } from '../../slider';

export type Testimonial = {
  name: string;
  rating: number;
  quote: string;
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
};

export default function Testimonials({ tasticId, title, items, background, innerSpacing, outerSpacing }) {
  const [isMobileSize] = useMediaQuery(xs);
  const [isTabletSize] = useMediaQuery(md);
  const [isDesktopSize] = useMediaQuery(xl);

  const { formatMessage } = useFormat({ name: 'common' });

  const sliderFixedMood: SliderProps = {
    slidesPerView: isMobileSize ? (isTabletSize ? (isDesktopSize ? 4 : 3) : 2) : 1,
    arrows: isMobileSize ? true : false,
    dots: isMobileSize ? false : true,
  };

  const sliderConfiguration: SliderProps = sliderFixedMood;

  return (
    <div className={`testimonials ${background} ${innerSpacing} ${outerSpacing}`}>
      {title && (
        <div className="mb-8 w-auto text-center">
          <h2 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-light-100">{title}</h2>
        </div>
      )}
      <div className="relative -mb-6 w-full overflow-x-auto pb-6">
        <Slider {...sliderConfiguration}>
          {items.map((item: Testimonial, index: number) => (
            <div key={`${tasticId}${index}`} className="">
              <blockquote className="rounded bg-amber-100 p-3">
                <p className="text-xl font-bold italic">&quot;{item.quote}&quot;</p>
                {item.rating >= 0 && (
                  <p>
                    {formatMessage({
                      id: 'rating',
                      defaultMessage: 'Rating',
                    })}
                    : {item.rating}/5
                  </p>
                )}
                <cite>{item.name}</cite>
              </blockquote>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
