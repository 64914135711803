import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import ExpertList, { ExpertItem } from '../../../../../components/snaq-ui/content/expert-list';

export const buildExpertList = (nodes, locale) => {
  const listItems: ExpertItem[] = [];

  if (!nodes) {
    return listItems;
  }

  nodes.forEach((node) => {
    if (
      !node.hasLivePage ||
      !node?.configuration?.teaserMedia ||
      !node?.configuration?.teaserTitle ||
      !node?.configuration?.contactEmail
    ) {
      return;
    }

    listItems.push({
      date: node.configuration.propsDate ?? undefined,
      image: node.configuration.teaserMedia,
      title: node.configuration.teaserTitle ?? '',
      description: node.configuration.teaserDescription ?? undefined,
      email: node.configuration.contactEmail ?? undefined,
    });

    if (node.children.length > 0) {
      const childNodes = buildExpertList(node.children, locale);

      childNodes.forEach((childNode) => listItems.push(childNode));
    }
  });

  return listItems;
};

const ExpertListTastic = ({ id, data }) => {
  const { title, expertsFolder, background, innerSpacing, outerSpacing } = data;
  const router = useRouter();

  const expertTeasers = useMemo(() => {
    const items: ExpertItem[] = buildExpertList(expertsFolder?.children, router?.locale);

    return items.sort((itemA, itemB) =>
      itemA.date && itemB.date ? new Date(itemB.date).getTime() - new Date(itemA.date).getTime() : 0,
    );
  }, [expertsFolder, router]);

  if (expertTeasers.length === 0) {
    return null;
  }

  return (
    <ExpertList
      tasticId={id}
      background={background}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
      title={title}
      items={expertTeasers}
    />
  );
};

export default ExpertListTastic;
