import { FC, useCallback, useMemo } from 'react';
import { Disclosure } from '@headlessui/react';
import { Product } from '@Types/product/Product';
import { Facet } from '@Types/result/Facet';
import { TermFacet } from '@Types/result/TermFacet';
import { default as IconMinus } from 'components/icons/minus';
import { default as IconPlus } from 'components/icons/plus';
import { URLParam } from 'helpers/utils/updateURLParams';
import TermFilter, { TermFilterParams } from '../term-filter';

type TermFilterDisclosureProps = {
  termKey: string;
  facets: Facet[];
  products: Product[];
  updateFilteringParams: (termKey: string, params: URLParam[]) => void;
  defaultOpen?: boolean;
  isBoolean?: boolean;
};

const TermFilterDisclosure: FC<TermFilterDisclosureProps> = ({
  termKey,
  facets,
  products,
  updateFilteringParams,
  defaultOpen = false,
  isBoolean = false,
}) => {
  const termFacet = useMemo(
    () => facets.find((facet) => facet.identifier === `variants.attributes.${termKey}`) as TermFacet,
    [termKey, facets],
  );

  // CHECK: Unclear if this is an appropriate place and method to determine the termLabel
  // NOTE: "official" commercetoolsTermFacetResultToTermFacet solution useless, since it just maps the facetKey to termFacet.label
  const termLabel: string | null = useMemo(() => {
    let label: string | null = null;
    for (const product of products) {
      if (label !== null) {
        break;
      }
      for (const variant of product.variants) {
        if (label !== null) {
          break;
        }
        for (const attributeLabelIndex in variant.attributeLabels) {
          const attributeLabel = variant.attributeLabels[attributeLabelIndex];
          if (attributeLabel.key === termKey) {
            label = attributeLabel.label;
            break;
          }
        }
      }
    }
    return label;
  }, [termKey, products]);

  const handleChange = useCallback(
    (values: Array<TermFilterParams>) =>
      updateFilteringParams(
        termKey,
        values.map(({ index, value }) => ({
          key: `facets[variants.attributes.${termKey}][terms][${index.toString()}]`,
          value,
        })),
      ),
    [termKey, updateFilteringParams],
  );

  if (!termLabel || !termFacet?.terms?.length) return <></>;

  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button className="disclosure-filter-btn">
            {termLabel}
            {open ? IconMinus({}) : IconPlus({})}
          </Disclosure.Button>
          <Disclosure.Panel className="disclosure-filter-panel">
            <div className="disclosure-filter-panel__inner">
              <TermFilter facet={termFacet} onChange={handleChange} isBoolean={isBoolean} />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default TermFilterDisclosure;
