import React, { FC, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { TwoThumbInputRange } from 'react-two-thumb-input-range';
import { useWindowSize } from 'usehooks-ts';
import Typography from '../typography';

type RangeInputValues = [number, number];

export interface RangeFacet {
  min?: number;
  max?: number;
  count?: number;
  minSelected?: number | null;
  maxSelected?: number | null;
}

export type RangeProps = {
  facet: RangeFacet;
  onChange: (values: [number, number]) => void;
  termKey?: string;
  decimalPlaces?: number;
  unit?: string;
};

function countDecimalPlaces(number): number | null {
  if (!number || isNaN(number)) {
    return null;
  }
  const decimalIndex = number.toString().indexOf('.');
  return decimalIndex >= 0 ? number.toString().length - decimalIndex - 1 : 0;
}

const RangeFilter: FC<RangeProps> = ({ facet, onChange, termKey, decimalPlaces, unit }) => {
  const { min, max, minSelected, maxSelected } = facet as RangeFacet;
  const { width: windowWidth } = useWindowSize();
  const widthRef = useRef(null);
  const [initializing, setInitializing] = useState<boolean>(false);
  const [inputWidth, setInputWidth] = useState<number>(0);
  const [minValue, setMinValue] = useState<number | null>(null);
  const [maxValue, setMaxValue] = useState<number | null>(null);
  const [values, setValues] = useState<RangeInputValues>([minValue, maxValue]);
  const [scope, setScope] = useState<number>(1);
  const [decimals, setDecimals] = useState<number>(0);
  const router = useRouter();

  useEffect(() => {
    if (
      countDecimalPlaces(min) === 3 ||
      countDecimalPlaces(max) === 3 ||
      countDecimalPlaces(minSelected) === 3 ||
      countDecimalPlaces(maxSelected) === 3 ||
      decimalPlaces === 3
    ) {
      setScope(1000);
      setDecimals(3);
    } else if (
      countDecimalPlaces(min) === 2 ||
      countDecimalPlaces(max) === 2 ||
      countDecimalPlaces(minSelected) === 2 ||
      countDecimalPlaces(maxSelected) === 2 ||
      decimalPlaces === 2
    ) {
      setScope(100);
      setDecimals(2);
    } else if (
      countDecimalPlaces(min) === 1 ||
      countDecimalPlaces(max) === 1 ||
      countDecimalPlaces(minSelected) === 1 ||
      countDecimalPlaces(maxSelected) === 1 ||
      decimalPlaces === 1
    ) {
      setScope(10);
      setDecimals(1);
    }
    if (termKey === 'ecom_channels') {
      setScope(1);
      setDecimals(0);
    }
    if (termKey === 'ecom_weight_number') {
      setScope(1000);
      setDecimals(2);
    }
    if (termKey === 'ecom_screen_size_number') {
      setScope(100);
      setDecimals(2);
    }
    if (termKey === 'ecom_weight_camera_body_number') {
      setScope(1000);
      setDecimals(2);
    }
    if (termKey === 'ecom_payload_max_number') {
      setScope(1000);
      setDecimals(0);
    }
  }, [min, max, minSelected, maxSelected, decimalPlaces, termKey]);

  useEffect(() => {
    setInputWidth(0);
    setTimeout(() => {
      if (widthRef.current) {
        setInputWidth(widthRef.current.clientWidth);
      }
    }, 200);
  }, [widthRef, windowWidth]);

  useEffect(() => {
    setInitializing(true);
    if (!max) {
      return;
    }

    setMinValue(min * scope);
    setMaxValue(max * scope);

    // Setting default values
    if (maxSelected) {
      const newMin = minSelected >= min ? minSelected : min;
      const newMax = maxSelected <= max ? maxSelected : max;
      if (newMin < newMax) {
        setValues([newMin * scope, newMax * scope]);
      } else {
        setValues([min * scope, max * scope]);
      }
    } else {
      setValues([min * scope, max * scope]);
    }
    setTimeout(() => {
      setInitializing(false);
    }, 200);
  }, [scope, min, max, minSelected, maxSelected]);

  if (values[0] === null || values[1] === null) {
    return null;
  }

  return (
    <>
      <div className="price-range-filter" ref={widthRef}>
        <>
          <div>
            <Typography>
              {Intl.NumberFormat(router?.locale || router?.defaultLocale, {
                maximumFractionDigits: decimals,
                minimumFractionDigits: decimals,
              }).format(values[0] / scope)}
            </Typography>
            {unit && <span> {unit}</span>}
          </div>
          <div className="price-range-filter__spacer"></div>
          <div>
            <Typography>
              {Number(values[1] / scope)
                .toLocaleString(router?.locale || router?.defaultLocale, {
                  maximumFractionDigits: decimals,
                  minimumFractionDigits: decimals,
                })
                .toString()}
            </Typography>
            {unit && <span> {unit}</span>}
          </div>
        </>
      </div>
      {!initializing && (
        <TwoThumbInputRange
          thumbStyle={{ borderRadius: '100%' }}
          inputStyle={{ width: inputWidth }}
          trackColor="#333"
          railColor="#e0e0e0"
          thumbColor="#333"
          showLabels={false}
          onChange={(updatedValues: RangeInputValues) => {
            if (updatedValues[0] < updatedValues[1]) {
              setValues(updatedValues);
              onChange([updatedValues[0] / scope, updatedValues[1] / scope]);
            }
          }}
          values={values}
          min={minValue}
          max={maxValue}
        />
      )}
    </>
  );
};

export default RangeFilter;
