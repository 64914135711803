import React from 'react';
import Button from '../../../../../components/snaq-ui/content/button';

const ButtonTastic = ({ data }) => {
  return (
    <Button
      ctaLabel={data.ctaLabel}
      ctaReference={data.ctaReference}
      ctaTitle={data.ctaTitle}
      ctaRel={data.ctaRel}
      alignment={data.alignment}
      background={data.background}
      paddingTop={data.paddingTop}
      paddingBottom={data.paddingBottom}
    />
  );
};

export default ButtonTastic;
