import React from 'react';

type Props = {
  className?: string;
  onClick?: () => void;
};

const Icon: React.FC<Props> = ({ className = '', onClick }: Props) => (
  <svg
    className={`icon icon-paypal-text ${className}`}
    viewBox="0 0 117.6 31.7"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      fill="#0070e0"
      d="M76.2 17.9c5 0 9.2-3.6 10-8.6.8-5-3.1-9.3-8.6-9.3h-8.9c-.3 0-.6.2-.7.6l-4 25.1c0 .3.1.6.4.7.1 0 .2.1.3.1h4.7c.3 0 .6-.2.7-.6l1.2-7.4c.1-.3.3-.6.7-.6h4.2zm4-8.8c-.3 1.8-1.7 3.2-4.4 3.2h-3.5l1.1-6.7h3.4c2.7 0 3.7 1.7 3.4 3.5zm26.3-1.2h-4.4c-.3 0-.6.2-.7.6l-.1.9s-3.5-3.8-9.6-1.2c-3.5 1.5-5.2 4.5-5.9 6.7 0 0-2.3 6.7 2.9 10.4 0 0 4.8 3.6 10.1-.2l-.1.6c0 .3.1.6.4.7.1 0 .2.1.3.1h4.5c.3 0 .6-.2.7-.6l2.7-17.2c0-.2 0-.4-.1-.5-.3-.2-.5-.3-.7-.3zm-6.5 9.5c-.4 2.5-2.5 4.3-5 4.3-.5 0-.9-.1-1.4-.2-1.9-.6-3-2.5-2.7-4.5.4-2.5 2.5-4.3 5-4.3.5 0 .9.1 1.4.2 1.9.6 3 2.5 2.7 4.5zM111.5.6l-4 25.1c0 .3.1.6.4.7.1 0 .2.1.3.1h4.7c.3 0 .6-.2.7-.6l4-25.1c0-.2 0-.4-.1-.5-.1-.1-.3-.2-.5-.2h-4.7c-.4-.1-.7.1-.8.5z"
    />
    <path
      fill="#003087"
      d="M12.1 17.9c5 0 9.2-3.6 10-8.6.8-5-3.1-9.3-8.6-9.3H4.6c-.3 0-.6.3-.6.6L0 25.7c0 .3.2.6.5.7h4.8c.3 0 .6-.2.7-.6l1.2-7.4c.1-.3.3-.6.7-.6 0 .1 4.2.1 4.2.1zm4-8.8c-.3 1.8-1.7 3.2-4.4 3.2H8.2l1.1-6.7h3.4c2.7 0 3.6 1.7 3.4 3.5zm26.3-1.2H38c-.3 0-.6.2-.7.6l-.1.9s-3.5-3.8-9.6-1.2c-3.5 1.5-5.2 4.5-5.9 6.7 0 0-2.3 6.7 2.9 10.4 0 0 4.8 3.6 10.1-.2l-.1.6c-.1.4.2.7.5.7h4.6c.3 0 .6-.2.7-.6l2.7-17.2c0-.3-.2-.6-.7-.7.1 0 .1 0 0 0zm-6.5 9.5c-.4 2.5-2.5 4.3-5 4.3-.5 0-.9-.1-1.4-.2-1.9-.6-3-2.5-2.7-4.5.4-2.5 2.5-4.3 5-4.3.5 0 .9.1 1.4.2 1.9.6 3 2.5 2.7 4.5zm22.9-9.1-5.4 9.1-2.7-9c-.1-.3-.4-.5-.7-.5h-4.8c-.3 0-.5.2-.5.5v.2l4.9 15.1-4.4 7.1c-.2.2-.1.6.2.7.1.1.2.1.3.1h5.2c.3 0 .6-.2.7-.4L65.2 8.7c.1-.2.1-.6-.2-.7-.1-.1-.2-.1-.3-.1h-5.2c-.3 0-.6.2-.7.4z"
    />
  </svg>
);

export default Icon;
