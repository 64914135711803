import React from 'react';
import { default as IconHeart } from 'components/icons/heart';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';

interface WishListButtonProps {
  wishlistItemCount?: number;
  wishlistLink?: Reference;
}

const WishListButton: React.FC<WishListButtonProps> = ({ wishlistItemCount, wishlistLink }) => {
  //i18n messages
  const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });

  return (
    <div className="short-link">
      <ReferenceLink target={wishlistLink} className="short-link__item">
        <span className="short-link__icon">
          {IconHeart({})}

          {wishlistItemCount > 0 && (
            <span className="short-link__counter">
              {wishlistItemCount}
              <span className="visually-hidden">
                {formatWishlistMessage({
                  id: 'wishlist.items.in.view',
                  defaultMessage: 'items in wishlist, view wishlist',
                })}
              </span>
            </span>
          )}
        </span>
        <div className="short-link__text">
          {formatWishlistMessage({
            id: 'wishlist',
            defaultMessage: 'Wishlist',
          })}
        </div>
      </ReferenceLink>
    </div>
  );
};

export default WishListButton;
