import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import ProductReview from '../../../../../components/snaq-ui/products/product-review';

function ProductReviewTastic({ data }) {
  const router = useRouter();
  const {
    product,
  }: {
    product: Product;
  } = data?.data?.dataSource;

  const currentVariantIdx = useMemo<number>(() => {
    const currentVariantSKU = router.asPath.match(/^.+(_|-)(\d{7})\??.*$/)[2];
    return product?.variants.findIndex(({ sku }) => sku === currentVariantSKU) ?? 0;
  }, [product, router.asPath]);

  const variant = useMemo<Variant>(() => product?.variants[currentVariantIdx], [product, currentVariantIdx]);

  if (!variant) return <></>;

  return <ProductReview variant={variant} />;
}

export default ProductReviewTastic;
