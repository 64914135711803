import React, { useEffect, useState } from 'react';
import { Cart } from '@Types/cart/Cart';
import { Discount } from '@Types/cart/Discount';
import { default as IconClose } from 'components/icons/close';
import { useCart } from 'frontastic/provider';
import { useFormat } from '../../../helpers/hooks/useFormat';

export interface Props {
  className?: string;
  cart: Cart;
}

const DiscountList: React.FC<Props> = ({ className, cart }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const { removeDiscountCode } = useCart();

  useEffect(() => {
    setDiscounts(cart?.discountCodes);
  }, [cart?.discountCodes]);

  const handleRemove = (discount) => {
    removeDiscountCode(discount);
  };

  if (!discounts || discounts.length === 0) {
    return null;
  }

  return (
    <ul className={`${className}`}>
      {discounts.map((discount) => (
        <li key={discount.discountId} className="d-flex justify-content-between align-items-center mt-2">
          <div className="m-0 me-2">
            <div className="mob-c-c3 dt-c-c3">{discount.code}</div>
            {discount.description && <div className="mt-1 mob-f-f4 dt-f-f4">{discount.description}</div>}
          </div>
          <button
            type="button"
            onClick={() => handleRemove(discount)}
            className="ms-auto bg-transparent border rounded-1"
            title={formatCartMessage({ id: 'remove', defaultMessage: 'Remove' })}
          >
            {IconClose({ className: 'h-px-12' })}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default DiscountList;
