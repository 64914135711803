import { ProductAsset } from '@Types/product/ProductAsset';
import { UIAsset } from '../../components/snaq-ui/products/product-details';

// Get mapped assets filtered by content-type
export const getAssets = (productAssets: ProductAsset[], contentType: string) => {
  const assets: UIAsset[] = [];
  productAssets.forEach((productAsset) => {
    const source = productAsset.sources.find((source) => source.contentType === contentType);
    if (source) {
      assets.push({
        key: productAsset.key,
        source: source,
        name: productAsset.name,
        description: productAsset.description || undefined,
        tags: [...productAsset.tags],
      });
    }
  });
  return assets;
};
