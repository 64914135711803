import React, { Fragment, useMemo } from 'react';
import NextLink from 'next/link';
import { Brand } from '@Types/snaq/product/Brand';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const navLetters = '0-9,A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z'.split(',');
export const brandRouteSlug = 'marken';

export interface Props {
  tasticId: string;
  title: string;
  brands: Brand[];
  background?: string;
  innerSpacing?: string;
  outerSpacing?: string;
}
const BrandList: React.FC<Props> = (props) => {
  const { tasticId, title, brands, background, innerSpacing, outerSpacing } = props;

  const brandGroups = useMemo(() => {
    const groups = {};
    brands
      .filter((brand) => !brand.hideBrand || brand.hideBrand !== '1')
      .sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .forEach((brand) => {
        let navGroup = brand.name.charAt(0).toUpperCase();
        if (navGroup === navGroup.toLowerCase()) {
          navGroup = '0-9';
        }
        if (!Object.keys(groups).includes(navGroup)) {
          groups[navGroup] = [];
        }
        groups[navGroup].push(brand);
      });
    return groups;
  }, [brands]);

  return (
    <section className={`${background} ${innerSpacing} ${outerSpacing}`}>
      {title && <h1 className="mb-40 text-center">{title}</h1>}
      <ul className="d-flex justify-content-lg-center rounded p-20 bg-grey-10 mb-40 overflow-auto text-nowrap list-unstyled">
        {navLetters.map((letter) => (
          <li key={`${tasticId}nav${letter}`} className="my-1 mx-2 mx-xl-10">
            {Object.keys(brandGroups).includes(letter) ? (
              <a href={'#brands-' + letter.toLowerCase()} className="link-reset mob-p-p2 dt-p-p2">
                <strong>{letter}</strong>
              </a>
            ) : (
              <span>{letter}</span>
            )}
          </li>
        ))}
      </ul>
      {navLetters.map((letter) => {
        if (!brandGroups[letter]) {
          return null;
        }
        return (
          <Fragment key={`${tasticId}row${letter}`}>
            <h5 id={'brands-' + letter.toLowerCase()} className="scroll-margin">
              {letter}
            </h5>
            <hr />
            <Row as="ul" className="list-unstyled mb-5">
              {brandGroups[letter].map((brand: Brand, index: number) => (
                <Col key={`${tasticId}row${letter}col${index}`} as="li" xs={12} sm={6} md={4} lg={3}>
                  <p>
                    <NextLink href={brandRouteSlug + '/' + brand.key}>
                      <a className="link-reset">{brand.name}</a>
                    </NextLink>
                  </p>
                </Col>
              ))}
            </Row>
          </Fragment>
        );
      })}
    </section>
  );
};

export default BrandList;
