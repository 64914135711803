import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className = '' }: Props) => (
  <svg
    className={`icon icon-edit-square ${className}`}
    viewBox="0 0 41.4 41.5"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3,41.5c-0.8,0-1.5-0.3-2.1-0.9C0.3,40,0,39.2,0,38.5v-30C0,7.7,0.3,7,0.9,6.3S2.2,5.5,3,5.5h20.2l-3,3H3v30h30V21.1l3-3
	v20.4c0,0.8-0.3,1.5-0.9,2.1c-0.6,0.6-1.3,0.9-2.1,0.9H3z M27.1,5.8L29.2,8L15,22.1v4.3h4.2l14.3-14.3l2.1,2.1L20.5,29.5H12V21
	L27.1,5.8z M35.7,14.2l-8.6-8.4l5-5C32.7,0.3,33.4,0,34.2,0c0.8,0,1.6,0.3,2.1,0.9l4.2,4.2c0.6,0.6,0.9,1.3,0.9,2.1
	c0,0.8-0.3,1.5-0.9,2.1L35.7,14.2z"
    />
  </svg>
);

export default Icon;
