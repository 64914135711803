import ProductHighlights from 'components/snaq-ui/products/highlights';

function ProductHighlightsTastic({ data }) {
  if (!data?.data?.dataSource?.items) return <p>No products found.</p>;

  return (
    <ProductHighlights
      products={data?.data?.dataSource?.items}
      title={data.title}
      ctaLabel={data.ctaLabel}
      ctaReference={data.ctaReference}
      ctaTitle={data.ctaTitle}
      ctaRel={data.ctaRel}
      background={data.background}
      innerSpacing={data.innerSpacing}
      outerSpacing={data.outerSpacing}
    />
  );
}

export default ProductHighlightsTastic;
